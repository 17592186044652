import React, {useEffect, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import "./ProjectsMultipleSelector.scss";
import {setSelectedTimeZone} from "../../../redux/reducers/timeline";
import {ApiProjectType, ProjectPreset} from "../../../constants/constants";
import {
    getFilterText,
    isDefaultSelection,
    MultipleFilterWithSearch
} from "../Tables/FilterBar/MultipleFilterWithSearchFilter";
import {setActiveProjects, setActiveProjectsExtra} from "../../../redux/reducers/projects";
import {getProjectLabel} from "../../../helpers/Tags";
import {Button, Dropdown, Space} from "antd";
import {DownOutlined, FilterFilled} from "@ant-design/icons";

const AllProjectsItem = {value: ProjectPreset.All, title: "— All Projects —", sortRank: 1, items: []};
const NoProjectsItem = {value: ProjectPreset.None, title: "— No Projects —", sortRank: 2, items: []};

const projectsExtraOptions = [AllProjectsItem, NoProjectsItem];

const mapToProjectsExtra = (val) => projectsExtraOptions.find((i) => i.value === val);

export const mapProjectToOption = (project) => ({
    value: project.Id,
    title: getProjectLabel(project),
    label: getProjectLabel(project),
});

export const isAllProjectsItemSelected = (selectedFilterItems) =>
    selectedFilterItems.find((value) => value === AllProjectsItem.value);
const isNoProjectsItemSelected = (selectedFilterItems) => selectedFilterItems.find((value) => value === NoProjectsItem.value);

export const filterHandler = (selectedProjects = []) => {
    return (item) =>
        item.IsAllProjects
            ? isAllProjectsItemSelected(selectedProjects)
            : (item.Projects.length === 0 && isNoProjectsItemSelected(selectedProjects)) ||
            _.intersection(
                item.Projects.map((p) => p.Id),
                selectedProjects
            ).length > 0;
};

const ProjectMultipleSelector = ({needResetFilter, allProjectsFlag, noProjectsFlag}) => {
    const [isVisible, setVisible] = useState(false);

    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State === ApiProjectType.Active);
    const draftProjects = useSelector((state) => state.projects.projects).filter((p) => p.State === ApiProjectType.Inactive);

    const activeProjects = useSelector((state) => state.projects.activeProjects);
    const defaultProjectsExtra = useSelector((state) => state.projects.activeProjectsExtra);
    const activeProjectsExtra = useSelector((state) =>
        state.projects.activeProjectsExtra.filter(
            (e) => (e === AllProjectsItem.value && allProjectsFlag) || (e === NoProjectsItem.value && noProjectsFlag)
        )
    );

    const getProjects = (ids = []) => [...projects, ...draftProjects].filter((p) => ids.includes(p.Id));
    const getSelectedExtra = (ids = []) => ids.filter((id) => [ProjectPreset.None, ProjectPreset.All].includes(id));

    const projectOptions = projects.map(mapProjectToOption);
    const draftProjectOptions = draftProjects.map(mapProjectToOption);
    const items = [...projectOptions, ...draftProjectOptions];
    const groups = [
        {
            title: "Active Projects",
            items: projectOptions,
            sortRank: 3,
        },
    ];

    if (draftProjectOptions.length > 0) {
        groups.push({
            title: "Draft Projects",
            items: draftProjectOptions,
            sortRank: 4,
        });
    }

    if (allProjectsFlag) {
        groups.push(AllProjectsItem);
        items.push(AllProjectsItem);
    }

    if (noProjectsFlag) {
        groups.push(NoProjectsItem);
        items.push(NoProjectsItem);
    }

    const [sessionItems, setSessionItems] = useState([]);

    const reset = useCallback(() => {
        setSessionItems([...activeProjectsExtra.map(mapToProjectsExtra), ...activeProjects.map(mapProjectToOption)]);
    }, [activeProjects, activeProjectsExtra]);

    useEffect(() => {
        if (activeProjectsExtra.length > 0 || activeProjects.length > 0) {
            reset();
        }
    }, [activeProjectsExtra.length, activeProjects.length]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    function onChanged(selectedItems) {
        setSessionItems(selectedItems);
    }

    const selectAllText = "(select all)";

    const submitFilter = () => {
        const filterValues = sessionItems.map((i) => i.value);
        const selectedProjects = getProjects(filterValues);

        if (allProjectsFlag || noProjectsFlag) {
            let values = getSelectedExtra(filterValues);

            if (!allProjectsFlag) {
                values = [...values, ...defaultProjectsExtra.filter((e) => e === AllProjectsItem.value)];
            }

            if (!noProjectsFlag) {
                values = [...values, ...defaultProjectsExtra.filter((e) => e === NoProjectsItem.value)];
            }

            dispatch(setActiveProjectsExtra(values));
        }
        dispatch(setActiveProjects(selectedProjects));

        if (
            !allProjectsFlag &&
            !noProjectsFlag &&
            sessionItems.length === 1 &&
            (activeProjects.length !== 1 || sessionItems[0].value !== activeProjects[0].value)
        ) {
            dispatch(setSelectedTimeZone(selectedProjects[0].TimeZoneId));
        }

        setVisible(false);
    };

    const cancelFilter = () => {
        setVisible(false);
        setSessionItems([
            ...(allProjectsFlag ? activeProjectsExtra.filter((e) => e === AllProjectsItem.value).map(mapToProjectsExtra) : []),
            ...(noProjectsFlag ? activeProjectsExtra.filter((e) => e === NoProjectsItem.value).map(mapToProjectsExtra) : []),
            ...activeProjects.map(mapProjectToOption),
        ]);
    };

    const selectedItems = [...activeProjectsExtra.map(mapToProjectsExtra), ...activeProjects.map(mapProjectToOption)];

    return (
        <Dropdown
            visible={isVisible}
            overlay={
                <MultipleFilterWithSearch
                    items={items}
                    groups={groups}
                    selectedItems={sessionItems}
                    onChanged={onChanged}
                    search
                    width={"auto"}
                    selectAllText={selectAllText}
                    className={`projects-scroll`}
                    buttons={
                        <div className="right">
                            <Space>
                                <Button onClick={() => cancelFilter()}>Cancel</Button>
                                <Button disabled={sessionItems.length === 0} onClick={() => submitFilter()}
                                        type="primary">
                                    Ok
                                </Button>
                            </Space>
                        </div>
                    }
                />
            }
            className={`multiple-items-filter projects-multiple-selector`}>
            <Button onClick={() => setVisible(true)} type="link" className="btn-multiple-items-filter">
                <span className="filter-text">{getFilterText(items, selectedItems, "All Projects")}</span>
                {isDefaultSelection(items, selectedItems) && <DownOutlined className="filter-text-down"/>}
                {!isDefaultSelection(items, selectedItems) &&
                    <FilterFilled className="active-filter filter-text-down"/>}
            </Button>
        </Dropdown>
    );
};

export default ProjectMultipleSelector;
