import api from "../api";
import store from "../redux/store";
import {setResources, setResourcesLookup, setCollections, setResourceUsages} from "../redux/reducers/resources";
import {expandSources} from "../helpers/api";
import moment from "moment";

const {dispatch} = store;

export const loadResources = (accountId) => {
    const allContacts = store.getState().contacts.contacts || [];

    return api.post(`api/Resource/GetByAccount`, {AccountId: accountId, WithSources: true})
        .then((response) => {
            const items = response?.Items || [];
            items.forEach(m => expandSources(m?.Sources, allContacts));
            dispatch(setResources(items));
            return response;
        });
};

export const refreshResources = () => {
    const accountId = store.getState().projects.activePortfolio.Id;
    return loadResources(accountId);
}

export const loadResourceUsages = (accountId, startDate, endDate) => {
    const payload = {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate
    };

    return api.post(`api/Resource/GetUsageByFilter`, payload)
        .then((response) => {
            dispatch(setResourceUsages(response.Items));
            return response.Items;
        });
};

export const getResourceUsage = (id) => {
    const allContacts = store.getState().contacts.contacts || [];

    return api.get(`api/Resource/GetUsage?id=${id}`)
        .then(response => {
            expandSources(response?.Sources, allContacts);
            return response;
        });
};


export const loadResourcesLookup = (accountId) =>
    api.post(`api/Resource/GetByAccount`, {AccountId: accountId, WithSources: false})
        .then((response) => {
            dispatch(setResourcesLookup(response.Items));
            return response.Items;
        });

export const refreshResourcesLookup = () => {
    const accountId = store.getState().projects.activePortfolio.Id;
    return loadResourcesLookup(accountId);
}
export const createResource = (payload) =>
    api.post(`api/Resource/Create`, payload);

export const quickCreateResource = (payload) =>
    api.post(`api/Resource/QuickCreate`, payload);

export const updateResource = (payload) =>
    api.post(`api/Resource/Update`, payload, `Update_Resource_${payload?.Id}`);

export const getResource = (id) =>
    api.get(`api/Resource/Get?id=${id}`);

export const deleteResources = (accountId, ids) =>
    api.post(`api/Resource/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})));

export const loadCollections = (accountId) =>
    api.post(`api/ResourceCollection/GetByAccount`, {AccountId: accountId, WithItems: true})
        .then((response) => {
            dispatch(setCollections(response.Items));
            return response.Items;
        });

export const refreshCollections = () => {
    const accountId = store.getState().projects.activePortfolio.Id;
    return loadCollections(accountId);
}

export const getCollection = (id) =>
    api.get(`api/ResourceCollection/Get?id=${id}`);

export const createCollection = (payload) =>
    api.post(`api/ResourceCollection/Create`, payload);

export const updateCollection = (payload) =>
    api.post(`api/ResourceCollection/Update`, payload, `Update_Collection_${payload?.Id}`);

export const deleteCollections = (accountId, ids) =>
    api.post(`api/ResourceCollection/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})));


export const createOrUpdateCollectionItem = (payload) =>
    api.post(`api/ResourceCollection/CreateOrUpdateItem`, payload, `CreateOrUpdate_CollectionItem_${payload?.CollectionId}_${payload?.ResourceId}_${payload?.SourceId}`);

export const resourceImport = (accountId, resources) =>
    api.post("api/ResourceImport/ParseResourceCsv", {
        AccountId: accountId,
        Resources: resources
    }, `Import_Resources_${accountId}`);

export const deleteCollectionItems = (accountId, ids) =>
    api.post(`api/ResourceCollection/BatchRemoveItems?accountId=${accountId}`, ids.map(Id => ({Id})));

export const downloadResourceItemsAsCsv = (accountId, portfolioName, itemIds = []) => {

    const timestamp = encodeURIComponent(moment().format("yyyy-MM-DDTHH-mm-ss"));
    const fileName = `${portfolioName}_Resources-Items_${timestamp}.csv`;

    const data = {
        accountId,
        ids: itemIds
    };

    api.fetchFile("api/ResourceCollection/DownloadResourceItemsAsCsv", fileName, data);
}

export const cloneCollections = (ids) =>
    api.post(`api/ResourceCollection/BatchClone`, ids.map(Id => ({Id})));