import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import ColumnsVisibility from "../../../Common/Tables/ColumnsVisibility/ColumnsVisibility";
import {updateUserSettings, whoAmI} from "../../../../services/auth";

export const TimelineColumns = Object.freeze({
    ProjectId: "Project",
    Location: "Location",
    TeamMembers: "Contacts",
    CategoryTags: "Category",
    DepartmentTags: "Department",
    Status: "Status",
    Duration: "Duration",
    LabourLines: "Labour Lines",
    Notes: "Details",
    Event: "Event Icons",
});

const TimelineColumnsVisibility = () => {
    const visibleColumns = useSelector((state) => state.currentUser.user.TablesVisibilitySettings.Timeline.Value || []);

    const items = _.keys(TimelineColumns).map((key, i) => ({
        title: TimelineColumns[key],
        value: key,
        sortRank: i,
    }));

    const onOk = (values) => {
        updateUserSettings({
            TablesVisibilitySettings: {Timeline: {Value: values}},
        }).then(whoAmI);
    };

    return <ColumnsVisibility columns={items} onOk={onOk} selected={visibleColumns}></ColumnsVisibility>;
};

export default TimelineColumnsVisibility;
