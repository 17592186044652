import React from "react";
import {Layout} from "antd";
import "./InstructorLayout.scss";
import TitleRow from "../../components/TitleRow/TitleRow";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import useActivePortfolio from "../../hooks/useActivePortfolio";
import {setSelected} from "../../redux/reducers/mainTable";
import usePermissions from "../../hooks/usePermissions";
import {pathCollection} from "../../helpers/paths";

const {Header, Content} = Layout;

const InstructorLayout = ({children, route}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isBulkSelection = useSelector((state) => state.mainTable.isBulkSelection);
    const currentUser = useSelector((state) => state.currentUser.user);
    const isRequested = useSelector((state) => state.currentUser.isRequested);
    const {isInstructor} = usePermissions();
    const isPortfolioLoaded = useActivePortfolio();

    useActivePortfolio();

    useEffect(() => {
        dispatch(setSelected([]));
    }, [route]);

    useEffect(() => {
        if (currentUser === null && isRequested) {
            history.push(pathCollection.logins.signin);
        }
    }, [currentUser, isRequested]);

    useEffect(() => {
        if (isPortfolioLoaded && !isInstructor) {
            history.push("/");
        }
    }, [isPortfolioLoaded, isInstructor]);

    return (currentUser && isRequested && isPortfolioLoaded && isInstructor && (<Layout style={{minHeight: "100vh"}}>
        <Layout className="main-content-layout">
            <Header className={{"batch-selection-header": isBulkSelection}}>
                <TitleRow hideTitleButtons={true} breadcrumbSpan={14} titleButtonsSpan={8}/>
            </Header>
            <Content>{children}</Content>
        </Layout>
    </Layout>));
};

export default InstructorLayout;
