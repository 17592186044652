import {createSlice} from "@reduxjs/toolkit";

const settingsSlice = createSlice({
    name: "labour",
    initialState: {rulesets: [], positions: [], lines: []},
    reducers: {
        setRulesets(state, action) {
            state.rulesets = action.payload;
        },
        setPositions(state, action) {
            state.positions = action.payload;
        },
        setLines(state, action) {
            state.lines = action.payload;
        },
    },
});

export const {setRulesets, setPositions, setLines} = settingsSlice.actions;

export default settingsSlice.reducer;
