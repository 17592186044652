import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";

const noItem = {
    value: "noItem",
    title: "— No Categories —",
    sortRank: 1,
};

function getItems(data) {
    return [
        noItem,
        ..._.chain(data)
            .map((d) => d.CategoryTags)
            .flatten()
            .uniqBy((d) => d.AccountTagId)
            .map((item) => ({value: item.AccountTagId, title: item.Name}))
            .value(),
    ];
}

const CategoryFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const items = useMemo(() => {
        return getItems(data);
    }, [data]);

    const itemsHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [itemsHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [itemsHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler(
            (item) =>
                _.intersection(
                    item.CategoryTags.map((x) => x.AccountTagId),
                    filterValues
                ).length > 0 ||
                (isNoItemSelected && item.CategoryTags.length === 0)
        );

        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Categories"
            onChanged={onChanged}
            search
        />
    );
};

export default CategoryFilter;
