import React from "react";
import {useSelector} from "react-redux";
import {downloadProductionBookAsCsv} from "../../../services/productionBooks";

const ExportProductionBook = ({disabled, book = null}) => {
    const portfolioTimeZoneId = useSelector((state) => state.projects.activePortfolio?.TimeZoneId);
    const onExport = () => {
        if (disabled || !book) return;

        const tz = book.TimeZoneId ?? portfolioTimeZoneId;
        downloadProductionBookAsCsv({...book, TimeZoneId: tz});
    };

    return (
        <div disabled={disabled} onClick={onExport}>
            Export
        </div>
    );
};

export default ExportProductionBook;
