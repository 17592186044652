import {Card, Space, Form} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {mapTextToOption} from "../../../helpers/Tags";
import {loadPortfolios, updatePortfolioPreferences} from "../../../services/projects";
import {updateUserSettings} from "../../../services/auth";
import currencyObject from "../../../helpers/currencies.json";
import {SelectWithFormItem} from "../../Common/Input";
import DateTimeFormat from "../../Common/DateTimeFormat/DateTimeFormat";
import ImageUpload, {changeImageUpdatePayload} from "../../Common/ImageUpload/ImageUpload";
import {getContact, loadContacts, updateContact} from "../../../services/contacts";
import {ApiContactType} from "../../../constants/constants";
import studentDefaultLogo from "../../Common/Images/student-license-logo.png";
import Rulesets from "./Rulesets";
import usePermissions from "../../../hooks/usePermissions";

const days = [
    {
        value: 0,
        label: "Sunday",
    },
    {
        value: 1,
        label: "Monday",
    },
    {
        value: 2,
        label: "Tuesday",
    },
    {
        value: 3,
        label: "Wednesday",
    },
    {
        value: 4,
        label: "Thursday",
    },
    {
        value: 5,
        label: "Friday",
    },
    {
        value: 6,
        label: "Saturday",
    },
];

const Preferences = ({activePortfolio}) => {
    const [form] = Form.useForm();
    const [contact, setContact] = useState(true);
    const timeZones = useSelector((state) => state.infrastructure.timeZones).map(mapTextToOption);
    const {isStudent} = usePermissions();
    const currencies = _.keys(currencyObject).map((code) => {
        const {name, symbol} = currencyObject[code];

        return {
            value: code,
            label: `${code} (${name}, ${symbol.grapheme})`,
        };
    });

    useEffect(() => {
        if (!activePortfolio) return;

        getContact(activePortfolio.Contact.Id).then((contact) => {
            setContact(contact);
        });
    }, [activePortfolio]);

    useEffect(() => {
        if (!activePortfolio) return;

        const values = prepareFormValues(activePortfolio);
        form.setFieldsValue(values);
    }, [activePortfolio, form, contact]);

    const prepareFormValues = (item) => {
        const {TimeZoneId, CurrencyCode, ProductionBookSettings, DateTimeSettings} = item;

        return {
            TimeZoneId,
            CurrencyCode,
            DateFormat: ProductionBookSettings.Date.Value,
            TimeFormat: ProductionBookSettings.Time.Value,
            StartWeekOn: DateTimeSettings.StartWeekOn.Value,
            ImageUrl: contact.ImageUrl,
        };
    };

    const save = async () => {
        const values = await form.getFieldsValue();

        updatePortfolioPreferences({
            AccountId: activePortfolio.Id,
            ...values,
            Settings: {
                Date: {},
                Time: {},
            },
        }).then(() => loadPortfolios());
    };

    const onSettingsChanged = async () => {
        const values = await form.getFieldsValue();
        updateUserSettings({
            AccountId: activePortfolio.Id,
            ProductionBookSettings: {
                Date: {Value: values.DateFormat},
                Time: {Value: values.TimeFormat},
            },
        }).then(loadPortfolios);
    };

    const onStartWeekOnChanged = async () => {
        const values = await form.getFieldsValue();
        updateUserSettings({
            AccountId: activePortfolio.Id,
            Settings: {
                Date: {Value: activePortfolio.DateTimeSettings.Date.Value},
                Time: {Value: activePortfolio.DateTimeSettings.Time.Value},
                StartWeekOn: {Value: values.StartWeekOn},
            },
        }).then(loadPortfolios);
    };

    const onImageChanged = async () => {
        const values = await form.getFieldsValue();
        updateContact({
            ...contact,
            ContactType: ApiContactType.Organization,
            ...changeImageUpdatePayload(values, contact),
        }).then(() => Promise.all([loadPortfolios(), loadContacts(activePortfolio.Id)]));
    };

    return (
        <Form
            form={form}
            labelCol={{span: 7}}
            wrapperCol={{span: 17}}
            layout="horizontal"
            size="middle"
            initialValues={{StartWeekOn: 0}}
            hidden={!activePortfolio}>
            <Space className="settings-blocks">
                <Card type="inner" title="Portfolio Preferences">
                    <SelectWithFormItem
                        label="Default Time Zone"
                        name="TimeZoneId"
                        style={{width: "300px"}}
                        options={timeZones}
                        onChanged={save}
                        showSearch={true}
                    />
                    <SelectWithFormItem
                        label="Currency"
                        name="CurrencyCode"
                        style={{width: "300px"}}
                        options={currencies}
                        onChanged={save}
                        showSearch={true}
                    />
                    <Form.Item label="Company Logo" name="ImageUrl" className="ant-form-item-without-validation">
                        <ImageUpload defaultLogo={isStudent ? studentDefaultLogo : undefined}
                                     onChanged={onImageChanged}/>
                    </Form.Item>

                    <DateTimeFormat
                        onChange={onSettingsChanged}
                        dateFormatLabel="Production Book Date Format::"
                        timeFormatLabel="Production Book Time Format::"></DateTimeFormat>

                    <SelectWithFormItem
                        label="Start week on"
                        name="StartWeekOn"
                        style={{width: "300px"}}
                        options={days}
                        onChanged={onStartWeekOnChanged}
                    />
                </Card>
            </Space>
            <Space className="settings-blocks">
                <Card type="inner" title="Labour Cost Calculation Rulesets">
                    <Rulesets activePortfolio={activePortfolio}/>
                </Card>
            </Space>
        </Form>
    );
};

export default Preferences;
