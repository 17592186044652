import {FolderOpenFilled} from "@ant-design/icons";
import {Badge} from "antd";
import React from "react";

const ReportTemplateDetailsPanelTitle = ({editedItem}) => (
    <>
        <FolderOpenFilled/> {editedItem.Name} <Badge className="old-paperwork-badge" count="Template"></Badge>
    </>
);

export default ReportTemplateDetailsPanelTitle;
