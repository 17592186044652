import React from 'react';
import {useParams} from 'react-router-dom';
import CreateAccount from "./CreateAccount";

const CreateAccountWrapper = ({buttonText, ...props}) => {
    const {linkHash} = useParams();

    let createNewAccountLink = '';
    let signInToAccountLink = '';

    if (props.createNewAccountLink !== undefined) {
        createNewAccountLink = props.createNewAccountLink.replace(':linkHash', linkHash);
    }

    if (props.signInToAccountLink !== undefined) {
        signInToAccountLink = props.signInToAccountLink.replace(':linkHash', linkHash);
    }

    return <CreateAccount {...props}
                          createNewAccountLink={createNewAccountLink}
                          signInToAccountLink={signInToAccountLink}
                          buttonText={buttonText}
    />;
};

export default CreateAccountWrapper;