import React, {useState} from "react";
import {Form} from "antd";
import Attachments from "./Filters/Attachments";
import {useEffect} from "react";

export const AttachmentsBlock = ({form, selectedProjects, setAttachmentsCount, disabled, onChanged}) => {
    const isBlockEnabled = form.getFieldValue("IsAttachmentsEnabled");
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (isBlockEnabled) {
            setAttachmentsCount(selected);
        } else {
            setAttachmentsCount(null);
        }
    }, [setAttachmentsCount, selectedProjects, isBlockEnabled, selected]);

    return (
        <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
            <Form.Item label="Attachments" name="Attachments" className="ant-form-item-without-validation">
                <Attachments
                    disabled={!isBlockEnabled}
                    projectIds={selectedProjects}
                    setSelectedAttachmentCount={setSelected}
                    onChanged={onChanged}></Attachments>
            </Form.Item>
        </Form>
    );
};
