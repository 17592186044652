import React from "react";
import {useDispatch} from "react-redux";
import {Form, Input, Button, message, Checkbox} from "antd";
import {login, whoAmI} from "../../services/auth";
import {NavLink, useHistory} from "react-router-dom";
import {pathCollection} from "../../helpers/paths";
import {setProjects} from "../../redux/reducers/projects";

const layout = {
    layout: "vertical",
};

const Signin = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        try {
            const loginResponse = await login(values.username, values.password, values.remember);
            if (!loginResponse.IsAuthenticated) {
                throw new Error(loginResponse.Message);
            }

            dispatch(setProjects([]));

            const whoAmIResponse = await whoAmI();
            if (!whoAmIResponse) {
                throw new Error("WhoAmI returns error");
            }

            history.push("/");
        } catch (err) {
            message.error(err.message || "Server error");
        }
    };

    const onFinishFailed = (errorInfo) => {
    };

    return (<Form {...layout} name="basic" initialValues={{remember: true}} onFinish={onFinish}
                  onFinishFailed={onFinishFailed}>
        <Form.Item label="Email Address" name="username"
                   rules={[{required: true, message: "Please input your username!"}]}>
            <Input placeholder="Enter Email Address"/>
        </Form.Item>

        <Form.Item label="Password" name="password"
                   rules={[{required: true, message: "Please input your password!"}]}>
            <Input.Password placeholder="Enter your Password"/>
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Keep me signed in</Checkbox>
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
                Sign In
            </Button>
        </Form.Item>
        <Form.Item>
            <NavLink to={pathCollection.logins.forgotPasswordRequest} exact={true} className="login_content_reset">
                Reset your password.
            </NavLink>
        </Form.Item>
    </Form>);
};

export default Signin;
