import React, {useState, useEffect} from "react";
import ViewEditList from "../ViewEditList";
import {Button, Row, Col, Input, Space} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import update from "immutability-helper";

const mapSpaceToRow = (spaces = []) =>
    spaces.map((space) => ({
        id: space.Id,
        value: space.Company,
    }));

const mapRowToSpace = (itemRows = []) =>
    itemRows.map((itemRow) => ({
        Id: itemRow.id,
        Company: itemRow.value,
    }));

const EditableRow = ({item = {}, onChange, remove, valuePlaceholder, onChanged}) => {
    const [value, setValue] = useState(item.value);

    useEffect(() => {
        setValue(item.value);
    }, [item]);

    const triggerChange = (changedValue) => {
        if (onChange) {
            onChange({value, ...item, ...changedValue});
        }
    };

    const onValueChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        triggerChange({value: newValue});
    };

    return (
        <Row align="middle">
            <Col span={22}>
                <Input
                    value={value}
                    onChange={onValueChange}
                    placeholder={valuePlaceholder}
                    onBlur={onChanged}
                    onPressEnter={onChanged}></Input>
            </Col>
            <Col span={2} className={{"btn-remove-row-holder": true}}>
                <CloseCircleOutlined className="btn-remove-row" onClick={remove}/>
            </Col>
        </Row>
    );
};

const SpacesEditList = ({items = [], onChange, onChanged, addNewRowTex, valuePlaceholder}) => {
    const [editableItems, setEditableItems] = useState(items);

    useEffect(() => {
        setEditableItems(items);
    }, [items]);

    function add() {
        setEditableItems([...editableItems, {}]);
    }

    function remove(index) {
        const items = editableItems.filter((e, i) => i !== index);
        setEditableItems(items);
        onChange(items);
        onChanged();
    }

    function onItemChange(index, item) {
        const changedItem = {...editableItems[index], ...item};

        let updatedItems = update(editableItems, {[index]: {$set: changedItem}});

        setEditableItems(updatedItems);
        onChange(updatedItems);
    }

    return (
        <div className="view-edit-list edit-mode">
            {editableItems.map((item, index) => (
                <EditableRow
                    key={index}
                    item={item}
                    valuePlaceholder={valuePlaceholder}
                    onChanged={onChanged}
                    onChange={(changedItem) => onItemChange(index, changedItem)}
                    remove={() => remove(index)}
                />
            ))}
            <Row>
                <Col span={24}>
                    <Button
                        block
                        type="dashed"
                        onClick={() => {
                            add();
                        }}
                        style={{width: "100%"}}>
                        {addNewRowTex}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const SpaceList = ({value, onChange, onChanged}) => (
    <ViewEditList
        items={mapSpaceToRow(value)}
        onChanged={onChanged}
        onChange={(value) => onChange(mapRowToSpace(value))}
        editOnly={true}
        EditList={SpacesEditList}
        emptyRowText="Spaces:"
        addNewRowTex="Add a Space"
        valuePlaceholder="Enter a name"
    />
);

export default SpaceList;
