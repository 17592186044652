import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {Button, List, message, Tree} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {ApiTimelineItemType, RequirementType} from "../../../../constants/constants";
import {pathCollection} from "../../../../helpers/paths";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {continueEdit, openEdit} from "../../../../redux/reducers/detailsPanel";
import {refreshPositions} from "../../../../services/positions";
import {getEvent, refreshTimeline} from "../../../../services/timeline";
import {getLabourLine, createLabourLine, updateLabourLine, createCrewBookings} from "../../../../services/labour";
import {InputNumberWithFormItem, SelectWithFormItem} from "../../../Common/Input";
import {mapPositionToSelect, sortLabourLines, sortPositions} from "../../../Requirements/Common/PositionSelector";
import {doBatchDeleteLabourLines} from "../../../Requirements/DeleteRequirement";
import {errorFromHttpResponse} from "../../../../helpers/error";
import CrewManagement from "../../CrewManagement/CrewManagement";

const getRowId = (record) => (record.Id ? `${record.Id}_${record.Quantity}` : "new");

const LabourLinesBlock = ({
                              taskId,
                              labourLines = [],
                              onRefresh,
                              displayCreateBookings,
                              bookings = [],
                              onBookingUpdate,
                              onBookingDelete,
                              projectId,
                          }) => {
    const dispatch = useDispatch();
    const positions = useSelector((state) => state.labour.positions);
    const [addLoading, setAddLoading] = useState(false);
    const [createBookingsLoading, setCreateBookingsLoading] = useState(false);
    const usedPositions = (labourLines || []).filter((p) => p.PositionId).map((p) => p.PositionId);

    useEffect(() => {
        if (!positions.length > 0) return;

        refreshPositions();
    }, []);

    const onSingleAdd = () => {
        setAddLoading(true);
        createLabourLine(taskId)
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                throw new Error(errorText);
            })
            .then(onRefresh)
            .catch(console.error)
            .finally(() => setAddLoading(false));
    };

    useEffect(() => {
    }, [labourLines]);

    const sortedLabourLines = sortLabourLines(labourLines);

    const getBookings = (id) => bookings.filter((b) => b.LabourLine?.Id === id);

    const onCreateCrewBookings = () => {
        setCreateBookingsLoading(true);
        createCrewBookings(taskId).then(() =>
            getEvent(taskId, projectId, ApiTimelineItemType.Task)
                .then((item) => dispatch(continueEdit({item})))
                .finally(() => setCreateBookingsLoading(true))
                .then(() => refreshTimeline(ApiTimelineItemType.Task))
        );
    };

    return (
        <div>
            <List
                header={
                    <div className="details-labour-item">
                        <div className="position">Position</div>
                        <div className="qty">Qty</div>
                        <div className="actions">
                            <Button loading={addLoading} type="primary" size="small" onClick={onSingleAdd}>
                                Add Labour Line
                            </Button>
                        </div>
                    </div>
                }
                size="small"
                dataSource={sortedLabourLines}
                renderItem={(item, index) => (
                    <List.Item key={getRowId(item)}>
                        <Tree
                            defaultExpandAll
                            showLine={true}
                            treeData={[
                                {
                                    selectable: false,
                                    key: item.Id,
                                    title: (
                                        <EditLabourLineRow item={item} onRefresh={onRefresh}
                                                           usedPositions={usedPositions} bookings={bookings}/>
                                    ),
                                    children: [
                                        {
                                            selectable: false,
                                            key: `${item.Id}_children`,
                                            title: getBookings(item.Id).length > 0 && (
                                                <CrewManagement
                                                    bookings={getBookings(item.Id)}
                                                    projectId={projectId}
                                                    taskId={taskId}
                                                    onDelete={onBookingDelete}
                                                    onChange={onBookingUpdate}></CrewManagement>
                                            ),
                                        },
                                    ],
                                },
                            ]}></Tree>
                    </List.Item>
                )}></List>
            {displayCreateBookings && (
                <div className="center">
                    <Button loading={createBookingsLoading} onClick={onCreateCrewBookings} type="primary">
                        Enable Crew Bookings
                    </Button>
                </div>
            )}
        </div>
    );
};

const EditLabourLineRow = ({item = {}, usedPositions, onRefresh, bookings = []}) => {
    const [positionId, setPositionId] = useState(item.PositionId);
    const [qty, setQty] = useState(item.Quantity);
    var minQty = bookings.filter((b) => b.LabourLine?.Id === item.Id && b.TeamMemberId).length || 0;

    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const positions = useSelector((state) => state.labour.positions);

    const save = (position, qty) => {
        const updatePayload = {
            ...item,
            PositionId: position,
            Quantity: qty,
        };

        updateLabourLine(updatePayload).then((response) => {
            onRefresh();
        });
    };

    const deleteRow = async () => {
        doBatchDeleteLabourLines([item], () => {
            onRefresh();
        });
    };

    const goToDetails = () => {
        history.push(urlWithPortfolioId(pathCollection.requirementsPath[RequirementType.Labour]));
        setTimeout(() => {
            getLabourLine(item.Id)
                .then((item) => dispatch(openEdit({item, type: RequirementType.Labour})))
                .catch(() => message.error("Server error"));
        }, 100);
    };

    return (
        <div className="details-labour-item">
            <div className="position">
                <SelectWithFormItem
                    allowClear
                    showSearch
                    style={{width: "100%"}}
                    className="ant-form-item-without-validation"
                    placeholder="Select position"
                    value={positionId}
                    onChanged={(val) => {
                        setPositionId(val);
                        save(val, qty);
                    }}
                    options={sortPositions(positions).map((p) => mapPositionToSelect(p, usedPositions))}></SelectWithFormItem>
            </div>
            <div className="qty">
                <InputNumberWithFormItem
                    value={qty}
                    style={{width: "100%"}}
                    onChange={(val) => {
                        setQty(val);
                    }}
                    onChanged={(e) => {
                        var val = parseInt(e.target.value, 10) || 0;
                        if (val < minQty)
                            message.warning("Some crew bookings already have people assigned. Please delete these bookings individually.");

                        save(positionId, qty);
                    }}
                />
            </div>

            <div className="actions">
                <Button onClick={goToDetails} type="link" size="small">
                    <EyeOutlined className="details"/>
                </Button>
                <Button onClick={deleteRow} type="link" size="small">
                    <DeleteOutlined className="delete"/>
                </Button>
            </div>
        </div>
    );
};

export default LabourLinesBlock;
