import React from "react";
import {
    red,
    volcano,
    orange,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta
} from "@ant-design/colors";
import {SwatchesPicker} from "react-color";
import {Dropdown} from "antd";
import "./ColorPicker.scss";

const grey = ["#ffffff", "#fafafa", "#f5f5f5", "#f0f0f0", "#d9d9d9"];

export const defaultColor = grey[4];

const ColorPicker = ({save, value, onChange, disabled = false}) => {
    const color = value || defaultColor;
    return (
        <Dropdown
            disabled={disabled}
            overlay={
                <SwatchesPicker
                    onChange={(c) => onChange(c.hex)}
                    onChangeComplete={save ? save : () => {
                    }}
                    color={color}
                    colors={[red, volcano, orange, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey].map((c) =>
                        c.slice(0, 5)
                    )}></SwatchesPicker>
            }>
            <div className="color_picker_holder">
                <div className="color_picker_example" style={{backgroundColor: color}}></div>
            </div>
        </Dropdown>
    );
};

export default ColorPicker;
