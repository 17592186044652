import {ContainerOutlined} from "@ant-design/icons";
import {Card, List, Button} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import "./PortfolioSelector.scss";
import useChangePortfolio from "../../../hooks/useChangePortfolio";
import {ApiPermissionLevel, ApiPermissionLevelName} from "../../../constants/constants";
import studentDefaultLogo from "../../Common/Images/student-license-logo.png";

const PortfolioSelector = () => {
    const portfolios = useSelector((state) => state.projects.portfolios);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const changePortfolio = useChangePortfolio();

    const isSelected = (item) => item.Id === activePortfolio?.Id;
    const getRole = (item) => ApiPermissionLevelName[item.PermissionLevel];

    if (portfolios.length <= 1) return false;

    return (
        <Card
            className="portfolio_selector"
            title={
                <>
                    <ContainerOutlined/> Portfolio Selector
                </>
            }>
            <List
                dataSource={portfolios}
                renderItem={(item) => (
                    <List.Item
                        className={classNames({selected: isSelected(item)})}
                        actions={
                            isSelected(item)
                                ? []
                                : [
                                    <Button type="link" onClick={() => changePortfolio(item.Id)}>
                                        Switch
                                    </Button>,
                                ]
                        }>
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    size={36}
                                    className="detail_panels_avatar"
                                    shape="square"
                                    src={item.PermissionLevel === ApiPermissionLevel.Student ? studentDefaultLogo : item.Contact.ImageUrl}
                                />
                            }
                            title={item.Contact.Company}
                            description={getRole(item)}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default PortfolioSelector;
