import React from "react";
import _ from "lodash";
import {message} from "antd";

export const validateResponseWithMessage = ({Message}) => {
    if (Message) {
        if (_.isString(Message)) {
            message.error(Message);
        } else if (_.isObject(Message)) {
            message.error(_.keys(Message)
                .map((key) => Message[key].join(";"))
                .join(";"));
        } else {
            message.error("Server error");
        }
        return false;
    }

    return true;
};
