import React from "react";
import _ from "lodash";
import "./ProductionBooksProjectMultipleSelector.scss";
import ProjectsMultipleSelector, {
    isAllProjectsItemSelected,
} from "../../../Common/ProjectsMultipleSelector/ProjectsMultipleSelector";

export const filterHandler = (selectedProjects = []) => {
    const isAllProjects = isAllProjectsItemSelected(selectedProjects);
    return (item) =>
        (!item.Schedule.IsAllProjects &&
            _.intersection(
                selectedProjects,
                item.Schedule.Projects.map((p) => p.Id)
            ).length >
            0 >
            0) ||
        (isAllProjects && item.Schedule.IsAllProjects);
};
const AttachmentsProjectMultipleSelector = ({needResetFilter}) => (
    <ProjectsMultipleSelector needResetFilter={needResetFilter} allProjectsFlag={true}/>
);
export default AttachmentsProjectMultipleSelector;
