import React, {useState} from "react";
import {Form, Input, Button, message} from "antd";
import {sendResetPasswordRequest} from "../../services/auth";

const ResetPasswordRequest = () => {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            await sendResetPasswordRequest(values.EmailAddress);
        } catch (e) {
        } finally {
            message.info("If the email address is associated with a Propared account, then an email has been sent.");
            setLoading(false);
        }
    };

    return (
        <Form layout="vertical" name="basic" initialValues={{remember: true}} onFinish={onFinish}>
            <Form.Item>
                Please enter an email address associated with your account. We'll send you an email with a link to reset
                your password.
            </Form.Item>
            <Form.Item name="EmailAddress" rules={[{required: true, message: "Please input your email address"}]}>
                <Input placeholder="Enter your email address"/>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" size="large">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetPasswordRequest;
