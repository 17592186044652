import {Button, Select, Form} from "antd";
import React, {useEffect} from "react";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

import "./SourcesManagement.scss";
import {formatLocationName, formatQuantity, infinity} from "../../../../helpers/text";
import {moneyFormatter, moneyParser} from "../../../../helpers/money";
import {InputNumberWithFormItem, SelectWithFormItem, TooltipWithOrNot, InputWithFormItem} from "../../../Common/Input";
import ContactSelect from "../../../Common/Selects/ContactSelect";
import {removeSource} from "./Remove/RemoveSource";

import usePermissions from "../../../../hooks/usePermissions";

const EditSourceRow = ({
                           item = {},
                           contacts = [],
                           onChange,
                           onDelete,
                           itemPersister,
                           onOpenCreatePopup,
                           disabledContactIds,
                           resourceId,
                           activePortfolioId,
                       }) => {
    const [form] = Form.useForm();

    const resources = useSelector((state) => state.resources.resources) || [];
    const resource = resources.find((r) => r.Id === resourceId);
    const {isProjectManager} = usePermissions();

    useEffect(() => {
        if (!item) return;

        const formData = {...item};
        form.setFieldsValue(formData);
    }, [item]);

    const prepareSaveRequest = async () => {
        const formValues = await form.getFieldsValue();

        const merged = {
            ...item,
            ...formValues,
        };

        const contact = contacts.find((c) => c.Id === merged.ContactId);

        const payload = {
            Id: merged.Id,

            ResourceId: resourceId,
            AccountId: activePortfolioId,
            ContactId: merged.ContactId,
            Description: merged.Description,
            Name: formatLocationName(contact, true),
            Quantity: merged.Quantity,
            DefaultCost: merged.DefaultCost,
            CostUnit: merged.CostUnit,
        };

        return payload;
    };

    const save = async () => {
        const payload = await prepareSaveRequest();
        return itemPersister(payload, item, onChange);
    };

    const onDeleteRow = async () => {
        const {Id: itemId} = item;

        const onFinish = () => onDelete && onDelete(itemId);
        if (itemId) {
            removeSource(item, resource, activePortfolioId, onFinish);
        } else {
            onFinish();
        }
    };

    const isOptionalFieldDisabled = !item.Id;

    const canDelete = !isProjectManager;

    return (
        <>
            <Form form={form} defaultValue={{CostUnit: ["Item"]}}>
                <div className="details-src-item">
                    <div className="source">
                        <Form.Item name={["ContactId"]} className="ant-form-item-without-validation">
                            <ContactSelect
                                selectedContactId={item?.ContactId}
                                onChange={(v) => {
                                }}
                                onChanged={save}
                                disabled={false}
                                disabledContactIds={disabledContactIds}
                                onOpenCreatePopup={(type) => onOpenCreatePopup(type, item)}
                                hideQuickCreate={false}
                            />
                        </Form.Item>
                    </div>
                    <div className="inventory">
                        <InputNumberWithFormItem
                            name="Quantity"
                            formatter={(value) => formatQuantity(value)}
                            parser={(value) => (value === infinity ? null : parseFloat(value, 10))}
                            min={0}
                            controls={false}
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                        />
                    </div>
                    <div className="cost">
                        <InputNumberWithFormItem
                            name="DefaultCost"
                            formatter={moneyFormatter}
                            parser={moneyParser}
                            controls={false}
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                        />
                    </div>
                    <div className="unit">
                        <SelectWithFormItem name="CostUnit" style={{width: "100%"}} onChange={save}
                                            disabled={isOptionalFieldDisabled}>
                            <Select.Option key="Item" value="Item">
                                /Item
                            </Select.Option>
                            <Select.Option key="Day" value="Day">
                                /Day
                            </Select.Option>
                        </SelectWithFormItem>
                    </div>
                    <div className="details">
                        <InputWithFormItem name="Description" onChanged={save}
                                           disabled={isOptionalFieldDisabled}></InputWithFormItem>
                    </div>
                    <div className="actions">
                        <TooltipWithOrNot showTooltip={!canDelete}
                                          title="Only Owners and Administrators may remove sources from items.">
                            <Button disabled={!canDelete} onClick={onDeleteRow} type="link" size="small">
                                <CloseCircleOutlined className="delete"/>
                            </Button>
                        </TooltipWithOrNot>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EditSourceRow;
