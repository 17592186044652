import React, {useState, useEffect} from "react";
import {ApiProjectType, ProjectType} from "../../../../constants/constants";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";

const noItem = {
    value: "noItem",
    title: "— No State —",
    sortRank: 1,
};

const items = [
    {
        title: ProjectType.Active,
        value: ApiProjectType.Active,
    },
    {
        title: ProjectType.Inactive,
        value: ApiProjectType.Inactive,
    },
];

const StateFilter = ({needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const reset = () => setSelectedItems(items);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter]);

    useEffect(() => {
        onChanged(items);
    }, []);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler((item) => filterValues.includes(item.State) || isNoItemSelected);
        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter selectedItems={selectedItems} items={items} allItemsText="All States"
                                        onChanged={onChanged}/>
    );
};

export default StateFilter;
