export const FieldType = Object.freeze({
    Roles: "Roles",
    Departments: "Departments",
    Email: "Email",
    Phone: "Phone",
    Address: "Address",
    Website: "Website",
    Notes: "Notes",
    Empty: "Empty",
    FirstName: "FirstName",
    LastName: "LastName",
    PersonPreferredName: "PersonPreferredName",
    OrganizationPreferredName: "OrganizationPreferredName",
    LocationPreferredName: "LocationPreferredName",
    OrganizationName: "OrganizationName",
    LocationName: "LocationName",
});

