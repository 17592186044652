import React from "react";
import classNames from "classnames";
import {message} from "antd";
import {batchRemove, refreshContacts} from "../../../services/contacts";
import "./DeleteContact.scss";
import usePermissions from "../../../hooks/usePermissions";
import {doBatchDelete} from "../../Common/Modal/BatchDelete/BatchDelete";
import DeleteAction from "../../Common/Tables/Actions/DeleteAction";
import {formatContactName} from "../../../helpers/text";
import {useDispatch} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";
import {TooltipWithOrNot} from "../../Common/Input";
import {errorFromHttpResponse} from "../../../helpers/error";

const getTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete ${formatContactName(selected[0], false)}?`;
    } else {
        return `Are you sure you want to delete these ${selected.length} contacts?`;
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Contact was deleted.`);
    } else {
        message.success(`${selected.length} contacts were deleted.`);
    }
};

const DeleteContact = ({record}) => {
    const dispatch = useDispatch();
    const {isProjectManager} = usePermissions();

    const canDelete = !isProjectManager;

    const className = classNames({
        disabled: !canDelete,
        "delete btn-table-actions": true,
    });
    return (
        <TooltipWithOrNot showTooltip={!canDelete} title="Only owners and administrators may delete contacts.">
            <DeleteAction
                record={record}
                className={className}
                onClick={() => {
                    if (!canDelete) return;

                    doBatchDeleteContacts([record], () => {
                        dispatch(setSelected([]));
                        refreshContacts();
                    });
                }}></DeleteAction>
        </TooltipWithOrNot>
    );
};

const DefaultDeleteContactsContent = (
    <>
        Their record and all information associated with them will be deleted FOREVER. <br/>
        <br/>
        You cannot undo this action.
    </>
);

export const doBatchDeleteContacts = (selected, onFinish, content = DefaultDeleteContactsContent) => {
    return doBatchDelete({
        onFinish,
        title: getTitle(selected),
        content,
        batchRemove: () =>
            batchRemove(selected.map((s) => s.Id)).catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                throw err;
            }),
        onFinishMessage: () => showFinishMessage(selected),
        okText: "Delete",
    });
};
export default DeleteContact;
