import React from "react";
// import ArchiveFilter from "./ArchiveFilter";
// import OrganizationFilter from "./OrganizationFilter";
import RoleFilter from "./RoleFilter/RoleFilter";
import GroupFilter from "./GroupFilter/GroupFilter";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import SearchFilter from "./SearchFilter/SearchFilter";
import GroupBy from "./GroupBy/GroupBy";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";
import {TeamContactType} from "../../../constants/constants";

const TeamFilterBar = ({contactType, ...props}) => {
    const components =
        contactType === TeamContactType.Location
            ? [RoleFilter, ChangedAtFilter, SearchFilter]
            : [RoleFilter, GroupFilter, ChangedAtFilter, SearchFilter];
    return <FilterBar {...props} filterComponents={components} GroupByComponent={GroupBy}/>;
};

export default TeamFilterBar;
