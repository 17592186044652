import {ApiContactType, ContactType} from "../../../constants/constants";
import _ from "lodash";

export const FieldType = Object.freeze({
    Roles: "Roles",
    Departments: "Departments",
    Email: "Email",
    Phone: "Phone",
    Address: "Address",
    Website: "Website",
    Notes: "Notes",
    Empty: "Empty",
    FirstName: "FirstName",
    LastName: "LastName",
    PersonPreferredName: "PersonPreferredName",
    OrganizationPreferredName: "OrganizationPreferredName",
    LocationPreferredName: "LocationPreferredName",
    OrganizationName: "OrganizationName",
    LocationName: "LocationName",
});

export const commonModelFields = {
    [FieldType.Roles]: "Specialties",
    [FieldType.Departments]: "Groups",
    [FieldType.Notes]: "Notes",
    [FieldType.Email]: "EmailAddresses",
    [FieldType.Phone]: "PhoneNumbers",
    [FieldType.Website]: "Websites",
    [FieldType.Address]: "Addresses",
    [FieldType.FirstName]: "FirstName",
    [FieldType.LastName]: "LastName",
    [FieldType.PersonPreferredName]: "Nickname",
    [FieldType.OrganizationName]: "Company",
    [FieldType.OrganizationPreferredName]: "AlsoKnownAs",
    [FieldType.LocationName]: "Company",
    [FieldType.LocationPreferredName]: "AlsoKnownAs",
};


const commonCsvToFieldType = {
    "First Name": FieldType.FirstName,
    "Last Name": FieldType.LastName,
    Roles: FieldType.Roles,
    Departments: FieldType.Departments,
    Email: FieldType.Email,
    Phone: FieldType.Phone,
    "Street Address": FieldType.Address,
    Website: FieldType.Website,
    Notes: FieldType.Notes,
};

const personCsvToFieldType = {
    "Preferred Name": FieldType.PersonPreferredName,
};

const organizationCsvToFieldType = {
    "Preferred Name": FieldType.OrganizationPreferredName,
    Name: FieldType.OrganizationName,
};

const locationCsvToFieldType = {
    "Preferred Name": FieldType.LocationPreferredName,
    Name: FieldType.LocationName,
};

export const getCsvToFieldType = (type) => {
    switch (type) {
        case ContactType.Person:
            return {
                ...personCsvToFieldType,
                ...commonCsvToFieldType,
            };
        case ContactType.Organization:
            return {
                ...organizationCsvToFieldType,
                ...commonCsvToFieldType,
            };
        case ContactType.Location:
            return {
                ...locationCsvToFieldType,
                ...commonCsvToFieldType,
            };
        default:
            return commonCsvToFieldType;
    }
};

export const ContactImportInputLabel = {
    [FieldType.Roles]: "Default Roles",
    [FieldType.Departments]: "Default Groups",
    [FieldType.Email]: "Email",
    [FieldType.Phone]: "Phone",
    [FieldType.Address]: "Address",
    [FieldType.Website]: "Website",
    [FieldType.Notes]: "Internal Details Notes",
    [FieldType.Empty]: "DO NOT IMPORT",
    [FieldType.FirstName]: "First Name",
    [FieldType.LastName]: "Last Name",
    [FieldType.PersonPreferredName]: "Preferred Name",
    [FieldType.OrganizationPreferredName]: "Short Name",
    [FieldType.LocationPreferredName]: "Short Name",
    [FieldType.OrganizationName]: "Organization Name",
    [FieldType.LocationName]: "Location Name",
};

const commonTypes = [
    FieldType.Roles,
    FieldType.Departments,
    FieldType.Email,
    FieldType.Phone,
    FieldType.Address,
    FieldType.Website,
    FieldType.Notes,
    FieldType.Empty,
];

export const getTargetFieldTypes = (type) => {
    switch (type) {
        case ContactType.Person:
            return [FieldType.FirstName, FieldType.LastName, FieldType.PersonPreferredName, ...commonTypes];
        case ContactType.Organization:
            return [FieldType.OrganizationName, FieldType.OrganizationPreferredName, ...commonTypes];
        case ContactType.Location:
            return [FieldType.LocationName, FieldType.LocationPreferredName, ...commonTypes];
        default:
            return commonTypes;
    }
};

export const apiContactTypes = {
    [ContactType.Person]: ApiContactType.Person,
    [ContactType.Organization]: ApiContactType.Organization,
    [ContactType.Location]: ApiContactType.Place,
}


const toModelField = (data, type) => {
    let parsed = null;
    switch (type) {
        case FieldType.Roles:
        case FieldType.Departments:
            parsed = data ? data.split(";") : [];
            break;

        case FieldType.Email:
            parsed = data ? [{EmailAddress: data, IsPrimary: true, IsForCommunication: true}] : [];
            break;

        case FieldType.Phone:
            parsed = data ? [{PhoneNumber: data}] : [];
            break;
        case FieldType.Website:
            parsed = data ? [{Url: data}] : [];
            break;
        case FieldType.Address:
            parsed = data ? [{Line1: data}] : [];
            break;
        default:
            parsed = data;

    }

    return parsed;
};


export const getParsedData = (dataList = [], columnsMap) => {
    const defaultFields = _.fromPairs(_.keys(commonModelFields).map((k) => [commonModelFields[k], toModelField(null, k)]));

    return dataList.map(data => ({
        //ContactType: contactType,
        ...defaultFields,
        ..._.fromPairs(
            data.map((d, i) => {
                return [commonModelFields[columnsMap[i]], toModelField(d, columnsMap[i])];
            })
        ),
    }))
}
