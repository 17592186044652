import React from "react";
import SearchFilter from "./SearchFilter";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter";

const ReportsFilterBar = ({...props}) => {
    return <FilterBar {...props} filterComponents={[ChangedAtFilter, SearchFilter]} GroupByComponent={null}/>;
};

export default ReportsFilterBar;
