import React, {useEffect, useRef, useState} from "react";
import {Form, Modal} from "antd";
import {InputWithFormItem, InputWithFormItemWithRef, SelectWithFormItem, TextAreaWithFormItem} from "../Input";
import {createContact, refreshContacts} from "../../../services/contacts";
import {ApiContactType} from "../../../constants";
import {EnvironmentOutlined, ExpandOutlined, ShopOutlined, UserOutlined} from "@ant-design/icons";
import Spaces from "./Spaces";
import {useSelector} from "react-redux";

const getEmails = (email) => (email ? [{EmailAddress: email, IsForCommunication: true}] : []);
const getPhones = (phone) => (phone ? [{PhoneNumber: phone}] : []);
const getWebsite = (website) => (website ? [{Url: website}] : []);
const getAddress = (address) => (address ? [{Line1: address}] : []);

const titles = {
    [ApiContactType.Person]: (
        <>
            <UserOutlined/> Quick-Create Person
        </>
    ),
    [ApiContactType.Organization]: (
        <>
            <ShopOutlined/> Quick-Create Organization
        </>
    ),
    [ApiContactType.Place]: (
        <>
            <EnvironmentOutlined/> Quick-Create Location
        </>
    ),

    [ApiContactType.Space]: (
        <>
            <ExpandOutlined/> Quick-Create Space
        </>
    ),
};

const PersonInputs = ({firstInputRef, save, isEmailRequired}) => (
    <>
        <InputWithFormItemWithRef
            name="FirstName"
            label="First Name"
            hasFeedback
            ref={firstInputRef}
            rules={[{required: true, message: "Please input your First Name"}]}
            onChanged={save}
            placeholder="Enter first name"
        />
        <InputWithFormItem name="LastName" label="Last Name" onChanged={save} placeholder="Enter last name"/>
        <InputWithFormItem
            name="Email"
            label="Email"
            onChanged={save}
            placeholder="Enter email"
            hasFeedback={isEmailRequired}
            rules={isEmailRequired ? [{required: true, message: "Please input your email"}] : []}
        />
        <InputWithFormItem name="Phone" label="Phone" onChanged={save} placeholder="Enter phone"/>
    </>
);

const OrganizationInputs = ({firstInputRef, save}) => (
    <>
        <InputWithFormItemWithRef
            name="Company"
            label="Name"
            hasFeedback
            ref={firstInputRef}
            rules={[{required: true, message: "Please input Name"}]}
            onChanged={save}
            placeholder="Enter name"
        />
        <InputWithFormItem name="AlsoKnownAs" label="Short Name" onChanged={save} placeholder="Enter short name"/>
        <InputWithFormItem name="Website" label="Website" onChanged={save} placeholder="Enter website"/>
    </>
);

const PlaceInputs = ({firstInputRef, save, hideSpaces}) => (
    <>
        <InputWithFormItemWithRef
            name="Company"
            label="Name"
            hasFeedback
            ref={firstInputRef}
            rules={[{required: true, message: "Please input Name"}]}
            onChanged={save}
            placeholder="Enter name"
        />
        <InputWithFormItem name="AlsoKnownAs" label="Short Name" onChanged={save} placeholder="Enter short name"/>
        <TextAreaWithFormItem name="Address" label="Address" autoSize={{minRows: 2}} onChanged={save}
                              placeholder="Enter an address"/>
        <Form.Item name="Spaces" label="Spaces" hidden={hideSpaces}>
            <Spaces onChanged={save}></Spaces>
        </Form.Item>
    </>
);

const SpaceInputs = ({firstInputRef, save, places = []}) => (
    <>
        <SelectWithFormItem
            allowClear={true}
            label="Location Name"
            name="parentContactId"
            showSearch={true}
            style={{width: "100%"}}
            placeholder="Select a location"
            options={places}
            onChanged={save}
            rules={[{required: true, message: "Please select a Location"}]}
        />
        <InputWithFormItemWithRef
            name="Company"
            label="Space Name"
            hasFeedback
            ref={firstInputRef}
            rules={[{required: true, message: "Please input Space Name"}]}
            onChanged={save}
            placeholder="Enter name"
        />
    </>
);

const CreateContact = ({
                           isVisible,
                           apiContactType,
                           onContactCreated,
                           onClose,
                           places,
                           parentContactId,
                           isEmailRequired,
                           hideSpaces,
                       }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const firstInputRef = useRef();

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => firstInputRef.current?.focus(), 100);
        }
    }, [isVisible]);

    async function handleOk() {
        try {
            setIsLoading(true);
            const finishedForm = await form.validateFields();

            const payload = {
                ContactType: apiContactType,
                FirstName: finishedForm.FirstName,
                LastName: finishedForm.LastName,
                Company: finishedForm.Company,
                AlsoKnownAs: finishedForm.AlsoKnownAs,
                Websites: getWebsite(finishedForm.Website),
                EmailAddresses: getEmails(finishedForm.Email),
                PhoneNumbers: getPhones(finishedForm.Phone),
                Addresses: getAddress(finishedForm.Address),
            };

            const createContactResponse = await createContact(
                activePortfolioId,
                apiContactType,
                payload,
                parentContactId || finishedForm.parentContactId,
                finishedForm.Spaces || []
            );
            const request = await refreshContacts();

            onContactCreated(createContactResponse.Id, createContactResponse, request?.Items || []);
            onClose();
            form.resetFields();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function close() {
        setIsLoading(false);
        onClose();
        form.resetFields();
    }

    function save() {
    }

    return (
        <Modal
            confirmLoading={isLoading}
            title={titles[apiContactType]}
            visible={isVisible}
            maskClosable={false}
            onOk={handleOk}
            onCancel={close}
            okText="Save">
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                {apiContactType === ApiContactType.Person && (
                    <PersonInputs save={save} firstInputRef={firstInputRef}
                                  isEmailRequired={isEmailRequired}></PersonInputs>
                )}
                {apiContactType === ApiContactType.Organization && (
                    <OrganizationInputs save={save} firstInputRef={firstInputRef}></OrganizationInputs>
                )}
                {apiContactType === ApiContactType.Place && (
                    <PlaceInputs save={save} firstInputRef={firstInputRef} hideSpaces={hideSpaces}></PlaceInputs>
                )}
                {apiContactType === ApiContactType.Space && (
                    <SpaceInputs save={save} firstInputRef={firstInputRef} places={places}
                                 parentContactId={parentContactId}></SpaceInputs>
                )}
            </Form>
        </Modal>
    );
};

export default CreateContact;
