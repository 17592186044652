import {useSelector} from "react-redux";
import {appendPortfolioId} from "../helpers/navigate";
import usePermissions from "./usePermissions";

const useUrlWithPortfolioId = () => {
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const {isStudent} = usePermissions();

    const urlWithPortfolioId = (url, portfolioId) => {

        if (portfolioId) {
            return appendPortfolioId(url, portfolioId, isStudent)
        }

        return activePortfolio ? appendPortfolioId(url, activePortfolio.Id, isStudent) : url;
    };

    return urlWithPortfolioId;
};

export default useUrlWithPortfolioId;
