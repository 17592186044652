import api from "../api/api";
import store from "../redux/store";
import {setProductionBooks} from "../redux/reducers/productionBooks";
import moment from "moment";

const {dispatch} = store;

const defaultFilter =
    {
        IncludeNotesType: true,
        IncludeScheduleType: true,
        IncludeTeamType: true,
        Keyword: ""
    }

export const loadProductionBooks = (activePortfolioId) =>
    api
        .post(`api/Filter/GetAll?`, defaultFilter)
        .then((response) => {
            const productionBooks = (response.Filters?.Filters || []).filter(pb => pb.AccountId === activePortfolioId)
            dispatch(setProductionBooks(productionBooks));
            return response;
        });


export const createOrUpdate = (payload) =>
    api.post(`api/Filter/CreateOrUpdate`, payload, payload.Id ? `Update_Filter_${payload.Id}` : null);

export const batchRemove = (ids) =>
    api.post(`api/Filter/BatchRemove?`, ids.map(Id => ({Id})));

export const getItem = (id) => api.get(`api/Filter/Get?id=${id}&includeFlags=383`);

export const sendFilterEmail = (payload) =>
    api.post(`api/Filter/SendFilterEmail?`, payload);

export const cloneProductionBooks = (ids) =>
    api.post(`api/Filter/BatchClone`, ids.map(Id => ({Id})));

export const downloadProductionBookAsCsv = (book) => {
    const clientTime = encodeURIComponent(moment().format("yyyy-MM-DDTHH-mm-ss"));
    const tzId = encodeURIComponent(book.TimeZoneId);
    const filterId = book.Id;
    const fileName = `${book.Name}-${clientTime}.csv`;

    const url = `api/Filter/GetCsvFromFilter?filterId=${filterId}&timezoneId=${tzId}&clientDateTime=${clientTime}`;
    api.fetchFile(url, fileName);
}
