import React, {useState} from "react";
import _ from "lodash";
import {Form, Select} from "antd";
import {UserOutlined, ShopOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {ContactType} from "../../../constants/constants";
import {useSelector} from "react-redux";
import PersonInputs from "../DetailsPanel/PersonInputs";
import LocationInputs from "../DetailsPanel/LocationInputs";
import OrganizationInputs from "../DetailsPanel/OrganizationInputs";
import Import, {CsvMapper} from "../../Common/Modal/Import";
import {
    ContactImportInputLabel,
    FieldType,
    getTargetFieldTypes,
    getCsvToFieldType,
    apiContactTypes,
    getParsedData,
} from "./helper";
import {contactImport, refreshContacts} from "../../../services/contacts";
import {loadTags} from "../../../services/tags";

const getIcon = (type) => {
    switch (type) {
        case ContactType.Person:
            return <UserOutlined/>;
        case ContactType.Organization:
            return <ShopOutlined/>;
        case ContactType.Location:
            return <EnvironmentOutlined/>;
        default:
            return null;
    }
};

const ContactCsvMapper = ({
                              fileData,
                              defaultContactType,
                              setErrorMessage,
                              setParsedData,
                              contactType,
                              setContactType
                          }) => {
    const fieldTypes = getTargetFieldTypes(contactType);
    const csvToFieldType = getCsvToFieldType(contactType);

    const validate = (columnsMap) => {
        const mapped = _.values(columnsMap);

        switch (contactType) {
            case ContactType.Person:
                setErrorMessage(mapped.includes(FieldType.FirstName) ? null : "First Name mapping required");
                break;
            case ContactType.Organization:
                setErrorMessage(mapped.includes(FieldType.OrganizationName) ? null : "Name mapping required");
                break;

            case ContactType.Location:
                setErrorMessage(mapped.includes(FieldType.LocationName) ? null : "Name mapping required");
                break;

            default:
                break;
        }
    };

    const inputs = fieldTypes.map((i) => ({value: i, label: ContactImportInputLabel[i]}));

    return (
        <CsvMapper
            fileData={fileData}
            validate={validate}
            inputs={inputs}
            fieldTypes={fieldTypes}
            csvToFieldType={csvToFieldType}
            setParsedData={setParsedData}
            getParsedData={getParsedData}
            renderPlaceholder1={() => (
                <Form.Item initialValue={ContactType.Person} labelCol={{span: 10}} wrapperCol={{span: 12}}
                           label="Contact Type">
                    <Select
                        value={contactType}
                        onChange={setContactType}
                        options={[ContactType.Person, ContactType.Organization, ContactType.Location].map((i) => ({value: i}))}
                        style={{width: "100%"}}></Select>
                </Form.Item>
            )}
            renderRecordViewTitle={() => (
                <>
                    {" "}
                    {getIcon(contactType)}
                    Preview Contact:
                </>
            )}
            renderRecordView={() => (
                <>
                    {contactType === ContactType.Person &&
                        <PersonInputs isReadOnly={true} isOptionalFieldDisabled={true}/>}
                    {contactType === ContactType.Organization &&
                        <OrganizationInputs isReadOnly={true} isOptionalFieldDisabled={true}/>}
                    {contactType === ContactType.Location &&
                        <LocationInputs isReadOnly={true} isOptionalFieldDisabled={true}/>}
                </>
            )}></CsvMapper>
    );
};

const ContactImport = ({contactType: defaultContactType, disabled}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio.Id);
    const [contactType, setContactType] = useState(defaultContactType);

    const prepareData = (data) =>
        (data || []).map((i) => {
            let result = {
                ContactType: apiContactTypes[contactType],
                ...i,
                SpecialtiesImported: i.Specialties,
                GroupsImported: i.Groups,
            };
            delete result.Specialties;
            delete result.Groups;

            return result;
        });

    const onImport = (parsedData) => contactImport(activePortfolioId, prepareData(parsedData));
    const onImportFinish = () => Promise.all([refreshContacts(), loadTags()]);

    const onClose = () => setContactType(defaultContactType);

    return (
        <Import
            disabled={disabled}
            mainIcon={<UserOutlined/>}
            title="Import Contacts from a .CSV"
            instructions={
                <p>
                    Import people from the selected .csv file. Adjust the field mappings, preview the results, and
                    import when you are
                    ready.
                </p>
            }
            onImport={onImport}
            onImportFinish={onImportFinish}
            onClose={onClose}
            CsvMapperComponent={(props) => (
                <ContactCsvMapper {...props} contactType={contactType} setContactType={setContactType}
                                  defaultContactType={contactType}/>
            )}></Import>
    );
};

export default ContactImport;
