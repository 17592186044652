import {createSlice} from "@reduxjs/toolkit";

const currentUserSlice = createSlice({
    name: "currentUser",
    initialState: {user: null, isRequested: false, avatar: null},
    reducers: {
        setCurrentUser(state, action) {
            state.user = action.payload;
            state.isRequested = true;
        },
        deleteCurrentUser(state, action) {
            state.user = null;
        },
        setAvatar(state, action) {
            state.avatar = action.payload;
        },
    },
});

export const {setCurrentUser, deleteCurrentUser, setAvatar} = currentUserSlice.actions;

export default currentUserSlice.reducer;
