import React from "react";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";
import {useSelector} from "react-redux";

const fieldsToSearch = ["Text", (item) => (item.DepartmentTags || []).map((x) => x.Name), "Status.Name", "ReportInfo.ReportName"];

const SearchFilter = ({setFilterHandler, needResetFilter}) => {
    const projects = useSelector((state) => state.projects.projects);

    const fieldsToSearchWithProjects = [
        ...fieldsToSearch,
        (item) => {
            const project = projects.find((p) => p.Id === item.ReportInfo.ProjectId);
            return project.ShortName ?? project.Name;
        },
    ];

    return (
        <CommonSearchFilter
            setFilterHandler={setFilterHandler}
            needResetFilter={needResetFilter}
            fieldsToSearch={fieldsToSearchWithProjects}
        />
    );
};

export default SearchFilter;
