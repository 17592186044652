import React from "react";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";
import {formatTeamMemberName, formatContactName} from "../../../../helpers/text";

const fieldsToSearch = [
    "Contact.FirstName",
    "Contact.LastName",
    "Contact.Nickname",
    (item) => item.RoleTags.map((x) => x.Name),
    (item) => item.GroupTags.map((x) => x.Name),
    "Contact.EmailAddresses[0].EmailAddress",
    "Contact.Addresses[0].Line1",
    "Contact.Company",
    (item) => (item.spaces || []).map((s) => formatTeamMemberName(s)),
    (item) => formatContactName(item.Contact?.Parent),
];

const SearchFilter = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter setFilterHandler={setFilterHandler} needResetFilter={needResetFilter}
                        fieldsToSearch={fieldsToSearch}/>
);

export default SearchFilter;
