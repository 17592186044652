import {List, Button} from "antd";
import React, {useEffect, useState} from "react";
import _ from "lodash";

import "./ItemsManagement.scss";
import EditRow from "./EditRow";
import {useSelector} from "react-redux";
import {loadResourcesLookup, loadCollections, getCollection} from "../../../../../services/resources";
import CreateResourceModal from "../../../../Common/QuickCreate/Resource";
import {ScreenType} from "../../../../../constants/constants";

const ItemsManagement = ({collection, onChange, onDelete}) => {

    const [freeSlotsCount, setFreeSlotsCount] = useState(0);
    const [isQuickCreateItemVisible, setIsQuickCreateItemVisible] = useState(false);
    const [[modalItem, modalItemIndex, forceSave], setModalItem] = useState([null, -1, false]);

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const resources = useSelector((state) => state.resources.resourcesLookup || []);
    const sortedResources = _.sortBy(resources, (s) => s.Name);

    const items = collection?.Items || [];

    const getSlots = (count) => {
        const template = {
            AccountId: activePortfolioId,
            CollectionId: collection?.Id,
            Quantity: 1,
        };

        let slots = [];
        for (var i = 0; i < count; i++)
            slots.push({...template});

        return slots;
    };

    const sourcesAndSlots = [
        ...items,
        ...getSlots(freeSlotsCount)
    ];

    useEffect(() => {
        if (!collection) return;

        if (sortedResources.length === 0) {
            loadResourcesLookup(activePortfolioId);
        }

    }, [collection?.Id, activePortfolioId, sortedResources.map(r => r.Id).join(""), isQuickCreateItemVisible]);

    useEffect(() => {
        if (!collection?.Id) return;
        if (sourcesAndSlots.length === 0) onSlotAdd();
    }, [collection?.Id, activePortfolioId, sourcesAndSlots.length]);

    const onSlotAdd = () => setFreeSlotsCount(freeSlotsCount + 1);
    const onSlotRemove = () => setFreeSlotsCount(freeSlotsCount - 1);

    const onItemDelete = (itemId) => {
        if (!itemId) onSlotRemove();
        onDelete(itemId);
        //refresh();
    };

    const onItemChange = (item) => {
        onChange(item);

        setModalItem([null, -1, false]);
        //refresh();
    }

    return (
        <>
            <div>
                <List hidden={!collection}
                      header={
                          <div className="details-src-item">
                              <div className="item">Item</div>
                              <div className="source">Source</div>
                              <div className="inventory">Quantity</div>
                              <div className="actions">
                                  <Button type="primary" size="small" onClick={onSlotAdd}>Add</Button>
                              </div>
                          </div>
                      }
                      size="small"
                      dataSource={sourcesAndSlots}
                      renderItem={(item, index) => {
                          const effectiveItem = modalItemIndex === index ? modalItem : item;
                          return (
                              <List.Item>
                                  <EditRow
                                      item={effectiveItem}
                                      collection={collection}
                                      setIsQuickCreateItemVisible={(visible) => {
                                          setIsQuickCreateItemVisible(visible);
                                          if (visible) {
                                              setModalItem([item, index, false]);
                                          }
                                      }}
                                      forceSave={modalItemIndex === index && forceSave}
                                      resources={sortedResources}
                                      onDelete={onItemDelete}
                                      onChange={onItemChange}
                                      activePortfolioId={activePortfolioId}
                                  />
                              </List.Item>
                          );
                      }}></List>
            </div>

            <CreateResourceModal
                refreshType={ScreenType.Collections}
                visible={isQuickCreateItemVisible}
                onClose={(data) => {
                    const createdId = data?.ResourceId;

                    if (createdId > 0) {
                        const newItem = {...modalItem, ResourceId: createdId};
                        loadResourcesLookup(activePortfolioId)
                            .then(items => setModalItem([newItem, modalItemIndex, true]));
                        setTimeout(() => setIsQuickCreateItemVisible(false), 500);
                    }

                    setIsQuickCreateItemVisible(false);

                }}/>

        </>
    );

};

export default ItemsManagement;
