import React from "react";
import {Button, Space} from "antd";
import {useDispatch} from "react-redux";

import {setUserToEdit} from "../../../redux/reducers/settings";
import DeleteUserAccess from "../Delete/DeleteUserAccess";
import TakeOwnership from "../TakeOwnership/TakeOwnership";

const SettingsRowButtons = () => {
    const dispatch = useDispatch();

    function newItemClick() {
        dispatch(setUserToEdit({}));
    }

    return (
        <Space wrap>
            <TakeOwnership></TakeOwnership>
            <DeleteUserAccess></DeleteUserAccess>
            <Button type="primary" onClick={newItemClick}>
                New User
            </Button>
        </Space>
    );
};

export default SettingsRowButtons;
