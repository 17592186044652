import React, {useEffect, useMemo} from "react";
import {Tabs} from "antd";
import _ from "lodash";
import {UserOutlined, ShopOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {loadTeam} from "../../services/team";
import {changeProject, getProjectId} from "../../helpers/navigate";
import {TeamContactType, ApiContactType} from "../../constants";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import TeamTable from "./TeamTable";
import {useState} from "react";

const {TabPane} = Tabs;

const tabs = [
    {
        key: TeamContactType.Person,
        path: "/team/people",
        contactsFilter: {Types: [0]},
    },
    {
        key: TeamContactType.Organization,
        path: "/team/organizations",
        contactsFilter: {Types: [1]},
    },
    {
        key: TeamContactType.Location,
        path: "/team/locations",
        contactsFilter: {Types: [2]},
    },
];
const getTabByKey = (key) => tabs.find((tab) => tab.key === key);

const Team = ({activeKey}) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const activeProject = useSelector((state) => state.projects.activeProject);
    const allTeamMembers = useSelector((state) => state.team.project[activeProject?.Id]) || [];
    const people = _.orderBy(
        allTeamMembers.filter((t) => t.Contact === null || t.Contact?.Type === ApiContactType.Person),
        [(p) => p.ContactId !== null, (p) => _.isEmpty(p.Contact?.LastName), (p) => p.Contact?.LastName]
    );

    const organizations = allTeamMembers.filter((t) => t.Contact === null || t.Contact?.Type === ApiContactType.Organization);
    const locations = allTeamMembers.filter((t) => t.Contact === null || t.Contact?.Type === ApiContactType.Place);
    const spaces = allTeamMembers.filter((t) => t.Contact?.Type === ApiContactType.Space);
    const [loading, setLoading] = useState(true);

    const locationsWithSpaces = useMemo(() => {
        return locations.map((l) => ({...l, spaces: spaces.filter((s) => s.Contact?.ParentId === l.ContactId)}));
    }, [locations, spaces]);

    useEffect(() => {
        if (!activeProject) return;

        const fetchData = async () => {
            setLoading(true);
            await loadTeam(activeProject.Id);
            setLoading(false);
        };
        fetchData();
    }, [activeProject, dispatch, activeKey]);

    const changeTab = (key) => {
        const path = getTabByKey(key).path;
        location.pathname = path;
        const projectId = getProjectId(location);
        changeProject(projectId, location, history);
    };

    return (
        <Tabs onChange={changeTab} type="card" activeKey={activeKey}>
            <TabPane
                tab={
                    <span>
            <UserOutlined/> People
          </span>
                }
                key={TeamContactType.Person}>
                <TeamTable items={people} contactType={TeamContactType.Person} loading={loading}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <ShopOutlined/> Organizations
          </span>
                }
                key={TeamContactType.Organization}>
                <TeamTable items={organizations} contactType={TeamContactType.Organization} loading={loading}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <EnvironmentOutlined/> Locations
          </span>
                }
                key={TeamContactType.Location}>
                <TeamTable items={locationsWithSpaces} contactType={TeamContactType.Location} loading={loading}/>
            </TabPane>
        </Tabs>
    );
};

export default Team;
