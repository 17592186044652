import React, {useEffect, useRef, useState} from "react";
import {Collapse, Form, message} from "antd";
import {ApiContactType, NewRecordState} from "../../../constants/constants";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {InputWithFormItemWithRef, TextAreaWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import {ContainerOutlined, FileTextOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {getTemplate, loadReports, updateTemplate} from "../../../services/reports";
import "./ReportsDetailsPanel.scss";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import DepartmentsNotes from "./DepartmentsNotes/DepartmentsNotes";
import AuditFormItem from "../../Common/AuditFormItem";

const InformationHeader = (
    <>
        <InfoCircleOutlined/> Template Information
    </>
);

const prepareFormData = (data) => {
    const formData = {
        ...data,
    };

    return formData;
};

const ReportTemplateDetailsPanel = ({}) => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);

    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [isQuickCreateOrgVisible, setIsQuickCreateOrgVisible] = useState(false);

    const activeProject = useSelector((state) => state.projects.activeProject);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        if (!itemToEdit) {
            setTimeout(() => firstInputRef.current.focus(), 200);
            return;
        }

        setEditItemState(NewRecordState.ReadyForEdit);
        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndUpdate = (finishedForm) => {
        const updatePayload = {
            Id: itemToEdit.Id,
            Name: finishedForm.Name,
            Notes: finishedForm.Notes,
            ReportNotes: finishedForm.ReportNotes,
        };

        updateTemplate(updatePayload)
            .then(() => getTemplate(itemToEdit.Id).then((item) => dispatch(continueEdit({item}))))
            .then(() => loadReports(activeProject?.Id, activePortfolio.Id))
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                    //Force reset fom if error!
                    form.setFieldsValue(itemToEdit);
                }
            });
    };

    const onFinish = (finishedForm) => {
        if (editItemState === NewRecordState.ReadyForEdit) {
            prepareAndUpdate(finishedForm);
        }
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            if (!isOptionalFieldDisabled) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;
    return (
        <div className="side-panel report-side-panel with-collapse">
            <Collapse defaultActiveKey={["Information", "InAttendance", "GeneralNotes", "DepartmentsNotes"]}>
                <Collapse.Panel key="Information" header={InformationHeader}>
                    <Form form={form} labelCol={{span: 4}} wrapperCol={{span: 8}} layout="horizontal" size="middle">
                        <InputWithFormItemWithRef
                            name="Name"
                            label="Template Name"
                            hasFeedback
                            rules={[{required: true, message: "Name"}]}
                            onChanged={save}
                            placeholder="Enter a name to create new Report"
                        />
                        {itemToEdit && (
                            <>
                                <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt"
                                               userProp="CreatedBy"/>
                                <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt"
                                               userProp="UpdatedBy"/>
                            </>
                        )}
                    </Form>
                </Collapse.Panel>

                <Collapse.Panel
                    key="GeneralNotes"
                    header={
                        <>
                            <ContainerOutlined/> General Notes
                        </>
                    }>
                    <Form form={form} labelCol={{span: 0}} wrapperCol={{span: 24}} layout="horizontal" size="middle">
                        <TextAreaWithFormItem
                            name="Notes"
                            label={null}
                            onChanged={save}
                            placeholder="Enter General Notes"
                            useTextEditor={true}
                            disabled={isOptionalFieldDisabled}
                        />
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel
                    key="DepartmentsNotes"
                    className="departments-notes-panel"
                    header={
                        <>
                            <FileTextOutlined/> Departments Notes
                        </>
                    }>
                    <Form form={form} labelCol={{span: 0}} wrapperCol={{span: 24}} layout="horizontal" size="middle">
                        <Form.Item name="ReportNotes" className="ant-form-item-without-validation">
                            <DepartmentsNotes disabled={isOptionalFieldDisabled} onChanged={save}></DepartmentsNotes>
                        </Form.Item>
                    </Form>
                </Collapse.Panel>
            </Collapse>

            <CreateContact
                apiContactType={ApiContactType.Person}
                isVisible={isQuickCreateContactVisible}
                onClose={() => setIsQuickCreateContactVisible(false)}
                onContactCreated={(contactId) => {
                    const values = form.getFieldsValue("TeamMemberIds");
                    onFinish({
                        ...values,
                        TeamMemberContactIds: [contactId],
                    });
                }}></CreateContact>

            <CreateContact
                apiContactType={ApiContactType.Organization}
                isVisible={isQuickCreateOrgVisible}
                onClose={() => setIsQuickCreateOrgVisible(false)}
                onContactCreated={(contactId) => {
                    const values = form.getFieldsValue("TeamMemberIds");
                    onFinish({
                        ...values,
                        TeamMemberContactIds: [contactId],
                    });
                }}></CreateContact>
        </div>
    );
};

export default ReportTemplateDetailsPanel;
