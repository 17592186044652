import React from "react";
import {useSelector} from "react-redux";
import {downloadContactsAsCsv} from "../../../services/contacts";
import {apiContactTypes} from "../ContactImport/helper";

const ContactExport = (props) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio.Id);
    const contactType = props.contactType;
    const selected = useSelector((store) => store.mainTable.selected);

    const onExport = () => {
        const types = [apiContactTypes[contactType]];
        const contactIds = selected.map((c) => c.Id);
        downloadContactsAsCsv(activePortfolioId, types, "", contactIds);
    };

    return <div onClick={onExport}> Export</div>;
};

export default ContactExport;
