import api from "../api/api";
import store from "../redux/store";
import {setNotes, setReports, setReportTemplates} from "../redux/reducers/reports";

const {dispatch} = store;

const defaultFilter =
    {
        Keyword: "",
        IncludeScheduleType: true,
        IncludeReportType: true,
        IncludeCallSheetType: true,
        ProjectDeliverablesSortOrder: 2,
        ProjectDeliverablesSortAscending: true
    }

export const loadReports = (projectId, accountId) =>
    api
        .post(`api/ProjectDeliverable/GetAll?projectId=${projectId}&accountId=${accountId}`, defaultFilter)
        .then((response) => {
            dispatch(setReports(response.Deliverables?.Deliverables || []));
            dispatch(setReportTemplates(response.DeliverableReportTemplates || []));
            return response;
        });

export const create = (projectId, payload) =>
    api.post(`api/ProjectDeliverable/Create?projectId=${projectId}&type=2`, payload);

export const remove = (id) =>
    api.post(`api/ProjectDeliverable/Remove?`, {Id: id});

export const removeTemplate = (id) =>
    api.post(`api/ProjectDeliverable/RemoveTemplate?id=${id}`);

export const update = (payload) =>
    api.post(`api/ProjectDeliverable/Update?`, payload, `Update_Report_${payload.Id}`);

export const updateTemplate = (payload) =>
    api.post(`api/ProjectDeliverable/UpdateTemplate?`, payload, `Update_Report_Template_${payload.Id}`);


export const changePubliclyAccessible = (deliverableId, IsPubliclyAccessible) =>
    api.post(`api/ProjectDeliverable/ChangePubliclyAccessible?deliverableId=${deliverableId}&IsPubliclyAccessible=${IsPubliclyAccessible}`, null, `ChangePubliclyAccessible_Report_${deliverableId}`);

export const getItem = (id) => api.get(`api/ProjectDeliverable/Get?id=${id}&includeFlags=25`, `Get_Report_${id}`);

export const getTemplate = (id) => api.get(`api/ProjectDeliverable/GetTemplate?id=${id}`, `Get_Template_${id}`);

export const refreshNotes = (projectIds) => api.post(`api/ProjectDeliverable/GetNotes`, projectIds)
    .then(notes => {
        dispatch(setNotes(notes));
        return notes;
    });

export const getNotes = (projectIds) => api.post(`api/ProjectDeliverable/GetNotes`, projectIds)

export const updateNotes = (payload) => api.post(`api/ProjectDeliverable/UpdateNotes`, payload);

export const markNoteDone = (ids, isDone) => api.post(`api/ProjectDeliverable/MarkNoteDone`, {
    Ids: ids,
    IsDone: isDone
});

export const createTemplate = (id, accountId) => api.post(`api/ProjectDeliverable/CreateTemplate?id=${id}&accountId=${accountId}`);

export const createReportFromEvent = (taskId, templateId) => api.post(`api/ProjectDeliverable/CreateFromTask?templateId=${templateId}&taskId=${taskId}`);

export const createFromTemplate = (templateId, projectId) => api.post(`api/ProjectDeliverable/CreateFromTemplate?templateId=${templateId}&projectId=${projectId}`);

export const sendReport = (payload) =>
    api.post(`api/ProjectDeliverable/Publish`, payload);

