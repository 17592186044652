import React, {useEffect} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import TreeSelectFilter from "./TreeSelectFilter";

export const selectDefaultOption = {value: "— All Categories —", title: "— All Categories —"};
export const noCategoriesOption = {value: "— Events With No Categories —", title: "— Events With No Categories —"};

const Categories = ({value, projectIds, setFilterHandler, onChange, onChanged, disabled}) => {
    const categories = useSelector((state) => state.tags.categories || []);

    function initFilterHandler(filterValues = []) {
        if (filterValues.length === 0) {
            setFilterHandler(null);
        } else {
            const isNoItemSelected = filterValues.find((i) => i === noCategoriesOption.value);
            setFilterHandler(
                (item) =>
                    _.intersection(item.CategoryTags, filterValues).length > 0 || (isNoItemSelected && item.CategoryTags.length === 0)
            );
        }
    }

    function onSelected(filterValues) {
        initFilterHandler(filterValues);
        onChange(filterValues);
        onChanged();
    }

    useEffect(() => initFilterHandler(value), [value]);

    const categoriesOption = "All Other Categories";
    const groups = [
        {
            title: "All Other Categories",
            key: categoriesOption,
            value: "All Other Categories",
            filterOff: true,
            children: [noCategoriesOption, ...categories.map((d) => ({
                value: d.AccountTagId.toString(),
                title: d.Name
            }))],
        },
    ];

    return (
        <TreeSelectFilter
            treeDefaultExpandedKeys={[categoriesOption]}
            value={value}
            disabled={disabled}
            treeData={groups}
            placeholder="All Categories"
            selectDefaultOption={selectDefaultOption}
            onChanged={onSelected}></TreeSelectFilter>
    );
};

export default Categories;
