import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";

const noItem = {
    value: "noItem",
    title: "— No Group —",
    sortRank: 1,
};

function getGroups(data) {
    return [
        noItem,
        ..._.chain(data)
            .map((d) => d.Groups)
            .flatten()
            .uniqBy((x) => x.AccountTagId)
            .map((item) => ({value: item.AccountTagId, title: item.Name}))
            .value(),
    ];
}

const GroupFilter = ({data = [], needResetFilter, setFilterHandler, allItemsText = "All Default Groups"}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const items = useMemo(() => {
        return getGroups(data);
    }, [data]);

    const groupsHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [groupsHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [groupsHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler(
            (item) =>
                _.intersection(
                    item.Groups.map((x) => x.AccountTagId),
                    filterValues
                ).length > 0 ||
                (isNoItemSelected && item.Groups.length === 0)
        );

        setSelectedItems(selectedItems);
    }

    const allItems = getGroups(data);

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={allItems}
            allItemsText={allItemsText}
            onChanged={onChanged}
            search
        />
    );
};

export default GroupFilter;
