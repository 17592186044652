import {createSlice} from "@reduxjs/toolkit";

const productionBooksSlice = createSlice({
    name: "productionBooks",
    initialState: {items: []},
    reducers: {
        setProductionBooks(state, action) {
            state.items = action.payload;
        },
    },
});

export const {setProductionBooks} = productionBooksSlice.actions;

export default productionBooksSlice.reducer;
