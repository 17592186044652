// import { Menu, Dropdown, Button, Space, Radio, Checkbox } from "antd";
// import { DownOutlined } from "@ant-design/icons";
// import "./GroupBy.scss";

// const handleGroupByFilterMenuClick = () => {};
// const GroupByFilterMenu = (
//   <Radio.Group onChange={() => {}}>
//     <Menu onClick={handleGroupByFilterMenuClick}>
//       <Menu.Item key="1">
//         <Radio value={1}>Organization</Radio>
//       </Menu.Item>
//       <Menu.Item key="2">
//         <Radio value={2}>Default Roles</Radio>
//       </Menu.Item>
//       <Menu.Item key="3">
//         <Radio value={3}>Default Groups</Radio>
//       </Menu.Item>
//     </Menu>
//   </Radio.Group>
// );

// const GroupByFilter = ({ value = "Group By" }) => (
//   <Dropdown overlay={GroupByFilterMenu} className="filter-group-by">
//     <Button type="link">
//       {value} <DownOutlined />
//     </Button>
//   </Dropdown>
// );

// export default GroupByFilter;

import React, {useState, useEffect} from "react";
import {Menu, Dropdown, Button, Space, Radio, Checkbox} from "antd";
import {DownOutlined} from "@ant-design/icons";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const ContactGroupBy = Object.freeze({
    DefaultRoles: "Default Roles",
    DefaultGroups: "Default Groups",
});

const items = [
    {
        value: ContactGroupBy.DefaultRoles,
        title: ContactGroupBy.DefaultRoles,
    },
    {
        value: ContactGroupBy.DefaultGroups,
        title: ContactGroupBy.DefaultGroups,
    },
];

const GroupByFilter = ({setGroupBy}) => {
    const [selectedItem, setSelectedItem] = useState();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
