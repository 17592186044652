import React from "react";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";

import {deleteResources, loadResources} from "../../../../services/resources";
import "./DeleteResource.scss";
import usePermissions from "../../../../hooks/usePermissions";
import {doBatchDelete} from "../../../Common/Modal/BatchDelete/BatchDelete";
import DeleteAction from "../../../Common/Tables/Actions/DeleteAction";
import {setSelected} from "../../../../redux/reducers/mainTable";
import {TooltipWithOrNot} from "../../../Common/Input";

const getTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete ${selected[0].Name}?`;
    } else {
        return `Are you sure you want to delete these ${selected.length} items?`;
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Resource was deleted.`);
    } else {
        message.success(`${selected.length} resources were deleted.`);
    }
};

const DeleteResource = ({record}) => {
    const dispatch = useDispatch();
    const {hasThingsAccess, isProjectManager} = usePermissions();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const accountId = activePortfolio?.Id;

    const canDelete = hasThingsAccess && !isProjectManager;

    const className = classNames({
        disabled: !canDelete,
        "delete btn-table-actions": true,
    });
    return (
        <TooltipWithOrNot showTooltip={!canDelete} title="Only Owners and Administrators may delete items.">
            <DeleteAction
                record={record}
                className={className}
                onClick={() => {
                    if (!canDelete) return;

                    doBatchDeleteResources(accountId, [record], () => {
                        dispatch(setSelected([]));
                        loadResources(accountId);
                    });
                }}></DeleteAction>
        </TooltipWithOrNot>
    );
};

export const doBatchDeleteResources = (accountId, selected, onFinish) => {
    return doBatchDelete({
        onFinish,
        title: getTitle(selected),
        content: (
            <>
                Their record and all information associated with them will be deleted FOREVER. <br/>
                <br/>
                You cannot undo this action.
            </>
        ),
        batchRemove: () => deleteResources(accountId, selected.map((s) => s.Id)),
        onFinishMessage: () => showFinishMessage(selected),
        okText: "Delete",
    });
};

export default DeleteResource;
