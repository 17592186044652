import React from "react";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {useSelector} from "react-redux";
import {TagIcon} from "../../../../helpers/Tags";

const mapValueToSelectedOptions = (value, options) => {
    if (value === 0) return [];

    return options.filter((x) => value.includes(x.value));
};

export const disableAllOption = {value: "— All Statuses —", title: "— All Statuses —"};
export const noStatusesOption = {value: "— No Status  —", title: "— No Status  —", sortRank: 0};

const Statuses = ({disabled, value = [], onChange, onChanged}) => {
    const statuses = useSelector((state) => state.tags.statuses || []);
    const statusOptions = statuses.map((status) => ({
        value: status.AccountTagId.toString(),
        icon: <TagIcon icon={status.Icon}/>,
        title: status.Name,
        sortRank: 2,
    }));

    function onItemsChanged(newSelectedItems) {
        const filterValues = newSelectedItems.map((i) => i.value);
        onChange(filterValues);
        onChanged();
    }

    const allOptions = [noStatusesOption, ...statusOptions];
    return (
        <MultipleFilterWithSearchFilter
            disabled={disabled}
            trigger="click"
            selectedItems={mapValueToSelectedOptions(value, allOptions)}
            items={allOptions}
            allItemsText="All Statuses"
            onChanged={onItemsChanged}
        />
    );
};

export default Statuses;
