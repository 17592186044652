import React from "react";
import {Alert, Row, Col} from "antd";
import {useSelector} from "react-redux";
import {AccountType} from "../../../constants/constants";

const NoActivePortfolioSecondRow = () => {
    const isPortfolioLoading = useSelector((state) => state.projects.isPortfolioLoading);

    const nonPersonalPortfolio = useSelector((state) => state.projects.portfolios).find((p) =>
        [AccountType.Group, AccountType.Admin].includes(p.Type)
    );
    return (
        !nonPersonalPortfolio &&
        !isPortfolioLoading && (
            <Row justify="center" className="no-subscriptions-alert">
                <Col>
                    <Alert
                        message="You do not have access to any subscriptions. Please contact an owner or administrator to confirm the correct email address or to update your access."
                        type="error"></Alert>
                </Col>
            </Row>
        )
    );
};

export default NoActivePortfolioSecondRow;
