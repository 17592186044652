import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {useSelector} from "react-redux";

const noItem = {
    value: "noItem",
    title: "— No Role —",
    sortRank: 1,
};

function getRoles(data) {
    return [
        noItem,
        ..._.chain(data)
            .map((d) => d.Specialties)
            .flatten()
            .uniqBy((x) => x.AccountTagId)
            .map((item) => ({value: item.AccountTagId, title: item.Name}))
            .value(),
    ];
}

const RoleFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();
    const positionName = useSelector((state) => state.detailsSubPanel.item?.PositionName);

    const items = useMemo(() => {
        return getRoles(data);
    }, [data]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, []);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        if (positionName) {
            const item = items.find((i) => i.title === positionName);
            onChanged(item ? [item] : items);
        } else {
            onChanged(items);
        }
    }, []);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler(
            (item) =>
                _.intersection(
                    item.Specialties.map((x) => x.AccountTagId),
                    filterValues
                ).length > 0 ||
                (isNoItemSelected && item.Specialties.length === 0)
        );

        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Roles"
            onChanged={onChanged}
            search
        />
    );
};

export default RoleFilter;
