const projectIdParamKey = "projectId";
const defaultPortfolioParamKey = "portfolio";
const studentPortfolioParamKey = "student";

export function changeProject(newProjectId, location, history) {
    const params = new URLSearchParams(location.search);

    params.set(projectIdParamKey, newProjectId);
    history.push(`${location.pathname}?${params.toString()}`);
}

export function getProjectId(location) {
    const params = new URLSearchParams(location.search);
    return params.get(projectIdParamKey);
}

export const changeActivePortfolioId = (portfolioId, location, history, isStudent) => {
    const params = new URLSearchParams(location.search);

    params.set(getPortfolioParamKey(isStudent), portfolioId);
    history.push(`${location.pathname}?${params.toString()}`);
}

export const appendPortfolioId = (url, portfolioId, isStudent) => {
    const params = new URLSearchParams();
    params.set(getPortfolioParamKey(isStudent), portfolioId);

    return (`${url}?${params.toString()}`);
}

export function getActivePortfolioId(location, isStudent) {
    const params = new URLSearchParams(location.search);

    return parseInt(params.get(getPortfolioParamKey(isStudent)), 10);
}

const getPortfolioParamKey = (isStudent) => {
    return isStudent ? studentPortfolioParamKey : defaultPortfolioParamKey;
};