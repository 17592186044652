import {FolderOpenFilled} from "@ant-design/icons";
import {Button, Card, List, Badge} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getRecentlyUpdatedData} from "../../../services/projects";
import {setRecentlyUpdatedProjects} from "../../../redux/reducers/projects";
import "./RecentlyUpdatedProjects.scss";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../helpers/paths";
import {ApiProjectType, ProjectType} from "../../../constants/constants";
import GoTo from "../../Common/Projects/GoTo";

const RecentlyUpdatedProjects = () => {
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const recentlyUpdatedProjects = useSelector((state) => state.projects.recentlyUpdatedProjects);
    const projects = useSelector((state) => state.projects.projects);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;

        (async () => {
            if (isMounted) setLoading(true);
            try {
                const data = await getRecentlyUpdatedData();
                if (isMounted) dispatch(setRecentlyUpdatedProjects(data.Projects));
            } catch (error) {
                console.error(error);
            }
            if (isMounted) setLoading(false);
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    const findProject = (id) => projects.find((p) => p.Id === id);

    return (
        <Card
            loading={loading}
            title={
                <>
                    <FolderOpenFilled/> Recently Updated Projects
                </>
            }
            extra={
                <Button type="link" size="small"
                        href={urlWithPortfolioId(pathCollection.projectsPath[ProjectType.Active])}>
                    See All Projects
                </Button>
            }
            className="recently-updated-projects">
            <List
                dataSource={recentlyUpdatedProjects}
                renderItem={(item) => (
                    <List.Item actions={[]}>
                        {item.Name}
                        <div className="go-to">
                            <GoTo project={item}></GoTo>
                        </div>

                        {findProject(item.Id)?.State === ApiProjectType.Inactive && (
                            <Badge className="project-status-badge project-status-badge-selector" count="Draft"></Badge>
                        )}
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default RecentlyUpdatedProjects;
