import React, {useState} from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {RequirementType, ResourceType} from "../../../constants/constants";
import {doBatchDeleteLabourLines, doBatchDeleteRequirements} from "../DeleteRequirement";
import {openNew} from "../../../redux/reducers/detailsPanel";
import usePermissions from "../../../hooks/usePermissions";
import {refreshRequirements} from "../../../services/requirements";
import {setSelected} from "../../../redux/reducers/mainTable";
import AddCollectionModal from "./AddCollectionModal";
import {onCloneRequirements} from "../RequirementItems/CloneFromExisting";
import BatchEdit from "../RequirementItems/BatchEdit";
import RequirementExport from "../RequirementItems/Export/RequirementExport";
import Shift from "../../Common/Modal/Shift";
import {shift} from "../../../services/requirements";
import {loadLabourLines} from "../../../services/labour";
import {doResetRates} from "../Labour/ResetRates/ResetRates";
import ClearSelection from "../../Common/ClearSelection";

const selectedType = {
    [RequirementType.Item]: "Requirements",
    [RequirementType.Labour]: "Labour Lines",
};

const RequirementSelected = ({selected, requirementType}) => (
    <div>{`${selected.length} ${selectedType[requirementType]} selected`}</div>
);

const RequirementsTitleRowButtons = ({requirementType}) => {
    const selected = useSelector((store) => store.mainTable.selected);
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    const [isAddCollectionPopupVisible, setIsAddCollectionPopupVisible] = useState(false);
    const [isShiftPopupVisible, setShiftPopupVisible] = useState(false);

    const {hasThingsAccess} = usePermissions();
    const dispatch = useDispatch();

    function handleNewRequirementClick(e) {
        const type = e.key;
        switch (type) {
            case RequirementType.Item:
                setTimeout(() => dispatch(openNew({type})), 100);
                break;

            case ResourceType.Collection:
                setIsAddCollectionPopupVisible(true);
                break;
            default:
                break;
        }
    }

    function handleNewLabourClick() {
        setTimeout(() => dispatch(openNew({type: RequirementType.Labour})), 100);
    }

    const onBatchDelete = () => {
        if (requirementType === RequirementType.Item) {
            doBatchDeleteRequirements(selected, () => {
                dispatch(setSelected([]));
                refreshRequirements();
            });
        }
        if (requirementType === RequirementType.Labour) {
            doBatchDeleteLabourLines(selected, () => {
                dispatch(setSelected([]));
                loadLabourLines(activePortfolio.Id);
            });
        }
    };
    const onResetRates = () => {
        if (selected.length === 0) return;

        doResetRates({
            selected,
            accountId: activePortfolio.Id,
            refreshSelection: () => {
                dispatch(setSelected([]));
            },
        });
    };

    const nothingSelected = selected.length === 0;
    const MoreMenu = (
        <Menu>
            {requirementType === RequirementType.Item && (
                <>
                    <Menu.Item key="1" disabled={nothingSelected} onClick={() => setShiftPopupVisible(true)}>
                        Shift
                    </Menu.Item>
                    <Menu.Item key="2" disabled={nothingSelected}
                               onClick={() => onCloneRequirements(selected, dispatch)}>
                        Clone
                    </Menu.Item>
                </>
            )}
            {requirementType === RequirementType.Labour && (
                <Menu.Item key="4" disabled={nothingSelected} onClick={onResetRates}>
                    Reset Rates
                </Menu.Item>
            )}
            {requirementType === RequirementType.Item && (
                <Menu.Item key="3">
                    <RequirementExport/>
                </Menu.Item>
            )}
        </Menu>
    );

    if (!hasThingsAccess) return null;

    const NewRequirementMenu = (
        <Menu onClick={(e) => handleNewRequirementClick(e)}>
            <Menu.Item key={RequirementType.Item}>Requirement</Menu.Item>
            <Menu.Item key={ResourceType.Collection}>By Collection</Menu.Item>
        </Menu>
    );

    return (
        <>
            <Space>
                {isBulkSelection && <RequirementSelected requirementType={requirementType} selected={selected}/>}
                <ClearSelection/>
                <Dropdown trigger="click" overlay={MoreMenu}>
                    <Button>
                        More <DownOutlined/>
                    </Button>
                </Dropdown>

                <Button danger disabled={selected.length === 0} onClick={(e) => onBatchDelete(e)}>
                    Delete
                </Button>

                {requirementType === RequirementType.Item && (
                    <Dropdown disabled={isBulkSelection} trigger="click" overlay={NewRequirementMenu}>
                        <Button type="primary">
                            New Requirement <DownOutlined/>
                        </Button>
                    </Dropdown>
                )}

                {requirementType === RequirementType.Labour && (
                    <Button type="primary" onClick={handleNewLabourClick}>
                        New Labour Line
                    </Button>
                )}

                {isBulkSelection && requirementType === RequirementType.Item && <BatchEdit selected={selected}/>}
            </Space>

            <AddCollectionModal visible={isAddCollectionPopupVisible}
                                onClose={() => setIsAddCollectionPopupVisible(false)}/>

            <Shift
                selected={selected}
                isVisible={isShiftPopupVisible}
                onClose={() => setShiftPopupVisible(false)}
                onShift={shift}
                itemName="requirement"
                showTimeUnits={false}
                startDateDetector={(selected) => selected.length > 0 && selected[selected.length - 1].Requirement.StartDateTime}
                earliestItemNameDetector={(selected) => selected.length > 0 && selected[selected.length - 1].Requirement?.Name}
            />
        </>
    );
};

export default RequirementsTitleRowButtons;
