import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Modal, message, Button} from "antd";
import {deleteBooking, deleteEvents, refreshTimeline} from "../../../services/timeline";
import "./DeleteEvents.scss";
import {useDispatch, useSelector} from "react-redux";
import {ApiTimelineItemType} from "../../../constants/constants";
import {setSelected} from "../../../redux/reducers/mainTable";

const getEventOrBooking = (selected) => (selected.ItemType === ApiTimelineItemType.Booking ? "booking" : "event");
const getEventsOrBookings = (selected) => (selected.ItemType === ApiTimelineItemType.Booking ? "bookings" : "events");

const DeleteContact = () => {
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const projects = useSelector((store) => store.projects.projects) || [];

    const getProject = (id) => projects.find((p) => p.Id === id);
    const dispatch = useDispatch();

    const getTitle = () => {
        if (selected.length === 1) {
            return `Are you sure you want to delete this ${getEventOrBooking(selected[0])} from ${
                getProject(selected[0].ProjectId)?.Name || ""
            }?`;
        } else {
            return `Are you sure you want to delete ${selected.length} ${getEventsOrBookings(selected[0])}?`;
        }
    };

    return (
        <Button
            disabled={selected.length === 0}
            danger
            onClick={(e) => {
                e.stopPropagation();

                Modal.confirm({
                    title: getTitle(),
                    icon: <DeleteOutlined/>,
                    content: "You cannot undo this action",
                    className: "delete-contact-modal",
                    okText: "Delete",
                    okButtonProps: {danger: true},
                    async onOk() {
                        const ids = selected.map((b) => b.Id);
                        if (selected[0].ItemType === ApiTimelineItemType.Booking) {
                            await deleteBooking(ids);
                        } else {
                            await deleteEvents(ids);
                        }
                        await refreshTimeline(selected[0].ItemType);

                        if (selected.length === 1) {
                            message.success(`${selected.length} ${getEventOrBooking(selected[0])} was deleted.`);
                        } else {
                            message.success(`${selected.length} ${getEventsOrBookings(selected[0])} were deleted.`);
                        }

                        dispatch(setSelected([]));
                    },
                    onCancel() {
                    },
                });
            }}>
            Delete
        </Button>
    );
};

export default DeleteContact;
