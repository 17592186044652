import React, {useEffect, useState} from "react";
import {Tabs} from "antd";

import {FolderOpenFilled, LockOutlined} from "@ant-design/icons";

import {ProjectType} from "../../constants";
import ProjectsTable from "./ProjectsTable";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";
import {refreshContacts} from "../../services/contacts";
import {refreshProjects, refreshArchivedProjects} from "../../services/projects";

const {TabPane} = Tabs;

const tabs = [
    {
        key: ProjectType.Active,
        path: "/projects/active",
    },
    {
        key: ProjectType.Archive,
        path: "/projects/archive",
    },
];

const getTabByKey = (key) => tabs.find((tab) => tab.key === key);

const Projects = ({activeKey}) => {
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const projects = useSelector((state) => state.projects.projects);
    const archivedProjects = useSelector((state) => state.projects.archivedProjects);
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const [isProjectsLoading, setProjectsLoading] = useState(false);

    const activeItems = projects.filter((p) => [0, 1].includes(p.State));

    useEffect(() => {
        if (activePortfolioId) {
            refreshContacts();

            if (activeKey === ProjectType.Archive) {
                setProjectsLoading(true);
                refreshArchivedProjects().finally(() => setProjectsLoading(false));
            }
            if (activeKey === ProjectType.Active) {
                setProjectsLoading(true);
                refreshProjects().finally(() => setProjectsLoading(false));
            }
        }
    }, [activePortfolioId]);

    const changeTab = (key) => {
        const path = getTabByKey(key).path;
        history.push(urlWithPortfolioId(path));
    };
    return (
        <Tabs onChange={changeTab} type="card" activeKey={activeKey}>
            <TabPane
                tab={
                    <span>
            <FolderOpenFilled/> Active
          </span>
                }
                key={ProjectType.Active}>
                <ProjectsTable loading={isProjectsLoading} isActiveProjectsTab items={activeItems}
                               type={ProjectType.Active}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <LockOutlined/> Archives
          </span>
                }
                className="archive-projects"
                key={ProjectType.Archive}>
                <ProjectsTable loading={isProjectsLoading} items={archivedProjects} type={ProjectType.Archive}/>
            </TabPane>
        </Tabs>
    );
};

export default Projects;
