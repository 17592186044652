import React from "react";
import {useSelector} from "react-redux";
import {MailOutlined} from "@ant-design/icons";
import EmailSend from "../../Common/EmailSend/EmailSend";
import {sendReport} from "../../../services/reports";
import {ApiDeliverableType} from "../../../constants/constants";

const Email = ({selected = [], disabled, renderAsButton}) => {
    const selectedReports = selected.filter((i) => i.Type === ApiDeliverableType.Report);
    const allProjects = useSelector((state) => state.projects.projects);

    const projectIds = selectedReports.map((s) => s.ProjectId);
    const targetProjects = allProjects.filter((p) => projectIds.includes(p.Id));

    const getMessageFirstLine = (count = 0) => {
        return selectedReports.length === 1
            ? `A link to the ${selectedReports[0].Name} will be emailed to ${count} ${count === 1 ? "person" : "people"}`
            : `Links to these Production Books will be emailed to ${count} ${count === 1 ? "person" : "people"}: ${selectedReports
                .map((i) => i.Name)
                .join(", ")}`;
    };

    const getTitle = () => (
        <>
            <MailOutlined/> Email {selectedReports.length === 1 ? selectedReports[0].Name : `${selectedReports.length} Reports`}
        </>
    );

    const getSubject = () => (
        selectedReports && selectedReports.length === 1 ? selectedReports[0].Name : ""
    );

    const onSendEmail = (isTest, values) =>
        sendReport({
            Subject: values.Subject,
            AccountId: values.AccountId,
            DeliverableId: selectedReports[0].Id,
            UseBcc: values.UseBcc,
            IncludeMyself: values.IncludeMyself,
            RecipientContactsIds: values.AdditionalContacts || [],
            RecipientGroupTagIds: values.RecipientGroupTagIds || [],
            RecipientNoGroupProjectIds: values.RecipientNoGroupProjectIds || [],
            EmailText: values.EmailText,
            RecipientsIds: [],
            RecipientContactGroups: values.RecipientContactGroups || [],
            IncludeNoGroupContacts: values.IncludeNoGroupContacts || false,
        });

    return (
        <EmailSend
            renderAsButton={renderAsButton}
            targetProjects={targetProjects}
            disabled={disabled}
            onSendEmail={onSendEmail}
            getTitle={getTitle}
            getSubject={getSubject}
            disableTest={true}
            getMessageFirstLine={getMessageFirstLine}></EmailSend>
    );
};

export default Email;
