import React from "react";
import {Upload, Modal, message} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import "./ImageUpload.scss";


const MaxFileSize = 1024 * 1024 * 3.8;

export const changeImageUpdatePayload = (values, item) => {
    const response = {};
    if (values.ImageUrl && values.ImageUrl.indexOf("data:") !== -1) {
        response.Photo = {
            IsChanged: true,
            Photo: values.ImageUrl.substr(values.ImageUrl.indexOf(",") + 1),
        };
        response.ImageUrl = null;
    }
    if (item.ImageUrl && values.ImageUrl === null) {
        response.Photo = {
            IsChanged: true,
            Photo: null,
        };
    }

    return response;
};

const ImageUpload = ({value, onChange, onChanged, defaultLogo}) => {

    if (defaultLogo) {
        return <img className="student-default-logo" src={defaultLogo} alt="Student Default Logo"/>;
    }

    let fileList = [];

    if (value) {
        if (value.indexOf("http") !== -1) {
            fileList = [
                {
                    uid: "-1",
                    name: "image.png",
                    status: "done",
                    url: value,
                },
            ];
        } else {
            fileList = [
                {
                    uid: "-1",
                    name: "image.png",
                    status: "uploading",
                },
            ];
        }
    }

    const onRemove = () => {
        Modal.confirm({
            title: "Are you sure you want to delete this photo?",
            icon: <DeleteOutlined/>,
            content: "You cannot undo this action",
            className: "delete-contact-modal",
            okText: "Delete",
            okButtonProps: {danger: true},
            onOk() {
                onChange(null);
                onChanged();
            },
            onCancel() {
            },
        });
    };

    const customRequest = ({file, onSuccess}) => {
        onSuccess(file);
        return {
            abort() {
                console.log("upload progress is aborted.");
            },
        };
    };

    return (
        <Upload
            customRequest={customRequest}
            listType="picture-card"
            fileList={fileList}
            onRemove={onRemove}
            beforeUpload={(file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const base64 = reader.result;

                        if (base64.length > MaxFileSize) {
                            message.error("The maximum upload size is 3mb. Please choose a smaller file.");
                            reject();
                        } else {
                            onChange(base64);
                            onChanged();
                            resolve(base64);
                        }
                    };
                });
            }}>
            {fileList.length === 0 && "+ Upload"}
        </Upload>
    );
};

export default ImageUpload;
