import React, {useState} from "react";
import {Form, Divider} from "antd";
import {FilterColumnType} from "../../../constants/constants";
import Locations from "./Filters/Locations";
import {useEffect} from "react";
import MultipleFilterWithSearchFilter from "../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {isOptionSelected} from "./Filters/TreeSelectFilter";
import {getEnumTitle} from "../../../helpers/text";

const items = [
    {title: "Address", value: FilterColumnType.Address},
    {title: "Spaces", value: FilterColumnType.Spaces},
];

export const LocationsBlock = ({form, selectedProjects, setLocationsCount, disabled, onChanged}) => {
    const [filterValue, setFilterValue] = useState(null);

    useEffect(() => {
        if (isBlockEnabled) {
            setLocationsCount(filterValue);
        } else {
            setLocationsCount(null);
        }
    }, [setLocationsCount, selectedProjects, filterValue]);
    const isBlockEnabled = form.getFieldValue("IsLocationsEnabled");

    return (
        <Form
            form={form}
            initialValues={{
                Locations: {
                    LocationColumns: items.map((v) => ({Type: v.value, IsVisible: true})),
                },
            }}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            layout="horizontal"
            size="middle">
            <Form.Item
                label="Locations"
                name={["Locations"]}
                getValueProps={(locations = {}) => {
                    return {
                        value: {
                            ...locations,
                            teamMembers: locations.Locations,
                            contacts: locations.LocationsLocationContacts,
                        },
                    };
                }}
                className="ant-form-item-without-validation">
                <Locations
                    onChange={(value) => {
                        form.setFieldsValue({
                            Locations: {
                                LocationColumns: value.LocationColumns,
                                Locations: value.teamMembers,
                                LocationsLocationContacts: value.contacts,
                            },
                        });
                    }}
                    onChanged={onChanged}
                    disabled={!isBlockEnabled}
                    selectProjectsLocations={true}
                    projectIds={selectedProjects}
                    setSelectedLocationCount={(val) => setFilterValue(val)}
                    setFilterHandler={() => {
                    }}></Locations>
            </Form.Item>
            <Divider orientation="left">Appearance</Divider>
            <Form.Item
                label="Columns"
                name={["Locations", "LocationColumns"]}
                className="ant-form-item-without-validation"
                getValueProps={(values = []) => {
                    return {
                        selectedItems: values
                            .filter((v) => v.IsVisible)
                            .map((v) => ({value: v.Type, title: getEnumTitle(FilterColumnType, v.Type)})),
                        value: values.filter((v) => v.IsVisible).map((v) => ({value: v.Type})),
                    };
                }}>
                <MultipleFilterWithSearchFilter
                    disabled={!isBlockEnabled}
                    onChanged={(options) => {
                        const val = options.map((o) => o.value);
                        form.setFieldsValue({
                            Locations: {
                                ...form.getFieldValue("Locations"),
                                LocationColumns: items.map((i) => ({
                                    Type: i.value,
                                    IsVisible: isOptionSelected(i.value, val)
                                })),
                            },
                        });
                        onChanged();
                    }}
                    items={items}
                    trigger="click"
                    nothingSelectedText="No Columns"
                    allItemsText="All Columns"></MultipleFilterWithSearchFilter>
            </Form.Item>
        </Form>
    );
};
