import React, {useState} from "react";
import _ from "lodash";
import {Modal, Form, message} from "antd";
import {BulbFilled} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import "./CloneFromExisting.scss";
import {cloneRequirements} from "../../../../services/requirements";
import {setSelected} from "../../../../redux/reducers/mainTable";

const Title = ({selected, requirements}) => (
    <>
        <BulbFilled/> Clone {selected.length} {requirements}
    </>
);

const getPlurality = (items = 0) => (items === 0 || items > 1 ? "Requirements" : "Requirement");

export const onCloneRequirements = async (selected, dispatch) => {
    const payload = {RequirementIds: selected.map((m) => m.Requirement.Id)};

    try {
        const result = await cloneRequirements(payload);
        const newSelected = result?.cloned.map((c) => result?.all.find((a) => a.Id === c.Id)) || [];
        dispatch(setSelected(newSelected));
        message.success(
            `${newSelected.length} ${getPlurality(newSelected.length)} ${newSelected.length > 1 ? "were" : "was"} cloned.`
        );
    } catch (err) {
        console.error(err);
    }
};

const CloneFromExisting = ({isVisible, onClose, selected}) => {
    const [form] = Form.useForm();
    var dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    async function handleOk() {
        setIsLoading(true);
        await onCloneRequirements(selected, dispatch);
        close();
    }

    function close() {
        onClose();
        form.resetFields();
    }

    return (
        <>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                <Modal
                    className="clone-project-modal"
                    title={<Title selected={selected} requirements={getPlurality(selected.length)}/>}
                    visible={isVisible}
                    maskClosable={false}
                    onOk={handleOk}
                    onCancel={close}
                    confirmLoading={isLoading}
                    okText={`Clone ${getPlurality(selected.length)}`}>
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}}
                                   className="ant-form-item-without-validation center">
                            During this operation {selected.length} {getPlurality(selected.length)} will be cloned.
                        </Form.Item>
                    </Form>
                </Modal>
            </Form>
        </>
    );
};
export default CloneFromExisting;
