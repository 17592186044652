import React, {useState} from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import BatchEdit from "../BatchEdit";
import CloneFromExisting from "../CloneFromExisting";

import {openNew} from "../../../redux/reducers/detailsPanel";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../../helpers/paths";
import {ApiProjectType, ProjectType, SidePanelItemType} from "../../../constants";
import usePermissions from "../../../hooks/usePermissions";
import {TooltipWithOrNot} from "../../Common/Input";
import Archive from "./Archive/Archive";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {deleteProject} from "../Delete/DeleteProjects";
import ClearSelection from "../../Common/ClearSelection";

const NewProjectKeys = {
    Blank: "Blank",
    Clone: "Clone",
};

const projectActionType = {
    [ProjectType.Active]: ApiProjectType.Archive,
    [ProjectType.Archive]: ApiProjectType.Inactive,
};

const ProjectsSelected = ({selected}) => <div>{`${selected.length} Projects Selected`}</div>;

const ProjectsTitleRowButtons = ({projectType}) => {
    const dispatch = useDispatch();
    const [isCloneVisible, setCloneVisible] = useState(false);
    const selected = useSelector((store) => store.mainTable.selected);
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const {canManageProjects, isProjectManager, isStudent, canStudentAddNewProject} = usePermissions();
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const nothingSelected = selected.length === 0;

    function handleNewProjectMenuClick(e) {
        history.push(urlWithPortfolioId(pathCollection.projectsPath[ProjectType.Active]));
        switch (e.key) {
            case NewProjectKeys.Blank:
                setTimeout(() => dispatch(openNew({type: SidePanelItemType.Project})), 100);
                break;
            case NewProjectKeys.Clone:
                setCloneVisible(true);
                break;
            default:
                break;
        }
    }

    function handleMoreMenuClick(e) {
    }

    const MoreMenu = (
        <Menu onClick={handleMoreMenuClick}>
            {projectType === ProjectType.Archive && (
                <Menu.Item disabled={nothingSelected || !canManageProjects} key="2"
                           onClick={() => deleteProject(selected)}>
                    Delete
                </Menu.Item>
            )}
        </Menu>
    );

    const NewProjectMenu = (
        <Menu onClick={handleNewProjectMenuClick}>
            <Menu.Item key={NewProjectKeys.Blank}>Blank</Menu.Item>
            <Menu.Item disabled={isProjectManager} key={NewProjectKeys.Clone}>
                Clone From Existing {isProjectManager}
            </Menu.Item>
        </Menu>
    );

    const checkPermissions = () => {
        return !canManageProjects || (isStudent && !canStudentAddNewProject);
    };

    const shouldShowTooltip = () => {
        return checkPermissions();
    };

    const isDropdownDisabled = () => {
        return checkPermissions();
    };

    return (
        <Space>
            {isBulkSelection && <ProjectsSelected selected={selected}/>}
            <ClearSelection/>
            {projectType === ProjectType.Archive && (
                <Dropdown trigger="click" overlay={MoreMenu}>
                    <Button>
                        More <DownOutlined/>
                    </Button>
                </Dropdown>
            )}
            <Archive selected={selected} apiProjectType={projectActionType[projectType]}></Archive>
            {!isBulkSelection && (
                <TooltipWithOrNot
                    showTooltip={shouldShowTooltip()}
                    title={isStudent ? "Users with student access may only have one active or draft project at a time. To create a new project, you’ll first need to archive the current one."
                        : "Only owners and administrators may create new projects. Contact an administrator to add a new project"}>
                    <div>
                        <Dropdown trigger="click" overlay={NewProjectMenu}
                                  disabled={isDropdownDisabled()}>
                            <Button type="primary">
                                New Project <DownOutlined/>
                            </Button>
                        </Dropdown>
                    </div>
                </TooltipWithOrNot>
            )}
            {isBulkSelection && <BatchEdit apiProjectType={projectActionType[projectType]} selected={selected}/>}
            <CloneFromExisting isVisible={isCloneVisible} onClose={() => setCloneVisible(false)}/>
        </Space>
    );
};

export default ProjectsTitleRowButtons;
