import React, {useEffect, useRef, useState} from "react";
import {Dropdown, DatePicker, Menu, Space, Button} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import "./MonthSelector.scss";
import {setActiveMonth} from "../../../redux/reducers/resources";
import moment from "moment";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";


const MonthMenu = ({isOpen, selectedMonth, onMonthChange}) => {
    return (
        <Menu className="project-selector-menu">
            <Menu.Item className="filter-search-item">
                <Space>
                    <DatePicker
                        className="date-picker-without-input"
                        open={isOpen}
                        picker="month"
                        bordered={false}
                        allowClear={false}
                        defaultPickerValue={selectedMonth}
                        value={selectedMonth}
                        onChange={onMonthChange}/>
                </Space>
            </Menu.Item>
        </Menu>
    );
}


const MonthSelector = () => {
    const dispatch = useDispatch();

    const {monthYearFull} = useDateTimeFormat();
    const activeMonthStr = useSelector((state) => state.resources.activeMonth);
    const activeMonth = activeMonthStr && moment(activeMonthStr, monthYearFull);

    const [isOpen, setIsOpen] = useState(false);
    const handleMonthChange = (value) => {
        setIsOpen(false);
        dispatch(setActiveMonth(value.format(monthYearFull)));
    }

    useEffect(() => {
        if (activeMonthStr) return;

        dispatch(setActiveMonth(moment().format(monthYearFull)));

    }, [activeMonthStr])

    return (
        <>
            {!activeMonth && "..."}
            {activeMonth && (
                <Dropdown
                    visible={isOpen}
                    trigger="click"
                    className="project-selector"
                    overlay={
                        <MonthMenu
                            isOpen={isOpen}
                            selectedMonth={activeMonth}
                            onMonthChange={handleMonthChange}/>
                    }
                    onVisibleChange={visible => setIsOpen(visible)}>
          <span className="selected-project">
            {activeMonthStr} <DownOutlined/>
          </span>
                </Dropdown>
            )}
        </>
    );
};

export default MonthSelector;
