import React from "react";
import _ from "lodash";
import {Select, Button} from "antd";
import {EnvironmentOutlined, ExpandOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {ApiContactType} from "../../../constants/constants";
import {formatContactName, formatTeamMemberName} from "../../../helpers/text";
import {useMemo} from "react";
import {createTeamMember, loadTeam} from "../../../services/team";

const placesToOptions = (places, spaces, teamSpacesHash) => {
    return places.map((p) => ({
        value: `contact:${p.Id}`,
        group: formatContactName(p),
        key: `contact:${p.Id}`,
        items: spaces
            .filter((s) => s.ParentId === p.Id)
            .map((s) => ({
                value: teamSpacesHash[s.Id] !== undefined ? teamSpacesHash[s.Id] : `contact:${s.Id}`,
                teamMemberId: teamSpacesHash[s.Id],
                contactId: s.Id,
                key: `contact:${s.Id}`,
                label: formatContactName(s),
            })),
    }));
};

const LocationSelect = ({
                            value,
                            onChange,
                            onChanged,
                            disabled,
                            projectId,
                            setIsQuickCreatePlaceVisible,
                            setIsQuickCreateSpaceVisible,
                            hideQuickCreate = false,
                            onlyTeamMembers = false,
                        }) => {
    const allTeamMembers = useSelector((state) => state.team.project[projectId]) || [];
    const allContacts = useSelector((state) => state.contacts.contacts);
    const spaces = allContacts.filter((c) => c.Type === ApiContactType.Space).filter((s) => s.ParentId != null);
    const places = allContacts.filter((c) => c.Type === ApiContactType.Place);
    const teamSpaces = allTeamMembers.filter((t) => t.Contact?.Type === ApiContactType.Space);

    const teamSpacesHash = teamSpaces.reduce((prev, curr) => ({...prev, [curr.ContactId]: curr.Id}), {});

    const locationGroups = useMemo(() => {
        const locations = allTeamMembers.filter((c) => c.Contact?.Type === ApiContactType.Space);
        const locationRawGroups = _.groupBy(locations, (l) => l.Contact?.ParentId);
        const groups = _.keys(locationRawGroups).map((key) => ({
            group: formatContactName(allContacts.find((c) => c.Id === parseInt(key))) || "- No Place -",
            items: _.sortBy(
                locationRawGroups[key].map((c) => ({
                    value: c.Id,
                    label: formatTeamMemberName(c),
                })),
                "label"
            ),
        }));

        return _.sortBy(groups, "group");
    }, [allTeamMembers]);

    const locations = onlyTeamMembers ? locationGroups : placesToOptions(places, spaces, teamSpacesHash);

    const onSelectionChange = (val) => {
        const contactIds = val.filter((v) => _.isString(v) && v.includes(":")).map((v) => parseInt(v.split(":")[1], 10));
        const locationIds = val.filter((v) => !_.isString(v));

        onChange({
            LocationIds: locationIds,
            NewLocationContactIds: contactIds,
        });
        onChanged();
    };

    return (
        <Select
            allowClear={true}
            mode="multiple"
            label="Location"
            value={value}
            showSearch={true}
            optionLabelProp="label"
            style={{width: "100%"}}
            placeholder="Select a location"
            onChange={onSelectionChange}
            disabled={disabled}
            filterOption={(input, option) => (option.header || option.label).toLowerCase().indexOf(input?.toLowerCase()) >= 0}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    {!hideQuickCreate && (
                        <div className="btn-quick-create">
                            <Button type="dashed" onClick={() => setIsQuickCreatePlaceVisible(true)}>
                                + Add a Location
                            </Button>
                            &nbsp;
                            <Button type="dashed" onClick={() => setIsQuickCreateSpaceVisible(true)}>
                                + Add a Space
                            </Button>
                        </div>
                    )}
                </>
            )}>
            {locations.map((group, gIndex) => (
                <Select.OptGroup
                    header={group.group}
                    label={
                        <>
                            <EnvironmentOutlined/> {group.group}
                        </>
                    }
                    key={gIndex}>
                    {group.items.map((contact) => (
                        <Select.Option
                            title={`${contact.label} (${group.group})`}
                            label={`${contact.label} (${group.group})`}
                            key={contact.value}
                            value={contact.value}>
                            <ExpandOutlined/> {contact.label}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            ))}
        </Select>
    );
};

export default LocationSelect;
