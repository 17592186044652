import React from "react";
import _ from "lodash";
import {GroupByOption} from "./FilterBar/GroupBy/GroupBy";
import GroupedTables from "../Common/Tables/GroupedTable";

function getAllProjects(data, projects) {
    return _.chain(data)
        .map((d) => d.ProjectId)
        .flatten()
        .uniq()
        .map((id) => projects.find((p) => p.Id === id))
        .value();
}

function getGroups(groupBy, dataSource, projects) {
    let groups = [];
    switch (groupBy) {
        case GroupByOption.Project:
            const dataProjects = getAllProjects(dataSource, projects);
            groups = dataProjects.map((project) => ({
                group: project.Name,
                items: dataSource.filter((item) => item.ProjectId === project.Id),
            }));
            break;

        case GroupByOption.Date:
        case GroupByOption.Location:
        case GroupByOption.Creator:
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });
    return groups;
}

const GroupReportsTable = ({columns, dataSource, onRow, groupByFilter, loading, projects}) => (
    <GroupedTables
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onRow={onRow}
        getGroups={() => getGroups(groupByFilter.value, dataSource, projects)}
    />
);

export default GroupReportsTable;
