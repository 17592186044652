import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Button, Space, Input} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import "./ProjectSelector.scss";
import {setActiveProject} from "../../../redux/reducers/projects";
import {ApiProjectType} from "../../../constants";
import _ from "lodash";

const ProjectSelectorMenu = ({activeProjects = [], draftProjects = [], selectedProjectId}) => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [displayedActiveProjects, setDisplayedActiveProjects] = useState(activeProjects);
    const [displayedReadOnlyProjects, setDisplayedReadOnlyProjects] = useState(draftProjects);
    useEffect(() => {
        setDisplayedActiveProjects(activeProjects);
        setDisplayedReadOnlyProjects(draftProjects);
    }, [activeProjects, draftProjects]);

    function onSearchChange(e) {
        const newSearchText = e.target.value;
        setSearchText(newSearchText);
        setDisplayedActiveProjects(
            activeProjects.filter((item) => item.Name.toLowerCase().indexOf(newSearchText.toLowerCase()) !== -1)
        );
        setDisplayedReadOnlyProjects(
            draftProjects.filter((item) => item.Name.toLowerCase().indexOf(newSearchText.toLowerCase()) !== -1)
        );
    }

    function onResetClick() {
        setSearchText("");
        setDisplayedActiveProjects(activeProjects);
        setDisplayedReadOnlyProjects(draftProjects);
    }

    const isResetDisabled = () => searchText === "";

    function onProjectSelectorMenuClick(e) {
        const project = [...activeProjects, ...displayedReadOnlyProjects].find((p) => p.Id === parseInt(e.key, 10));
        dispatch(setActiveProject(project));
    }

    return (
        <Menu style={{width: "auto"}} className="project-selector-menu" selectedKeys={[selectedProjectId.toString()]}>
            <Menu.Item key="search" className="filter-search-item">
                <Space>
                    <Input.Search placeholder="search" size="small" value={searchText}
                                  onChange={onSearchChange}></Input.Search>
                    <Button size="small" type="link" disabled={isResetDisabled()} onClick={onResetClick}>
                        Reset
                    </Button>
                </Space>
            </Menu.Item>
            <Menu.Divider></Menu.Divider>
            <Menu.ItemGroup className="projects-menu-group">
                {displayedActiveProjects.length > 0 && (
                    <Menu.ItemGroup title="Active Projects">
                        {_.sortBy(displayedActiveProjects, "Name").map((project) => (
                            <Menu.Item key={project.Id} onClick={onProjectSelectorMenuClick}>
                                {project.Name}
                            </Menu.Item>
                        ))}
                    </Menu.ItemGroup>
                )}
                {displayedReadOnlyProjects.length > 0 && (
                    <Menu.ItemGroup title="Draft Projects">
                        {_.sortBy(displayedReadOnlyProjects, "Name").map((project) => (
                            <Menu.Item key={project.Id} onClick={onProjectSelectorMenuClick}>
                                {project.Name}
                            </Menu.Item>
                        ))}
                    </Menu.ItemGroup>
                )}
            </Menu.ItemGroup>
        </Menu>
    );
};

const ProjectSelector = () => {
    const dispatch = useDispatch();

    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const activeProject = useSelector((state) => state.projects.activeProject);
    const activeProjects = projects.filter((p) => p.State === ApiProjectType.Active);
    const draftProjects = projects.filter((p) => p.State === ApiProjectType.Inactive);
    useEffect(() => {
        if (activeProject) return;

        if (projects.length !== 0) {
            dispatch(setActiveProject(projects[0]));
            return;
        }

        return;
    }, [activeProject, projects]);

    return (
        <>
            {!activeProject && "..."}
            {activeProject && (
                <Dropdown
                    trigger="click"
                    className="project-selector"
                    overlay={
                        <ProjectSelectorMenu
                            activeProjects={activeProjects}
                            draftProjects={draftProjects}
                            selectedProjectId={activeProject.Id}
                        />
                    }>
          <span className="selected-project">
            {activeProject.Name} <DownOutlined/>
          </span>
                </Dropdown>
            )}
        </>
    );
};

export default ProjectSelector;
