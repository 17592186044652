import React from "react";
import _ from "lodash";
import moment from "moment";
import {TimelineGroupBy} from "./FilterBar/GroupBy";
import {getLocations} from "./FilterBar/LocationsFilter";
import {getItems as getAllPositions} from "./FilterBar/PositionFilter/PositionFilter";
import GroupedTables from "../Common/Tables/GroupedTable";
import {formatMomentDuration} from "../../helpers/time";
import useDateTimeFormat from "../../hooks/useDateTimeFormat";
import {useSelector} from "react-redux";
import {Space, Tag} from "antd";
import {getStatuses} from "./FilterBar/StatusFilter/StatusFilter";
import {getTotalDuration} from "./TimelineTableFooter";

function getAllCategories(data) {
    return _.chain(data)
        .map((d) => d.CategoryTags)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getAllDepartment(data) {
    return _.chain(data)
        .map((d) => d.DepartmentTags)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getEvents(data) {
    return _.chain(data)
        .map((d) => d.BookingTask)
        .uniqBy("Id")
        .value();
}

function getAllProjects(data) {
    return _.chain(data)
        .map((d) => d.ProjectId)
        .uniqBy((id) => id)
        .value();
}

function getAllContacts(data) {
    return _.chain(data)
        .filter((d) => d.PeopleOrganizations)
        .map((d) => d.PeopleOrganizations)
        .flatten()
        .filter((tm) => tm.ContactId)
        .uniqBy((tm) => tm.ContactId)
        .value();
}

const TextControl = ({items}) => {
    const duration = getTotalDuration(items);
    return (
        <>
            <Space direction="horizontal" size={0} className="header-totals-text-only">
                <span style={{width: "50px", marginRight: "155px"}}>{formatMomentDuration(duration)}</span>
            </Space>
        </>
    );
};

const formatTimeFrame = (task, formats) => {
    if (task.StartDateTime === null) return "";

    const mStart = moment(task.StartDateTime);
    const mEnd = moment(task.EndDateTime);
    const now = moment();

    const daysDiff = mEnd.diff(mStart, "days");

    const formatStart = now.year() !== mStart.year() ? formats.dayDateLongFormat : formats.dayDateLongNoYearFormat;
    const formatEnd = now.year() !== mEnd.year() ? formats.dayDateLongFormat : formats.dayDateLongNoYearFormat;

    switch (true) {
        case task.IsAllDay:
            return `${moment(task.StartDateTime).format(formatStart)} - ${moment(task.EndDateTime).format(formatEnd)} `;
        case task.IsTimeOnly:
            return `${moment(task.StartDateTime).format(formats.time)} - ${moment(task.EndDateTime).format(formats.time)} `;
        default:
            return daysDiff === 0
                ? `${moment(task.StartDateTime).format(formatStart)}  ${moment(task.StartDateTime).format(formats.time)} - ${moment(
                    task.EndDateTime
                ).format(formats.time)}`
                : `${moment(task.StartDateTime).format(formatStart)}  ${moment(task.StartDateTime).format(formats.time)} -  ${moment(
                    task.EndDateTime
                ).format(formatEnd)} ${moment(task.EndDateTime).format(formats.time)}`;
    }
};

const formatEventName = (task, dateTimeFormats, projectTag) => {
    return (
        <>
            {task.Name} &nbsp;&nbsp;&nbsp; {formatTimeFrame(task, dateTimeFormats)} {projectTag}
        </>
    );
};

const formatLocationName = (group) =>
    group.spaceName === group.locationName ? group.spaceName : `${group.spaceName} (${group.locationName}) `;

function getGroups(groupBy, dataSource, dateTimeFormats, allProjects) {
    let groups = [];
    let noGroupItems = [];
    let noGroupText = "";

    const getProject = (projectId) => {
        const project = allProjects.find((p) => p.Id === projectId);
        return (
            <Tag className="crew-project-tag" color={project?.Color}>
                {project?.ShortName || project?.Name}
            </Tag>
        );
    };

    switch (groupBy) {
        case TimelineGroupBy.Project:
            const projects = getAllProjects(dataSource).map((id) => allProjects.find((p) => p.Id === id));
            groups = projects.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.ProjectId === group.Id),
            }));
            break;

        case TimelineGroupBy.Date:
            const withDate = dataSource
                .filter((d) => d.StartDateTime)
                .map((d) => ({
                    date: moment(d.StartDateTime).format(`dddd, ${dateTimeFormats.dateLongFormat}`),
                    item: d
                }));
            const withDateGroups = _.groupBy(withDate, "date");

            groups = _.keys(withDateGroups).map((key) => ({
                group: key,
                items: withDateGroups[key].map((i) => i.item),
            }));

            noGroupItems = dataSource.filter((item) => item.StartDateTime === null);
            noGroupText = "No Date";

            break;

        case TimelineGroupBy.Status:
            const statuses = getStatuses(dataSource);
            groups = statuses.map((group) => ({
                group: group.title,
                items: dataSource.filter((item) => item.StatusTag?.AccountTagId === group.value),
            }));

            noGroupItems = dataSource.filter((item) => !item.StatusTag);
            noGroupText = "No Status";
            break;

        case TimelineGroupBy.Location:
            const locations = getLocations(dataSource);
            const locationsSorted = _.sortBy(locations, [(m) => m.locationName.toLowerCase(), (m) => m.spaceName.toLowerCase()]);
            groups = locationsSorted.map((group) => ({
                group: formatLocationName(group),
                items: dataSource.filter(
                    (item) =>
                        item.Locations.map((l) => l.Id).includes(group.value) ||
                        item.Locations.map((l) => l.ContactId).includes(group.contactId)
                ),
            }));

            noGroupItems = dataSource.filter((item) => item.Locations.length === 0);
            noGroupText = "No Location";
            break;

        case TimelineGroupBy.Team:
        case TimelineGroupBy.Contact:
            const contacts = getAllContacts(dataSource);
            groups = contacts.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.PeopleOrganizations?.map((t) => t.ContactId).includes(group.ContactId)),
            }));
            groups = _.sortBy(groups, (g) => g.group.toLowerCase());

            const noTeamItems = dataSource.filter((item) => item.PeopleOrganizations?.length === 0);
            const noTeamText = "No Team";

            if (noTeamItems.length !== 0) {
                groups.push({group: noTeamText, items: noTeamItems});
            }

            noGroupItems = dataSource.filter((item) => item.PeopleOrganizations?.filter((tm) => tm.ContactId === null).length !== 0);
            noGroupText = "No Assigned Contact";
            break;

        case TimelineGroupBy.Category:
            const categories = getAllCategories(dataSource);

            groups = categories.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.CategoryTags.map((x) => x.AccountTagId).includes(group.AccountTagId)),
            }));
            noGroupItems = dataSource.filter((item) => item.CategoryTags.length === 0);
            noGroupText = "No Categories";

            break;
        case TimelineGroupBy.Department:
            const departments = getAllDepartment(dataSource);

            groups = departments.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.DepartmentTags.map((x) => x.AccountTagId).includes(group.AccountTagId)),
            }));
            noGroupItems = dataSource.filter((item) => item.DepartmentTags.length === 0);
            noGroupText = "No Department";

            break;
        case TimelineGroupBy.Event:
            const events = getEvents(dataSource);
            groups = events.map((group) => {
                const items = dataSource.filter((item) => item.BookingTask.Id === group.Id);
                return {
                    name: group.Id,
                    group: formatEventName(group, dateTimeFormats, getProject(items[0].ProjectId)),
                    items,
                };
            });

            break;

        case TimelineGroupBy.Position:
            const positions = getAllPositions(dataSource);
            groups = positions.map((group) => ({
                group: group.title,
                items: dataSource.filter((item) => item.LabourLine === group.value),
            }));
            noGroupItems = dataSource.filter((item) => !item.LabourLine);
            noGroupText = "No Position";

            break;
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({group: noGroupText, items: noGroupItems});
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
        group.rightTextClass = "header-totals-text-right";
        group.rightText = <TextControl items={group.items}/>;
    });
    return groups;
}

const GroupTimelineTable = ({columns, footer, dataSource, onRow, groupByFilter, loading, enableInfinityScrolling}) => {
    const dateTimeFormats = useDateTimeFormat();
    const allProjects = useSelector((state) => state.projects.projects);

    return (
        <GroupedTables
            footer={footer}
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            enableInfinityScrolling={enableInfinityScrolling}
            getGroups={() => getGroups(groupByFilter.value, dataSource, dateTimeFormats, allProjects)}
        />
    );
};

export default GroupTimelineTable;
