import React, {useEffect, useState} from "react";
import {ApiFilterTaskType} from "../../../../constants/constants";
import {Switch} from "antd";

const IncludeBookings = ({value, disabled, onChange, onChanged}) => {
    const [selectedItem, setSelectedItem] = useState(false);

    useEffect(() => {
        setSelectedItem(value & ApiFilterTaskType.Booking);
    }, [value]);

    function onSelected(selectedValue) {
        setSelectedItem(selectedValue);
        onChange(selectedValue ? ApiFilterTaskType.All : ApiFilterTaskType.Task);
        onChanged();
    }

    return <Switch disabled={disabled} onChange={onSelected} checked={selectedItem}/>;
};

export default IncludeBookings;
