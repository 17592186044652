import {EyeOutlined} from "@ant-design/icons";
import {Button, Form, Input, message} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {ApiProjectType, ApiTimelineItemType, SidePanelItemType} from "../../../../constants/constants";
import {mapWCToSelect} from "../../../../helpers/select";
import {pathCollection} from "../../../../helpers/paths";
import {getProjectTimetable} from "../../../../services/projects";
import {getEvent} from "../../../../services/timeline";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {SelectWithFormItem} from "../../../Common/Input";
import {openEdit} from "../../../../redux/reducers/detailsPanel";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import FormProjectSelect from "../../../Timeline/DetailsPanel/FormProjectSelect/FormProjectSelect";
import AuditFormItem from "../../../Common/AuditFormItem";
import {formatDuration} from "../../../../helpers/time";
import {formatLocationName} from "../../../../helpers/text";

const InformationBlock = ({form, itemToEdit, save, singleProject}) => {
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const [tasks, setTasks] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const dateTimeFormats = useDateTimeFormat();

    const onChangeProject = (id) => {
        form.setFieldsValue({
            TaskId: null,
        });

        var projectId = parseInt(id, 10);
        loadEvents(projectId);
    };

    const goToEvent = () => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Event]));
        getEvent(itemToEdit.Task.Id, itemToEdit.Task.Project.Id, ApiTimelineItemType.Task)
            .then((item) => dispatch(openEdit({item, type: SidePanelItemType.Event})))
            .catch(() => message.error("Server error"));
    };

    useEffect(() => {
        if (itemToEdit?.Task) setTasks([itemToEdit.Task]);
    }, [itemToEdit?.Task.ProjectId]);

    useEffect(() => {
        if (!itemToEdit?.ProjectId && singleProject) loadEvents(singleProject.Id);
    }, [itemToEdit?.Task.ProjectId, singleProject]);

    const loadEvents = (projectId) => {
        if (projectId) {
            setEventsLoading(true);
            getProjectTimetable(projectId)
                .then((response) => setTasks(response.Items))
                .finally(() => setEventsLoading(false));
        }
    };

    return (
        <>
            <FormProjectSelect projects={projects} project={itemToEdit?.Project}
                               save={onChangeProject}></FormProjectSelect>
            <Form.Item label="Linked Event" className="ant-form-item-without-validation">
                <Input.Group>
                    {itemToEdit?.Task ? (
                        <span className="selected-task">{mapWCToSelect(itemToEdit.Task, dateTimeFormats).label}</span>
                    ) : (
                        <SelectWithFormItem
                            disabled={itemToEdit?.Task}
                            name="TaskId"
                            className="ant-form-item-without-validation"
                            showSearch={true}
                            style={{width: "300px"}}
                            placeholder="Select an Event"
                            loading={eventsLoading}
                            rules={[{required: true, message: "Event"}]}
                            options={tasks.map((task) => mapWCToSelect(task, dateTimeFormats))}
                            onChanged={save}
                        />
                    )}
                    <Form.Item className="goto-event goto-event-labour">
                        {itemToEdit?.Task.Id && (
                            <Button type="link" size="small" onClick={goToEvent}>
                                <EyeOutlined/>
                            </Button>
                        )}
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            {itemToEdit?.Task && (
                <Form.Item label="Duration" className="ant-form-item-without-validation">
                    {itemToEdit?.Task.IsAllDay ? (
                        <span className="no-time">No Time Specified</span>
                    ) : (
                        formatDuration(itemToEdit?.Task.Duration)
                    )}
                </Form.Item>
            )}

            <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="Creator"/>
            <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="Updater"/>
        </>
    );
};

export default InformationBlock;
