import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getActivePortfolioId} from "../helpers/navigate";
import useChangePortfolio from "./useChangePortfolio";
import {useState} from "react";
import usePermissions from "./usePermissions";

const useActivePortfolio = () => {
    const [isPortfolioLoaded, setPortfolioLoaded] = useState(false);
    const changePortfolio = useChangePortfolio();
    const location = useLocation();

    const portfolios = useSelector((state) => state.projects.portfolios);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const {isStudent} = usePermissions();

    useEffect(() => {
        if (portfolios.length === 0) {
            return
        }

        const portfolioFromQuery = getActivePortfolioId(location, isStudent);
        if (portfolioFromQuery) {
            if (activePortfolio?.Id !== portfolioFromQuery) {
                changePortfolio(portfolioFromQuery);
            }
        } else if (activePortfolio) {
            changePortfolio(activePortfolio.Id);
        } else {
            changePortfolio(null);
        }

        setPortfolioLoaded(true);
    }, [location, portfolios, activePortfolio]);

    return isPortfolioLoaded;
};

export default useActivePortfolio;