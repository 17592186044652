import React, {useEffect, useState} from 'react';
import {message, Spin, Switch} from 'antd';
import {CopyOutlined} from "@ant-design/icons";
import StudentsTable from '../StudentsTable/StudentsTable';
import {useSelector} from "react-redux";
import {updateInvitationStatusByAccountId} from "../../../services/inviteLinks";
import './StudentInvitationManagementSidebar.scss';

const onCopyUrl = (url) => {
    if (!url) return;

    navigator.clipboard.writeText(url);
    message.info("Copied");
};

const combineInviteStudentLink = (linkHash) => {
    if (!linkHash) {
        return "";
    }

    return `${window.location.protocol}//${window.location.host}/student-account-new-user/${linkHash}`;
};

const isOver = (count, limit) => limit < count;

const StudentInvitationManagementSidebar = () => {

    const inviteLink = useSelector(state => state.inviteLinks?.inviteLink);
    const studentSignupLink = combineInviteStudentLink(inviteLink?.Link);

    const [isInviteLinkEnabled, setIsInviteLinkEnabled] = useState(!!inviteLink?.IsEnabled);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setIsInviteLinkEnabled(!!inviteLink?.IsEnabled);
    }, [inviteLink]);

    if (!inviteLink) {
        return null;
    }

    const handleSwitchChange = async (checked) => {

        setIsInviteLinkEnabled(checked);
        setLoading(true);

        updateInvitationStatusByAccountId(inviteLink.AccountId, checked)
            .finally(() => setLoading(false));

    };

    const invitationsLimit = inviteLink.InvitedAccounts.InvitationsLimit;
    const usedInvitationsCount = inviteLink.InvitedAccounts.UsedInvitationsCount;

    return (<div className="student-invitation-management-sidebar">
        <div className="management-panel">
            <div className="panel-header">
                <div className="panel-row">
                    <span className="panel-text">Student Signup Link:</span>
                    <div className={"signup-link"}>
                        {isInviteLinkEnabled ? (<a href={studentSignupLink} target="blank">
                            <span>{studentSignupLink}</span>
                        </a>) : (<span className="disabled">{studentSignupLink}</span>)}
                    </div>
                    <span
                        className={`url-copy ${!isInviteLinkEnabled ? 'disabled' : ''}`}
                        onClick={() => isInviteLinkEnabled && onCopyUrl(studentSignupLink)}>
                        <CopyOutlined/>
                    </span>
                </div>
                <Spin spinning={isLoading}>
                    <div className="panel-row">
                        <span className="panel-text">Enabled:</span>
                        <Switch checked={isInviteLinkEnabled} onChange={handleSwitchChange}/>
                    </div>
                </Spin>
                <div className={"limits"}>
                    <div className={`panel-row ${isOver(usedInvitationsCount, invitationsLimit) ? "over" : ""}`}>
                        <span className="panel-text">Student Accounts:</span>
                        {usedInvitationsCount} of {invitationsLimit} used
                    </div>
                </div>
            </div>
            <StudentsTable/>
        </div>
    </div>);
};

export default StudentInvitationManagementSidebar;