import {Button, Form, message} from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApiContactType, ApiPermissionLevel, ApiPermissionLevelName, ApiProjectType} from "../../../constants/constants";
import {create, update, loadAccountMembers} from "../../../services/settings";
import {formatContactName} from "../../../helpers/text";
import {clearUserToEdit} from "../../../redux/reducers/settings";
import {InputWithFormItemWithRef, SelectWithFormItem} from "../../Common/Input";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import {errorFromHttpResponse} from "../../../helpers/error";
import usePermissions from "../../../hooks/usePermissions";
import {mapProjectWithBadgeToTag, mapProjectWithBadge} from "../../../helpers/Tags";
import AuditFormItem from "../../Common/AuditFormItem";

const EditUser = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [accessType, setAccessType] = useState();
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);

    const {isOwner} = usePermissions();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const userToEdit = useSelector((state) => state.settings.userToEdit);
    const allContacts = useSelector((state) => state.contacts.contacts);

    const projects = useSelector((state) => state.projects.projects)
        .filter((p) => p.State !== ApiProjectType.Archive)
        .map(mapProjectWithBadgeToTag);

    var selectedArchProjects = userToEdit?.AssistantProjects
        ? userToEdit.AssistantProjects.filter((p) => p.State === ApiProjectType.Archive).map((p) => ({
            ...mapProjectWithBadge(p),
            value: p.Id.toString(),
        }))
        : [];

    const people = allContacts
        .filter((c) => c.Type === ApiContactType.Person)
        .map((c) => ({
            value: c.Id,
            label: formatContactName(c, false),
            contact: c,
        }));

    useEffect(() => {
        if (!userToEdit) return;

        const {CreatedFromContactId, PermissionLevel, AssistantProjects = [], CreatedAt, UpdatedAt} = userToEdit;

        const activeProjects = AssistantProjects.filter((p) => p.State !== ApiProjectType.Archive);
        const archivedProjects = AssistantProjects.filter((p) => p.State === ApiProjectType.Archive);

        form.setFieldsValue({
            CreatedFromContactId,
            PermissionLevel,
            Projects: [...activeProjects, ...archivedProjects].map((p) => p.Id.toString()),
            CreatedAt,
            UpdatedAt,
        });

        setEmailAddresses(userToEdit.EmailAddress ? [userToEdit.EmailAddress] : []);
        setAccessType(PermissionLevel);
    }, [userToEdit]);

    if (!userToEdit) return <></>;

    const isNew = userToEdit?.Id == null;

    const isAdminExceed = false;
    const isPMExceed = false;
    const accessTypes = [
        {
            disabled: isAdminExceed || !isOwner,
            label: ApiPermissionLevelName[ApiPermissionLevel.Administrator],
            value: ApiPermissionLevel.Administrator,
        },
        {
            disabled: isPMExceed,
            label: ApiPermissionLevelName[ApiPermissionLevel.AssistantOrViewer],
            value: ApiPermissionLevel.AssistantOrViewer,
        },
    ];

    const getPayload = (values) => ({
        Id: values.Id,
        PermissionLevel: values.PermissionLevel,
        ContactId: values.CreatedFromContactId,
        AssistantProjectIds: (values.Projects || []).map((id) => parseInt(id, 10)),
        EmailAddresses: emailAddresses.length > 0 ? emailAddresses : [values.EmailAddress],
        ViewerProjectIds: [],
        AddressBookAccessLevel: 1,
    });

    async function createUser(values) {
        try {
            await create(activePortfolio.Id, getPayload(values));
            message.success("New User was created");
            await loadAccountMembers(activePortfolio.Id);
        } catch (err) {
            await loadAccountMembers(activePortfolio.Id);
            const errorText = errorFromHttpResponse(err);
            message.error(errorText);
            throw new Error(errorText);
        }
    }

    async function updateUser(values) {
        try {
            await update(activePortfolio.Id, getPayload({...values, Id: userToEdit.Id}));
            await loadAccountMembers(activePortfolio.Id);
        } catch (err) {
            await loadAccountMembers(activePortfolio.Id);
            const errorText = errorFromHttpResponse(err);
            message.error(errorText);
            throw new Error(errorText);
        }
    }

    async function handleOk() {
        setIsLoading(true);
        try {
            const values = await form.validateFields();
            if (isNew) {
                await createUser(values);
            } else {
                await updateUser(values);
            }
            close();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function close() {
        form.resetFields();
        dispatch(clearUserToEdit());
    }

    const onPeopleChanged = (value, option) => {
        const contactEmailAddresses = option.contact.EmailAddresses;
        if (contactEmailAddresses.length > 0) {
            setEmailAddresses(contactEmailAddresses.map((e) => e.EmailAddress));
        } else {
            setEmailAddresses([]);
        }
    };

    const title = isNew ? "New User" : formatContactName(userToEdit.CreatedFromContact, false);
    const isAccessTypeDisabled = isNew && emailAddresses.length === 0 && !form.getFieldValue("EmailAddress");

    return (
        <>
            <Modal
                title={title}
                visible={true}
                maskClosable={false}
                onOk={handleOk}
                onCancel={close}
                okText="Save"
                confirmLoading={isLoading}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                    <SelectWithFormItem
                        label="User"
                        name="CreatedFromContactId"
                        showSearch={true}
                        style={{width: "100%"}}
                        placeholder="Select a contact or add a new one "
                        onChanged={onPeopleChanged}
                        rules={[{required: true, message: "Please select a contact"}]}
                        options={people}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <div className="btn-quick-create">
                                    <Button type="dashed" onClick={() => setIsQuickCreateContactVisible(true)}>
                                        + Add a Contact
                                    </Button>
                                </div>
                            </>
                        )}
                    />

                    {form.getFieldValue("CreatedFromContactId") && (
                        <>
                            {emailAddresses.length === 0 && (
                                <InputWithFormItemWithRef
                                    name="EmailAddress"
                                    label="Email"
                                    placeholder="Enter Email"
                                    onChanged={() => setEmailAddresses([])}
                                    rules={[{required: true, message: "Please enter Email"}]}
                                />
                            )}

                            {emailAddresses.length > 0 && (
                                <Form.Item label="Email" className="ant-form-item-without-validation">
                                    {emailAddresses.join(", ")}
                                </Form.Item>
                            )}
                        </>
                    )}

                    <SelectWithFormItem
                        label="Access Type"
                        name="PermissionLevel"
                        style={{width: "100%"}}
                        placeholder="Select an access type"
                        disabled={isAccessTypeDisabled}
                        rules={[{required: true, message: "Please select Access type"}]}
                        options={accessTypes}
                        onChanged={setAccessType}
                    />
                    {accessType === ApiPermissionLevel.Administrator && (
                        <Form.Item label="Editable Projects" className="ant-form-item-without-validation">
                            All Projects
                        </Form.Item>
                    )}

                    {accessType === ApiPermissionLevel.AssistantOrViewer && (
                        <SelectWithFormItem
                            mode="tags"
                            label="Editable Projects"
                            name={["Projects"]}
                            showSearch={true}
                            style={{width: "100%"}}
                            placeholder="Select projects"
                            rules={[{required: true, message: "Please select Projects"}]}
                            options={[...projects, ...selectedArchProjects]}
                        />
                    )}

                    {userToEdit?.Id && (
                        <>
                            <AuditFormItem item={userToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>
                            <AuditFormItem item={userToEdit} label="Last Updated" timeProp="UpdatedAt"
                                           userProp="UpdatedBy"/>
                        </>
                    )}
                </Form>
            </Modal>

            <CreateContact
                apiContactType={ApiContactType.Person}
                isVisible={isQuickCreateContactVisible}
                onClose={() => setIsQuickCreateContactVisible(false)}
                onContactCreated={(contactId, contact) => {
                    form.setFieldsValue({
                        CreatedFromContactId: contactId,
                    });

                    const contactEmails = contact?.EmailAddresses?.map((e) => e.EmailAddress);
                    if (contactEmails && contactEmails.length > 0) {
                        setEmailAddresses(contactEmails);
                    } else {
                        setEmailAddresses([]);
                    }
                }}
            />
        </>
    );
};

export default EditUser;
