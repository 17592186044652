import React from "react";
import {Menu, Dropdown, Button, Radio} from "antd";
import {DownOutlined} from "@ant-design/icons";
import "./SingleItemFilter.scss";

const SingleItem = ({onChanged, selectedItem = {}, items = [], hasReset}) => {
    function onCheckboxChange(item, e) {
        onChanged(item);
    }

    function onResetClick() {
        onChanged();
    }

    const isSelected = (item) => item.value === selectedItem.value;
    const isResetDisabled = !selectedItem.value;

    return (
        <Radio.Group value={selectedItem.value}>
            <Menu selectable={false} style={{width: 250}} className="single-item-filter-menu">
                {items.map((item, index) => (
                    <Menu.Item key={item.value} disabled={item.disabled}>
                        <Radio
                            value={item.value}
                            checked={isSelected(item)}
                            onChange={(e) => onCheckboxChange(item, e)}
                            disabled={item.disabled}>
                            {item.title}
                        </Radio>
                        {hasReset && index === 0 && (
                            <Button className="btn-reset" size="small" type="link" disabled={isResetDisabled}
                                    onClick={onResetClick}>
                                Reset
                            </Button>
                        )}
                    </Menu.Item>
                ))}
            </Menu>
        </Radio.Group>
    );
};

const SingleItemFilter = ({
                              items = [],
                              selectedItem,
                              onChanged,
                              getFilterText,
                              className = "",
                              hasReset = false,
                              trigger = "click",
                              disabled,
                          }) => {
    const defaultFilterText = selectedItem && selectedItem.title;
    const filterText = getFilterText ? getFilterText(selectedItem) : defaultFilterText;

    function onFilterChanged(filterItems) {
        onChanged(filterItems);
    }

    return (
        <Dropdown
            disabled={disabled}
            trigger={trigger}
            overlay={<SingleItem items={items} selectedItem={selectedItem} onChanged={onFilterChanged}
                                 hasReset={hasReset}/>}
            className={`single-item-filter ${className}`}>
            <Button type="link" className="btn-single-items-filter">
                <span className="filter-text">{filterText}</span>
                <DownOutlined className="filter-text-down"/>
            </Button>
        </Dropdown>
    );
};

export default SingleItemFilter;
