import React, {} from "react";
import {Collapse, Space} from "antd";
import {ContactsFilled} from "@ant-design/icons";

import {useSelector} from "react-redux";
import SourcesBlock from "./SourcesBlock";
import _ from "lodash";

const SourcesHeader = (
    <>
        <ContactsFilled/> Sources
    </>
);

const UsageDetailsPanel = () => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const {Sources = []} = itemToEdit;
    const hasInventory = () => Sources.filter(m => m.Inventory).length > 0;
    const hasQuantity = () => _.chain(Sources)
        .flatMap(s => s.Requirements)
        .sumBy(r => r.Quantity)
        .value() > 0;

    const hasData = hasInventory() || hasQuantity();

    return (
        <>
            <div className="side-panel with-collapse" hidden={!hasData}>
                <Collapse defaultActiveKey={["Sources"]} className="outer-details-collapse">
                    <Collapse.Panel key="Sources" header={SourcesHeader}>
                        <SourcesBlock sources={itemToEdit?.Sources}/>
                    </Collapse.Panel>
                </Collapse>
            </div>
            <h2 hidden={hasData} style={{textAlign: "center"}}>No item usage found</h2>
        </>
    );
};

export default UsageDetailsPanel;
