import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {Col, Row, List, Button} from "antd";
import {loadRulesets, deleteRuleset} from "../../../../services/rulesets";
import EditAction from "../../../Common/Tables/Actions/EditAction";
import DeleteAction from "../../../Common/Tables/Actions/DeleteAction";
import {TooltipWithOrNot} from "../../../Common/Input";
import {useState} from "react";
import AddRulesetModal from "./AddRulesetModal";
import ReplaceRulesetModal from "./ReplaceRulesetModal";

const Rulesets = ({activePortfolio}) => {
    const rulesets = useSelector(state => state.labour.rulesets);
    const [isAddModalVisible, setAddModalVisible] = useState(false);
    const [isReplaceModalVisible, setReplaceModalVisible] = useState(false);
    const [editedRuleset, setEditedRuleset] = useState(null);

    useEffect(() => {
        if (!activePortfolio) return;

        loadRulesets(activePortfolio.Id);

    }, [activePortfolio]);

    const onAdd = () => {
        setEditedRuleset(null);
        setAddModalVisible(true);
    };

    const onEdit = (item) => {
        setEditedRuleset(item);
        setAddModalVisible(true);
    };

    const onCloseModal = () => {
        setEditedRuleset(null);
        setAddModalVisible(false);
        setReplaceModalVisible(false);
    };

    const canDelete = rulesets.length > 1;

    const doDelete = (item) => {
        deleteRuleset({RemoveId: item.Id}).then((response) => {
            loadRulesets(activePortfolio.Id);
        });
    };

    const onDelete = (item) => {
        if (!canDelete || !item) return;

        const shouldReplace = item.PositionsCount > 0;
        if (shouldReplace) {
            setEditedRuleset(item);
            setReplaceModalVisible(true);
        } else {
            doDelete(item);
        }
    };

    const EditItem = (props) => <EditAction onClick={onEdit}  {...props} />;


    const DeleteItem = (props) => {

        return (
            <TooltipWithOrNot showTooltip={!canDelete} title="There must be at least one ruleset.">
        <span>
          <DeleteAction onClick={onDelete}
                        className={`${canDelete ? "delete" : "disabled"} btn-table-actions`} {...props} />
        </span>
            </TooltipWithOrNot>
        );
    };

    return (
        <>
            <List hidden={!activePortfolio} className="common-table"
                  header={
                      <Row align="middle">
                          <Col span={21}><h4>Ruleset</h4></Col>
                          <Col span={3}>
                              <Button type="primary" size="small" onClick={onAdd}>Add</Button>
                          </Col>
                      </Row>
                  }
                  size="small"
                  dataSource={rulesets}
                  renderItem={(item) => {
                      const {Name} = item;
                      return (
                          <Row align="middle">
                              <Col span={21}>{Name}</Col>
                              <Col span={3} className="top-actions">
                                  <EditItem record={item}/>
                                  <DeleteItem record={item}/>
                              </Col>
                          </Row>
                      );
                  }}></List>

            <AddRulesetModal
                itemToEdit={editedRuleset}
                visible={isAddModalVisible}
                onClose={onCloseModal}/>

            <ReplaceRulesetModal
                itemToDelete={editedRuleset}
                visible={isReplaceModalVisible}
                onClose={onCloseModal}
                rulesets={rulesets}/>
        </>
    );
};

export default Rulesets;
