import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ApiProjectType} from "../../../../constants/constants";
import {mapProjectWithBadgeToTag} from "../../../../helpers/Tags";
import usePermissions from "../../../../hooks/usePermissions";
import TreeSelectFilter from "./TreeSelectFilter";

const selectDefaultOption = {value: "— All Projects —", title: "— All Projects (current and future) —"};

const Projects = ({value, onChange, onChanged, defaultSelected = [], disabled, forwardRef}) => {
    const {isProjectManager} = usePermissions();
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State === ApiProjectType.Active);
    const inActiveProjects = value?.Projects?.filter((p) => p.State !== ApiProjectType.Active);
    const portfolioProjects = useSelector((state) => state.projects.portfolioProjects);
    const [selectedItems, setSelectedItems] = useState(value);

    const projectsForSelector = disabled ? portfolioProjects : [...projects, ...inActiveProjects];

    useEffect(() => {
        if (value != null) {
            if (value.IsAllProjects) {
                setSelectedItems([]);
            } else {
                setSelectedItems(value.Projects.map((i) => i.Id.toString()));
            }
        }
    }, [value]);

    function onItemsChanged(selectedItems, isDisableChecked) {
        const isAllProjects = !isProjectManager && (isDisableChecked || selectedItems.length === 0);
        const filterValues = selectedItems.map((i) => parseInt(i, 10));
        if (isAllProjects) {
            onChange({
                ...value,
                IsAllProjects: true,
                Projects: [],
            });
            onChanged();
        } else {
            onChange({
                ...value,
                IsAllProjects: false,
                Projects: projectsForSelector.filter((p) => filterValues.includes(p.Id)),
            });
            onChanged();
        }
    }

    const treeData = [
        {
            value: "(select all current projects)",
            title: "(select all current projects)",
            key: "(select all current projects)",
            children: projectsForSelector.map((p) => ({
                ...mapProjectWithBadgeToTag(p),
                disabled: false,
            })),
            filterOff: true,
        },
    ];

    return (
        <TreeSelectFilter
            forwardRef={forwardRef}
            disabled={disabled}
            value={selectedItems}
            placeholder="All Projects"
            defaultSelected={defaultSelected}
            onChanged={onItemsChanged}
            treeData={treeData}
            treeDefaultExpandedKeys={[treeData[0].value]}
            selectDefaultOption={!isProjectManager ? selectDefaultOption : null}
            onSelectAllChange={() => {
            }}
            onResetCompleted={() => {
            }}></TreeSelectFilter>
    );
};

export const ProjectsWithRef = React.forwardRef(function InputWithFormItemWithRef(props, ref) {
    return <Projects {...props} forwardRef={ref}/>;
});

export default Projects;
