import {Form, List} from "antd";
import _ from "lodash";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {ApiPermissionLevel, ApiPermissionLevelName} from "../../constants/constants";
import logo from "./LogoMark_Pond_32.png";

const getAccessType = (item) => {
    if (item.PermissionLevel === ApiPermissionLevel.AssistantOrViewer) {
        return (
            <>
                {ApiPermissionLevelName[item.PermissionLevel]} <br/>{" "}
                {_.sortBy(item.AssistantsProjects, "Name")
                    .map((p) => p.Name)
                    .join(", ")}
            </>
        );
    } else return ApiPermissionLevelName[item.PermissionLevel];
};

const MyAccess = () => {
    const portfolios = useSelector((state) => state.projects.portfolios);

    return (
        <List
            itemLayout="horizontal"
            dataSource={portfolios}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<img src={logo} alt="Propared"/>}
                        title={item.Contact.Company}
                        description={getAccessType(item)}
                    />
                </List.Item>
            )}
        />
    );
};

export default MyAccess;
