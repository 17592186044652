import React, {useState, useEffect, useRef, useCallback} from "react";
import {Collapse, Form, message} from "antd";
import {BulbOutlined, InfoCircleOutlined} from "@ant-design/icons";
import moment from "moment";

import {NewRecordState} from "../../../../constants/constants";
import {
    loadRequirements,
    createRequirement,
    updateRequirement,
    refreshRequirements
} from "../../../../services/requirements";

import {continueEdit} from "../../../../redux/reducers/detailsPanel";
import {isRequestCanceled, errorFromHttpResponse} from "../../../../helpers/error";
import {useSelector, useDispatch} from "react-redux";
import InformationBlock from "./InformationBlock";
import ItemBlock from "./ItemBlock";
import SourcesManagement from "../SourcesManagement/SourcesManagement";
import {refreshResources} from "../../../../services/resources";

const InformationHeader = (
    <>
        <InfoCircleOutlined/> Information
    </>
);
const ItemHeader = (
    <>
        <BulbOutlined/> Item
    </>
);

const prepareFormData = (requirement) => {
    const {StartDateTime: start, EndDateTime: end, Project, Location, Resource} = requirement;

    const formData = {
        ...requirement,
        Dates: [start && moment(start), end && moment(end)],
        ProjectIds: Project ? [Project.Id?.toString()] : [],
        Location: Location && {...Location, Id: Location.Id?.toString()},
        Resource: Resource && {...Resource, Id: Resource.Id},
    };

    return formData;
};

const RequirementDetailsPanel = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const focusedInputRef = useRef();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;

    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const singleProject = selectedProjects.length === 1 && !itemToEdit ? selectedProjects[0] : null;
    const resources = useSelector((state) => state.resources.resourcesLookup || []);

    const onSelectResource = (id) => {
        form.setFieldsValue({Resource: {Id: id}});
        const currentDepartmentTag = itemToEdit.DepartmentTagId;
        if (id && !currentDepartmentTag) {
            const resource = resources.find((r) => r.Id === id);
            form.setFieldsValue({DepartmentTagId: resource.DepartmentTagId});
        }
        save();
    };

    useEffect(() => {
        refreshResources();
    }, [activePortfolio]);

    useEffect(() => {
        if (!itemToEdit) {
            setTimeout(() => focusedInputRef?.current?.focus(), 200);
            return;
        }

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit, form]);

    const prepareAndCreate = useCallback(
        (finishedForm) => {
            const [start, end] = finishedForm.Dates || [];

            const payload = {
                Name: finishedForm.Name,
                AccountId: activePortfolio.Id,
                ProjectId: finishedForm.ProjectIds[0],

                StartDateTime: start?.format("YYYY-MM-DD[T]00:00:00"),
                EndDateTime: end?.format("YYYY-MM-DD[T]00:00:00"),
            };

            createRequirement(payload)
                .catch((err) => {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    throw new Error(errorText);
                })
                .then((response) => {
                    const item = {
                        Id: response.Id,
                        Name: payload.Name,
                        Project: {Id: payload.ProjectId},

                        StartDateTime: payload.StartDateTime,
                        EndDateTime: payload.EndDateTime,
                    };

                    dispatch(continueEdit({item}));
                    setEditItemState(NewRecordState.ReadyForEdit);

                    loadRequirements(payload.AccountId);

                    message.success(`${item.Name} was created`);
                })
                .catch(console.error);

            setEditItemState(NewRecordState.RequiredFieldsSubmitted);
        },
        [itemToEdit?.Id, dispatch]
    );

    const prepareAndUpdate = useCallback(
        (finishedForm) => {
            const [start, end] = finishedForm.Dates || [];

            const updatePayload = {
                Id: itemToEdit.Id,
                ProjectId: finishedForm.ProjectIds[0],
                LocationId: finishedForm.Location?.Id,
                ResourceId: finishedForm.Resource?.Id,
                DepartmentTagId: finishedForm.DepartmentTagId,

                StatusTag: finishedForm.StatusTag,
                StatusTagId: finishedForm.StatusTag?.AccountTagId,

                Name: finishedForm.Name,
                Description: finishedForm.Description,

                StartDateTime: start?.format("YYYY-MM-DD[T]00:00:00"),
                EndDateTime: end?.format("YYYY-MM-DD[T]00:00:00"),
            };

            updateRequirement(updatePayload)
                .then((response) => {
                    const item = {
                        ...itemToEdit,
                        ...updatePayload,
                        Version: response.Version,
                        Project: {Id: updatePayload.ProjectId},
                        Resource: updatePayload.ResourceId && {Id: updatePayload.ResourceId},
                        Location: updatePayload.LocationId && {Id: parseInt(updatePayload.LocationId)},
                    };
                    dispatch(continueEdit({item}));

                    loadRequirements(activePortfolio.Id);
                })
                .catch((err) => {
                    if (!isRequestCanceled(err)) {
                        const errorText = errorFromHttpResponse(err);
                        message.error(errorText);
                        console.error(err);
                    }
                });
        },
        [itemToEdit?.Id, dispatch]
    );

    const onFinish = useCallback(
        (finishedForm) => {
            if (editItemState === NewRecordState.Empty) {
                prepareAndCreate(finishedForm);
            }
            if (editItemState === NewRecordState.ReadyForEdit) {
                prepareAndUpdate(finishedForm);
            }
        },
        [editItemState, prepareAndCreate, prepareAndUpdate]
    );

    const save = useCallback(async () => {
        try {
            let values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();

            if (!isOptionalFieldDisabled || (values.Name && values.ProjectIds[0] > 0)) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    return (
        <div className="side-panel with-collapse">
            <Collapse defaultActiveKey={["Information", "Item"]}>
                <Collapse.Panel key="Information" header={InformationHeader}>
                    <Form
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        size="middle"
                        initialValues={{ProjectIds: singleProject ? [singleProject?.Id.toString()] : []}}>

                        <InformationBlock
                            form={form}
                            save={save}
                            focusedInputRef={itemToEdit ? null : focusedInputRef}
                            isSingleProject={!!singleProject}
                            itemToEdit={itemToEdit}
                            isOptionalFieldDisabled={isOptionalFieldDisabled}
                        />

                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="Item" header={ItemHeader} className="req-management-panel">
                    <Form
                        className="req-panel-well"
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        size="middle">
                        <ItemBlock
                            form={form}
                            requirement={itemToEdit}
                            isOptionalFieldDisabled={isOptionalFieldDisabled}
                            onChangeResource={onSelectResource}
                        />
                    </Form>

                    <SourcesManagement
                        isOptionalFieldDisabled={isOptionalFieldDisabled}
                        requirement={itemToEdit}
                        onChangeSource={() => {
                            save().then((r) => refreshRequirements());
                        }}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default RequirementDetailsPanel;
