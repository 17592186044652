import {createSlice} from "@reduxjs/toolkit";

const inviteLinksSlice = createSlice({
    name: "inviteLinksSlice", initialState: {
        shortInfo: null,
        inviteLink: null,
    }, reducers: {
        setShortInfo(state, action) {
            state.shortInfo = action.payload;
        }, setInviteLink(state, action) {
            state.inviteLink = action.payload;
        }
    },
});

export const {setShortInfo, setInviteLink} = inviteLinksSlice.actions;

export default inviteLinksSlice.reducer;

