import {FolderOpenFilled} from "@ant-design/icons";
import React from "react";
import {ApiProjectType} from "../../../constants/constants";

const ProjectDetailsPanelTitle = ({editedItem}) => (
    <>
        <FolderOpenFilled/> {editedItem.Name} {editedItem.State === ApiProjectType.Archive && "(Archived)"}
    </>
);

export default ProjectDetailsPanelTitle;
