import React from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {SidePanelItemType} from "../../../constants/constants";

import {openNew} from "../../../redux/reducers/detailsPanel";
import {deleteAttachments} from "../Delete/DeleteAttachments";
import BatchEdit from "../BatchEdit";
import ClearSelection from "../../Common/ClearSelection";

const AttachmentsSelected = ({selected}) => <div>{`${selected.length} Attachments selected`}</div>;

const AttachmentsRowButtons = ({type}) => {
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);

    function newItemClick() {
        setTimeout(() => dispatch(openNew({type: SidePanelItemType.Attachment})), 100);
    }

    function handleMoreMenuClick(e) {
    }

    const dispatch = useDispatch();

    const nothingSelected = selected.length === 0;

    const MoreMenu = (
        <Menu onClick={handleMoreMenuClick}>
            <Menu.Item
                key="1"
                disabled={nothingSelected}
                onClick={() => {
                    deleteAttachments(selected);
                }}>
                Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Space>
            {isBulkSelection && <AttachmentsSelected selected={selected}/>}
            <ClearSelection/>
            <Dropdown trigger="click" overlay={MoreMenu}>
                <Button>
                    More <DownOutlined/>
                </Button>
            </Dropdown>
            {/* <Button danger>Archive</Button> */}
            {/* <Button danger>Delete</Button> */}
            {!isBulkSelection && (
                <>
                    <Button type="primary" onClick={newItemClick}>
                        New Attachment
                    </Button>
                </>
            )}
            {isBulkSelection && <BatchEdit selected={selected}/>}
        </Space>
    );
};

export default AttachmentsRowButtons;
