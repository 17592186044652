import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {BulbFilled} from "@ant-design/icons";
import {Table} from "antd";

import Import, {CsvMapper} from "../../../Common/Modal/Import/Import";
import {TimelineImportInputLabel, FieldType, getTargetFieldTypes, getCsvToFieldType, getParsedData} from "./helper";
import {formatAmount} from "../../../../helpers/money";
import {resourceImport, refreshResources} from "../../../../services/resources";
import InformationBlock from "../DetailsPanel/InformationBlock";
import {loadTags} from "../../../../services/tags";

const SourcesPreviewList = ({sources = []}) => {
    const columns = [
        {
            title: "Source",
            dataIndex: "Name",
            width: "auto",
        },
        {
            title: "Inventory",
            dataIndex: "Quantity",
            width: "75px",
        },
        {
            title: "Default Cost",
            dataIndex: "Cost",
            width: "100px",
            render: (d) => d && formatAmount(d),
        },
        {
            title: "",
            dataIndex: "Unit",
            width: "50px",
        },
        {
            title: "Details",
            dataIndex: "Description",
            width: "50px",
        },
    ];
    return (
        sources?.length > 0 && (
            <>
                <br/>
                <Table pagination={false} className="common-table" size="large" rowKey="Key" dataSource={sources}
                       columns={columns}/>
            </>
        )
    );
};

const ResourceCsvMapper = ({fileData, setErrorMessage, setParsedData}) => {
    const fieldTypes = getTargetFieldTypes();
    const csvToFieldType = getCsvToFieldType();

    const validate = (columnsMap) => {
        const mapped = _.values(columnsMap);
        setErrorMessage(mapped.includes(FieldType.Name) ? null : "Name mapping required");
    };

    const inputs = fieldTypes.map((i) => ({value: i, label: TimelineImportInputLabel[i]}));

    return (
        <CsvMapper
            fileData={fileData}
            validate={validate}
            inputs={inputs}
            fieldTypes={fieldTypes}
            csvToFieldType={csvToFieldType}
            setParsedData={setParsedData}
            getParsedData={getParsedData}
            renderRecordViewTitle={() => (
                <>
                    {" "}
                    <BulbFilled/> Preview Item:
                </>
            )}
            renderRecordView={(form, row) => {
                return (
                    <>
                        <InformationBlock form={form} itemToEdit={row} isReadOnly={true} isOptionalFieldDisabled={true}
                                          save={() => {
                                          }}/>

                        <SourcesPreviewList sources={row?.Sources}/>
                    </>
                );
            }}></CsvMapper>
    );
};

const ResourceImport = ({disabled}) => {
    const accountId = useSelector((state) => state.projects.activePortfolio?.Id);

    const prepareData = (data) => data.map((i) => ({...i, DepartmentTag: i.DepartmentTagId}));
    const onImport = (parsedData) => resourceImport(accountId, prepareData(parsedData));
    const onImportFinish = () => Promise.all([refreshResources(), loadTags()]);
    const onClose = () => {
    };

    return (
        <Import
            disabled={disabled}
            mainIcon={<BulbFilled/>}
            title="Import Items from a .CSV"
            instructions={
                <p>
                    Import resource items from the selected .csv file. Adjust the field mappings, preview the results,
                    and import when you
                    are ready.
                </p>
            }
            onImport={onImport}
            onImportFinish={onImportFinish}
            onClose={onClose}
            CsvMapperComponent={(props) => <ResourceCsvMapper {...props} />}
            importResultError={
                <>
                    We had trouble with some of the field values for these records.
                    <br/>
                    <br/>
                    Items without name specified have been ignored.
                    <br/>
                    <br/>
                    Sources with a location or contact that we couldn't correctly match to an existing contact, have
                    been skipped.
                </>
            }></Import>
    );
};

export default ResourceImport;
