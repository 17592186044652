import api from "../api";
import store from "../redux/store";
import {setShortInfo, setInviteLink} from "../redux/reducers/inviteLinks";

const {dispatch} = store;

export const getShortInfoByLinkHash = (linkHash) => api
    .get(`/api/InviteLink/GetShortInfoByLinkHash?linkHash=${linkHash}`)
    .then((response) => {
        return response;
    });

export const deleteInvitationById = (id) => api
    .post(`/api/InviteLink/DeleteInvitationById`, {id});

export const updateInvitationStatusByAccountId = (accountId, isEnabled) => api
    .post(`/api/InviteLink/UpdateInvitationStatusByAccountId`, {
        AccountId: accountId, IsEnabled: isEnabled
    })
    .then((response) => {
        return response;
    });

export const loadInviteLink = (accountId) => api
    .get(`/api/InviteLink/Get?accountId=${accountId}`)
    .then((response) => {
        dispatch(setInviteLink(response));
        return response;
    });

export const loadCompanyNameByLinkHash = (linkHash) => {
    if (linkHash === undefined) {
        dispatch(setShortInfo(null));
        return;
    }

    return getShortInfoByLinkHash(linkHash)
        .then(response => {
            dispatch(setShortInfo(response));
            return response;
        });
}