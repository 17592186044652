import React from "react";
import _ from "lodash";
import {ProductionBooksGroupBy} from "./FilterBar/GroupBy/GroupBy";
import GroupedTables from "../Common/Tables/GroupedTable";

function getAllProjects(data) {
    return _.chain(data)
        .filter((d) => !d.Schedule.IsAllProjects)
        .map((d) => d.Schedule.Projects)
        .flatten()
        .uniqBy((d) => d.Id)
        .value();
}

function getGroups(groupBy, dataSource) {
    let groups = [];
    switch (groupBy) {
        case ProductionBooksGroupBy.Project:
            const projects = getAllProjects(dataSource);
            const allProject = {group: "All Projects", items: dataSource.filter((item) => item.Schedule.IsAllProjects)};
            groups = [
                allProject,
                ...projects.map((group) => ({
                    group: group.Name,
                    items: dataSource.filter(
                        (item) => !item.Schedule.IsAllProjects && item.Schedule.Projects.find((p) => p.Id === group.Id)
                    ),
                })),
            ];

            break;

        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });

    return groups;
}

const GroupProductionBooksTable = ({columns, dataSource, onRow, groupByFilter, loading}) => (
    <GroupedTables
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onRow={onRow}
        getGroups={() => getGroups(groupByFilter.value, dataSource)}
    />
);

export default GroupProductionBooksTable;
