import React from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {SidePanelItemType} from "../../../constants/constants";

import {openNew} from "../../../redux/reducers/detailsPanel";
import {deleteProductionBooks} from "../Delete/DeleteProductionBooks";
import Email from "../Email/Email";
import CloneProductionBook from "../Clone/Clone";
import ExportProductionBook from "../Export";
import usePermissions from "../../../hooks/usePermissions";
import ClearSelection from "../../Common/ClearSelection";

const ProductionBooksSelected = ({selected}) => <div>{`${selected.length} Production Books selected`}</div>;

const ProductionBooksRowButtons = () => {
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const {canCreateProductionBooks} = usePermissions();

    function newItemClick() {
        setTimeout(() => dispatch(openNew({type: SidePanelItemType.ProductionBooks})), 100);
    }

    function handleMoreMenuClick(e) {
    }

    const dispatch = useDispatch();

    const nothingSelected = selected.length === 0;
    const isSingleItemSelected = selected.length === 1;
    const book = selected.length === 1 ? selected[0] : null;

    const MoreMenu = (
        <Menu onClick={handleMoreMenuClick}>
            <Menu.Item key="1" disabled={nothingSelected}>
                <CloneProductionBook disabled={nothingSelected} selected={selected}></CloneProductionBook>
            </Menu.Item>
            <Menu.Item key="2" disabled={!isSingleItemSelected}>
                <Email selected={selected} disabled={!isSingleItemSelected}></Email>
            </Menu.Item>
            <Menu.Item key="3" disabled={!isSingleItemSelected}>
                <ExportProductionBook book={book} disabled={!isSingleItemSelected}></ExportProductionBook>
            </Menu.Item>
            <Menu.Item
                key="4"
                disabled={nothingSelected}
                onClick={() => {
                    deleteProductionBooks(selected, activePortfolioId, dispatch);
                }}>
                Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Space>
            {isBulkSelection && <ProductionBooksSelected selected={selected}/>}
            <ClearSelection/>
            <Dropdown trigger="click" overlay={MoreMenu}>
                <Button>
                    More <DownOutlined/>
                </Button>
            </Dropdown>
            {!isBulkSelection && (
                <>
                    <Button disabled={!canCreateProductionBooks} type="primary" onClick={newItemClick}>
                        New Production Book
                    </Button>
                </>
            )}
        </Space>
    );
};

export default ProductionBooksRowButtons;
