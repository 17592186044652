import React, {useState} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const ProductionBooksGroupBy = Object.freeze({
    Project: "Projects",
    Category: "Categories",
    Department: "Departments",
});

const defaultItems = {
    project: {
        value: ProductionBooksGroupBy.Project,
        title: ProductionBooksGroupBy.Project,
    },
};

const items = [defaultItems.project];

const GroupByFilter = ({setGroupBy, type}) => {
    const [selectedItem, setSelectedItem] = useState();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
