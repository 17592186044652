import React, {useState, useRef} from "react";
import _ from "lodash";
import {List, Button, Input} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {ApiContactType} from "../../../../constants";
import {createContact, updateContact} from "../../../../services/contacts";
import "./Spaces.scss";
import {useEffect} from "react";
import {doBatchDeleteContacts} from "../../DeleteContact/DeleteContact";
import usePermissions from "../../../../hooks/usePermissions";
import {TooltipWithOrNot} from "../../../Common/Input";

const EditSpaceRow = ({
                          item = {},
                          parentContactId,
                          activePortfolioId,
                          onUpdate,
                          onDelete,
                          onContactCreate,
                          notesRef,
                          nameRef,
                          isDeleteDisabled,
                          isProjectManager,
                      }) => {
    const [name, setName] = useState(item.Company);
    const [notes, setNotes] = useState(item.Notes);
    const onChanged = () => {
        if (!name) return;

        if (!item?.Id) {
            createContact(
                activePortfolioId,
                ApiContactType.Space,
                {
                    Company: name,
                    ContactType: ApiContactType.Space,
                },
                parentContactId
            ).then((response) => {
                onContactCreate({Id: response.Id, Company: name});
            });
        } else {
            const updatePayload = {
                Company: name,
                Notes: notes,
                Id: item.Id,
                ContactType: ApiContactType.Space,
            };
            updateContact(updatePayload, parentContactId).then(() => onUpdate({...item, ...updatePayload}));
        }
    };

    const deleteSpace = async () => {
        if (isDeleteDisabled) return;

        const {Id: itemId} = item;
        doBatchDeleteContacts([item], () => onDelete(itemId), DeleteContactsContent);
    };

    return (
        <div className="space-item">
            <div className="title">
                <Input
                    value={name}
                    onChange={({target: {value}}) => setName(value)}
                    ref={nameRef}
                    onPressEnter={onChanged}
                    onBlur={onChanged}></Input>
            </div>
            <div className="notes">
                <Input.TextArea
                    value={notes}
                    ref={notesRef}
                    disabled={!item?.Id}
                    onChange={({target: {value}}) => setNotes(value)}
                    onPressEnter={onChanged}
                    onBlur={onChanged}></Input.TextArea>
            </div>
            <div className="actions">
                <TooltipWithOrNot
                    showTooltip={isProjectManager}
                    title="Only owners and administrators may delete spaces. Contact an administrator to delete a space.">
                    <Button onClick={deleteSpace} disabled={isDeleteDisabled} type="link" size="small">
                        <DeleteOutlined className={`delete ${isDeleteDisabled ? "disabled" : ""}`}/>
                    </Button>
                </TooltipWithOrNot>
            </div>
        </div>
    );
};

const DeleteContactsContent = (
    <>
        It will be deleted from everywhere that it appears in your projects. <br/>
        <br/>
        You cannot undo this action.
    </>
);

const Spaces = ({activePortfolioId, value, onChange, onChanged, parentContactId, isReadOnly}) => {
    const [showNewRow, setShowNewRow] = useState(false);
    const {isProjectManager} = usePermissions();
    useEffect(() => {
        setShowNewRow(false);
    }, [parentContactId]);

    const onUpdate = (space) => {
        onChange([...value.filter((s) => s.Id !== space.Id), space]);
        onChanged();
    };

    const lastNotesRef = useRef();
    const lastNameRef = useRef();

    const onContactCreate = (space) => {
        onChange([...value, space]);
        onChanged();
        setShowNewRow(false);
        lastNotesRef.current?.focus();
    };

    const addSpace = () => {
        setShowNewRow(true);
        setTimeout(() => lastNameRef.current.focus(), 100);
    };

    const onDelete = (itemId) => {
        if (!itemId) setShowNewRow(false);
        else {
            onChange(value.filter((item) => item.Id !== itemId));
            onChanged();
        }
    };

    const spaces = _.sortBy(showNewRow ? [...value, {}] : value, "Id");

    return (
        <List
            header={
                <div className="space-item">
                    <div className="title">Space</div>
                    <div className="notes">Details</div>
                    <div className="actions">
                        {!isReadOnly && (
                            <Button type="primary" size="small" onClick={addSpace}>
                                Add a space
                            </Button>
                        )}
                    </div>
                </div>
            }
            size="small"
            dataSource={spaces}
            renderItem={(item, index) => (
                <List.Item>
                    <EditSpaceRow
                        isDeleteDisabled={((value || []).length === 1 && item.Id) || isProjectManager}
                        isProjectManager={isProjectManager}
                        key={`${item.Id}-${index}`}
                        item={item}
                        onDelete={onDelete}
                        parentContactId={parentContactId}
                        onUpdate={onUpdate}
                        onContactCreate={onContactCreate}
                        activePortfolioId={activePortfolioId}
                        notesRef={index === spaces.length - 1 ? lastNotesRef : null}
                        nameRef={index === spaces.length - 1 ? lastNameRef : null}
                    />
                </List.Item>
            )}></List>
    );
};

export default Spaces;
