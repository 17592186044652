import React from "react";
import {useLocation} from "react-router-dom";
import {getRoute} from "../../../helpers";
import "./Breadcrumb.scss";

const Breadcrumbs = () => {
    const route = useLocation();

    const {breadcrumbs = []} = getRoute(route.pathname) || {};
    return (
        <span className="ant-breadcrumb-root">
      {breadcrumbs.map((breadcrumb, index) => (
          <span key={index} className={index === 0 ? "first-breadcrumb" : ""}>
          <breadcrumb.icon/>
              {typeof breadcrumb.content === "function" ? <breadcrumb.content/> : breadcrumb.content}
              <span className="ant-breadcrumb-separator">/</span>
        </span>
      ))}
    </span>
    );
};

export default Breadcrumbs;
