import React from "react";
import {message} from "antd";
import {doBatchDelete} from "../../Common/Modal/BatchDelete/BatchDelete";
import {batchRemove, refreshArchivedProjects} from "../../../services/projects";

const getTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete ${selected[0].Name}?`;
    } else {
        return `Are you sure you want to delete these ${selected.length} projects?`;
    }
};

const getDescription = (selected) => {
    if (selected.length === 1) {
        return (
            <>
                {selected[0].Name} will be deleted FOREVER. You will lose all Team and Timeline information in this
                project.
                <br/>
                <br/>
                You cannot undo this action
            </>
        );
    } else {
        return (
            <>
                They will be deleted FOREVER. You will lose all Team and Timeline information in these projects.
                <br/>
                <br/>
                You cannot undo this action
            </>
        );
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`${selected[0].Name} was deleted.`);
    } else {
        message.success(`${selected.length} Projects were deleted.`);
    }
};

const deleteProject = (selected) =>
    doBatchDelete({
        okText: "Delete",
        onFinish: () => refreshArchivedProjects(),
        title: getTitle(selected),
        content: getDescription(selected),
        batchRemove: () => batchRemove(selected.map((s) => s.Id)),
        onFinishMessage: () => showFinishMessage(selected),
    });

export {deleteProject};
