import React from "react";
import _ from "lodash";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";
import {useSelector} from "react-redux";

const fieldsToSearch = [
    (item) => (item.CategoryTags || []).map((x) => x.Name),
    (item) => (item.DepartmentTags || []).map((x) => x.Name),
    "LabourLine",
    "Name",
    "Project.Name",
    "Notes",
    "StatusTag.Name",
    (item) => {
        return (item.Locations || []).map((m) => m.Name);
    },
    (item) => {
        return (item.PeopleOrganizations || []).map((m) => m.Name);
    },
    (item) => _.flatten((item.PeopleOrganizations || []).map((m) => m.RoleTags.map((x) => x.Name))),
];

const SearchFilter = ({setFilterHandler, needResetFilter}) => {
    const projects = useSelector((state) => state.projects.projects);

    const fieldsToSearchWithProjects = [...fieldsToSearch, (item) => projects.find((p) => p.Id === item.ProjectId).Name];

    return (
        <CommonSearchFilter
            setFilterHandler={setFilterHandler}
            needResetFilter={needResetFilter}
            fieldsToSearch={fieldsToSearchWithProjects}
        />
    );
};

export default SearchFilter;
