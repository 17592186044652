import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {useSelector} from "react-redux";
import {whoAmILight, updateUserSettings} from "../../../../services/auth";

const noItem = {
    value: -1,
    title: "— No Departments —",
    sortRank: 1,
};

const nothingSelectedValue = -100;

function getItems(data) {
    return [
        noItem,
        ..._.chain(data)
            .map((d) => d.DepartmentTags)
            .flatten()
            .uniqBy((x) => x.AccountTagId)
            .map((item) => ({value: item.AccountTagId, title: item.Name}))
            .value(),
    ];
}

const DepartmentsFilter = ({data, needResetFilter, setFilterHandler}) => {
    const todaysNoteDepartments = useSelector((state) => state.currentUser.user.TodaysNoteDepartments || []);

    const [selectedItems, setSelectedItems] = useState();

    const items = useMemo(() => {
        return getItems(data);
    }, [data]);

    const itemsHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        onSelectionChanged([], true);
    }, [itemsHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        if (itemsHash) {
            if (todaysNoteDepartments.length === 0) {
                onChanged(items);
            } else if (todaysNoteDepartments.length === 1 && todaysNoteDepartments[0] === nothingSelectedValue) {
                onChanged([]);
            } else {
                onChanged(items.filter((i) => todaysNoteDepartments.includes(i.value)));
            }
        }
    }, [itemsHash, todaysNoteDepartments]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        if (selectedItems.length === 0) {
            setFilterHandler(() => false);
            return;
        }

        setFilterHandler(
            (item) =>
                _.intersection(
                    item.DepartmentTags.map((x) => x.AccountTagId),
                    filterValues
                ).length > 0 ||
                (isNoItemSelected && item.HasNoDepartmentTag)
        );

        setSelectedItems(selectedItems);
    }

    const onSelectionChanged = (values, selectDefault = false) => {
        setSelectedItems(selectDefault ? items : values);
        const selectedDepartments =
            selectDefault || values.length > 0 ? values.map((x) => x.value).join(",") : nothingSelectedValue.toString();
        updateUserSettings({
            TodaysNoteDepartments: selectedDepartments,
        }).then(whoAmILight);
    };

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Departments"
            onChanged={onSelectionChanged}
            search
        />
    );
};

export default DepartmentsFilter;
