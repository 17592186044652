import _ from "lodash";

export const FieldType = Object.freeze({
    Name: "Name",
    Department: "DepartmentTag",
    Description: "Description",
    Source: "Source",
    SourceDetails: "SourceDetails",
    Quantity: "Quantity",
    Cost: "Cost",
    Unit: "Unit",
    Empty: "Empty",
});

const commonCsvToFieldType = {
    Name: FieldType.Name,
    Item: FieldType.Name,
    Resource: FieldType.Name,

    Department: FieldType.Department,

    Notes: FieldType.Description,
    Details: FieldType.Description,

    Source: FieldType.Source,

    Inventory: FieldType.Quantity,
    Inv: FieldType.Quantity,
    Quantity: FieldType.Quantity,
    Qty: FieldType.Quantity,
    Number: FieldType.Quantity,
    Num: FieldType.Quantity,

    Cost: FieldType.Cost,
    Price: FieldType.Cost,
    "Default Cost": FieldType.Cost,

    Type: FieldType.Unit,
    "Cost Type": FieldType.Unit,
    "Default Cost Type": FieldType.Unit,
    Unit: FieldType.Unit,
    "Cost Unit": FieldType.Unit,
    "Default Cost Unit": FieldType.Unit,
    "Source Details": FieldType.SourceDetails
};

export const commonModelFields = {
    [FieldType.Name]: "Name",
    [FieldType.Department]: "DepartmentTag",
    [FieldType.Description]: "Description",
    [FieldType.Source]: "Source",
    [FieldType.SourceDetails]: "SourceDetails",
    [FieldType.Quantity]: "Quantity",
    [FieldType.Cost]: "Cost",
    [FieldType.Unit]: "Unit",

};

export const getCsvToFieldType = () => commonCsvToFieldType;

export const TimelineImportInputLabel = {
    [FieldType.Name]: "Item Name",
    [FieldType.Department]: "Department",
    [FieldType.Description]: "Details",
    [FieldType.Source]: "Source",
    [FieldType.SourceDetails]: "Source Details",
    [FieldType.Quantity]: "Inventory",
    [FieldType.Cost]: "Default Cost",
    [FieldType.Unit]: "Cost Type",
};

const commonTypes = [
    FieldType.Name,
    FieldType.Department,
    FieldType.Description,
    FieldType.Source,
    FieldType.SourceDetails,
    FieldType.Quantity,
    FieldType.Cost,
    FieldType.Unit,
];

export const getTargetFieldTypes = () => commonTypes

const toModelField = (data, type) => {
    return data;
};

function parseUnit(unit) {
    switch (unit) {
        case "/Item":
        case "/Day":
        case "Flat":
            return unit;
        default:
            return "/Item";
    }
};

export const getParsedData = (dataList = [], columnsMap) => {
    const defaultFields = _.fromPairs(_.keys(commonModelFields).map((k) => [commonModelFields[k], toModelField(null, k)]));

    const plainItems = dataList.map(data => ({
        ...defaultFields,
        ..._.fromPairs(
            data.map((d, i) => {
                return [commonModelFields[columnsMap[i]], toModelField(d, columnsMap[i])];
            })
        ),
    }));

    const bySource = _.groupBy(plainItems, m => `${m?.Name}_${m?.DepartmentTag}_${m?.Description}`);

    const result = _.keys(bySource).filter(k => bySource[k] && bySource[k][0]).map(k => {
        const first = bySource[k][0];
        const sources = bySource[k].map((s, i) => {
            return {
                Key: i,
                Name: s.Source,
                Quantity: _.toFinite(s.Quantity) ? _.toInteger(s.Quantity) : null,
                Cost: _.toFinite(s.Cost) ? _.toNumber(s.Cost) : null,
                Unit: parseUnit(s.Unit),
                Description: s.SourceDetails
            }
        });
        return {
            Name: first.Name,
            DepartmentTagId: first.DepartmentTag ? [first.DepartmentTag] : [],
            Description: first.Description,
            Sources: sources
        }
    });

    return result;
}
