import api from "../api";
import store from "../redux/store";
import {setPositions} from "../redux/reducers/labour";

const {dispatch} = store;

export const loadPositions = (accountId) =>
    api.post(`api/LabourPosition/GetByAccount`, {accountId})
        .then((response) => {
            dispatch(setPositions(
                response.Items
            ));
        });

export const refreshPositions = () => {
    const accountId = store.getState().projects.activePortfolio.Id;
    return loadPositions(accountId);
};

export const getPosition = (id) =>
    api.get(`api/LabourPosition/Get?id=${id}`);

export const createPosition = (payload) =>
    api.post(`api/LabourPosition/Create`, payload, `Create_Position_${payload?.AccountId}`);

export const updatePosition = (payload) =>
    api.post(`api/LabourPosition/Update`, payload, `Update_Position_${payload?.Id}`);

export const deletePositions = (accountId, ids) =>
    api.post(`api/LabourPosition/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})));

