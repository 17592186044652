import api from "../api/api";
import store from "../redux/store";
import {setAccountMembers, setCurrentPlan, setMaxMembers} from "../redux/reducers/settings";

const {dispatch} = store;

export const loadAccountMembers = (accountId) =>
    api
        .get(`api/AccountMember/GetPaged?accountId=${accountId}`)
        .then((response) => {
            dispatch(setAccountMembers(response.Items || []));
            dispatch(setMaxMembers({
                MaxAdmins: response.MaxAdmins,
                MaxAssistantsPerProject: response.MaxAssistantsPerProject
            }))
            return response;
        });

export const create = (accountId, payload) =>
    api.post(`api/AccountMember/Create?accountId=${accountId}`, payload);

export const remove = (id) =>
    api.post(`api/AccountMember/Remove`, {Id: id});

export const update = (accountId, payload) =>
    api.post(`api/AccountMember/Update?accountId=${accountId}`, payload);

export const getItem = (id) => api.get(`api/AccountMember/Get?id=${id}&includeFlags=3`);

export const getAccessByProject = (accountId, projectId) => api.get(`api/AccountMember/GetByProject?accountId=${accountId}&projectId=${projectId}`);

export const loadCurrentPlan = (id, timezone) => api.get(`api/AccountSubscription/GetCurrentPlan?accountId=${id}&timeZoneId=${encodeURIComponent(timezone)}`)
    .then((response) => {
        dispatch(setCurrentPlan(response));
        return response;
    });

export const transferOwnership = (accountId, accountMemberId) =>
    api.post(`api/Contact/TransferOwnership?accountId=${accountId}&accountMemberId=${accountMemberId}`, {});


