import React from "react";
import FilterBar from "../../../Common/Tables/FilterBar/FilterBar";
import IsDoneFilter from "./IsDoneFilter";
import SearchFilter from "./SearchFilter";
import StatusFilter from "./StatusFilter";
import DepartmentsFilter from "./DepartmentsFilter";
import ChangedAtFilter from "../../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";

const GroupByComponent = ({setGroupBy}) => {
    setTimeout(() => setGroupBy("Dates"), 100);
    return "";
};

const NotesFilterBar = (props) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[IsDoneFilter, DepartmentsFilter, StatusFilter, ChangedAtFilter, SearchFilter]}
            GroupByComponent={GroupByComponent}
        />
    );
};

export default NotesFilterBar;
