import {Alert, Row, Col} from "antd";
import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {ApiPermissionLevel} from "../../../constants/constants";
import "./SettingsHeaderSecondRow.scss";
import {getMaxProjectPms} from "../FilterBar/Limits/Limits";

const SettingsHeaderSecondRow = () => {
    const currentPlan = useSelector((state) => state.settings?.currentPlan);
    const {MaxAssistantsPerProject = 0, MaxAdmins = 0} = currentPlan || {};
    const accountMembers = useSelector((state) => state.settings.accountMembers);
    const admins = accountMembers.filter((a) => a.PermissionLevel === ApiPermissionLevel.Administrator).length;
    const maxProjectPms = getMaxProjectPms(accountMembers);

    const visible = currentPlan && (maxProjectPms > MaxAssistantsPerProject || admins > MaxAdmins);
    return (
        visible && (
            <Row justify="center" className="settings-alert">
                <Col>
                    <Alert
                        message="There are more users assigned than your subscription allows for. Please remove existing users or contact us to add more."
                        type="error"></Alert>
                </Col>
            </Row>
        )
    );
};

export default SettingsHeaderSecondRow;
