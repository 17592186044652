import React from "react";
import SearchFilter from "./SearchFilter/SearchFilter";
import FilterBar from "../../../Common/Tables/FilterBar/FilterBar";

const TagsFilterBar = ({...props}) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[SearchFilter]}
            // additionalFilters={additionalFilters}
        />
    );
};

export default TagsFilterBar;
