import React from "react";
import classNames from "classnames";

import {message} from "antd";
import {deleteRequirements, refreshRequirements} from "../../../services/requirements";
import "./DeleteRequirement.scss";
import usePermissions from "../../../hooks/usePermissions";
import {doBatchDelete} from "../../Common/Modal/BatchDelete/BatchDelete";
import DeleteAction from "../../Common/Tables/Actions/DeleteAction";
import {useDispatch} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";
import {TooltipWithOrNot} from "../../Common/Input";
import {deleteLabourLines} from "../../../services/labour";

const getDeleteLabourLinesTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete this labour line?`;
    } else {
        return `Are you sure you want to delete ${selected.length} labour lines?`;
    }
};

const getTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete ${selected[0].Requirement.Name}?`;
    } else {
        return `Are you sure you want to delete these ${selected.length} items?`;
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Resource was deleted.`);
    } else {
        message.success(`${selected.length} resources were deleted.`);
    }
};

const showFinishLabourLinesMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Labour line was deleted.`);
    } else {
        message.success(`${selected.length} labour lines were deleted.`);
    }
};

const DeleteRequirement = ({record}) => {
    const dispatch = useDispatch();
    const {hasThingsAccess} = usePermissions();
    const canDelete = hasThingsAccess;

    const className = classNames({
        disabled: !canDelete,
        "delete btn-table-actions": true,
    });

    return (
        <TooltipWithOrNot showTooltip={!canDelete} title="Things add-on is not activated.">
            <DeleteAction
                record={record}
                className={className}
                onClick={() => {
                    if (!canDelete) return;

                    doBatchDeleteRequirements([record], () => {
                        dispatch(setSelected([]));
                        refreshRequirements();
                    });
                }}
            />
        </TooltipWithOrNot>
    );
};

export const doBatchDeleteRequirements = (selected, onFinish) => {
    return doBatchDelete({
        onFinish,
        title: getTitle(selected),
        content: (
            <>
                Their record and all information associated with them will be deleted FOREVER. <br/>
                <br/>
                You cannot undo this action.
            </>
        ),
        batchRemove: () => deleteRequirements(selected.map((s) => s.Id)),
        onFinishMessage: () => showFinishMessage(selected),
        okText: "Delete",
    });
};

export const doBatchDeleteLabourLines = (selected, onFinish) => {
    return doBatchDelete({
        onFinish,
        title: getDeleteLabourLinesTitle(selected),
        content: <>Any associated crew bookings will also be deleted.</>,
        batchRemove: () => deleteLabourLines(selected.map((s) => s.Id)),
        onFinishMessage: () => showFinishLabourLinesMessage(selected),
        okText: "Delete",
    });
};

export default DeleteRequirement;
