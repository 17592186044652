import React from "react";
import {ApiPermissionLevelName} from "../../../../constants/constants";
import {formatContactName} from "../../../../helpers/text";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";

const fieldsToSearch = [
    "Name",
    (item) => {
        return [ApiPermissionLevelName[item.PermissionLevel]];
    },
    (item) => {
        return formatContactName(item.CreatedFromContact);
    },
];

const SearchFilter = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter setFilterHandler={setFilterHandler} needResetFilter={needResetFilter}
                        fieldsToSearch={fieldsToSearch}/>
);

export default SearchFilter;
