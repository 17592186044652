import React, {useEffect} from "react";
import _ from "lodash";
import {Button} from "antd";
import {useSelector} from "react-redux";
import {SelectWithFormItem} from "../../Common/Input";
import {createResource, loadResourcesLookup} from "../../../services/resources";
import {SearchOutlined} from "@ant-design/icons";

const ResourceSelect = (props) => {
    const {
        form,
        name,
        label,
        onChange,
        onChanged,
        disabled,
        setIsQuickCreateItemVisible,
        hideQuickCreate = false,
        onSearchClick,
        className = "",
        ...passThroughProps
    } = props;

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const resourcesUnsorted = useSelector((state) => state.resources.resourcesLookup || []);
    const resources = _.sortBy(resourcesUnsorted, (m) => m.Name);

    useEffect(() => {
        if (!activePortfolioId) return;

        loadResourcesLookup(activePortfolioId);
    }, [activePortfolioId, form]);

    const onSelectionChange = (val) => {
        if (_.isString(val)) {
            const resourceId = parseInt(val.split(":")[1], 10);

            createResource(resources.find((s) => s.Id === resourceId)).then((res) =>
                loadResourcesLookup(activePortfolioId).then(() => {
                    onChange(res.Id);
                    onChanged();
                })
            );
        } else {
            onChange(val);
            onChanged();
        }
    };

    return (
        <SelectWithFormItem
            name={name}
            label={label}
            className={className}
            form={form}
            allowClear={true}
            showSearch={true}
            optionLabelProp="label"
            style={{width: "100%"}}
            placeholder="Select an Item"
            mode="single"
            onChange={onSelectionChange}
            onChanged={onSelectionChange}
            disabled={disabled}
            {...passThroughProps}
            options={resources.map((m) => ({value: m.Id, label: m.Name}))}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    {!hideQuickCreate && (
                        <div className="btn-quick-create">
                            <Button type="dashed" onClick={() => setIsQuickCreateItemVisible(true)}>
                                + Create a new Item
                            </Button>
                            &nbsp;
                            {onSearchClick && (
                                <Button type="dashed" onClick={onSearchClick}>
                                    <SearchOutlined/> Search
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}></SelectWithFormItem>
    );
};

export default ResourceSelect;
