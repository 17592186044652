import React from "react";
import _ from "lodash";
import moment from "moment";
import GroupedTables from "../../Common/Tables/GroupedTable";
import {useSelector} from "react-redux";

const getKey = (item) => item.LinkedIds.join(",");

const NO_DUE_DATE = "No Due Date";
const groupNames = [NO_DUE_DATE, "Overdue", "Due Today", "Due Later", "Done"];

function getProjectName(id, projects) {
    const project = projects.find((p) => p.Id === id);
    return project?.ShortName ?? project?.Name;
}

export const sortNotes = (items, projects) =>
    _.sortBy(items, ["DueDate", (item) => getProjectName(item.ReportInfo.ProjectId, projects), "Text"]);

function getGroups(dataSource, projects, notesFilterIsDone) {
    const today = moment();
    let grouped = {};
    if (notesFilterIsDone) {
        grouped = {
            Done: dataSource.filter((item) => item.IsDone),
        };
    } else {
        grouped = {
            "No Due Date": dataSource.filter((item) => !item.DueDate),
            Overdue: dataSource.filter((item) => item.DueDate && moment(item.DueDate).isBefore(today, "day")),
            "Due Today": dataSource.filter((item) => item.DueDate && moment(item.DueDate).isSame(today, "day")),
            "Due Later": dataSource.filter((item) => item.DueDate && moment(item.DueDate).isAfter(today, "day")),
        };
    }

    return groupNames
        .map((name) => ({
            group: name,
            items: sortNotes(grouped[name], projects),
            keys: grouped[name]?.map(getKey),
        }))
        .filter((g) => g.items.length > 0);
}

const GroupNotesTable = ({columns, dataSource, onRow}) => {
    const projects = useSelector((state) => state.projects.projects) || [];
    const notesFilterIsDone = useSelector((state) => state.reports.notesFilterIsDone);
    const groups = getGroups(dataSource, projects, notesFilterIsDone);
    const defaultCollapseActiveKey = groupNames.filter((item) => !item.includes(NO_DUE_DATE));
    return (
        <GroupedTables
            defaultCollapseActiveKey={defaultCollapseActiveKey}
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            getRowKey={getKey}
            getGroups={() => groups}
        />
    );
};

export default GroupNotesTable;
