import React, {useState, useEffect} from "react";
import _ from "lodash";
import {Modal, Form, Select} from "antd";
import {FileTextFilled} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {createReportFromEvent, loadReports} from "../../../services/reports";
import {getEvent, refreshTimeline} from "../../../services/timeline";
import {ApiTimelineItemType, SidePanelItemType} from "../../../constants/constants";
import {openEdit, detailsSubPanel} from "../../../redux/reducers/detailsPanel";
import {selectFilterOption} from "../../Common/Input";

const {openEdit: openEditSubPanel} = detailsSubPanel;

const Title = () => (
    <>
        <FileTextFilled/> New Report from Event
    </>
);

const mapTemplates = (template) => ({
    value: template.Id,
    label: template.Name,
});

const blankTemplate = {value: "Blank", label: "— Blank —"};

const CreateReportFromEvent = ({isVisible, onClose, selected}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const templates = useSelector((state) => state.reports.templates);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        if (!isVisible) return;

        const loadData = async () => {
            try {
                setIsDataLoading(true);
                await loadReports(selected[0].ProjectId, activePortfolio.Id);
            } finally {
                setIsDataLoading(false);
            }
        };
        loadData();
    }, [selected, isVisible]);

    async function handleOk() {
        setIsLoading(true);
        try {
            const values = await form.validateFields();
            const templateId = values.TemplateId === blankTemplate.value ? null : values.TemplateId;
            const report = await createReportFromEvent(selected[0].Id, templateId);
            const eventItem = await getEvent(selected[0].Id, selected[0].ProjectId, ApiTimelineItemType.Task);
            await refreshTimeline(ApiTimelineItemType.Task);
            dispatch(openEdit({item: eventItem, type: SidePanelItemType.Event}));
            dispatch(openEditSubPanel({item: report, type: SidePanelItemType.Reports}));

            close();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function close() {
        onClose();
        form.resetFields();
    }

    const save = () => {
    };
    return (
        <>
            <Form
                initialValues={{
                    TemplateId: blankTemplate.value,
                }}
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 18}}
                layout="horizontal"
                size="middle">
                <Modal
                    title={<Title/>}
                    visible={isVisible}
                    maskClosable={false}
                    onOk={handleOk}
                    onCancel={close}
                    confirmLoading={isLoading}
                    okText={`Create New Report`}>
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <Form.Item label="Template" name="TemplateId" hasFeedback
                                   rules={[{required: true, message: "Template"}]}>
                            <Select
                                loading={isDataLoading}
                                showSearch
                                style={{width: "100%"}}
                                onChange={save}
                                onBlur={save}
                                filterOption={selectFilterOption}
                                options={[blankTemplate, ..._.sortBy(templates.map(mapTemplates), "label")]}></Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </Form>
        </>
    );
};
export default CreateReportFromEvent;
