import React from "react";
import {message} from "antd";
import {loadCollections, cloneCollections} from "../../../../services/resources";
import {errorFromHttpResponse} from "../../../../helpers/error";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../../../redux/reducers/mainTable";

const getMessage = (selected) => `${selected.length} ${selected.length === 1 ? 'collection' : 'collections'} successfully cloned.`;

const CloneCollection = ({disabled, selected = []}) => {
    const dispatch = useDispatch();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const onClone = () => {
        if (disabled) return;

        cloneCollections(selected.map((i) => i.Id))
            .then(() => loadCollections(activePortfolioId))
            .then(() => {
                message.success(getMessage(selected));
                dispatch(setSelected([]));
            })
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
            });
    };

    return (
        <div disabled={disabled} onClick={onClone}>
            Clone
        </div>
    );
};

export default CloneCollection;