import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";
import {Button, Checkbox, DatePicker, List, message, Select} from "antd";
import {DeleteOutlined, EyeFilled, PlusOutlined} from "@ant-design/icons";
import "./DepartmentsNotes.scss";
import {createColorTagRendered, mapTagToOption} from "../../../../helpers/Tags";
import {useDispatch, useSelector} from "react-redux";
import {SidePanelItemType, TagType} from "../../../../constants/constants";
import CreateTag from "../../../Common/QuickCreate/Tag";
import {selectFilterOption} from "../../../Common/Input";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import moment from "moment";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../../helpers/paths";
import {getNotes} from "../../../../services/reports";
import {setActiveProjects} from "../../../../redux/reducers/projects";
import {setNotesFilterIsDone} from "../../../../redux/reducers/reports";
import {openEdit} from "../../../../redux/reducers/detailsPanel";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const dueDateToApi = (val) => (val ? val.startOf("day").utcOffset(0, true).format() : null);

const modules = {
    toolbar: {
        container: [['bold', 'italic', 'underline'], [{'list': 'ordered'}, {'list': 'bullet'}], [{color: []}], ['link'], ['clean'], // ['emoji']
        ],
    }, 'emoji-toolbar': false, 'emoji-textarea': false, 'emoji-shortname': false,
};

const EditRow = ({item = {}, onUpdate, onDelete, allDepartments, setFocusDepartments, focusDepartments = false}) => {
    const departmentsForItem = item.linked.filter((d) => d.accountTagId).map((d) => d.accountTagId.toString());
    const [departments, setDepartments] = useState(departmentsForItem);
    const [isDepartmentsVisible, setDepartmentsVisible] = useState(false);
    const [note, setNote] = useState(item.text);
    const [isTrack, setIsTrack] = useState(item.isTrack);
    const [dueDate, setDueDate] = useState(item.dueDate ? moment(item.dueDate) : null);
    const [createTagType, setCreateTagType] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const dateTimeFormats = useDateTimeFormat();
    const projects = useSelector((state) => state.projects.projects);
    const textInputHref = useRef();
    const departmentsInputHref = useRef();

    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    useEffect(() => {
        if (!item.id) {
            textInputHref.current.focus();
        }
    }, [item.id]);

    useEffect(() => {
        setDepartments(item.accountTagId ? departmentsForItem : []);
    }, [item.accountTagId, departmentsForItem.join(",")]);

    useEffect(() => {
        setIsTrack(item.isTrack);
        setDueDate(item.dueDate ? moment(item.dueDate) : null);
    }, [item.isTrack, item.dueDate]);

    useEffect(() => {
        if (focusDepartments) {
            departmentsInputHref.current.focus();
        }
    }, [focusDepartments]);

    useEffect(() => {

        const closeButton = document.querySelector('.ant-drawer-close');
        if (closeButton) {
            const handleCloseButtonClicked = (event) => {
                if (textInputHref.current) {
                    const quillInstance = textInputHref.current.getEditor();
                    quillInstance.blur();
                }
            };

            closeButton.addEventListener('click', handleCloseButtonClicked);

            return () => {
                closeButton.removeEventListener('click', handleCloseButtonClicked);
            };
        }
    }, []);

    const deleteItem = async () => {
        onDelete(item);
    };

    const gotToNote = () => {
        if (!item.isTrack) return;

        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Notes]));
        dispatch(setActiveProjects(projects));
        getNotes(projects.map((p) => p.Id))
            .then((notes) => {
                const note = notes.find((n) => n.LinkedIds.includes(item.id));
                if (note) {
                    dispatch(setNotesFilterIsDone(note.IsDone));
                    dispatch(openEdit({item: note, type: SidePanelItemType.Notes}));
                }
            })
            .catch(() => message.error("Server error"));
    };

    const onDateChange = (val) => {
        onUpdate({
            id: item.id,
            accountTagId: item.accountTagId,
            note: note,
            fromNote: item.text,
            departments: departments,
            linked: item.linked,
            isTrack,
            dueDate: dueDateToApi(val),
        });
    };

    const onTrackChange = (val) => {
        onUpdate({
            id: item.id,
            accountTagId: item.accountTagId,
            note: note,
            fromNote: item.text,
            departments: departments,
            linked: item.linked,
            isTrack: val,
            dueDate: dueDateToApi(dueDate),
        });
    };

    const onItemChange = () => {
        const isItemChanged = item.text !== note || (!item.accountTagId && departments.length > 0) || (item.accountTagId && _.xor(departments, departmentsForItem).length !== 0);

        if (!isItemChanged) return;

        onUpdate({
            id: item.id,
            accountTagId: item.accountTagId,
            note: note,
            fromNote: item.text,
            departments: departments,
            linked: item.linked,
            isTrack,
            dueDate: dueDateToApi(dueDate),
        });
    };

    const onTagCreated = (newTag) => {
        onUpdate({
            id: item.id,
            accountTagId: item.accountTagId,
            note: note,
            fromNote: item.text,
            departments: [...departments, newTag.AccountTagId.toString()],
            linked: item.linked,
            isTrack,
            dueDate: dueDateToApi(dueDate),
        });

        if (!item.accountTagId && !_.isEmpty(note)) {
            setDepartments([]);
        }
    };

    return (<div className="departments-notes-item">
        <div className="notes">
            <ReactQuill
                className={`quill-text-editor`}
                ref={textInputHref}
                value={note}
                onFocus={() => {
                    setFocusDepartments(-1);
                }}
                onKeyDown={(e) => {
                    if (e.code === "Tab") {
                        setFocusDepartments(item.id);
                        e.preventDefault();
                    }
                }}
                onChange={value => setNote(value)}
                onBlur={(value, source) => {
                    if (source !== 'silent') {
                        onItemChange(value);
                    }
                }}
                modules={modules}
            />
        </div>
        <div className="departments">
            <Select
                allowClear
                showSearch
                ref={departmentsInputHref}
                filterOption={selectFilterOption}
                searchValue={searchValue}
                mode="multiple"
                value={departments}
                open={isDepartmentsVisible}
                tagRender={createColorTagRendered("green", true)}
                style={{width: "100%"}}
                placeholder="Select departments"
                options={allDepartments.map(mapTagToOption)}
                onFocus={() => {
                    setDepartmentsVisible(true);
                }}
                onDropdownVisibleChange={(open) => {
                    setDepartmentsVisible(open);
                    if (!open) {
                        setTimeout(() => {
                            setSearchValue("");
                            onItemChange();
                        }, 200);
                    }
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setDepartmentsVisible(false);
                        setSearchValue("");
                    }, 200);
                }}
                onSearch={setSearchValue}
                onClear={() => {
                    setDepartments([]);
                }}
                onInputKeyDown={(e) => {
                    if (e.code === "Escape") {
                        setDepartmentsVisible(false);
                    }
                }}
                onChange={setDepartments}
                dropdownRender={(menu) => (<>
                    {menu}
                    <div className="btn-quick-create">
                        <Button
                            type="dashed"
                            onClick={() => {
                                setCreateTagType(TagType.Department);
                            }}>
                            + Add a Department
                        </Button>
                    </div>
                </>)}></Select>
        </div>
        <div className="track">
            <Checkbox
                checked={isTrack}
                onChange={(e) => {
                    setIsTrack(e.target.checked);
                    onTrackChange(e.target.checked);
                }}></Checkbox>
        </div>
        <div className="due-date">
            {!item.isDone ? (<DatePicker
                disabled={!isTrack}
                value={dueDate}
                onChange={(val) => {
                    setDueDate(val);
                    onDateChange(val);
                }}
                style={{width: "100%"}}
                format={dateTimeFormats.date}></DatePicker>) : (<span className="done">Done</span>)}
        </div>
        <div className="actions">
            <Button onClick={gotToNote} type="link" size="small" disabled={!isTrack}>
                <EyeFilled/>
            </Button>
            <Button onClick={deleteItem} type="link" size="small">
                <DeleteOutlined className="delete"/>
            </Button>
        </div>
        <CreateTag
            defaultName={searchValue}
            tagType={createTagType}
            onCreated={onTagCreated}
            onClose={() => setCreateTagType(null)}></CreateTag>
    </div>);
};

const getUniqNotes = (items) => _.chain(items)
    .groupBy((item) => `${item.AccountTagId}_${item.Text}`)
    .values()
    .map((g) => g[0])
    .value();

const getGroups = (items, departments) => {
    const groups = _.sortBy(_.groupBy(items, "Name"), 'Name');
    const result = _.keys(groups)
        .sort()
        .map((g) => {
            var notes = groups[g]
                .sort((a, b) => (a.Text > b.Text ? 1 : -1))
                .map((item) => ({
                    id: item.Id,
                    text: item.Text,
                    accountTagId: item.AccountTagId,
                    name: item.Name,
                    isTrack: item.IsTrack,
                    isDone: item.IsDone,
                    dueDate: item.DueDate,
                    linked: items
                        .filter((i) => (!_.isEmpty(i.Text) ? i.Text === item.Text : i.Id === item.Id))
                        .map((i) => ({
                            accountTagId: i.AccountTagId, id: i.Id,
                        })),
                }));
            return {
                accountTagId: groups[g][0].AccountTagId,
                name: departments.find((d) => d.AccountTagId === groups[g][0].AccountTagId)?.Name,
                notes,
            };
        });

    let noDepartment = result.find((i) => _.isNull(i.accountTagId));
    return noDepartment ? [noDepartment, ...result.filter((i) => !_.isNull(i.accountTagId))] : result;
};

const DepartmentsNotes = ({value = [], onChange, onChanged, projectId, disabled}) => {
    const [openDepartmentsId, setOpenDepartmentsId] = useState(-1);
    const departments = useSelector((state) => state.tags.departments || []);
    const groups = getGroups(value, departments);
    const onUpdate = (item) => {
        if (item.id) {
            const linkedIds = item.linked.map((l) => l.id);
            const oldDepartmentIds = item.linked.map((l) => l.accountTagId).filter((tag) => tag);
            const currentDepartmentIds = item.departments.map((d) => parseInt(d, 10));
            let deletedIds = [];
            let newDepartments = [];

            if (item.note === "" && item.fromNote === "" && item.departments.length > 0) {
                //update departments if note is empty text
                let updatedItems = value.map((v) => ({
                    ...v, AccountTagId: v.Id === item.id ? parseInt(item.departments[0], 10) : v.AccountTagId,
                }));

                updatedItems = [...updatedItems, ...currentDepartmentIds.map((department) => ({
                    AccountTagId: department,
                    Name: departments.find((x) => x.AccountTagId === department)?.Name,
                    Text: "",
                })),];

                onChange(getUniqNotes(updatedItems));
                onChanged();
                return;
            }

            if (oldDepartmentIds.length === 0 && currentDepartmentIds.length > 0) {
                newDepartments = currentDepartmentIds;
                deletedIds.push(item.id);
            } else if (oldDepartmentIds.length > 0 && currentDepartmentIds.length === 0 && item.accountTagId) {
                newDepartments.push(null);
                deletedIds = linkedIds;
            } else if (oldDepartmentIds.length > 0 && currentDepartmentIds.length > 0) {
                deletedIds = item.linked.filter((i) => i.accountTagId && !currentDepartmentIds.includes(i.accountTagId)).map((i) => i.id);
                newDepartments = currentDepartmentIds.filter((d) => !oldDepartmentIds.includes(d));
            }

            let updatedItems = value
                .filter((item) => !deletedIds.includes(item.Id))
                .map((i) => ({
                    ...i,
                    IsTrack: linkedIds.includes(i.Id) ? item.isTrack : i.IsTrack,
                    DueDate: linkedIds.includes(i.Id) ? item.dueDate : i.DueDate,
                    Text: linkedIds.includes(i.Id) ? item.note : i.Text,
                }));

            updatedItems = getUniqNotes(updatedItems);

            const newItems = newDepartments.map((department) => ({
                AccountTagId: department,
                Name: departments.find((x) => x.AccountTagId === department)?.Name,
                Text: item.note,
                IsTrack: item.isTrack,
                DueDate: item.dueDate,
            }));

            onChange([...newItems, ...updatedItems]);
            onChanged();
            return;
        } else {
            const departments = item.departments.map((d) => parseInt(d, 10));
            if (departments.length === 0) {
                departments.push(null);
            }
            const newItems = departments.map((department) => ({
                AccountTagId: department,
                Name: departments.find((x) => x.AccountTagId === department)?.Name,
                Text: item.note,
                IsTrack: item.isTrack,
                DueDate: item.dueDate,
            }));
            onChange([...newItems, ...value]);
            onChanged();
            return;
        }
    };

    const onDelete = (item) => {
        const id = item.linked.find((l) => l.accountTagId === item.accountTagId || (_.isNil(l.accountTagId) && _.isNil(item.accountTagId)))?.id;
        onChange(value.filter((i) => i.Id !== id));
        onChanged();
    };

    const addNewGroupNote = (item = {}) => {
        setOpenDepartmentsId(-1);
        const newItem = {
            AccountTagId: item.accountTagId || null, Name: item.name || null, Text: "",
        };
        onChange([newItem, ...value]);
        onChanged();
    };

    const addNoDepartmentDisabled = value.find((i) => i.AccountTagId == null && i.Text === "");

    return (<List
        header={<div className="departments-notes-item">
            <div className="notes">Note</div>
            <div className="departments">Department</div>
            <div className="track">Track</div>
            <div className="due-date">Due Date</div>
            <div className="actions">
                <Button type="primary" size="small" disabled={disabled || addNoDepartmentDisabled}
                        onClick={() => addNewGroupNote()}>
                    Add a Note
                </Button>
            </div>
        </div>}
        size="small"
        dataSource={groups}
        renderItem={(item, index) => (<List.Item className="group">
            <div className="group-header">
                <div>{`${item.name || "No Department"} (${item.notes.length})`}</div>
                <div className="add-button">
                    {item.accountTagId && (<Button
                        disabled={item.notes.find((n) => n.text === "")}
                        onClick={() => addNewGroupNote(item)}
                        type="primary"
                        size="small">
                        <PlusOutlined/>
                    </Button>)}
                </div>
            </div>
            {item.notes.map((record, i) => (<div key={`${index}-${i}-${record.text}`}>
                <EditRow
                    item={record}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    projectId={projectId}
                    allDepartments={departments}
                    focusDepartments={openDepartmentsId === record.id}
                    setFocusDepartments={setOpenDepartmentsId}
                />
            </div>))}
        </List.Item>)}></List>);
};

export default DepartmentsNotes;
