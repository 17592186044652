import {createSlice} from "@reduxjs/toolkit";

const resourcesSlice = createSlice({
    name: "resources",
    initialState: {
        resources: {},
        collections: [],
        sources: [],
        resourcesLookup: [],
        activeMonth: null,
        usages: []
    },
    reducers: {
        setResources(state, action) {
            state.resources = action.payload;
        },
        setResourcesLookup(state, action) {
            state.resourcesLookup = action.payload;
        },
        setCollections(state, action) {
            state.collections = action.payload;
        },
        setSources(state, action) {
            const {resourceId = 0, items = []} = action.payload;
            let sources = state?.sources || [];
            sources[resourceId] = items;
            state.sources = sources;
        },
        setActiveMonth(state, action) {
            state.activeMonth = action.payload;
        },
        setResourceUsages(state, action) {
            state.usages = action.payload;
        },
    },
});

export const {
    setResources,
    setResourcesLookup,
    setCollections,
    setSources,
    setActiveMonth,
    setResourceUsages
} = resourcesSlice.actions;

export default resourcesSlice.reducer;
