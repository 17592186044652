import React, {useState} from "react";
import _ from "lodash";
import {Tag, message} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import FilterBar from "../FilterBar";
import {startLoading, endLoading} from "../../../redux/reducers/detailsPanel";
import {useDispatch} from "react-redux";
import {RequirementType} from "../../../constants/constants";
import {getRequirement} from "../../../services/requirements";
import ProjectsTag from "../../Common/Projects/ProjectsTag";
import TableWithGroups from "../../Common/Tables/TableWithGroups";
import EditAction from "../../Common/Tables/Actions/EditAction";
import CloneAction from "./Actions/CloneAction";
import ShiftAction from "./Actions/ShiftAction";
import Shift from "../../Common/Modal/Shift";
import {shift} from "../../../services/requirements";
import {dateRange} from "../../../helpers/time";
import {formatInventory, formatLocationName} from "../../../helpers/text";
import {defaultCostFactorMapper, formatTotalCost} from "../../../helpers/money";
import moment from "moment";
import "./RequirementsTable.scss";
import GroupRequirementsTable from "./GroupRequirementsTable";
import RequirementsTableFooter, {GroupRequirementsTableFooter} from "./RequirementsTableFooter";
import {onCloneRequirements} from "./CloneFromExisting";
import {useSelector} from "react-redux";

const allProjectsTag = <Tag>All Projects</Tag>;

const ConflictIcon = (props) => <InfoCircleFilled className="warning-icon" {...props} />;

const defaultColumns = {
    project: {
        className: "project-column",
        title: "Project",
        dataIndex: "Project",
        width: "15%",
        render: (project) => (project ? <ProjectsTag projects={[project]}/> : allProjectsTag),
    },
    requirement: {
        title: "Requirement",
        dataIndex: ["Requirement", "Name"],
        width: "10%",
    },
    resource: {
        title: "Item",
        dataIndex: ["Resource", "Name"],
        width: "10%",
        render: (resource, record) => (
            <>
                <ConflictIcon hidden={!record.HasConflicts}/> {resource}
            </>
        ),
    },
    sources: {
        title: "Sources",
        dataIndex: "Sources",
        key: "Src",
        width: "10%",
        render: (items = []) =>
            items
                .filter((m) => m.Value?.Quantity > 0)
                .map((m) => m.Name)
                .join(", "),
    },
    dates: {
        title: "Dates",
        dataIndex: "Requirement",
        width: "150px",
    },
    quantity: {
        title: "Qty",
        dataIndex: "Sources",
        width: "40px",
        render: (r) => formatInventory(r?.map((s) => s.Value)),
    },
    cost: {
        title: "Cost",
        dataIndex: "Sources",
        width: "70px",
        render: (s, record) => {
            const sources = s?.map((s) => s.Value);
            const {StartDateTime: start, EndDateTime: end} = record?.Requirement || {};

            const factorMapper = (m) => {
                const days = start && end ? moment(end).diff(start, "days") + 1 : 1;
                return {...defaultCostFactorMapper(m), days: days};
            };

            return formatTotalCost(sources, factorMapper);
        },
    },
    location: {
        title: "Location",
        dataIndex: "Location",
        width: "150px",
    },
    department: {
        title: "Department",
        dataIndex: ["Requirement", "DepartmentTag"],
        width: "100px",
        render: (s) => s?.Name,
    },
    status: {
        title: "Status",
        dataIndex: "Status",
        width: "100px",
        render: (x, record) => record.Requirement?.StatusTag?.Name || ''
    },
};

const RequirementsTable = ({items = []}) => {
    const dispatch = useDispatch();
    const formats = useDateTimeFormat();
    const contacts = useSelector((state) => state.contacts.contacts) || [];
    const [isShiftPopupVisible, setShiftPopupVisible] = useState(false);
    const [activeItem, setActiveItem] = useState();
    const activeItems = activeItem ? [activeItem] : [];

    function loadItemToEdit(item) {
        const id = item.Requirement?.Id;
        const mergedItem = {...item, Id: id};

        dispatch(startLoading({mergedItem, type: RequirementType.Item}));
        getRequirement(id)
            .then((response) => dispatch(endLoading({item: response})))
            .catch(() => message.error("Server error"));
    }

    const columns = [
        defaultColumns.project,
        defaultColumns.requirement,
        defaultColumns.resource,
        defaultColumns.sources,
        {
            ...defaultColumns.dates,
            render: (d) => {
                return dateRange(d?.StartDateTime, d?.EndDateTime, formats);
            },
        },
        defaultColumns.quantity,
        defaultColumns.cost,
        {
            ...defaultColumns.location,
            render: (d) => {
                return formatLocationName(contacts.find((c) => c.Id === d?.Id));
            },
        },
        defaultColumns.department,
        defaultColumns.status,
    ];

    const onOpenShiftModal = (record) => {
        setShiftPopupVisible(true);
        setActiveItem(record);
    };

    const onCloseShiftModal = () => {
        setShiftPopupVisible(false);
        setActiveItem(null);
    };

    const EditRequirement = (props) => <EditAction onClick={loadItemToEdit} {...props} />;
    const CloneRequirement = (props) => (
        <CloneAction onClick={(record) => onCloneRequirements([record], dispatch)}
                     title="Clone Requirement" {...props} />
    );
    const ShiftRequirement = (props) => <ShiftAction onClick={onOpenShiftModal} title="Shift Requirement" {...props} />;

    return (
        <>
            <TableWithGroups
                items={items}
                filterBarProps={{type: RequirementType.Item}}
                columns={columns}
                FilterBar={FilterBar}
                loadItemToEdit={loadItemToEdit}
                actions={[EditRequirement, CloneRequirement, ShiftRequirement]}
                footer={RequirementsTableFooter}
                GroupTable={GroupRequirementsTable}
                groupFooter={GroupRequirementsTableFooter}
                preserveFilteredData={true}
            />

            <Shift
                selected={activeItems}
                isVisible={isShiftPopupVisible}
                onClose={onCloseShiftModal}
                onShift={shift}
                itemName="requirement"
                showTimeUnits={false}
                startDateDetector={(selected) => selected.length > 0 && selected[selected.length - 1].Requirement.StartDateTime}
                earliestItemNameDetector={(selected) => selected.length > 0 && selected[selected.length - 1].Requirement?.Name}
            />
        </>
    );
};

export default RequirementsTable;
