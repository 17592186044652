import React from "react";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import {formatContactName} from "../../../helpers/text";
import {ApiContactType} from "../../../constants/constants";

const swapSpaceContact = (targetContact, sourceContact, projectName, onOk) => {
    const targetContactName = formatContactName(targetContact);
    const sourceContactName = formatContactName(sourceContact);
    const swapSpaceTitle = `Are you sure you want to replace ${sourceContactName} with ${targetContactName} in ${projectName}`;
    const swapSpaceDescription = (
        <div>
            {targetContactName} will appear anywhere in the project where {sourceContactName} was previously mentioned
            <br/>
            <br/>
            You cannot undo this action
        </div>
    );

    Modal.confirm({
        title: swapSpaceTitle,
        icon: <ExclamationCircleOutlined/>,
        content: swapSpaceDescription,
        okText: "Yes, Swap Contact",
        onOk,
        onCancel() {
        },
    });
};

const swapContactDescriptions = {
    [ApiContactType.Person]: (contactName, sourceContactName) => (
        <div>
            {contactName} will appear anywhere in the project where {sourceContactName} was previously mentioned.
        </div>
    ),
    [ApiContactType.Organization]: (contactName, sourceContactName) => (
        <div>
            {contactName} will appear anywhere in the project where {sourceContactName} was previously mentioned.
        </div>
    ),
    [ApiContactType.Place]: (contactName, sourceContactName) => (
        <div>
            {contactName} will appear anywhere in the project where {sourceContactName} was previously mentioned. Any
            spaces related to
            {sourceContactName} will be replaced with {contactName}
        </div>
    ),
};

const swapContact = (targetContact, sourceContactName, projectName, onOk, onCancel) => {
    const contactName = formatContactName(targetContact);
    const swapSpaceTitle = `Are you sure you want to replace ${sourceContactName} with ${contactName} in ${projectName}`;

    Modal.confirm({
        title: swapSpaceTitle,
        icon: <ExclamationCircleOutlined/>,
        content: swapContactDescriptions[targetContact.Type](contactName, sourceContactName),
        okText: "Yes, Swap Contact",
        onOk,
        onCancel,
    });
};

export {swapContact, swapSpaceContact};
