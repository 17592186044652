import React, {useState, useEffect} from "react";
import moment from "moment";
import {Modal, Form, Radio, DatePicker} from "antd";
import {ClockCircleFilled} from "@ant-design/icons";
import {InputNumberWithFormItem} from "../../Input";
import "./Shift.scss";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";

const shiftByDateLabel = "Starting Date";
const shiftByUnitOfTimeLabel = "Unit of Time";

const directionForwardLabel = "Later";
const directionBackwardLabel = "Earlier";

const shiftByOptions = [{label: shiftByDateLabel, value: shiftByDateLabel}, {
    label: shiftByUnitOfTimeLabel,
    value: shiftByUnitOfTimeLabel
},];

const directionByOptions = [{
    label: directionForwardLabel,
    value: directionForwardLabel
}, {label: directionBackwardLabel, value: directionBackwardLabel},];

const dayTimeUnits = ["Years", "Months", "Weeks", "Days"];
const allDayTimeUnits = [...dayTimeUnits, "Hours", "Minutes"];
const daysMultiplication = {
    Years: 365, Months: 30, Weeks: 7, Days: 1,
};

export const getByShiftByDate = (selected, date, startDateDetector = null) => {
    const start = startDateDetector ? startDateDetector(selected) : selected[0].StartDateTime;
    if (start) {
        return `${date.startOf("day").diff(moment(start).startOf("day"), "days")}.00:00:00`;
    } else {
        return "0.00:00:00";
    }
};

const ShiftModal = ({
                        isVisible,
                        onClose,
                        selected,
                        onShift,
                        showTimeUnits = true,
                        itemName = "event",
                        startDateDetector = null,
                        earliestItemNameDetector = null,
                    }) => {
    const [form] = Form.useForm();
    const dateTimeFormats = useDateTimeFormat();
    const [shiftBy, setShiftBy] = useState(shiftByDateLabel);
    const [direction, setDirection] = useState(directionForwardLabel);
    const [timeLength, setTimeLength] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const timeUnits = showTimeUnits ? allDayTimeUnits : dayTimeUnits;
    const itemNames = `${itemName}s`;
    const itemCaption = `${itemName[0].toUpperCase()}${itemName.substring(1)}`;
    const itemCaptions = `${itemCaption}s`;

    const earliestItemName = earliestItemNameDetector ? earliestItemNameDetector(selected) : (selected.length > 0 && selected[0] !== undefined) ? selected[0].Name : undefined;

    useEffect(() => {
        setTimeout(() => form.setFieldsValue({Projects: []}), 100);
    }, [form]);

    useEffect(() => {
        if (!isVisible) {
            if (timeLength !== null) setTimeLength(null);
            if (selectedDate !== null) setSelectedDate(null);
        }
    }, [isVisible]);

    async function handleOk() {
        try {
            await shiftItems();
            close();
        } catch (err) {
            console.error(err);
        }
    }

    function close() {
        onClose();
        form.resetFields();
    }

    const shiftItems = async () => {
        const values = await form.validateFields();

        let shiftString = "";
        if (shiftBy === shiftByUnitOfTimeLabel) {
            const totalDays = dayTimeUnits.reduce((prev, curr) => prev + (values[curr] > 0 ? values[curr] * daysMultiplication[curr] : 0), 0);

            shiftString = `${direction === directionBackwardLabel ? "-" : ""}${totalDays}.${values.Hours || 0}:${values.Minutes || 0}:00`;
        } else {
            shiftString = getByShiftByDate(selected, selectedDate, startDateDetector);
        }

        return onShift({
            Shift: shiftString, Ids: selected.map(({Id, ItemType}) => ({Id, ItemType})),
        });
    };

    const onTimeLengthChange = () => {
        const values = form.getFieldsValue();

        const strings = allDayTimeUnits
            .map((key) => (values[key] > 0 ? `${values[key]} ${values[key] === 1 ? key.substring(0, key.length - 1) : key}` : null))
            .filter((s) => s !== null);

        const result = strings.length === 0 ? null : strings
            .reduce((prev, curr, index) => {
                if (strings.length === 1) return `${curr}`; else if (index + 1 === strings.length) return `${prev} and ${curr}`; else return `${prev} ${curr},`;
            }, "")
            .toLowerCase();

        setTimeLength(result);
    };

    const isOkEnabled = () => (shiftBy === shiftByUnitOfTimeLabel && timeLength !== null) || (shiftBy === shiftByDateLabel && selectedDate !== null);

    return (<>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                <Modal
                    className="shift-common-modal"
                    maskClosable={false}
                    title={<>
                        <ClockCircleFilled/> Shift Time-frame
                    </>}
                    visible={isVisible}
                    onOk={handleOk}
                    onCancel={close}
                    okButtonProps={{disabled: !isOkEnabled()}}
                    okText={`Shift ${itemCaptions}`}>
                    <>
                        {isVisible && (
                            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal"
                                  size="middle">
                                <Form.Item label="Shift By" className="ant-form-item-without-validation mid-size-item">
                                    <Radio.Group
                                        size="large"
                                        value={shiftBy}
                                        onChange={(e) => setShiftBy(e.target.value)}
                                        options={shiftByOptions}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                                {shiftBy === shiftByUnitOfTimeLabel && (<>
                                        <Form.Item label="Direction"
                                                   className="ant-form-item-without-validation mid-size-item">
                                            <Radio.Group
                                                size="middle"
                                                value={direction}
                                                onChange={(e) => setDirection(e.target.value)}
                                                options={directionByOptions}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </Form.Item>
                                        {timeUnits.map((unit) => (
                                            <InputNumberWithFormItem name={unit} label={unit} key={unit} min={0}
                                                                     onChange={onTimeLengthChange}/>))}
                                    </>)}

                                {shiftBy === shiftByDateLabel && (
                                    <Form.Item label="Starting Date" name="Date" valuePropName="checked">
                                        <DatePicker
                                            style={{width: "100%"}}
                                            format={dateTimeFormats.date}
                                            onChange={(val) => setSelectedDate(val)}></DatePicker>
                                    </Form.Item>)}
                                <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}}
                                           className="ant-form-item-without-validation center">
                                    {shiftBy === shiftByUnitOfTimeLabel && timeLength !== null && (<>
                                            {selected.length === 1 && (<>
                                                    {earliestItemName} will be
                                                    shifted {direction.toLowerCase()} by {timeLength}.
                                                </>)}
                                            {selected.length > 1 && (<>
                                                    {selected.length} {itemNames} will be
                                                    shifted {direction.toLowerCase()} by {timeLength}.
                                                </>)}
                                        </>)}

                                    {shiftBy === shiftByDateLabel && (<>
                                            {selected.length === 1 && selectedDate && (<>
                                                    {earliestItemName} will be set
                                                    to {selectedDate.format(dateTimeFormats.dateLongFormat)}.
                                                </>)}

                                            {selected.length > 1 && selectedDate && (<>
                                                    {selected.length} {itemNames} will be
                                                    shifted. {earliestItemName} will be set to{" "}
                                                    {selectedDate.format(dateTimeFormats.dateLongFormat)}. The rest of
                                                    the {itemNames} will be set relative
                                                    to the selected date.
                                                </>)}
                                        </>)}
                                </Form.Item>
                            </Form>)}
                    </>
                </Modal>
            </Form>
        </>);
};

export default ShiftModal;
