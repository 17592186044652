import React from "react";
import _ from "lodash";
import {Popover} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import {dateRange} from "../../../../helpers/time";

const AvailabilityConflicts = ({overlaps = [], Icon = InfoCircleOutlined, isOverAllocated}) => {
    const formats = useDateTimeFormat();

    const nonZeroOverlaps = overlaps.filter((m) => m.Quantity > 0);
    const hasOverlaps = nonZeroOverlaps.length > 0;
    if (!hasOverlaps && !isOverAllocated) {
        return null;
    }

    const title = hasOverlaps ? "Additional Item Usage:" : "No additional item usage on these dates.";

    return (
        <Popover
            content={
                hasOverlaps && (
                    <>
                        {nonZeroOverlaps.map((conflict) => {
                            const dates = dateRange(conflict.StartDateTime, conflict.EndDateTime, formats);
                            return (
                                <div key={conflict.Id}>
                                    ({conflict.Quantity}) {conflict.Requirement.Name} [{conflict.Project.Name}] {dates}
                                </div>
                            );
                        })}
                    </>
                )
            }
            title={() => <b>{title}</b>}
            trigger="hover">
            <Icon/>
        </Popover>
    );
};

export default AvailabilityConflicts;
