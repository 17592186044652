import React, {useEffect} from "react";
import update from "immutability-helper";
import {Button, Row, Col, Input, Space} from "antd";

import {CloseCircleOutlined} from "@ant-design/icons";
import ViewEditList from "../ViewEditList";
import {useState} from "react";

const getPrimaryButtonType = (IsForCommunication) => (IsForCommunication ? "primary" : "default");

const EmailAddressRow = ({item = {}, onChange, remove, valuePlaceholder}) => {
    const [EmailAddress, setEmailAddress] = useState(item.value);
    const [IsForCommunication, setIsForCommunication] = useState(item.IsForCommunication);

    useEffect(() => {
        setEmailAddress(item.value);
        setIsForCommunication(item.IsForCommunication);
    }, [item]);

    const triggerChange = (changedValue) => {
        if (onChange) {
            onChange({value: EmailAddress, IsForCommunication, ...item, ...changedValue});
        }
    };

    const clickIsForCommunication = () => {
        setIsForCommunication(true);
        triggerChange({IsForCommunication: true});
    };

    const onEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmailAddress(newEmail);
        triggerChange({value: newEmail});
    };

    return (
        <Row align="middle">
            <Col span={7}>{/* <Input disabled={true} placeholder="Enter an type"></Input> */}</Col>
            <Col span={14} offset={1}>
                <Space>
                    <Input value={EmailAddress} onChange={onEmailChange} placeholder={valuePlaceholder}></Input>
                    <Button size="small" type={getPrimaryButtonType(IsForCommunication)}
                            onClick={clickIsForCommunication}>
                        Primary
                    </Button>
                </Space>
            </Col>
            <Col span={2} className={{"btn-remove-row-holder": true, disabled: IsForCommunication}}>
                <CloseCircleOutlined className="btn-remove-row" onClick={IsForCommunication ? () => {
                } : remove}/>
            </Col>
        </Row>
    );
};

const EditEmailList = ({items = [], onChange, onChanged, onCancel, addNewRowTex, valuePlaceholder}) => {
    const [emails, setEmails] = useState(items);

    useEffect(() => {
        setEmails(items);
    }, [items]);

    function add() {
        const newEmail = emails.length === 0 ? {IsForCommunication: true} : {};
        setEmails([...emails, newEmail]);
    }

    function remove(index) {
        setEmails(emails.filter((e, i) => i !== index));
    }

    function onEmailChange(index, emailItem) {
        const changedEmail = {...emails[index], ...emailItem};

        let updatedEmails = update(emails, {[index]: {$set: changedEmail}});

        if (changedEmail.IsForCommunication) {
            updatedEmails = updatedEmails.map((email, i) => update(email, {IsForCommunication: {$set: i === index}}));
        }
        setEmails(updatedEmails);
    }

    function saveClick() {
        if (onChange) {
            onChange(emails);
        }
        if (onChanged) {
            onChanged();
        }
    }

    return (
        <div className="view-edit-list edit-mode">
            {emails.map((emailItem, index) => (
                <EmailAddressRow
                    key={index}
                    item={emailItem}
                    valuePlaceholder={valuePlaceholder}
                    onChange={(changedEmail) => onEmailChange(index, changedEmail)}
                    remove={() => remove(index)}
                />
            ))}
            <Row>
                <Col span={24}>
                    <Button
                        block
                        type="dashed"
                        onClick={() => {
                            add();
                        }}
                        style={{width: "100%"}}>
                        {addNewRowTex}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="btn-actions-holder">
                    <Space>
                        <Button type="primary" size="small" onClick={saveClick}>
                            Save
                        </Button>
                        <Button size="small" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

const mapEmailToRow = (emails = []) =>
    emails.map((email) => ({
        id: email.Id,
        value: email.EmailAddress,
        IsForCommunication: email.IsForCommunication,
    }));

const mapRowToEmail = (itemRows = []) =>
    itemRows.map((itemRow) => ({
        Id: itemRow.id,
        EmailAddress: itemRow.value,
        IsForCommunication: itemRow.IsForCommunication,
    }));

const EmailList = ({value, onChanged, onChange, readOnly, contactId}) => (
    <ViewEditList
        contactId={contactId}
        EditList={EditEmailList}
        items={mapEmailToRow(value)}
        onChanged={onChanged}
        onChange={(value) => onChange(mapRowToEmail(value))}
        readOnly={readOnly}
        addNewRowTex="Add an Email"
        valuePlaceholder="Enter an email"
        emptyRowText="Email: "
    />
);

export default EmailList;
