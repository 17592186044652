import React from "react";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";

import "./DeleteCollection.scss";

import {deleteCollections, loadCollections} from "../../../../services/resources";

import usePermissions from "../../../../hooks/usePermissions";
import {setSelected} from "../../../../redux/reducers/mainTable";

import {doBatchDelete} from "../../../Common/Modal/BatchDelete/BatchDelete";
import DeleteAction from "../../../Common/Tables/Actions/DeleteAction";
import {TooltipWithOrNot} from "../../../Common/Input";

const getTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete ${selected[0].Name}?`;
    } else {
        return `Are you sure you want to delete these ${selected.length} collections?`;
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Collection was deleted.`);
    } else {
        message.success(`${selected.length} collections were deleted.`);
    }
};

const DeleteCollection = ({record}) => {
    const dispatch = useDispatch();
    const {hasThingsAccess} = usePermissions();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const accountId = activePortfolio?.Id;

    const canDelete = hasThingsAccess;

    const className = classNames({
        disabled: !canDelete,
        "delete btn-table-actions": true,
    });
    return (
        <TooltipWithOrNot showTooltip={!canDelete} title="Things add-on is not activated.">
            <DeleteAction
                record={record}
                className={className}
                onClick={() => {
                    if (!canDelete) return;

                    doBatchDeleteCollections(accountId, [record], () => {
                        dispatch(setSelected([]));
                        loadCollections(accountId);
                    });
                }}></DeleteAction>
        </TooltipWithOrNot>
    );
};

export const doBatchDeleteCollections = (accountId, selected, onFinish) => {
    return doBatchDelete({
        onFinish,
        title: getTitle(selected),
        content: (
            <>
                Their record and all information associated with them will be deleted FOREVER. <br/>
                <br/>
                You cannot undo this action.
            </>
        ),
        batchRemove: () => deleteCollections(accountId, selected.map((s) => s.Id)),
        onFinishMessage: () => showFinishMessage(selected),
        okText: "Delete",
    });
};

export default DeleteCollection;
