import {ToolOutlined} from "@ant-design/icons";
import React from "react";

const PositionDetailsPanelTitle = ({editedItem}) => {
    const {Name, Source} = editedItem || {};

    let title = Name || "Position";

    if (Name && Source?.Name) {
        title = `${Name} (${Source.Name})`;
    }

    return (
        <>
            <ToolOutlined/> {title}
        </>
    );
};

export default PositionDetailsPanelTitle;
