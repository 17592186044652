import React, {useState, useEffect} from "react";
import {Menu, Dropdown, Button, Space, Radio, Checkbox} from "antd";
import {DownOutlined} from "@ant-design/icons";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const ProjectGroupBy = Object.freeze({
    State: "State",
    Month: "Month",
    Year: "Year",
    Status: "Status",
    Organization: "Organization",
    Location: "Location",
    Category: "Category",
    ProjectManager: "Project Manager",
});

const stateItem = {
    value: ProjectGroupBy.State,
    title: ProjectGroupBy.State,
};

const GroupByFilter = ({setGroupBy, isActiveProjectsTab}) => {
    const [selectedItem, setSelectedItem] = useState();

    const items = [
        {
            value: ProjectGroupBy.Month,
            title: ProjectGroupBy.Month,
        },
        {
            value: ProjectGroupBy.Year,
            title: ProjectGroupBy.Year,
        },
        // {
        //   value: ProjectGroupBy.Status,
        //   title: ProjectGroupBy.Status + "-?",
        // },
        // {
        //   value: ProjectGroupBy.Organization,
        //   title: ProjectGroupBy.Organization + "-?",
        // },
        // {
        //   value: ProjectGroupBy.Location,
        //   title: ProjectGroupBy.Location + "-?",
        // },
        {
            value: ProjectGroupBy.Category,
            title: ProjectGroupBy.Category,
        },
        {
            value: ProjectGroupBy.ProjectManager,
            title: ProjectGroupBy.ProjectManager,
        },
    ];

    if (isActiveProjectsTab) {
        items.unshift(stateItem);
    }

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
