import React from "react";
import {useSelector} from "react-redux";
import {downloadRequirementsAsCsv} from "../../../../services/requirements";
import {RequirementGroupBy} from "../../FilterBar/GroupBy/GroupBy";
import {getGroups} from "../GroupRequirementsTable";

const RequirementExport = (props) => {
    const {selected, groupedBy, filtered} = useSelector((store) => store.mainTable);
    const allContacts = useSelector((state) => state.contacts.contacts);
    const allProjects = useSelector((state) => state.projects.projects);
    const requirements = useSelector((state) => state.requirements.requirements);

    const {requirements: groupBy = null} = groupedBy;

    const onExport = () => {
        const itemsToExport = selected.length > 0 ? selected : filtered?.length > 0 ? filtered : requirements;

        let ids = [];
        if (!groupBy) {
            ids = itemsToExport.map((c) => ({RequirementId: c.Id}));
        } else {
            const groups = getGroups(groupBy, requirements, allContacts, allProjects);
            groups.forEach((group) => {
                group.items.forEach((item) => {
                    if (itemsToExport.find((m) => m.Id === item.Id)) {
                        const sourceId = groupBy === RequirementGroupBy.Source ? item.Sources[0].Id : null;
                        ids.push({
                            RequirementId: item.Id,
                            SourceId: sourceId,
                        });
                    }
                });
            });
        }

        downloadRequirementsAsCsv(ids);
    };

    return <div onClick={onExport}> Export</div>;
};

export default RequirementExport;
