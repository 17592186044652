import React, {useState, useEffect} from "react";
import _ from "lodash";
import {Input} from "antd";

const hasText = (source = "", text = "") => (source || "").toLowerCase().indexOf(text.toLowerCase()) !== -1;

const SearchFilter = ({setFilterHandler, needResetFilter, fieldsToSearch = []}) => {
    const [text, setText] = useState("");

    function matchSearch(item, searchText) {
        if (_.isEmpty(searchText)) {
            return true;
        }
        return fieldsToSearch.reduce((matched, fieldName) => {
            if (matched === true) return true;

            let field = _.get(item, fieldName);
            if (_.isNil(field) && _.isFunction(fieldName)) field = fieldName(item);

            if (_.isNil(field)) return false;

            if (_.isString(field)) return hasText(field, searchText);

            if (_.isArray(field)) return _.some(field, (f) => hasText(f, searchText));

            throw new Error("Search is not implemented");
        }, false);
    }

    useEffect(() => {
        if (needResetFilter) {
            setText("");
        }
    }, [needResetFilter]);

    function onChange(e) {
        const txt = e.target.value;
        setText(txt);
        setTimeout(() => setFilterHandler((item) => matchSearch(item, txt)), 0);
    }

    return <Input.Search value={text} onChange={onChange} placeholder="Search" allowClear={true}/>;
};
export default SearchFilter;
