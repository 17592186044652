import React, {useState} from 'react';
import {Spin, Table} from 'antd';
import DeleteStudent from '../DeleteStudent/DeleteStudent';
import './StudentsTable.scss';
import {useSelector} from "react-redux";
import {deleteInvitationById, loadInviteLink} from "../../../services/inviteLinks";

const StudentsTable = () => {
    const inviteLink = useSelector(state => state.inviteLinks.inviteLink);
    const accountInvitations = inviteLink?.InvitedAccounts?.AccountInvitations || [];
    const [isLoading, setLoading] = useState(false);

    const handleDelete = (record) => {
        setLoading(true);
        deleteInvitationById(record.Id)
            .then(() => loadInviteLink(inviteLink.AccountId))
            .finally(() => setLoading(false));
    };

    const columns = [{
        title: 'Name', dataIndex: 'Name', key: 'Name',
    }, {
        title: 'Email', dataIndex: 'Email', key: 'Email', render: email => <a href={`mailto:${email}`}>{email}</a>,
    }, {
        key: 'action', render: (text, record) => (<DeleteStudent
            student={record}
            onOk={() => handleDelete(record)}
            onCancel={() => {
            }}
        />),
    }];

    return (<Spin spinning={isLoading}>
        <Table rowKey="Id" className="bold-header" dataSource={accountInvitations} columns={columns}
               pagination={false}/>
    </Spin>);
};

export default StudentsTable;