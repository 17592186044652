import React, {useState} from "react";

import {Modal, message, Form} from "antd";
import {useSelector} from "react-redux";
import {SelectWithFormItem} from "../../Common/Input";
import {createProjectColorTagRendered, mapProjectWithBadgeToTag} from "../../../helpers/Tags";
import {ApiProjectType} from "../../../constants/constants";
import {createTeamMembers} from "../../../services/team";
import {TeamOutlined} from "@ant-design/icons";

const AddToTeams = ({disabled}) => {
    const selected = useSelector((store) => store.mainTable.selected);
    const [form] = Form.useForm();
    const [isVisible, setVisible] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);

    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);

    const onOk = () => {
        setSubmitting(true);

        createTeamMembers({
            ProjectIds: selectedProjects.map((p) => parseInt(p, 10)),
            ContactIds: selected.map((c) => c.Id),
        })
            .then(() => {
                message.success(`The selected contacts have been added to the selected projects.`);
                setVisible(false);
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <div disabled={disabled} onClick={() => setVisible(!disabled)}>
                Add to Teams
            </div>
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                <Modal
                    width={560}
                    title={
                        <>
                            <TeamOutlined/> Add Contacts to Project Teams
                        </>
                    }
                    visible={isVisible}
                    maskClosable={false}
                    onOk={onOk}
                    onCancel={() => setVisible(false)}
                    okButtonProps={{disabled: selectedProjects.length === 0}}
                    afterClose={() => form.resetFields()}
                    confirmLoading={isSubmitting}
                    okText="Add Contacts">
                    <div>
                        <p className="center"> Select projects that you'd like to add these contacts to.</p>

                        <SelectWithFormItem
                            label="Projects"
                            name="Projects"
                            tagRender={createProjectColorTagRendered(projects, true)}
                            showSearch={true}
                            style={{width: "100%"}}
                            mode="tags"
                            placeholder="Select a Project"
                            onChanged={setSelectedProjects}
                            options={projects.map(mapProjectWithBadgeToTag)}></SelectWithFormItem>
                    </div>
                </Modal>
            </Form>
        </>
    );
};

export default AddToTeams;
