import {BulbFilled} from "@ant-design/icons";
import React from "react";

const ResourceDetailsPanelTitle = ({editedItem}) => (
    <>
        <BulbFilled/> {editedItem.Name} {editedItem.IsArchived && "(Archived)"}
    </>
);

export default ResourceDetailsPanelTitle;
