import React, {useState} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import TreeSelectFilter from "./TreeSelectFilter";
import {useEffect} from "react";

export const disableAllOption = {value: "— All Project Attachments —", title: "— All Project Attachments —"};

const ProjectAttachmentsKey = "Project Attachments";
const AllOtherAttachmentsKey = "All Other Attachments";

const isAttachmentsInProjects = (attachment, projectIds) => attachment.Projects.find((p) => projectIds.includes(p.Id)) != null;

const filterEnabledAttachments = (attachments) => attachments.filter((a) => a.IsUrlEnabled);

const mapAttachmentToOption = (attachment) => ({
    value: attachment.Id,
    key: attachment.Id,
    isUrlEnabled: attachment.IsUrlEnabled,
    title: attachment.IsUrlEnabled ? attachment.Name : `${attachment.Name} (disabled)`,
});

const Attachments = ({value, onChanged, onChange, projectIds, disabled, setSelectedAttachmentCount}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const attachments = useSelector((state) => state.attachments.attachments);

    const projectAttachments = attachments.filter((a) => a.IsAllProjects || isAttachmentsInProjects(a, projectIds));

    const otherAttachments = _.xor(attachments, projectAttachments);

    const projectAttachmentsOption = {
        title: ProjectAttachmentsKey,
        value: ProjectAttachmentsKey,
        key: ProjectAttachmentsKey,
        filterOff: true,
        children: projectAttachments.map(mapAttachmentToOption),
    };

    const allOtherAttachmentsOption = {
        title: AllOtherAttachmentsKey,
        value: AllOtherAttachmentsKey,
        key: AllOtherAttachmentsKey,
        filterOff: true,
        children: otherAttachments.map(mapAttachmentToOption),
    };
    const groups = [projectAttachmentsOption];

    if (otherAttachments.length > 0) {
        groups.push(allOtherAttachmentsOption);
    }

    function onSelected(filterValues) {
        setSelectedItems(filterValues);
        onChange({
            Attachments: filterValues.map((id) => ({Id: id})),
        });

        onChanged();
    }

    useEffect(() => {
        let subItemsCount = 0;
        if (!disabled) {
            if (selectedItems.length === 0) subItemsCount = filterEnabledAttachments(projectAttachments).length;
            else
                subItemsCount = filterEnabledAttachments([...projectAttachments, ...otherAttachments]).filter((a) =>
                    selectedItems.includes(a.Id)
                ).length;
        }
        setSelectedAttachmentCount(subItemsCount);
    }, [selectedItems]);

    useEffect(() => {
        if (value != null) setSelectedItems(value.Attachments.map((i) => i.Id));
    }, [value]);

    return (
        <TreeSelectFilter
            treeDefaultExpandedKeys={[projectAttachmentsOption.value]}
            selectDefaultOption={disableAllOption}
            disabled={disabled}
            value={selectedItems}
            treeData={groups}
            placeholder="All Project Attachments"
            onChanged={onSelected}></TreeSelectFilter>
    );
};

export default Attachments;
