import React, {useState} from "react";
import _ from "lodash";
import {Button, Modal, Form} from "antd";
import {UserOutlined, ShopOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {ApiContactType, ContactType, TagType} from "../../../constants/constants";
import {getTags, mapTagToOption, getTagsPayload} from "../../../helpers/Tags";
import {useSelector} from "react-redux";
import {batchUpdate} from "../../../services/contacts";
import "./BatchEdit.scss";
import {useEffect} from "react";
import BulkEditableField, {isFieldDisabled} from "../../Common/BulkEditableField/BulkEditableField";
import TagsSelect from "../../Common/Selects/TagsSelect";
import ContactSelect from "../../Common/Selects/ContactSelect";
import CreateContact from "../../Common/QuickCreate/CreateContact";

const icon = {
    [ContactType.Person]: <UserOutlined/>,
    [ContactType.Organization]: <ShopOutlined/>,
    [ContactType.Location]: <EnvironmentOutlined/>,
};

const contactTypeText = {
    [ContactType.Person]: "People",
    [ContactType.Organization]: "Organizations",
    [ContactType.Location]: "Locations",
};

const Title = ({contactType, selected}) => (
    <>
        {icon[contactType]} {`Bulk Edit ${selected.length} ${contactTypeText[contactType]}`}
    </>
);

function getFormData(selected) {
    const parents = _.intersection(selected.map((x) => x.ParentId));
    return {
        Specialties: getTags(selected, "Specialties"),
        Groups: getTags(selected, "Groups"),
        ParentId: parents.length === 1 ? parents[0] : null,
    };
}

const BulkEdit = ({selected, contactType}) => {
    const [isVisible, setVisible] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);

    const roles = useSelector((state) => state.tags.roles).map(mapTagToOption);
    const groups = useSelector((state) => state.tags.groups).map(mapTagToOption);
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) {
            const formData = getFormData(selected);
            form.setFieldsValue(formData);
            setSelectedContactId(formData.ParentId);
        }
    }, [selected, isVisible]);

    function showModal() {
        setContentVisible(true);
        setVisible(true);
    }

    function onOk() {
        const selectedContactIds = selected.map((i) => i.Id);

        const formValue = form.getFieldsValue();
        const parentData =
            contactType === ContactType.Person
                ? {
                    isUpdated: !isFieldDisabled("ParentId", form),
                    parentId: formValue.ParentId,
                }
                : {};
        setLoading(true);
        batchUpdate(
            selectedContactIds,
            getTagsPayload(formValue.Specialties, !isFieldDisabled("Specialties", form)),
            getTagsPayload(formValue.Groups, !isFieldDisabled("Groups", form)),
            parentData
        ).finally(() => {
            setLoading(false);
            close();
        });
    }

    function onCancel() {
        close();
    }

    function close() {
        setContentVisible(false);
        setVisible(false);
        form.resetFields();
    }

    return (
        <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
            <Button type="primary" onClick={showModal}>
                Edit
            </Button>

            <Modal
                confirmLoading={loading}
                title={<Title contactType={contactType} selected={selected}/>}
                visible={isVisible}
                maskClosable={false}
                onOk={onOk}
                onCancel={onCancel}>
                {isContentVisible && (
                    <>
                        <BulkEditableField name="Specialties" form={form}>
                            {(name, isFieldDisabled) => (
                                <TagsSelect
                                    tagType={TagType.Role}
                                    disabled={isFieldDisabled}
                                    label="Default Roles"
                                    name={name}
                                    placeholder="Select default project roles"
                                    hasEnableCheckbox={true}
                                    options={roles}
                                    form={form}
                                />
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="Groups" form={form}>
                            {(name, isFieldDisabled) => (
                                <TagsSelect
                                    tagType={TagType.Group}
                                    disabled={isFieldDisabled}
                                    label="Default Groups"
                                    name={name}
                                    placeholder="Select default project groups"
                                    options={groups}
                                    form={form}
                                />
                            )}
                        </BulkEditableField>

                        {contactType === ContactType.Person && (
                            <BulkEditableField name="ParentId" form={form}>
                                {(name, isFieldDisabled) => (
                                    <Form.Item
                                        shouldUpdate={(prevValues, curValues) => {
                                            return true;
                                        }}
                                        name={name}
                                        label={"Organization"}
                                        className="ant-form-item-without-validation">
                                        <ContactSelect
                                            organizationsOnly={true}
                                            allowClear={true}
                                            placeholder="Select an organization"
                                            selectedContactId={selectedContactId}
                                            onChange={(v) => {
                                                form.setFieldsValue({ParentId: v});
                                                setSelectedContactId(v);
                                            }}
                                            onChanged={() => {
                                            }}
                                            disabled={isFieldDisabled}
                                            onOpenCreatePopup={() => setIsQuickCreateContactVisible(true)}
                                            hideQuickCreate={false}
                                        />
                                    </Form.Item>
                                )}
                            </BulkEditableField>
                        )}
                        <CreateContact
                            form={form}
                            apiContactType={ApiContactType.Organization}
                            isVisible={isQuickCreateContactVisible}
                            isEmailRequired={true}
                            onClose={() => {
                                setIsQuickCreateContactVisible(false);
                            }}
                            onContactCreated={(contactId) => {
                                form.setFieldsValue({ParentId: contactId});
                                setSelectedContactId(contactId);
                            }}
                            hideSpaces={true}
                        />
                    </>
                )}
            </Modal>
        </Form>
    );
};
export default BulkEdit;
