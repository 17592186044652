import {ToolOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import React from "react";
import _ from "lodash";
import {ApiTimelineItemType} from "../../../../constants/constants";
import {getEvent} from "../../../../services/timeline";

const enableBookingsConfirm = ({taskId, projectId, onOk}) => {
    getEvent(taskId, projectId, ApiTimelineItemType.Task).then((task) => {
        if (!task.IsLabourLineSynced && task.LabourLines.length > 0) {
            const bookingsCount = _.sum(task.LabourLines.map((ll) => ll.Quantity));
            Modal.confirm({
                title: "Do you want to enable bookings for this event?",
                icon: <ToolOutlined/>,
                content: `This will create ${bookingsCount} bookings based on the associated labour lines.`,
                okText: "Create Bookings",
                className: "enable-bookings-confirm",
                onOk,
                onCancel() {
                },
            });
        } else {
            onOk();
        }
    });
};
export {enableBookingsConfirm};
