import React from "react";
import ChangedAtFilter from "../../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import FilterBar from "../../../Common/Tables/FilterBar";

import SearchFilter from "./SearchFilter";
import GroupBy from "./GroupBy";


const PositionsFilterBar = (props) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[ChangedAtFilter, SearchFilter]}
            GroupByComponent={GroupBy}
        />
    );
};

export default PositionsFilterBar;
