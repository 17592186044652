import {Table} from "antd";
import moment from "moment";
import React from "react";
import {formatMomentDuration} from "../../helpers/time";

export const getTotalDuration = (pageData) => {
    return (
        pageData.reduce((prev, curr) => prev.add(moment.duration(curr.Duration)), moment.duration(0, "minutes")) ||
        moment.duration(0, "minutes")
    );
};

export const BookingsTableFooter = (pageData) => {
    const duration = getTotalDuration(pageData);
    return (
        <>
            <Table.Summary.Row className="req-summary">
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>{formatMomentDuration(duration)} </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};

export const GroupBookingsTableFooter = (pageData) => {
    const duration = getTotalDuration(pageData);
    return (
        <>
            <Table.Summary.Row className="req-summary">
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>{formatMomentDuration(duration)} </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};
