import {Button, message, Modal} from "antd";
import React from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {formatContactName} from "../../../helpers/text";
import usePermissions from "../../../hooks/usePermissions";
import {loadCurrentPlan, transferOwnership} from "../../../services/settings";
import {ApiPermissionLevel} from "../../../constants/constants";
import {TooltipWithOrNot} from "../../Common/Input";

const geTitle = (targetUser, currentPlan) =>
    `Are you sure you want to transfer ownership of ${currentPlan.AccountName} to ${formatContactName(
        targetUser.CreatedFromContact,
        false
    )} `;

const getContent = ({CreatedFromContact}) =>
    `You and ${formatContactName(CreatedFromContact, false)} will switch roles: ${formatContactName(
        CreatedFromContact,
        false
    )} will be the new owner`;

const TakeOwnership = () => {
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const currentPlan = useSelector((store) => store.settings.currentPlan);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const {isOwner} = usePermissions();

    useEffect(() => {
        if (isOwner) {
            loadCurrentPlan(activePortfolio.Id, activePortfolio.TimeZoneId);
        }
    }, [isOwner]);

    const onTakeOwnershipClick = () => {
        if (selected.length !== 1) return;

        if (selected[0].MemberAccountId) {
            Modal.confirm({
                title: geTitle(selected[0], currentPlan),
                content: getContent(selected[0]),
                okText: "Yes, Transfer Ownership",
                async onOk() {
                    try {
                        await transferOwnership(activePortfolio.Id, selected[0].MemberAccountId);
                        message.success(
                            `Ownership of ${currentPlan.AccountName} transfer was transferred to ${formatContactName(
                                selected[0].CreatedFromContact,
                                false
                            )} `
                        );
                    } catch (e) {
                        message.error("Server error");
                    }
                },
                onCancel() {
                },
            });
        } else {
            message.error("Ownership cannot be transferred to this user because they have not created their Propared account.");
        }
    };

    return (
        <TooltipWithOrNot
            showTooltip={selected.length > 0 && selected[0].PermissionLevel !== ApiPermissionLevel.Administrator}
            title="Ownership may only be transferred to an administrator.">
            <Button
                disabled={!(isOwner && selected.length > 0 && selected[0].PermissionLevel === ApiPermissionLevel.Administrator)}
                onClick={onTakeOwnershipClick}>
                Transfer Ownership
            </Button>
        </TooltipWithOrNot>
    );
};

export default TakeOwnership;
