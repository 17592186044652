import React, {useState, useEffect} from "react";
import moment from "moment";
import SingleItemFilter from "../SingleItemFilter";

const allTime = {value: "All Changes", title: "All Changes"};

const items = [
    allTime,
    {
        value: "24 Hours",
        title: "24 Hours",
        date: moment().add(-1, "day"),
    },
    {
        value: "7 Days",
        title: "7 Days",
        date: moment().add(-7, "day"),
    },
    {
        value: "30 Days",
        title: "30 Days",
        date: moment().add(-30, "day"),
    },
];

const defaultItem = items[0];
const ChangedAtFilter = ({setFilterHandler, needResetFilter}) => {
    const [selectedItem, setSelectedItem] = useState(defaultItem);

    useEffect(() => {
        if (needResetFilter) {
            setSelectedItem(defaultItem);
        }
    }, [needResetFilter]);

    function onChanged(selectedItem) {
        setSelectedItem(selectedItem);
        setFilterHandler((item) => selectedItem.value === allTime.value || moment(item.UpdatedAt) >= selectedItem.date);
    }

    return <SingleItemFilter selectedItem={selectedItem} items={items} onChanged={onChanged}/>;
};

export default ChangedAtFilter;
