import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, DatePicker, Form, message, Row, Tag} from "antd";
import {SelectWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";
import TagsSelect from "../../../Common/Selects/TagsSelect";
import {useDispatch, useSelector} from "react-redux";
import {close, openEdit} from "../../../../redux/reducers/detailsPanel";
import {setActiveProject} from "../../../../redux/reducers/projects";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../../helpers/paths";
import AuditFormItem from "../../../Common/AuditFormItem";
import {NewRecordState, SidePanelItemType, TagType} from "../../../../constants/constants";
import {mapTagToOption, TagIcon} from "../../../../helpers/Tags";
import moment from "moment";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import {EyeOutlined, FileTextFilled} from "@ant-design/icons";
import "./NotesDetailsPanel.scss";
import {getItem, getNotes, markNoteDone, refreshNotes, updateNotes} from "../../../../services/reports";
import {useHistory} from "react-router-dom";
import {setNotes} from "../../../../redux/reducers/reports";
import {sanitizeHTML} from "../../../../helpers/html";

const prepareFormData = (data) => {
    const formData = {
        ...data,
        DepartmentTags: [...data.DepartmentTags, data.HasNoDepartmentTag ? noDepartmentTag : null]
            .filter((t) => t)
            .map((x) => x.AccountTagId.toString()),
    };

    return formData;
};

const noDepartmentTag = {
    AccountTagId: -100,
    Name: "— No Department —",
};

const NotesDetailsPanel = () => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const nextInputRef = useRef();
    const dateTimeFormats = useDateTimeFormat();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const [doneLoading, setDoneLoading] = useState(false);

    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const projects = useSelector((state) => state.projects.projects);
    const statuses = useSelector((state) => state.tags.statuses || []);
    const selectedProjects = useSelector((state) => state.projects.activeProjects);

    const statusOptions = statuses.map((status) => ({
        value: status.AccountTagId,
        title: status.Name,
        label: (
            <>
                <TagIcon icon={status.Icon}/> {status.Name}
            </>
        ),
    }));

    const departments = useSelector((state) => state.tags.departments || []);

    if (editItemState === NewRecordState.RequiredFieldsSubmitted) {
        setTimeout(() => nextInputRef.current.focus(), 100);
    }

    useEffect(() => {
        setEditItemState(NewRecordState.ReadyForEdit);
        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit, form]);

    const prepareAndUpdate = useCallback(
        (finishedForm) => {
            const noDepartment = finishedForm.DepartmentTags.includes(noDepartmentTag.AccountTagId.toString());
            const departments = finishedForm.DepartmentTags.map((d) => parseInt(d, 10)).filter(
                (d) => d !== noDepartmentTag.AccountTagId
            );

            const payload = {
                Ids: itemToEdit.LinkedIds,
                HasNoDepartment: noDepartment,
                Departments: departments,
                Text: sanitizeHTML(finishedForm.Text),
                DueDate: finishedForm.DueDate ? moment(finishedForm.DueDate).startOf("day").utcOffset(0, true).format() : null,
                StatusTag: finishedForm.Status?.AccountTagId,
            };
            updateNotes(payload).then(() => refreshNotes(selectedProjects.map((p) => p.Id)));
        },
        [itemToEdit?.Id, dispatch]
    );

    const onFinish = useCallback(
        (finishedForm) => {
            prepareAndUpdate(finishedForm);
        },
        [editItemState, prepareAndUpdate]
    );

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    const goToReport = () => {
        const project = projects.find((p) => p.Id === itemToEdit.ReportInfo.ProjectId);
        dispatch(setActiveProject(project));
        history.push(urlWithPortfolioId(pathCollection.reports));
        getItem(itemToEdit.ReportInfo.ReportId)
            .then((item) => dispatch(openEdit({item, type: SidePanelItemType.Reports})))
            .catch(() => message.error("Server error"));
    };

    const onDoneClick = (e) => {
        e.stopPropagation();
        setDoneLoading(true);
        markNoteDone(itemToEdit.LinkedIds, !itemToEdit.IsDone)
            .then(() => getNotes(selectedProjects.map((x) => x.Id)))
            .then((notes) => {
                setDoneLoading(false);
                dispatch(close());
                dispatch(setNotes(notes));
            })
            .then(() => message.success(`Note marked as ${itemToEdit.IsDone ? "Not Done" : "Done"}.`));
    };

    const project = projects.find((p) => p.Id === itemToEdit.ReportInfo.ProjectId);
    return (
        <div className="side-panel notes-details-panel">
            <Form
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                layout="horizontal"
                size="middle"
                initialValues={{IsUrlEnabled: true}}>
                <Form.Item label="Project" className="ant-form-item-without-validation">
                    {project && (
                        <Tag className="project_tag" color={project.Color}>
                            {project.Name}
                        </Tag>
                    )}
                </Form.Item>

                <TextAreaWithFormItem
                    name="Text" label="Note"
                    useTextEditor={true}
                    onChanged={save}/>

                <TagsSelect
                    tagType={TagType.Department}
                    label="Departments"
                    name="DepartmentTags"
                    placeholder="Select default project roles"
                    options={[noDepartmentTag, ...departments].map(mapTagToOption)}
                    save={save}
                    form={form}
                />

                <Form.Item
                    label="Due Date"
                    getValueProps={(a) => ({
                        value: a ? moment(a) : null,
                    })}
                    name={"DueDate"}
                    className="ant-form-item-without-validation">
                    <DatePicker style={{width: "100%"}} format={dateTimeFormats.date} onChange={save}></DatePicker>
                </Form.Item>
                <SelectWithFormItem
                    label="Status"
                    name={["Status", "AccountTagId"]}
                    showSearch={true}
                    allowClear={true}
                    style={{width: "100%"}}
                    placeholder="Select a Status"
                    options={statusOptions}
                    onChanged={save}
                    filterOption={(input, option) => option.title.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                />
                <Form.Item label="Linked Report" className="ant-form-item-without-validation">
                    <div className="linked-report">
            <span className="grey">
              <FileTextFilled/>
            </span>{" "}
                        <div className="linked-report-name">
                            {itemToEdit.ReportInfo.ReportName}
                            {itemToEdit.ReportInfo.ReportDate && (
                                <> ({moment(itemToEdit.ReportInfo.ReportDate).format(dateTimeFormats.date)})</>
                            )}
                        </div>
                        <Button type="link" size="small" onClick={goToReport}>
                            <EyeOutlined/>
                        </Button>
                    </div>
                </Form.Item>
                <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>
                <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="UpdatedBy"/>
                <Row>
                    <Col span={15} offset={9}>
                        <Button type="primary" onClick={onDoneClick} loading={doneLoading}>
                            {itemToEdit.IsDone ? "Mark as Not Done" : "Mark as Done"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default NotesDetailsPanel;
