import React from "react";
import SearchFilter from "./SearchFilter";

import FilterBar from "../../../Common/Tables/FilterBar";

const UsageFilterBar = (props) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[SearchFilter]}
        />
    );
};

export default UsageFilterBar;
