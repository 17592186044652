import React, {useState, useEffect, useRef} from "react";
import _ from "lodash";
import classNames from "classnames";
import {Table, message} from "antd";
import FilterBar from "../FilterBar/FilterBar";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";
import {useLocation} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import {formatContactName} from "../../../helpers/text";
import {ApiPermissionLevel, ApiPermissionLevelName, ApiProjectType} from "../../../constants";
import {loadAccountMembers, getItem} from "../../../services/settings";
import {setUserToEdit, updateUserToEdit} from "../../../redux/reducers/settings";
import EditUser from "./EditUser";
import {loadContacts} from "../../../services/contacts";
import usePermissions from "../../../hooks/usePermissions";
import {getProjectLabel} from "../../../helpers/Tags";
import {useVT} from "virtualizedtableforantd4";
import useWindowSize from "../../../hooks/useWindowSize";

const getRowId = (record) => `${record.Id}_type_${record.ItemType}`;

const getEditableProjectText = (projects, record) => {
    if ([ApiPermissionLevel.Owner, ApiPermissionLevel.Administrator].includes(record.PermissionLevel)) return "All Projects";

    const activeProjects = _.chain(projects)
        .filter((p) => [ApiProjectType.Active, ApiProjectType.Inactive].includes(p.State))
        .sortBy((p) => p.Name)
        .map((p) => getProjectLabel(p))
        .value();
    const nonActiveProjects = _.chain(projects)
        .filter((p) => [ApiProjectType.Archive].includes(p.State))
        .sortBy((p) => p.Name)
        .map((p) => getProjectLabel(p))
        .value();

    return [...activeProjects, ...nonActiveProjects].join(", ");
};

const defaultColumns = {
    name: {
        title: "User Name",
        dataIndex: "CreatedFromContact",
        width: "30%",
        key: "Id",
        render: (contact) => formatContactName(contact, false),
    }, accessType: {
        title: "Access Type",
        dataIndex: "PermissionLevel",
        width: "15%",
        render: (permissionLevel) => ApiPermissionLevelName[permissionLevel] || "",
    }, portfolioVisibility: {
        title: "Portfolio Visibility", dataIndex: "", width: "15%",
    }, projects: {
        title: "Editable Projects", dataIndex: "AssistantProjects", render: getEditableProjectText,
    },
};

const isOwnerOrStudent = (record) => record.PermissionLevel === ApiPermissionLevel.Owner || record.PermissionLevel === ApiPermissionLevel.Student;

const isAdministrator = (record) => record.PermissionLevel === ApiPermissionLevel.Administrator;

const ReportsTable = ({type}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const items = useSelector((state) => state.settings.accountMembers);
    const [filteredData, setFilteredData] = useState(items);
    const location = useLocation();
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const {isAdmin} = usePermissions();

    const size = useWindowSize();
    const tableRef = useRef();

    useEffect(() => {
        if (!activePortfolio?.Id) return;

        const loadData = async () => {
            try {
                setLoading(true);
                await Promise.all([loadAccountMembers(activePortfolio.Id), loadContacts(activePortfolio.Id)]);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, [activePortfolio?.Id]);

    useEffect(() => {
        dispatch(setSelected([]));
    }, [location, dispatch]);

    useEffect(() => setFilteredData(items), [items]);

    function setSelectedRowKeys(selected) {
        const selectedItems = items.filter((i) => selected.includes(getRowId(i)));

        dispatch(setSelected(selectedItems));
    }

    function loadItemToEdit(item) {
        dispatch(setUserToEdit(item));
        getItem(item.Id)
            .then((item) => dispatch(updateUserToEdit(item)))
            .catch(() => message.error("Server error"));
    }

    function applyFilters(filtersData) {
        setFilteredData(filtersData);
    }

    const canEdit = (record) => !isOwnerOrStudent(record) && !(isAdmin && isAdministrator(record));

    const actionsColumn = {
        key: "action", width: "0px", className: "actions-column", alwaysVisible: true, render: (record) => (<>
            <EditOutlined
                onClick={(e) => {
                    if (!isOwnerOrStudent(record)) loadItemToEdit(record);
                    e.stopPropagation();
                }}
                className={classNames({"btn-table-actions": true, disabled: isOwnerOrStudent(record)})}
            />
        </>),
    };

    const columns = [defaultColumns.name, defaultColumns.accessType, defaultColumns.projects, actionsColumn];
    const rowClassName = (record) => (record && itemToEdit && record.Id === itemToEdit.Id ? "row-selected-for-edit" : "");
    const tableHeight = (size?.height || 800) - (tableRef.current?.offsetTop || 0) - 40;
    const [vt] = useVT(() => ({scroll: {y: tableHeight}}), [tableHeight, items]);

    return (<>
        <FilterBar type={type} items={items} applyFilters={applyFilters}/>

        <Table
            ref={tableRef}
            loading={loading}
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            rowKey={getRowId}
            className="common-table"
            size="small"
            rowClassName={rowClassName}
            scroll={{y: tableHeight}}
            components={vt}
            rowSelection={{
                hideSelectAll: true,
                type: "radio",
                onChange: (selected) => setSelectedRowKeys(selected),
                renderCell: (checked, record, index, originNode) => (canEdit(record) ? originNode : null),
            }}
            onRow={(record) => ({
                onClick: () => (canEdit(record) ? loadItemToEdit(record) : null),
            })}
        />

        <EditUser></EditUser>
    </>);
};

export default ReportsTable;
