import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {DatePicker, Form, message, Modal} from "antd";
import {ClockCircleFilled} from "@ant-design/icons";
import {SelectWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import "./CloneFromExisting.scss";
import {createProjectColorTagRendered, mapProjectWithBadgeToTag} from "../../../helpers/Tags";
import {cloneBookings, cloneToProjectBatch} from "../../../services/timeline";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {ApiProjectType, ApiTimelineItemType} from "../../../constants/constants";
import {setSelected} from "../../../redux/reducers/mainTable";
import moment from "moment";

const apiDateFormat = "YYYY-MM-DDT00:00:00";

const Title = ({selected, events}) => (<>
        <ClockCircleFilled/> Clone {selected.length} {events}
    </>);

const getEvents = (items = 0) => (items === 0 || items > 1 ? "events" : "event");
const getIntoProjectsText = (length = 0) => ` into ${length} projects.`;

export const onCloneBookings = async (selected, dispatch) => {
    var response = await cloneBookings({BookingsId: selected.map((s) => s.Id)});

    message.success(`${selected.length} ${selected.length === 1 ? "Booking" : "Bookings"} ${selected.length > 1 ? "were" : "was"} cloned.`);
    var bookings = (response.Bookings || []).map((b) => ({...b, ItemType: ApiTimelineItemType.Booking}));
    dispatch(setSelected(bookings));
};

const CloneFromExisting = ({isVisible, onClose, selected}) => {
    const [form] = Form.useForm();
    var dispatch = useDispatch();
    const dateTimeFormats = useDateTimeFormat();
    const selectedTimeZone = useSelector((state) => state.timeline.selectedTimeZone);
    const events = useSelector((state) => state.timeline.events);
    const projects = useSelector((state) => state.projects.projects)
        .filter((p) => p.State !== ApiProjectType.Archive)
        .map(mapProjectWithBadgeToTag);

    const [dates, setDates] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [isDateOpen, setDateOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    var ids = selected.filter(s => s !== undefined).map((s) => s.Id);

    const selectedEvents = events.filter((e) => ids.includes(e.Id));
    const eventsProjectIds = _.uniq(selectedEvents.map((p) => p.ProjectId?.toString()));
    const projectId = eventsProjectIds.length === 1 ? eventsProjectIds[0] : null;

    useEffect(() => {
        if (!isVisible) {
            form.resetFields();
            return;
        }

        const defaultProject = !!projectId ? [projectId] : [];

        form.setFieldsValue({Projects: defaultProject});
        setSelectedProjects(defaultProject);
    }, [eventsProjectIds.join(":"), selected, isVisible]);

    async function handleOk() {
        setIsLoading(true);
        try {
            var items = await cloneEvents();
            dispatch(setSelected(items));
            message.success(summaryText);
            close();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function close() {
        form.resetFields();
        setDateOpen(false);
        setDates([]);
        setSelectedProjects([]);
        setTimeout(() => {
            onClose();
        }, 100);
    }

    const cloneEvents = async () => {
        const values = await form.validateFields();

        return await cloneToProjectBatch({
            ProjectIds: values.Projects.map((p) => parseInt(p, 10)),
            TaskIds: selected.map(({Id, ItemType}) => ({Id, ItemType})),
            StartDates: dates.map((d) => d.format(apiDateFormat)),
            TimeZoneId: selectedTimeZone,
        });
    };

    const datesString = useMemo(() => {
        return dates
            .filter((d) => d)
            .map((d) => d.format(dateTimeFormats.date))
            .join(", ");
    }, [dates]);

    const summaryText = useMemo(() => {
        return `${selected.length} ${getEvents(selected.length)} will be cloned ${dates.length || 1} time${dates.length > 1 ? "s" : ""}${selectedProjects.length > 1 ? getIntoProjectsText(selectedProjects.length) : "."}`;
    }, [dates, selected, selectedProjects]);

    const earliestStartDate = useMemo(() => {
        if (selectedEvents.length === 0) {
            return moment();
        }

        return moment.min(selectedEvents.map(event => moment(event.StartDateTime)));
    }, [selectedEvents]);

    return (<>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                <Modal
                    className="clone-project-modal"
                    title={<Title selected={selected} events={getEvents(selected.length)}/>}
                    visible={isVisible}
                    onOk={handleOk}
                    onCancel={close}
                    maskClosable={false}
                    confirmLoading={isLoading}
                    okText={`Clone Events`}>
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}}
                                   className="ant-form-item-without-validation center">
                            Select projects and a starting date to clone the events to.
                        </Form.Item>
                        <SelectWithFormItem
                            showSearch
                            searchValue={searchValue}
                            onSearch={setSearchValue}
                            label="Projects"
                            name="Projects"
                            mode="multiple"
                            tagRender={createProjectColorTagRendered(projects, true)}
                            hasFeedback
                            rules={[{required: true, message: "Select one or more projects."}]}
                            style={{width: "100%"}}
                            placeholder="Select projects"
                            options={projects}
                            onChange={setSelectedProjects}
                            onClose={() => {
                                setSearchValue("");
                            }}
                        />
                        <Form.Item label="Dates" className="clone-dates-clone" name="Dates">
                            <DatePicker
                                open={isDateOpen}
                                onClick={() => setDateOpen(true)}
                                onBlur={() => setDateOpen(false)}
                                format={() => datesString}
                                showToday={false}
                                style={{width: "118px"}}
                                defaultPickerValue={earliestStartDate}
                                onSelect={(current) => {
                                    const isSelected = dates.find((d) => d.isSame(current, "day"));
                                    if (isSelected) {
                                        setDates(dates.filter((d) => !d.isSame(current, "day")));
                                    } else {
                                        setDates([...dates, current]);
                                    }
                                }}
                                onChange={(val) => {
                                    if (!val) {
                                        setDates([]);
                                        return;
                                    }

                                    const isSelected = dates.find((d) => d.isSame(val, "day"));
                                    if (isSelected) {
                                        setDates(dates.filter((d) => !d.isSame(val, "day")));
                                    } else {
                                        setDates([...dates, val]);
                                    }
                                }}
                                dateRender={(current) => {
                                    const isSelected = dates.find((d) => d.isSame(current, "day"));
                                    if (isSelected) {
                                        return (<div
                                                className="ant-picker-cell ant-picker-cell-in-view ant-picker-cell-selected">
                                                <div className="ant-picker-cell-inner">{current.date()}</div>
                                            </div>);
                                    }
                                    return current.date();
                                }}
                                renderExtraFooter={() => {
                                    return <>{summaryText}</>;
                                }}></DatePicker>
                        </Form.Item>
                        <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}}
                                   className="ant-form-item-without-validation center">
                            {summaryText}
                        </Form.Item>
                    </Form>
                </Modal>
            </Form>
        </>);
};
export default CloneFromExisting;
