import {Button} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/reducers/mainTable";

const ClearSelection = () => {
    const selected = useSelector((store) => store.mainTable.selected);
    const dispatch = useDispatch();
    const onClearClick = () => {
        dispatch(setSelected([]));
    };
    return selected.length > 0 && <Button onClick={onClearClick}>Clear Selection</Button>;
};

export default ClearSelection;
