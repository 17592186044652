import {Col, Row} from "antd";
import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {ApiPermissionLevel, ApiProjectType} from "../../../../constants/constants";
import "./Limits.scss";

const isOver = (count, limit) => limit < count;

export const getMaxProjectPms = (accountMembers) =>
    _.chain(accountMembers)
        .filter((a) => a.PermissionLevel === ApiPermissionLevel.AssistantOrViewer)
        .map((a) => a.AssistantProjects)
        .flatten()
        .filter((p) => [ApiProjectType.Active, ApiProjectType.Inactive].includes(p.State))
        .groupBy("Id")
        .values()
        .map((i) => i.length)
        .max()
        .value() || 0;

const Limits = () => {
    const maxMembers = useSelector((state) => state.settings?.maxMembers);
    const {MaxAssistantsPerProject = 0, MaxAdmins = 0} = maxMembers || {};
    const accountMembers = useSelector((state) => state.settings.accountMembers);
    const admins = accountMembers.filter((a) => a.PermissionLevel === ApiPermissionLevel.Administrator).length;
    var maxProjectPms = getMaxProjectPms(accountMembers);

    return (
        <Row className="limits">
            <Col span={5} offset={1} className={maxMembers && isOver(admins, MaxAdmins) ? "over" : ""}>
                Administrators:
            </Col>
            <Col span={4} className={maxMembers && isOver(admins, MaxAdmins) ? "over" : ""}>
                {admins} of {MaxAdmins} used
            </Col>
            <Col span={9} offset={1}
                 className={maxMembers && isOver(maxProjectPms, MaxAssistantsPerProject) ? "over" : ""}>
                Project Managers per project:
            </Col>
            <Col span={4} className={maxMembers && isOver(maxProjectPms, MaxAssistantsPerProject) ? "over" : ""}>
                {maxProjectPms} of {MaxAssistantsPerProject} used
            </Col>
        </Row>
    );
};

export default Limits;
