import {Button, Select, Form} from "antd";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {InfoCircleFilled, InfoCircleOutlined, RedoOutlined} from "@ant-design/icons";
import "./SourcesManagement.scss";

import {infinity} from "../../../../helpers/text";
import {moneyFormatter, moneyParser} from "../../../../helpers/money";
import {upsertSource} from "../../../../services/requirements";
import {InputNumberWithFormItem, SelectWithFormItem} from "../../../Common/Input";
import AvailabilityConflicts from "../Conflicts/AvailabilityConflicts";

const PartiallyMissingIcon = (props) => <InfoCircleOutlined className="info-icon" {...props} />;
const ConflictIcon = (props) => <InfoCircleFilled className="warning-icon" {...props} />;

function getAvailability(item) {
    const quantity = item.Value?.Quantity || 0;
    const inventory = item.Default?.Quantity;
    const overlaps = item.Overlaps || [];

    if (!_.isNumber(inventory)) return infinity;

    const available = inventory - _.sumBy(overlaps, (m) => m.Quantity) - quantity;

    return available;
}

const AvailabilityNumber = ({item}) => {
    const available = getAvailability(item);
    const className = available < 0 ? "negative" : "";

    return <span className={className}>{available}</span>;
};

const EditSourceRow = ({item, onChange, activePortfolioId}) => {
    const [form] = Form.useForm();
    const [isSaving, setSaving] = useState(false);
    const [saveTimeoutId, setTimeoutId] = useState();

    useEffect(() => {
        if (!item) return;

        const {Value, Default} = item;
        const merged = {...Default, ...Value};
        const formData = {...merged, Quantity: Value?.Quantity || 0};
        form.setFieldsValue(formData);
    }, [item]);

    const onSave = () => {
        if (isSaving) return false;
        if (saveTimeoutId) clearTimeout(saveTimeoutId);

        const timeout = setTimeout(async () => {
            setSaving(true);
            clearTimeout(saveTimeoutId);
            setTimeoutId(null);
            await save();
            setSaving(false);
        }, 500);

        setTimeoutId(timeout);
        return true;
    };

    const save = async () => {
        const formValues = await form.getFieldsValue();

        const payload = {
            AccountId: activePortfolioId,
            ResourceId: item.ResourceId,
            RequirementId: item.RequirementId,
            SourceId: item.Default ? item.Id : null,

            Quantity: formValues.Quantity,
            Cost: formValues.Cost,
            Unit: formValues.Unit,
        };

        return upsertSource(payload).then((response) => {
            if (onChange) onChange(item);

            //item.Id = response.Id
        });
    };

    const onResetRow = () => {
        const {Default} = item;
        const {Cost, Unit} = Default;
        const formData = {Cost, Unit};

        form.setFieldsValue(formData);
        save();
    };

    const avty = getAvailability(item);
    const isOverAllocated = avty < 0 && item.Value?.Quantity > 0;
    const OverlapIcon = isOverAllocated ? ConflictIcon : PartiallyMissingIcon;

    return (
        <>
            <Form form={form} initialValues={{Unit: "Item"}}>
                <div className="details-src-item">
                    <div className="source">
                        <Form.Item className="ant-form-item-without-validation">{item.Name}</Form.Item>
                    </div>
                    <div className="inventory">
                        <InputNumberWithFormItem
                            name="Quantity"
                            formatter={(value) => value}
                            parser={(value) => (value ? parseInt(value, 10) : 0)}
                            min={0}
                            controls={false}
                            onChanged={save}
                        />
                    </div>
                    <div className="availability">
                        <AvailabilityNumber item={item}/>
                    </div>
                    <div className="cost">
                        <InputNumberWithFormItem
                            name="Cost"
                            formatter={moneyFormatter}
                            parser={moneyParser}
                            controls={false}
                            onChanged={save}
                        />
                    </div>
                    <div className="unit">
                        <SelectWithFormItem name="Unit" style={{width: "100%"}} onChange={save}>
                            <Select.Option key="Item" value="Item">
                                /Item
                            </Select.Option>
                            <Select.Option key="Day" value="Day">
                                /Day
                            </Select.Option>
                            <Select.Option key="Flat" value="Flat">
                                /Flat
                            </Select.Option>
                        </SelectWithFormItem>
                    </div>
                    <div className="actions">
                        <AvailabilityConflicts overlaps={item?.Overlaps} isOverAllocated={isOverAllocated}
                                               Icon={OverlapIcon}/>
                        <Button hidden={!item?.Default} onClick={onResetRow} type="link" size="small">
                            <RedoOutlined/>
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EditSourceRow;
