import React from "react";
import {Table} from "antd";

import {formatInventory} from "../../../helpers/text";
import {defaultCostFactorMapper, formatAmount, getTotalCost} from "../../../helpers/money";
import moment from "moment";

export const getRequirementsSummary = (pageData = []) => {
    let totalQty = 0;
    let totalCost = 0;

    pageData.forEach(({Requirement: requirement, Sources}) => {
        const sources = Sources?.map((s) => s.Value);
        const {StartDateTime: start, EndDateTime: end} = requirement;

        const factorMapper = (m) => {
            const days = start && end ? moment(end).diff(start, "days") + 1 : 1;
            return {...defaultCostFactorMapper(m), days: days};
        };

        const cost = getTotalCost(sources, factorMapper);

        totalCost += cost;
        totalQty += formatInventory(sources);
    });

    return {totalCost, totalQty};
};

const RequirementsTableFooter = (pageData) => {
    const {totalCost, totalQty} = getRequirementsSummary(pageData);
    return (<>
        <Table.Summary.Row className="req-summary">
            <Table.Summary.Cell index={0}> </Table.Summary.Cell>
            <Table.Summary.Cell index={1}> </Table.Summary.Cell>
            <Table.Summary.Cell index={2}> </Table.Summary.Cell>
            <Table.Summary.Cell index={3}> </Table.Summary.Cell>
            <Table.Summary.Cell index={4}> </Table.Summary.Cell>
            <Table.Summary.Cell index={5}> </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
                <b>{totalQty}</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}>
                <div className="no-wrap"><b>{formatAmount(totalCost)}</b></div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8}> </Table.Summary.Cell>
            <Table.Summary.Cell index={9}> </Table.Summary.Cell>
            <Table.Summary.Cell index={10}> </Table.Summary.Cell>
        </Table.Summary.Row>
    </>);
};

export const GroupRequirementsTableFooter = (pageData) => {
    const {totalCost, totalQty} = getRequirementsSummary(pageData);
    return (<>
        <Table.Summary.Row className="req-summary">
            <Table.Summary.Cell index={0}> </Table.Summary.Cell>
            <Table.Summary.Cell index={1}> </Table.Summary.Cell>
            <Table.Summary.Cell index={2}> </Table.Summary.Cell>
            <Table.Summary.Cell index={3}> </Table.Summary.Cell>
            <Table.Summary.Cell index={4}> </Table.Summary.Cell>
            <Table.Summary.Cell index={5}> </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
                <b>{totalQty}</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}>
                <div className="no-wrap"><b>{formatAmount(totalCost)}</b></div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8}> </Table.Summary.Cell>
            <Table.Summary.Cell index={9}> </Table.Summary.Cell>
            <Table.Summary.Cell index={10}> </Table.Summary.Cell>
        </Table.Summary.Row>
    </>);
};

export default RequirementsTableFooter;
