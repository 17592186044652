import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Modal, message} from "antd";
import {deleteAttachmentBatch, refreshAttachments} from "../../../services/attachments";
import "./DeleteAttachments.scss";

const deleteAttachments = (selected) => {
    const getTitle = () => {
        if (selected.length === 1) {
            return `Are you sure you want to delete this attachment?`;
        } else {
            return `Are you sure you want to delete ${selected.length} attachments?`;
        }
    };

    Modal.confirm({
        title: getTitle(),
        icon: <DeleteOutlined/>,
        content: "You cannot undo this action",
        className: "delete-contact-modal",
        okText: "Delete",
        okButtonProps: {danger: true},
        async onOk() {
            const ids = selected.map((b) => b.Id);
            await deleteAttachmentBatch(ids);
            await refreshAttachments();

            if (selected.length === 1) {
                message.success(`Attachment was deleted.`);
            } else {
                message.success(`${selected.length} attachments were deleted.`);
            }
        },
        onCancel() {
        },
    });
};

export {deleteAttachments};
