import React from "react";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";

const fieldsToSearch = [
    "Name",
    (item) => {
        return (item.Projects || []).map((p) => p.Name);
    },
];

const SearchFilter = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter setFilterHandler={setFilterHandler} needResetFilter={needResetFilter}
                        fieldsToSearch={fieldsToSearch}/>
);

export default SearchFilter;
