import {Button, Form} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import _ from "lodash";
import {CloseCircleOutlined} from "@ant-design/icons";
import "./ItemsManagement.scss";
import {loadSources} from "../../../../../services/sources";
import {deleteCollectionItems, createOrUpdateCollectionItem} from "../../../../../services/resources";
import {InputNumberWithFormItem, SelectWithFormItem} from "../../../../Common/Input";
import ResourceSelect from "../../../../Common/Selects/ResourceSelect";

const EditRow = ({item = {}, setIsQuickCreateItemVisible, collection, onChange, onDelete, forceSave = false}) => {
    const [form] = Form.useForm();
    const [isSaving, setSaving] = useState(false);
    const [saveTimeoutId, setTimeoutId] = useState();

    const collectionId = collection?.Id;
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const [resourceId, setResourceId] = useState();
    const sources = useSelector((state) => state.resources.sources[resourceId] || []);
    const sortedSources = _.sortBy(sources, (s) => s.Name);

    useEffect(() => {
        if (!item) return;

        const formData = {...item};
        form.setFieldsValue(formData);
        setResourceId(formData.ResourceId);
    }, [item]);

    useEffect(() => {
        if (!resourceId) return;

        loadSources({resourceId: resourceId, accountId: activePortfolioId});
    }, [resourceId, form]);

    useEffect(() => {
        if (!forceSave) return;

        save();
    }, [resourceId, form, forceSave]);
    const prepareSaveRequest = async () => {
        const formValues = await form.getFieldsValue();

        const merged = {
            ...item,
            ...formValues,
        };

        const payload = {
            Id: merged.Id,
            AccountId: activePortfolioId,

            CollectionId: collectionId,
            ResourceId: merged.ResourceId,
            SourceId: merged.SourceId,

            Quantity: merged.Quantity,
        };

        return payload;
    };

    const save = async () => {
        const payload = await prepareSaveRequest();

        if (!payload.ResourceId) return;

        const response = await createOrUpdateCollectionItem(payload);
        return await onChange({...payload, ...response});
    };

    const onSave = () => {
        if (isSaving) return false;
        if (saveTimeoutId) clearTimeout(saveTimeoutId);

        const timeout = setTimeout(async () => {
            setSaving(true);
            clearTimeout(saveTimeoutId);
            setTimeoutId(null);
            await save();
            setSaving(false);
        }, 500);

        setTimeoutId(timeout);
        return true;
    };

    const onResourceChange = async (id) => {
        await form.setFieldsValue({SourceId: null});
        setResourceId(id);

        await save();
    };

    const onDeleteRow = async () => {
        const {Id: itemId} = item;

        if (itemId) {
            await deleteCollectionItems(activePortfolioId, [itemId]);
        }

        onDelete(itemId);
    };

    return (
        <>
            <Form form={form}>
                <div className="details-src-item">
                    <div className="item">
                        <ResourceSelect
                            form={form}
                            name={["ResourceId"]}
                            className="source-ddl"
                            onChanged={onResourceChange}
                            onChange={onResourceChange}
                            setIsQuickCreateItemVisible={setIsQuickCreateItemVisible}
                            hideQuickCreate={!setIsQuickCreateItemVisible}
                        />
                    </div>
                    <div className="source">
                        <SelectWithFormItem
                            form={form}
                            disabled={!resourceId}
                            name={["SourceId"]}
                            mode="single"
                            allowClear={true}
                            showSearch
                            className="source-ddl"
                            options={sortedSources.map((m) => ({value: m.Id, label: m.Name}))}
                            style={{width: "100%"}}
                            placeholder="Select a source"
                            onChanged={save}></SelectWithFormItem>
                    </div>
                    <div className="inventory">
                        <InputNumberWithFormItem
                            disabled={!resourceId}
                            name="Quantity"
                            formatter={(value) => value.toString()}
                            parser={(value) => parseInt(value, 10) || 0}
                            min={1}
                            controls={false}
                            onChanged={save}
                        />
                    </div>
                    <div className="actions">
                        <Button onClick={onDeleteRow} type="link" size="small">
                            <CloseCircleOutlined className="delete"/>
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EditRow;
