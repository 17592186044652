import React from "react";
import {Button, Space} from "antd";
import {useDispatch} from "react-redux";
import {openNew} from "../../../redux/reducers/detailsPanel";
import {ProjectType, SidePanelItemType} from "../../../constants";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../helpers/paths";
import usePermissions from "../../../hooks/usePermissions";
import {TooltipWithOrNot} from "../../Common/Input";

const HomeTitleRowButtons = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {canManageProjects, isStudent, canStudentAddNewProject} = usePermissions();

    const createNewProject = () => {
        history.push(urlWithPortfolioId(pathCollection.projectsPath[ProjectType.Active]));
        setTimeout(() => dispatch(openNew({type: SidePanelItemType.Project})), 100);
    };

    const checkPermissions = () => {
        return !canManageProjects || (isStudent && !canStudentAddNewProject);
    };

    const shouldShowTooltip = () => {
        return checkPermissions();
    };

    const isButtonDisabled = () => {
        return checkPermissions();
    };

    return (
        <Space>
            <TooltipWithOrNot
                showTooltip={shouldShowTooltip()}
                title={isStudent ? "Users with student access may only have one active or draft project at a time. To create a new project, you’ll first need to archive the current one."
                    : "Only owners and administrators may create new projects. Contact an administrator to add a new project"}>
                <Button type="primary" onClick={createNewProject}
                        disabled={isButtonDisabled()}>
                    New Project
                </Button>
            </TooltipWithOrNot>
        </Space>
    );
};

export default HomeTitleRowButtons;
