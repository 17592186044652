import React from "react";
import _ from "lodash";
import {Select, Button, Menu, Dropdown} from "antd";
import {EnvironmentOutlined, ExpandOutlined, UserOutlined, ShopOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {ApiContactType} from "../../../constants/constants";
import {formatLocationName} from "../../../helpers/text";

const placesToOptions = (places, spaces) => {
    return places.map((p) => {
        const items = spaces
            .filter((s) => s.ParentId === p.Id)
            .map((s) => ({
                value: s.Id,
                contactId: s.Id,
                key: s.Id,
                label: formatLocationName(s),
            }));

        return {
            value: p.Id,
            group: formatLocationName(p),
            key: p.Id,
            items: _.sortBy(items, (c) => c.label),
        };
    });
};

const contactsToOptions = (contacts) =>
    contacts.map((c) => ({
        value: c.Id,
        contactId: c.Id,
        key: c.Id,
        label: formatLocationName(c),
    }));

const renderContactOption = ({item, Icon, group = null}) => {
    return (
        <Select.Option title={`${item.label}`} label={`${item.label}`} key={item.value} value={item.value}>
            <Icon/> {item.label}
        </Select.Option>
    );
};

const renderContactGroup = ({key, group, GroupIcon, ItemIcon}) => {
    return (
        <Select.OptGroup
            key={key}
            header={group.group}
            label={
                <>
                    <GroupIcon/> {group.group}
                </>
            }>
            {group.items.map((contact) => renderContactOption({item: contact, Icon: ItemIcon, group}))}
        </Select.OptGroup>
    );
};

const ContactSelect = ({
                           selectedContactId,
                           onChange,
                           onChanged,
                           disabled,
                           placeholder = "Select a contact",
                           disabledContactIds = [],
                           disabledContactTypes = [],
                           groupSpaces = true,
                           onOpenCreatePopup,
                           hideQuickCreate = false,
                           className = "source-ddl",
                           allowClear = false,
                           organizationsOnly,
                       }) => {
    const allContacts = useSelector((state) => state.contacts.contacts).filter(
        (c) => !_.includes(disabledContactIds, c.Id) && (disabledContactTypes.length === 0 || !disabledContactTypes.includes(c.Type))
    );

    const spaces = allContacts.filter((c) => c.Type === ApiContactType.Space).filter((s) => s.ParentId != null);
    const places = allContacts.filter((c) => c.Type === ApiContactType.Place);
    const organizations = allContacts.filter((c) => c.Type === ApiContactType.Organization);
    const people = allContacts.filter((c) => c.Type === ApiContactType.Person);

    const locations = placesToOptions(places, spaces);
    const companies = contactsToOptions(organizations);
    const persons = contactsToOptions(people);

    const onSelectionChange = (val) => {
        onChange(val);
        onChanged();
    };

    const handleCreateMenuClick = (e) => {
        const contactType = parseInt(e.key, 10);
        onOpenCreatePopup && onOpenCreatePopup(contactType);
    };

    const isCreateMenuItemHidden = (type) => disabledContactTypes.includes(type);
    const AddContactMenu = (
        <Menu onClick={handleCreateMenuClick}>
            <Menu.Item key={ApiContactType.Person} hidden={isCreateMenuItemHidden(ApiContactType.Person)}>
                Person
            </Menu.Item>
            <Menu.Item key={ApiContactType.Organization} hidden={isCreateMenuItemHidden(ApiContactType.Organization)}>
                Organization
            </Menu.Item>
            <Menu.Item key={ApiContactType.Place} hidden={isCreateMenuItemHidden(ApiContactType.Place)}>
                Location
            </Menu.Item>
            <Menu.Item key={ApiContactType.Space} hidden={isCreateMenuItemHidden(ApiContactType.Space)}>
                Space
            </Menu.Item>
        </Menu>
    );

    const onCreateOrganizationClick = () => onOpenCreatePopup(ApiContactType.Organization);

    const onSearch = (input, option) => (option.header || option.label).toLowerCase().indexOf(input?.toLowerCase()) >= 0;

    return (
        <Select
            value={selectedContactId}
            allowClear={allowClear}
            showSearch={true}
            optionLabelProp="label"
            style={{width: "100%"}}
            className={className}
            placeholder={placeholder}
            onChange={onSelectionChange}
            disabled={disabled}
            filterOption={onSearch}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    {!hideQuickCreate &&
                        (organizationsOnly ? (
                            <div className="btn-quick-create">
                                <Button type="dashed" onClick={onCreateOrganizationClick}>
                                    + Create an Organization
                                </Button>
                            </div>
                        ) : (
                            <div className="btn-quick-create">
                                <Dropdown trigger="click" overlay={AddContactMenu}>
                                    <Button type="dashed">+ Add a Contact</Button>
                                </Dropdown>
                            </div>
                        ))}
                </>
            )}>
            {companies.map((company) =>
                renderContactOption({
                    item: company,
                    Icon: ShopOutlined,
                })
            )}

            {!organizationsOnly &&
                groupSpaces &&
                locations.map((group, i) =>
                    renderContactGroup({
                        key: `l${i}`,
                        group: group,
                        GroupIcon: EnvironmentOutlined,
                        ItemIcon: ExpandOutlined,
                    })
                )}
            {!organizationsOnly &&
                !groupSpaces &&
                contactsToOptions(places).map((place) =>
                    renderContactOption({
                        item: place,
                        Icon: EnvironmentOutlined,
                    })
                )}
            {!organizationsOnly &&
                !groupSpaces &&
                contactsToOptions(spaces).map((space) =>
                    renderContactOption({
                        item: space,
                        Icon: ExpandOutlined,
                    })
                )}
            {!organizationsOnly &&
                persons.map((person) =>
                    renderContactOption({
                        item: person,
                        Icon: UserOutlined,
                    })
                )}
        </Select>
    );
};

export default ContactSelect;
