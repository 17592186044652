import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import GroupedTables from "../../Common/Tables/GroupedTable";
import {ItemGroupBy} from "./FilterBar/GroupBy";
import {Space} from "antd";

function getAllSources(data) {
    return _.chain(data)
        .map((d) => d.Source || null)
        .uniqBy((d) => d?.Id)
        .value();
}

function getAllDepartments(data) {
    return _.chain(data)
        .filter((d) => d.DepartmentTag)
        .map((d) => d.DepartmentTag)
        .uniqBy((d) => d.AccountTagId)
        .sortBy((x) => x.Name)
        .value();
}

function getGroups(groupBy, dataSource, allContacts) {
    let groups = [];
    let noGroupItems = [];
    let noGroupTitle = "";
    let noGroupText = null;

    const dataSourceSorted = _.sortBy(dataSource, (d) => d.Name);

    const TextControl = ({items}) => {
        const {totalQty} = items?.length;
        return (
            <>
                <Space direction="horizontal" size="large">
                    <span className="col-50px">{totalQty}</span>
                </Space>
            </>
        );
    };

    switch (groupBy) {
        case ItemGroupBy.Source:
            const sources = getAllSources(dataSourceSorted);

            groups = sources
                .filter((g) => g)
                .map((group) => {
                    const sourceItems = dataSourceSorted.filter((item) => item.Source?.Id === group.Id);
                    const sourceItemsSorted = _.sortBy(sourceItems, [(m) => m.Source?.Name, (m) => m.Name], ["asc", "asc"]);
                    return {
                        group: group.Name,
                        level: 200,
                        items: sourceItemsSorted,
                        rightText: <TextControl items={sourceItemsSorted}/>,
                    };
                });

            noGroupItems = dataSourceSorted.filter((item) => !item.Source);
            noGroupItems = _.sortBy(noGroupItems, (m) => m.Name);
            noGroupTitle = "No Source";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;

        case ItemGroupBy.Department:
            const departments = getAllDepartments(dataSourceSorted);

            groups = departments.map((group) => {
                const departmentItems = dataSourceSorted.filter((item) => item.DepartmentTag?.AccountTagId === group.AccountTagId);
                return {
                    group: group.Name,
                    level: 200,
                    items: departmentItems,
                    rightText: <TextControl items={departmentItems}/>,
                };
            });

            noGroupItems = dataSourceSorted.filter((item) => !item.DepartmentTag);
            noGroupTitle = "No Department";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({
            group: noGroupTitle,
            level: 200,
            items: noGroupItems,
            rightText: noGroupText,
        });
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });
    return groups;
}

const GroupPositionsTable = ({columns, dataSource, onRow, groupByFilter, loading, footer}) => {
    const allContacts = useSelector((state) => state.contacts.contacts);

    return (
        <GroupedTables
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            footer={footer}
            getGroups={() => getGroups(groupByFilter.value, dataSource, allContacts)}
        />
    );
};

export default GroupPositionsTable;
