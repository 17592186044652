import React from "react";
import {CopyFilled} from "@ant-design/icons";
import {Popover} from "antd";

const CloneAction = ({onClick, record, title}) => (
    <Popover trigger="hover" content={<div>{title}</div>}>
        <CopyFilled
            className="btn-table-actions"
            onClick={(e) => {
                if (onClick) {
                    e.stopPropagation();
                    onClick(record);
                }
            }}
        />
    </Popover>
);

export default CloneAction;
