import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import _ from "lodash";

import {Tabs} from "antd";
import {BulbOutlined, GroupOutlined, AreaChartOutlined, ToolOutlined} from "@ant-design/icons";

import {ResourceType} from "../../constants";
import {resourcesPath} from "../../helpers/paths";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";
import {loadCollections, loadResources, loadResourceUsages} from "../../services/resources";
import {loadPositions} from "../../services/positions";
import {loadRulesets} from "../../services/rulesets";
import ItemsTable from "./Items/ItemsTable";
import CollectionsTable from "./Collections/CollectionsTable";
import UsageChart from "./Usage/UsageChart";
import PositionsTable from "./Positions/PositionsTable";
import useDateTimeFormat from "../../hooks/useDateTimeFormat";
import moment from "moment";

const {TabPane} = Tabs;

const tabs = [
    {
        key: ResourceType.Item,
        path: resourcesPath[ResourceType.Item],
    },
    {
        key: ResourceType.Collection,
        path: resourcesPath[ResourceType.Collection],
    },
    {
        key: ResourceType.Usage,
        path: resourcesPath[ResourceType.Usage],
    },
    {
        key: ResourceType.Position,
        path: resourcesPath[ResourceType.Position],
    },
];

const getTabByKey = (key) => tabs.find((tab) => tab.key === key);

const Positions = ({activeKey}) => {
    const history = useHistory();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const rawItems = useSelector((state) => state.resources.resources);
    const items = _.sortBy(rawItems, (m) => m.Name);
    const rawCollections = useSelector((state) => state.resources.collections);
    const collections = _.sortBy(rawCollections, (m) => m.Name);

    const dateFormats = useDateTimeFormat();
    const activeMonthStr = useSelector((state) => state.resources.activeMonth);
    const activeMonth = activeMonthStr && moment(activeMonthStr, dateFormats.monthYearFull);

    const rawUsages = useSelector((state) => state.resources.usages);
    const usages = _.sortBy(rawUsages, (m) => m.Name);

    const positions = useSelector((state) => state.labour.positions);

    const urlWithPortfolioId = useUrlWithPortfolioId();

    const fetchItems = async () => {
        await loadResources(activePortfolio.Id);
    };

    const fetchCollections = async () => {
        await loadCollections(activePortfolio.Id);
    };

    const fetchPositions = async () => {
        await loadRulesets(activePortfolio.Id);
        await loadPositions(activePortfolio.Id);
    };

    const fetchUsage = () => {
        const daysInMonth = activeMonth.daysInMonth();
        const startDate = activeMonth.clone().date(1).format(dateFormats.universalDateFormat);
        const endDate = activeMonth.clone().date(daysInMonth).format(dateFormats.universalDateFormat);

        return loadResourceUsages(activePortfolio.Id, startDate, endDate);
    };

    useEffect(() => {
        if (!activePortfolio) return;

        switch (activeKey) {
            case ResourceType.Item:
                fetchItems();
                break;
            case ResourceType.Collection:
                fetchCollections();
                break;
            case ResourceType.Usage:
                if (activeMonth) fetchUsage();
                break;
            case ResourceType.Position:
                fetchPositions();
                break;
            default:
                break;
        }
    }, [activePortfolio, activeMonthStr]);

    const changeTab = (key) => {
        const path = getTabByKey(key).path;
        history.push(urlWithPortfolioId(path));
    };

    return (
        <Tabs onChange={changeTab} type="card" activeKey={activeKey}>
            <TabPane
                tab={
                    <span>
            <BulbOutlined/> Items
          </span>
                }
                key={ResourceType.Item}>
                <ItemsTable items={items}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <GroupOutlined/> Collections
          </span>
                }
                key={ResourceType.Collection}>
                <CollectionsTable collections={collections}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <AreaChartOutlined/> Usage
          </span>
                }
                key={ResourceType.Usage}>
                <UsageChart items={usages}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <ToolOutlined/> Positions
          </span>
                }
                key={ResourceType.Position}>
                <PositionsTable positions={positions}/>
            </TabPane>
        </Tabs>
    );
};

export default Positions;
