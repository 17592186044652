import React from "react";
import {Form} from "antd";

import useDateTimeFormat from "../../hooks/useDateTimeFormat";
import {timeToLong} from "../../helpers/time";
import {formatContactName} from "../../helpers/text";

const AuditFormItem = (props) => {
    const formats = useDateTimeFormat();
    const {label = "Timestamp", timeProp, userProp, item, ...passThroughProps} = props;

    const time = item && timeProp && item[`${timeProp}`];
    const user = item && userProp && item[`${userProp}`];
    const hidden = !time && !user;

    return (
        <Form.Item hidden={hidden} label={label} className="ant-form-item-without-validation" {...passThroughProps}>
      <span style={{color: "#aaa"}}>
        {time && timeToLong(time, formats)}
          {user && <> by {formatContactName(user)} </>}
      </span>
        </Form.Item>
    );
};

export default AuditFormItem;
