import React from "react";
import {Form} from "antd";
import {FilterColumnType} from "../../../constants/constants";
import MultipleFilterWithSearchFilter from "../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {isOptionSelected} from "./Filters/TreeSelectFilter";
import {getEnumTitle} from "../../../helpers/text";

const items = [
    {title: "Cost", value: FilterColumnType.Cost},
    {title: "Source", value: FilterColumnType.Source},
    {title: "Location", value: FilterColumnType.Location},
    {title: "Dates", value: FilterColumnType.Dates},
    {title: "Details", value: FilterColumnType.Details},
    {title: "Status", value: FilterColumnType.Status},
];

export const RequirementsBlock = ({form, onChanged}) => {
    const isBlockEnabled = form.getFieldValue("IsRequirementsEnabled");

    return (
        <Form
            form={form}
            initialValues={{
                Requirements: {
                    RequirementColumns: items.map((v) => ({Type: v.value, IsVisible: true})),
                },
            }}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            layout="horizontal"
            size="middle">
            <Form.Item
                label="Columns"
                name={["Requirements", "RequirementColumns"]}
                className="ant-form-item-without-validation"
                getValueProps={(values = []) => {
                    return {
                        selectedItems: values
                            .filter((v) => v.IsVisible)
                            .map((v) => ({value: v.Type, title: getEnumTitle(FilterColumnType, v.Type)})),
                        value: values.filter((v) => v.IsVisible).map((v) => ({value: v.Type})),
                    };
                }}>
                <MultipleFilterWithSearchFilter
                    disabled={!isBlockEnabled}
                    onChanged={(options) => {
                        const val = options.map((o) => o.value);
                        form.setFieldsValue({
                            Requirements: {
                                ...form.getFieldValue("Requirements"),
                                RequirementColumns: items.map((i) => ({
                                    Type: i.value,
                                    IsVisible: isOptionSelected(i.value, val)
                                })),
                            },
                        });
                        onChanged();
                    }}
                    items={items}
                    trigger="click"
                    nothingSelectedText="No Columns"
                    allItemsText="All Columns"></MultipleFilterWithSearchFilter>
            </Form.Item>
        </Form>
    );
};
