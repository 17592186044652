import React, {useEffect, useState} from "react";
import "./InstructorDashboard.scss";
import PortfolioSelector from "../Home/PortfolioSelector/";
import {loadInviteLink} from "../../services/inviteLinks";
import {useSelector} from "react-redux";
import StudentInvitationManagementSidebar
    from '../InstructorDashboard/StudentInvitationManagementSidebar/StudentInvitationManagementSidebar';
import InvitationAlert from "./InvitationAlert/InvitationAlert";

const InstructorDashboard = () => {

    const [isLoading, setLoading] = useState(true);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        if (activePortfolio) {

            const fetchData = async () => {
                try {
                    await loadInviteLink(activePortfolio.Id);
                } catch (err) {
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [activePortfolio]);

    return (
        <>
            <InvitationAlert/>
            <div className="instructor-dashboard">
                <div className="columns-container">
                    <div className="col1">
                        <PortfolioSelector/>
                    </div>
                    <div className="col2">
                        <StudentInvitationManagementSidebar/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InstructorDashboard;