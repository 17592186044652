import api from "../api";
import store from "../redux/store";
import {setContacts} from "../redux/reducers/contacts";
import {ApiContactType} from "../constants";
import {loadTags} from "./tags";
import {setSelected} from "../redux/reducers/mainTable";

const {dispatch} = store;

const contactsDefaultFilter = {
    ExcludeInDeliverables: true,
    Include: 2045,
    Keyword: "",
    OnlyWithEmailAddress: false,
    OnlyWithoutParent: false,
};

export const loadContacts = (accountId = "") =>
    api
        .post(`/api/Contact/GetPaged?accountId=${accountId}`, {
            IncludeTagsInSearch: true,
            Filter: contactsDefaultFilter
        })
        .then((response) => {
            dispatch(setContacts(response.Items));
            return response;
        });

export const refreshContacts = () => {
    const accountId = store.getState().projects.activePortfolio.Id
    return loadContacts(accountId);
}

const createSpace = (accountId, name, locationId) =>
    createContact(accountId, ApiContactType.Space, {
        Company: name,
        ContactType: ApiContactType.Space,
    }, locationId);

const createLocation = async (accountId = "", contactPayload, parentContactId = "", spaces = []) => {
    const location = await api.post(`api/Contact/Create?accountId=${accountId}&type=${ApiContactType.Place}&parentId=${parentContactId}`, contactPayload)
    if (spaces.length > 0) {
        await Promise.all(spaces.map(space => createSpace(accountId, space.Company, location.Id)))
    }

    return location;
}

export const createContact = (accountId, type, contactPayload, parentContactId = "", spaces) => {
    if (type === ApiContactType.Place) {
        return createLocation(accountId, contactPayload, parentContactId, spaces)
    }
    return api.post(`api/Contact/Create?accountId=${accountId}&type=${type}&parentId=${parentContactId}`, contactPayload);
}

export const updateContact = (contactPayload, parentContactId = "") =>
    api.post(`api/Contact/Update?parentId=${parentContactId}`, contactPayload, `Update_Contact_${contactPayload.Id}`)
        .then(loadTags);

export const getContact = (id) => api.get(`api/Contact/Get?id=${id}&includeFlags=1981`, `Get_Contact_${id}`);

export const downloadContactsAsCsv = (accountId, types = 15, keywords = '', contactIdsFilter = []) => {
    const data = {
        accountId,
        types,
        keywords,
        contactIdsFilter
    };

    api.fetchFile("api/Contact/DownloadContactsAsCsv", "Contacts.csv", data);
}

export const getChildren = (id) => api.get(`api/Contact/GetChildren?parentId=${id}&includeFlags=0`);

export const batchUpdate = (contactIds, rolesData, groupsData, parentData = {}) => {
    const payload = {
        Versions: contactIds.map(id => ({Id: id})),
        UpdateSpecialities: rolesData.isUpdated,
        Specialities: rolesData.tags,
        UpdateOnlyCommonSpecialities: rolesData.updateOnlyCommon,
        UpdateGroups: groupsData.isUpdated,
        Groups: groupsData.tags,
        UpdateOnlyCommonGroups: groupsData.updateOnlyCommon,
        ParentId: parentData.parentId,
        UpdateParent: parentData.isUpdated,
    };

    return api.post(`api/Contact/BatchUpdate`, payload).then(() => refreshContacts()
        .then(response => {
            dispatch(setSelected(response.Items.filter(i => contactIds.includes(i.Id))));
        }));
}

export const batchRemove = (contactIds) => {
    return api.post(`api/Contact/BatchRemove`, contactIds.map(id => ({Id: id})))
}

export const getAffectedProjects = (contactId) => {
    const requestPayload = {RequestedContactIds: [contactId]};
    return api.post(`api/Contact/GetAffectedProjectsByContact`, requestPayload);
}

export const mergeContact = (from, to) => {
    return api.post(`api/Contact/Merge`, {
        DuplicateId: from,
        PrincipalId: to
    })
}

export const contactImport = (accountId, contacts) =>
    api.post(`api/ContactImport/ParseCsv?accountId=${accountId}`, {Contacts: contacts})
