import {formatLocationName} from "../helpers/text";

export const expandSources = (sources, contacts = []) => {
    sources.forEach(source => {
        if (source?.ContactId && source.Id > 0) {
            const contact = contacts.find(c => c.Id === source.ContactId);
            source.Name = contact ? formatLocationName(contact) : source.Name;
        }
    });
};

export const expandRequirement = (req, contacts = []) => {
    const {Location, Sources = []} = req || {};

    if (Location) {
        const contact = contacts.find(c => c.Id === Location.Id);
        Location.Name = contact ? formatLocationName(contact) : Location.Name;
    }

    expandSources(Sources, contacts);

    return req;
};
