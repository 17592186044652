import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {formatTimelineTeamMemberName} from "../../../../helpers/text";
import {useSelector} from "react-redux";

const noItem = {
    value: "noItem",
    title: "— No Team —",
    sortRank: 1,
};

const noContact = {
    value: "noContact",
    title: "— No Assigned Contact —",
    sortRank: 2,
};

function getTeamMembersForProject(data, projectId) {
    return [
        noItem,
        ..._.chain(data)
            .filter((d) => d.ProjectId === projectId && d.PeopleOrganizations)
            .map((d) => d.PeopleOrganizations)
            .flatten()
            .sortBy((t) => !t.ContactId)
            .map((item) => ({value: item.Id, title: formatTimelineTeamMemberName(item)}))
            .uniqBy((item) => item.value)
            .value(),
    ];
}

function getContacts(data) {
    return [
        noItem,
        noContact,
        ..._.chain(data)
            .filter((d) => d.PeopleOrganizations)
            .map((d) => d.PeopleOrganizations)
            .flatten()
            .filter((t) => t.ContactId)
            .map((item) => ({value: item.ContactId, title: item.Name}))
            .uniqBy((item) => item.value)
            .value(),
    ];
}

const TeamFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();
    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const selectedProjectId = selectedProjects.length === 1 ? selectedProjects[0].Id : null;

    const items = useMemo(() => {
        return selectedProjectId ? getTeamMembersForProject(data, selectedProjectId) : getContacts(data);
    }, [data, selectedProjectId]);

    const itemsHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [itemsHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [itemsHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);
        const isNoContactSelected = selectedItems.find((i) => i.value === noContact.value);

        setFilterHandler((item) => {
            const memberIds = item.PeopleOrganizations?.map((m) => m.Id) || [];
            const contactIds = item.PeopleOrganizations?.map((m) => m.ContactId).filter((id) => id) || [];

            if (selectedProjectId) {
                return (
                    _.intersection(memberIds, filterValues).length > 0 ||
                    (isNoItemSelected && (item.PeopleOrganizations?.length === 0 || !item.PeopleOrganizations))
                );
            } else {
                return (
                    _.intersection(contactIds, filterValues).length > 0 ||
                    (isNoItemSelected && (item.PeopleOrganizations?.length === 0 || !item.PeopleOrganizations)) ||
                    (isNoContactSelected && memberIds.length > contactIds.length)
                );
            }
        });

        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Contacts"
            onChanged={onChanged}
            search
        />
    );
};

export default TeamFilter;
