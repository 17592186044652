import React from "react";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";
import {formatContactName} from "../../../../helpers/text";

const fieldsToSearch = [
    "Name",
    (item) => item.CategoryTags.map((x) => x.Name),
    (item) => item.ProjectAccountMembers.AssistantMembers.map((m) => formatContactName(m.CreatedFromContact)),
    "Notes",
];

const SearchFilter = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter setFilterHandler={setFilterHandler} needResetFilter={needResetFilter}
                        fieldsToSearch={fieldsToSearch}/>
);

export default SearchFilter;
