import React, {useState, useEffect, useRef, useCallback} from "react";
import {Collapse, Form, message} from "antd";
import {BulbFilled, InfoCircleOutlined} from "@ant-design/icons";
import {NewRecordState} from "../../../../constants/constants";
import {loadCollections, getCollection, createCollection, updateCollection} from "../../../../services/resources";
import {loadContacts} from "../../../../services/contacts";

import {continueEdit} from "../../../../redux/reducers/detailsPanel";
import {isRequestCanceled, errorFromHttpResponse} from "../../../../helpers/error";
import {useSelector, useDispatch} from "react-redux";
import InformationBlock from "./InformationBlock";
import ItemsManagement from "./ItemsManagement";

const InformationHeader = (
    <>
        <InfoCircleOutlined/> Information
    </>
);
const SourcesHeader = (
    <>
        <BulbFilled/> Items
    </>
);

const prepareFormData = (collection) => {
    const formData = {...collection};

    return formData;
};

const CollectionDetailsPanel = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const nextInputRef = useRef();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;
    const allContacts = useSelector((state) => state.contacts.contacts);

    useEffect(() => {
        if (!activePortfolio) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolio.Id);
        }
    }, [activePortfolio]);

    useEffect(() => {
        if (!itemToEdit) return;

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndCreate = useCallback(
        (finishedForm) => {
            const payload = {
                Name: finishedForm.Name,
                AccountId: activePortfolio.Id,
            };

            createCollection(payload)
                .catch((err) => {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    throw new Error(errorText);
                })
                .then((response) => {
                    const item = {Id: response.Id, Name: payload.Name};
                    dispatch(continueEdit({item}));
                    setEditItemState(NewRecordState.ReadyForEdit);
                    loadCollections(payload.AccountId);
                    message.success(`${item.Name} was created`);
                })
                .catch(console.error);

            setEditItemState(NewRecordState.RequiredFieldsSubmitted);
        },
        [itemToEdit?.Id, dispatch]
    );

    const prepareAndUpdate = useCallback(
        (finishedForm) => {

            const updatePayload = {
                Id: itemToEdit.Id,
                Name: finishedForm.Name,
                Description: finishedForm.Description,
            };

            updateCollection(updatePayload)
                .then((response) => {
                    const item = {
                        ...itemToEdit,
                        ...updatePayload,
                        Version: response.Version
                    };
                    dispatch(continueEdit({item}));
                    loadCollections(activePortfolio.Id);
                })
                .catch((err) => {
                    if (!isRequestCanceled(err)) {
                        const errorText = errorFromHttpResponse(err);
                        message.error(errorText);
                        console.error(err);
                    }
                });
        },
        [itemToEdit?.Id, dispatch]
    );

    const onFinish = useCallback(
        (finishedForm) => {
            if (editItemState === NewRecordState.Empty) {
                prepareAndCreate(finishedForm);
            }
            if (editItemState === NewRecordState.ReadyForEdit) {
                prepareAndUpdate(finishedForm);
            }
        },
        [editItemState, prepareAndCreate, prepareAndUpdate]
    );

    const save = useCallback(async () => {
        try {
            const values = isOptionalFieldDisabled
                ? await form.getFieldsValue()
                : await form.validateFields();

            if (!isOptionalFieldDisabled || values.Name) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    const reload = () => {
        getCollection(itemToEdit.Id)
            .then((collectionResponse) => {
                dispatch(continueEdit({item: collectionResponse}));
                loadCollections(activePortfolio?.Id);
            })
    }

    return (
        <div className="side-panel with-collapse">
            <Collapse defaultActiveKey={["Information", "Items"]}>
                <Collapse.Panel key="Information" header={InformationHeader}>
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <InformationBlock
                            form={form}
                            save={save}
                            firstInputRef={firstInputRef}
                            nextInputRef={nextInputRef}
                            itemToEdit={itemToEdit}
                            isOptionalFieldDisabled={isOptionalFieldDisabled}
                        />
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="Items" header={SourcesHeader} className="collection-management-panel">
                    <ItemsManagement
                        collection={itemToEdit}
                        onChange={reload}
                        onDelete={reload}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default CollectionDetailsPanel;
