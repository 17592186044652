import React, {useState} from "react";
import {useSelector} from "react-redux";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";
import {useDispatch} from "react-redux";
import {setGroupedBy} from "../../../../redux/reducers/mainTable";
import {RequirementType} from "../../../../constants/constants";

export const RequirementGroupBy = Object.freeze({
    Project: "Project",
    Requirement: "Requirement",
    Resource: "Item",
    Source: "Source",
    Location: "Location",
    Department: "Department",
    Event: "Event",
    Position: "Position",
});

const defaultItems = {
    project: {
        value: RequirementGroupBy.Project,
        title: RequirementGroupBy.Project,
    },
    requirement: {
        value: RequirementGroupBy.Requirement,
        title: RequirementGroupBy.Requirement,
    },
    resource: {
        value: RequirementGroupBy.Resource,
        title: RequirementGroupBy.Resource,
    },
    source: {
        value: RequirementGroupBy.Source,
        title: RequirementGroupBy.Source,
    },
    location: {
        value: RequirementGroupBy.Location,
        title: RequirementGroupBy.Location,
    },
    department: {
        value: RequirementGroupBy.Department,
        title: RequirementGroupBy.Department,
    },
    event: {
        value: RequirementGroupBy.Event,
        title: RequirementGroupBy.Event,
    },
    position: {
        value: RequirementGroupBy.Position,
        title: RequirementGroupBy.Position,
    },
};

const requirementItems = [
    defaultItems.project,
    defaultItems.requirement,
    defaultItems.resource,
    defaultItems.source,
    defaultItems.location,
    defaultItems.department,
];

const labourItems = [
    defaultItems.project,
    defaultItems.event,
    defaultItems.position,
    defaultItems.source,
    defaultItems.department,
];

const GroupByFilter = ({setGroupBy, type}) => {
    const items = type === RequirementType.Item ? requirementItems : labourItems;
    const [selectedItem, setSelectedItem] = useState();
    const groupedBy = useSelector((state) => state.mainTable.groupedBy);
    const dispatch = useDispatch();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);

        dispatch(setGroupedBy({...groupedBy, requirements: selectedItem?.value}));
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
