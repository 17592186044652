import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useSelector} from "react-redux";
import {mapTextToOption} from "../../../../helpers/Tags";

const TimeZone = ({value, disabled, onChange, onChanged}) => {
    const timeZones = [
        {
            value: "PTZ",
            label: "Use Project Timezones",
        },
        ...useSelector((state) => state.infrastructure.timeZones).map(mapTextToOption),
    ];

    const [selectedItem, setSelectedItem] = useState(timeZones[0].value);

    useEffect(() => {
        if (value != null) setSelectedItem(timeZones.find((i) => i.value === value).value);
    }, [value]);

    function onSelected(value) {
        onChange(value);
        onChanged();
    }

    return <Select disabled={disabled} value={selectedItem} options={timeZones} onChange={onSelected}
                   showSearch={true}/>;
};

export default TimeZone;
