import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Modal, message} from "antd";
import {deleteSources, loadSources} from "../../../../../services/sources";
import "./RemoveSource.scss";

const removeSource = (source, resource, accountId, onFinish) => {
    if (!source || !resource || !accountId) return;

    const getTitle = () => {
        return `Are you sure you want to remove ${source?.Name} from ${resource?.Name}?`;
    };

    Modal.confirm({
        title: getTitle(),
        icon: <DeleteOutlined/>,
        content: (<>
            <p>This will also remove the source from all requirements where {resource?.Name} has been added.</p>
            <p>You cannot undo this action.</p>
        </>),
        className: "remove-source-modal",
        okText: "Remove",
        okButtonProps: {danger: true},
        async onOk() {
            try {
                const ids = [source.Id];
                await deleteSources(accountId, ids);
                await loadSources({resourceId: resource.Id, accountId: accountId});

                message.success(`Source ${source.Name} was removed.`);
            } finally {
                onFinish && onFinish(source?.Id);
            }
        },
        onCancel() {
            onFinish && onFinish(source?.Id)
        },
    });
};

export {removeSource};
