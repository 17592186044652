import {BulbFilled} from "@ant-design/icons";
import React from "react";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import moment from "moment";

const UsageDetailsPanelTitle = ({editedItem}) => {
    const formats = useDateTimeFormat();
    const {Name, Date} = editedItem;
    const date = Date.isMoment ? Date : moment(Date);
    return (
        <>
            <BulbFilled/> {Name} ({date?.format(formats.date)})
        </>
    );
};

export default UsageDetailsPanelTitle;
