import React, {useCallback, useState, useEffect} from "react";
import * as PromiseBluebird from "bluebird";
import {Form, message, Tag, Divider, Col, Row, Button} from "antd";
import PhoneList from "../../Common/ViewEditList/PhoneList/PhoneList";
import EmailList from "../../Common/ViewEditList/EmailList/EmailList";
import {swapContact} from "../../Common/Modal/SwapContact";
import {removeContact} from "../../Common/Modal/RemoveContact";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import {
    NewRecordState, TeamContactType, ApiContactType, SidePanelItemType, TagType
} from "../../../constants/constants";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import {errorFromHttpResponse} from "../../../helpers/error";
import {SelectWithFormItem} from "../../Common/Input";
import {useSelector, useDispatch} from "react-redux";
import {updateTeamMember, loadTeam, getTeamMember, createTeamMember, batchDelete} from "../../../services/team";
import {getChildren} from "../../../services/contacts";
import {loadContacts} from "../../../services/contacts";
import {geTagsGroup} from "../../../helpers/Tags";
import "./TeamMemberDetailsPanel.scss";
import SpaceList from "../../Common/ViewEditList/TeamSpaceList/TeamSpaceList";
import TagsSelect from "../../Common/Selects/TagsSelect";
import {formatContactName} from "../../../helpers/text";
import {ShopOutlined} from "@ant-design/icons";
import AuditFormItem from "../../Common/AuditFormItem";
import {useNavigation} from "../../../hooks/useNavigation";
import Address from "../../Common/Address";

const prepareFormData = (item) => {
    const formData = {
        ...item,
        GroupTags: item.GroupTags.map((t) => t.AccountTagId.toString()),
        RoleTags: item.RoleTags.map((t) => t.AccountTagId.toString()),
    };
    return formData;
};

const Website = ({website}) => website ? (<div className="view-edit-list">
    <Row align="middle">
        <Col span={16} offset={8}>
            <a href={website.Url} target="_blank" rel="noopener noreferrer">
                {website.Url}
            </a>
        </Col>
    </Row>
</div>) : (false);

const ContactInfo = ({contact}) => (<>
    {contact.Type === ApiContactType.Organization && contact.Websites.length > 0 && (<Row>
        <Col span={8} className="view-row-item-type">
            <div className="ant-form-item-without-validation">Address</div>
        </Col>

        <Col span={16}>
            <div className="ant-form-item-without-validation">
                <Website website={contact.Websites?.[0]}></Website>
            </div>
        </Col>
    </Row>)}

    {contact.Type === ApiContactType.Place && contact.Addresses.length > 0 && (<Row>
        <Col span={24} className="contact-block">
            <Address address={contact.Addresses[0]} span={16} offset={8}></Address>
        </Col>
    </Row>)}
    {contact.Type === ApiContactType.Person && contact.Parent && (
        <Form.Item label="Organization" className="ant-form-item-without-validation">
            <ShopOutlined/> {formatContactName(contact.Parent)}
        </Form.Item>)}

    <Row>
        <Col span={24} className="contact-block">
            <EmailList value={contact.EmailAddresses} readOnly={true}></EmailList>
        </Col>
    </Row>
    <Row>
        <Col span={24} className="contact-block">
            <PhoneList value={contact.PhoneNumbers} readOnly={true}></PhoneList>
        </Col>
    </Row>
</>);

const contactLabels = {
    [SidePanelItemType.TeamPerson]: "Person",
    [SidePanelItemType.TeamOrganization]: "Organization",
    [SidePanelItemType.TeamLocation]: "Location",
};
const newContactType = {
    [SidePanelItemType.TeamPerson]: ApiContactType.Person,
    [SidePanelItemType.TeamOrganization]: ApiContactType.Organization,
    [SidePanelItemType.TeamLocation]: ApiContactType.Place,
};
const quickCreateButtonText = {
    [SidePanelItemType.TeamPerson]: "Create Person",
    [SidePanelItemType.TeamOrganization]: "Create Organization",
    [SidePanelItemType.TeamLocation]: "Create Location",
};

const prepareUpdateContactPayload = (contact) => {
    const contactPayload = contact ? {...contact} : null;
    if (contactPayload) {
        contactPayload.ContactType = contact.Type;
        contactPayload.Groups = contact.Groups.map((x) => x.AccountTagId);
        contactPayload.Specialties = contact.Specialties.map((x) => x.AccountTagId);
        delete contactPayload.Type;
    }
    return contactPayload;
};

const TeamMemberDetailsPanel = ({type}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [currentUserState, setCurrentUserState] = useState(NewRecordState.Empty);
    const [isQuickCreateVisible, setIsQuickCreateVisible] = useState(false);
    const [isQuickCreateSpaceVisible, setIsQuickCreateSpaceVisible] = useState(false);
    const [createdContactId, setCreatedContactId] = useState(null);
    const [contactSpaces, setContactSpaces] = useState([]);
    const [quickCreatedSpaces, setQuickCreatedSpaces] = useState([]);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const activeProject = useSelector((state) => state.projects.activeProject);

    const allContacts = useSelector((state) => state.contacts.contacts);
    const people = allContacts.filter((c) => c.Type === ApiContactType.Person);
    const places = allContacts.filter((c) => c.Type === ApiContactType.Place);
    const organizations = allContacts.filter((c) => c.Type === ApiContactType.Organization);
    let contacts = [];

    const projectRoles = useSelector((state) => state.tags.projectRoles[activeProject?.Id] || []);
    const roles = useSelector((state) => state.tags.roles).filter((t) => projectRoles.map((x) => x.AccountTagId).indexOf(t.AccountTagId) === -1);
    const projectGroups = useSelector((state) => state.tags.projectGroups[activeProject?.Id] || []);
    const groups = useSelector((state) => state.tags.groups).filter((t) => projectGroups.map((x) => x.AccountTagId).indexOf(t.AccountTagId) === -1);

    const itemToEdit = useSelector((state) => state.detailsPanel.item);

    const teamContactType = useSelector((state) => state.detailsPanel.type);
    const allSpaces = useSelector((state) => state.team.project[activeProject?.Id] || []).filter((t) => t.Contact?.Type === ApiContactType.Space);

    const {goToContact} = useNavigation();

    let relatedSpaces = [];

    switch (teamContactType) {
        case TeamContactType.Person:
            contacts = people.map((c) => ({
                value: c.Id, label: `${c.Nickname || c.FirstName || ""} ${c.LastName || ""}`.trim(),
            }));
            break;
        case TeamContactType.Organization:
            contacts = organizations.map((c) => ({
                value: c.Id, label: c.Company,
            }));
            break;
        case TeamContactType.Location:
            contacts = places.map((c) => ({
                value: c.Id, label: c.Company,
            }));
            if (itemToEdit?.Contact?.Id) {
                relatedSpaces = allSpaces.filter((s) => s.Contact.Parent?.Id === itemToEdit.Contact.Id);
            }
            break;
        default:
            break;
    }

    useEffect(() => {
        if (!activePortfolio) {
            return;
        }

        loadContacts(activePortfolio.Id);
    }, [activePortfolio]);

    const getSpacesHash = (spaces) => spaces.map((s) => `${s.Id}${s.Contact?.Id}`).join("");
    useEffect(() => {
        if (!itemToEdit) return;
        setCurrentUserState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        formData.Spaces = [...relatedSpaces, ...quickCreatedSpaces];

        form.setFieldsValue(formData);
    }, [itemToEdit, getSpacesHash([...relatedSpaces, ...quickCreatedSpaces])]);

    const loadTeamSpaces = useCallback(() => {
        if (!itemToEdit?.Contact) return;
        return getChildren(itemToEdit.Contact?.Id).then((children) => {
            setContactSpaces(children);
            return children;
        });
    }, [itemToEdit?.Contact?.Id]);

    useEffect(() => {
        if (!itemToEdit || teamContactType !== TeamContactType.Location) return;

        loadTeamSpaces();
    }, [itemToEdit, teamContactType, loadTeamSpaces]);

    const prepareAndCreate = useCallback((finishedForm) => {
        const contact = allContacts.find((c) => c.Id === finishedForm.ContactId);
        const contactPayload = prepareUpdateContactPayload(contact);
        const RoleTags = contact ? contact.Specialties.map((x) => x.AccountTagId) : finishedForm.RoleTags;
        const GroupTags = contact ? contact.Groups.map((x) => x.AccountTagId) : finishedForm.GroupTags;

        const teamMemberPayload = {
            Contact: contactPayload, RoleTags, GroupTags,
        };

        createTeamMember(activeProject.Id, teamMemberPayload)
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                throw new Error(errorText);
            })
            .then((createTeamMemberResponse) => {
                return Promise.all([loadTeam(activeProject.Id), getTeamMember(createTeamMemberResponse.Id).then((teamMember) => {
                    dispatch(continueEdit({item: teamMember}));
                    setCurrentUserState(NewRecordState.ReadyForEdit);
                    message.success(`team member was created`);
                }),]);
            })
            .catch((err) => {
                console.error(err);
                setCurrentUserState(NewRecordState.Empty);
            });

        setCurrentUserState(NewRecordState.RequiredFieldsSubmitted);
    }, [activeProject?.Id, allContacts, dispatch]);

    const prepareAndUpdate = useCallback((finishedForm) => {
        const contact = allContacts.find((c) => c.Id === finishedForm.ContactId);
        const contactPayload = prepareUpdateContactPayload(contact);

        const updatePayload = {
            RoleTags: finishedForm.RoleTags?.map((t) => parseInt(t, 10)) || [],
            GroupTags: finishedForm.GroupTags?.map((t) => parseInt(t, 10)) || [],
            Id: itemToEdit.Id,
            Contact: contactPayload,
            CurrentContactPermissionLevel: null,
        };

        updateTeamMember(updatePayload)
            .then(() => {
                getTeamMember(updatePayload.Id).then((tm) => {
                    dispatch(continueEdit({item: tm}));
                });
            })
            .then(() => loadTeam(activeProject.Id))
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                console.error(err);
            });
    }, [activeProject?.Id, itemToEdit?.Id, allContacts, dispatch]);

    const onFinish = useCallback((finishedForm) => {
        if (currentUserState === NewRecordState.Empty) {
            prepareAndCreate(finishedForm);
        }

        if (currentUserState === NewRecordState.ReadyForEdit) {
            prepareAndUpdate(finishedForm);
        }
    }, [currentUserState, prepareAndCreate, prepareAndUpdate]);

    const onContactCreated = async (contactId) => {
        form.setFieldsValue({ContactId: contactId});
        setCreatedContactId(contactId);
    };

    const onSpaceCreated = async (contactId) => {
        loadTeamSpaces().then((spaces) => {
            const space = spaces.find((s) => s.Id === contactId);
            setQuickCreatedSpaces([...quickCreatedSpaces, {Contact: space}]);
        });
    };

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    const onSpaceCanceled = () => {
        setQuickCreatedSpaces([]);
    };

    const onSpacesChanged = async () => {
        const teamMemberSpaces = form.getFieldValue("Spaces");
        const spaceTeamMemberIds = teamMemberSpaces.map((s) => s.Id);
        const newSpaces = teamMemberSpaces.filter((s) => !s.Id);
        const deletedSpaces = relatedSpaces.filter((s) => !spaceTeamMemberIds.includes(s.Id));
        const updatedSpaces = teamMemberSpaces.filter((s) => relatedSpaces.find((rs) => rs.Id === s.Id && rs.Contact.Id !== s.Contact.Id));

        // update team member
        try {
            await PromiseBluebird.map(newSpaces, (space) => createTeamMember(activeProject?.Id, {
                Contact: space.Contact,
            }), {concurrency: 1});

            if (deletedSpaces.length > 0) {
                await batchDelete(deletedSpaces);
            }

            await PromiseBluebird.map(updatedSpaces, (space) => updateTeamMember({
                Id: space.Id,
                Contact: prepareUpdateContactPayload(space.Contact)
            }), {
                concurrency: 1,
            });
        } catch (e) {
            message.error("Server Error");
        }

        try {
            await Promise.all([loadTeam(activeProject.Id), getTeamMember(itemToEdit.Id)]);
        } catch (e) {
            message.error("Server Error");
        }
        setQuickCreatedSpaces([]);
    };

    useEffect(() => {
        if (createdContactId === null) return;

        onContactChanged(createdContactId);
        setCreatedContactId(null);
        save();
    }, [createdContactId, save]);

    const onContactChanged = (value) => {
        const cancel = () => {
            form.setFieldsValue({ContactId: itemToEdit?.Contact?.Id});
            save();
        };

        if (value) {
            if (itemToEdit?.Contact?.Id) {
                const targetContact = contacts.find((c) => c.value === value);
                swapContact(itemToEdit?.Contact, targetContact.label, activeProject.Name, save, cancel);
            } else {
                save();
            }
        } else {
            removeContact(itemToEdit?.Contact, activeProject.Name, save, cancel);
        }
    };

    return (<div className="side-panel">
        <Form
            form={form}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            layout="horizontal"
            size="middle"
            className="team-member-details">
            <Divider orientation="left" className="divider-first">
                Team Member Info
            </Divider>
            <SelectWithFormItem
                allowClear={true}
                label={contactLabels[type]}
                name="ContactId"
                showSearch={true}
                style={{width: "100%"}}
                placeholder="Select a person"
                options={contacts}
                onChanged={onContactChanged}
                dropdownRender={(menu) => (<>
                    {menu}
                    <div className="btn-quick-create">
                        <Button type="dashed" onClick={() => setIsQuickCreateVisible(true)}>
                            {quickCreateButtonText[type]}
                        </Button>
                    </div>
                </>)}
            />
            {teamContactType === TeamContactType.Location && (
                <Form.Item name="Spaces" label="Spaces" className="ant-form-item-without-validation">
                    <SpaceList
                        contactId={form.getFieldValue("ContactId")}
                        onCancel={onSpaceCanceled}
                        onChanged={onSpacesChanged}
                        spaces={contactSpaces}
                        project={activeProject}
                        onCreateSpaceClick={() => setIsQuickCreateSpaceVisible(true)}></SpaceList>
                </Form.Item>)}
            <TagsSelect
                tagType={TagType.Role}
                label="Project Roles"
                name="RoleTags"
                placeholder="Select project roles"
                groups={[geTagsGroup("Project Roles", projectRoles), geTagsGroup("All Roles", roles)]}
                save={save}
                form={form}
            />

            {teamContactType !== TeamContactType.Location && (<TagsSelect
                tagType={TagType.Group}
                label="Project Groups"
                name="GroupTags"
                placeholder="Select project groups"
                groups={[geTagsGroup("Project Groups", projectGroups), geTagsGroup("All Groups", groups)]}
                save={save}
                form={form}
            />)}
            {activeProject && (<Form.Item label="Project" className="ant-form-item-without-validation">
                <Tag className="project_tag" color={activeProject.Color}>
                    {activeProject.Name}
                </Tag>
            </Form.Item>)}
            {itemToEdit && (<Form.Item label="Unique ID" className="ant-form-item-without-validation">
                {`Team Member ${itemToEdit.Id}`}
            </Form.Item>)}
            <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>
            <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="UpdatedBy"/>

            <Divider orientation="left">Contact Info</Divider>
            {!itemToEdit?.Contact &&
                <div className="no-contact">No Contact has been associated with this team member</div>}
            {itemToEdit?.Contact && (<>
                <ContactInfo contact={itemToEdit.Contact}></ContactInfo>
                <Row>
                    <Col span={16} offset={8}>
                        <Button type="primary" onClick={() => goToContact(itemToEdit.Contact)}>
                            Go To Contact Record
                        </Button>
                    </Col>
                </Row>
            </>)}
        </Form>

        <CreateContact
            isVisible={isQuickCreateVisible}
            apiContactType={newContactType[type]}
            onContactCreated={onContactCreated}
            onClose={() => setIsQuickCreateVisible(false)}
        />

        <CreateContact
            places={contacts}
            parentContactId={form.getFieldValue("ContactId")}
            isVisible={isQuickCreateSpaceVisible}
            apiContactType={ApiContactType.Space}
            onContactCreated={onSpaceCreated}
            onClose={() => setIsQuickCreateSpaceVisible(false)}
        />
    </div>);
};

export default TeamMemberDetailsPanel;
