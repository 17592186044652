import React from "react";
import {EyeFilled} from "@ant-design/icons";

const ViewAction = ({viewUrl}) => (
    <a href={viewUrl} onClick={(e) => e.stopPropagation()} target="blank" rel="noopener, noreferrer">
        <EyeFilled className="view btn-table-actions"/>
    </a>
);

export default ViewAction;
