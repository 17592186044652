import api from "../api";
import store from "../redux/store";
import {setTeam, setTeams} from "../redux/reducers/team";
import {TagType} from "../constants";
import {loadTags} from "./tags";
import moment from "moment";

const {dispatch} = store;

const teamDefaultFilter =
    {
        Keyword: "",
        Types: [0, 2, 3, 1],
        OnlyWithEmailAddress: false,
        OnlyWithoutParent: true,
        IncludeEmailAddresses: true,
        IncludeContactAddresses: true,
        RoleTags: [],
        GroupTags: []
    }

export const loadTeam = (projectId = "", filter = {}) =>
    api
        .post(`api/ProjectTeam/GetPaged?projectId=${projectId}`, {
            Filter: {...teamDefaultFilter, ...filter},
        })
        .then((team) => {
            dispatch(setTeam({projectId, items: team.Items}));
            return team;
        });

export const loadTeams = (accountId, projectIds = [], filter = {}) =>
    api
        .post(`api/ProjectTeam/GetByProjects?accountId=${accountId}`, {
                OrderBy: 0, Filter: {Types: [0, 1, 2, 3], Include: 1, ExcludeInDeliverables: true, Keyword: ""},
                ProjectIds: projectIds
            }
        )
        .then((response) => {
            dispatch(setTeams(response.Items));
        });


export const refreshTeam = () => {
    const projectId = store.getState().projects.activeProject.Id
    loadTeam(projectId)
}

export const createTeamMember = (projectId = "", contactPayload) =>
    api.post(`api/ProjectTeam/Create?projectId=${projectId}`, contactPayload)
        .then((tm) => {
            loadTags();
            return tm
        });

export const createTeamMembers = (payload) =>
    api.post(`api/ProjectTeam/BatchCreate`, payload)


export const updateTeamMember = (teamMemberPayload) =>
    api.post(`api/ProjectTeam/Update`, teamMemberPayload).then((tm) => {
        loadTags();
        return tm
    });

export const getTeamMember = (id) => api.get(`api/ProjectTeam/Get?id=${id}&includeFlags=1981`);

export const batchUpdate = (contactIds, rolesData, groupsData) => {
    const payload = {
        Versions: contactIds.map(id => ({Id: id})),
        UpdateRoleTags: rolesData.isUpdated,
        RoleTags: rolesData.tags,
        UpdateOnlyCommonRoleTags: rolesData.updateOnlyCommon,
        UpdateGroupTags: groupsData.isUpdated,
        GroupTags: groupsData.tags,
        UpdateOnlyCommonGroupTags: groupsData.updateOnlyCommon
    };

    return api.post(`api/ProjectTeam/BatchUpdate`, payload)
        .then(() => Promise.all([refreshTeam(), loadTags()]))
}

export const batchDelete = (teamMembers) => {
    const removePayload = teamMembers.map(c => ({Id: c.Id}));
    return api.post(`api/ProjectTeam/BatchRemove`, removePayload)
        .then(loadTags)
}

export const getByTags = (projectIds = []) => {
    const accountId = store.getState().projects.activePortfolio.Id
    const payload = {
        ProjectIds: projectIds,
        AccountId: accountId,
        TagTypes: [TagType.Group],
        IncludeProjects: true,
        Contact: {
            Skip: 0, Take: 1, OrderBy: 0, Filter:
                {
                    Types: [],
                    Include: 1,
                    ExcludeInDeliverables: true,
                    OnlyWithEmailAddress: true,
                    Keyword: ""
                },
            SuppressFullLoading: true,
            CheckIsTeamMemberProjectId: 1
        },
        "Keyword": ""
    }
    return api.post(`api/ProjectTeam/GetByTags`, payload)
}

export const downloadTeamMembersAsCsv = (activeProject, types = 15, keywords = '', teamMemberIds = []) => {

    const timestamp = encodeURIComponent(moment().format("yyyy-MM-DDTHH-mm-ss"));
    const fileName = `${activeProject.Name}_Team_${timestamp}.csv`;

    const data = {
        projectId: activeProject.Id,
        types,
        keywords,
        teamMemberIds
    };

    api.fetchFile("api/ProjectTeam/DownloadTeamMembersAsCsv", fileName, data);
}

export const sendEmail = (payload) =>
    api.post(`api/ProjectTeam/SendEmail?`, payload);
