import React, {useEffect, useState} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import {ApiFilterLayoutType} from "../../../../constants/constants";

export const scheduleGroupBy = [
    {
        value: ApiFilterLayoutType.GroupedByDay,
        title: "Day",
    },
    {
        value: ApiFilterLayoutType.GroupedByLocation,
        title: "Location",
    },
    {
        value: ApiFilterLayoutType.GroupedByProject,
        title: "Project",
    },
];

export const teamGroupBy = [
    {
        value: "Contacts",
        title: "Contacts",
    },
    {
        value: "Project Groups",
        title: "Project Groups",
    },
    {
        value: "Organizations",
        title: "Organizations",
    },
];

const GroupBy = ({value, items, disabled, onChange, onChanged}) => {
    const defaultItem = items[0];

    const [selectedItem, setSelectedItem] = useState(defaultItem);

    useEffect(() => {
        if (value != null) setSelectedItem(items.find((i) => i.value === value));
    }, [value]);

    function onSelected(selectedItem) {
        setSelectedItem(selectedItem);
        onChange(selectedItem.value);
        onChanged();
        //setFilterHandler((item) => selectedItem.value === allTime.value || moment(item.UpdatedAt) >= selectedItem.date);
    }

    return (
        <SingleItemFilter disabled={disabled} selectedItem={selectedItem} items={items} onChanged={onSelected}
                          trigger="click"/>
    );
};

export default GroupBy;
