import React from "react";
import {Form} from "antd";
import _ from "lodash";
import ImageUpload from "../../../Common/ImageUpload/ImageUpload";
import {InputWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";
import {geTagsGroup} from "../../../../helpers/Tags";
import {formatInventory} from "../../../../helpers/text";
import AuditFormItem from "../../../Common/AuditFormItem";
import {useSelector} from "react-redux";
import {TagType} from "../../../../constants/constants";
import TagsSelect from "../../../Common/Selects/TagsSelect";

const InformationBlock = ({form, itemToEdit, save, isOptionalFieldDisabled, isReadOnly}) => {
    const departments = useSelector((state) => state.tags.departments || []);
    const sources = itemToEdit?.Sources || [];

    const collections =
        itemToEdit?.Collections &&
        _.join(
            _.sortBy(
                itemToEdit?.Collections.map((c) => c.Name),
                (c) => c.Id
            ),
            ", "
        );

    const onChangeDepartment = () => {
        const list = form.getFieldValue("DepartmentTagId");
        const tag = list.pop();
        form.setFieldsValue({DepartmentTagId: tag ? parseInt(tag, 10) : null});

        save();
    };

    const onChangeImage = async (base64) => {
        const values = {ImageUrl: base64};
        const item = {...itemToEdit};
        if (values.ImageUrl && values.ImageUrl.indexOf("data:image") !== -1) {
            values.Photo = {
                IsChanged: true,
                Photo: values.ImageUrl.substr(values.ImageUrl.indexOf(",") + 1),
            };
            values.ImageUrl = null;
        } else if (item.ImageUrl && values.ImageUrl === null) {
            values.Photo = {
                IsChanged: true,
                Photo: null,
            };
            item.ImageUrl = null;
            item.Photo = {...values.Photo};
        }

        await form.setFieldsValue(values);
    };

    return (
        <>
            <InputWithFormItem
                name="Name"
                label={"Item"}
                hasFeedback
                rules={[{required: true, message: "Name"}]}
                onChanged={save}
                placeholder="Enter an item name"
                disabled={isReadOnly}
            />
            <TagsSelect
                tagType={TagType.Department}
                disabled={isOptionalFieldDisabled}
                label={"Department"}
                name={"DepartmentTagId"}
                placeholder="Select a department"
                save={onChangeDepartment}
                form={form}
                getValueProps={(tagId) => ({value: tagId ? [tagId.toString()] : []})}
                groups={[geTagsGroup("All Departments", departments)]}
            />

            <TextAreaWithFormItem
                disabled={isOptionalFieldDisabled}
                name="Description"
                label={"Details"}
                placeholder="Enter details"
                autoSize={{minRows: 2}}
                onChanged={save}
            />

            <Form.Item
                disabled={isOptionalFieldDisabled}
                hidden={isReadOnly}
                label={"Photo"}
                name="ImageUrl"
                className="ant-form-item-without-validation">
                <ImageUpload onChange={onChangeImage} onChanged={save}/>
            </Form.Item>

            <InputWithFormItem name="Photo" disabled={isReadOnly} type="hidden"/>

            <Form.Item hidden={isReadOnly} label={"Total Inventory"} className="ant-form-item-without-validation">
                {formatInventory(sources)}
            </Form.Item>
            <Form.Item hidden={!collections || isReadOnly} label={"Collections"}
                       className="ant-form-item-without-validation">
                {collections}
            </Form.Item>
            <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="Creator"/>

            <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="Updater"/>
        </>
    );
};

export default InformationBlock;
