import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {ApiContactType} from "../../../../constants/constants";
import {formatContactName} from "../../../../helpers/text";
import TreeSelectFilter from "./TreeSelectFilter";

export const selectDefaultOption = {value: "— All Locations —", title: "— All Locations —"};
export const selectAllProjectDefaultOption = {value: "— All Project Locations —", title: "— All Project Locations —"};

const ProjectLocationsKey = "Project Locations";
const AllOtherLocationsKey = "All Other Locations";

const teamPlacesToOptions = (places, spaces, teamSpaces, value = []) => {
    return _.chain(places)
        .uniqBy((p) => p.ContactId)
        .map((p) => ({
            value: p.Id,
            title: formatContactName(p.Contact),
            key: p.Id,
            children: spaces
                .filter((s) => s.ParentId === p.ContactId)
                .map((s) => {
                    const team = value.find((t) => t.ContactId === s.Id) || teamSpaces.find((t) => t.ContactId === s.Id);
                    const id = team ? team.Id : `contact:${s.Id}`;
                    return {
                        value: id,
                        key: id,
                        title: formatContactName(s),
                    };
                }),
        }))
        .sortBy("title")
        .value();
};

const contactPlacesToOptions = (places, spaces) => {
    return places.map((p) => ({
        value: `contact:${p.Id}`,
        title: formatContactName(p),
        key: `contact:${p.Id}`,
        children: spaces
            .filter((s) => s.ParentId === p.Id)
            .map((s) => ({value: `contact:${s.Id}`, key: `contact:${s.Id}`, title: formatContactName(s)})),
    }));
};

const sort = (items) => _.sortBy(items, (i) => i);

const Locations = ({
                       value = {},
                       setSelectedLocationCount,
                       setFilterHandler,
                       onChange,
                       onChanged,
                       projectIds,
                       selectProjectsLocations,
                       disabled,
                   }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const {teamMembers = [], contacts = []} = value;

    useEffect(() => {
        if (teamMembers.length > 0 || contacts.length > 0)
            setSelectedItems(sort([...teamMembers.map((v) => v.Id), ...contacts.map((v) => `contact:${v.Id}`)]));
    }, [teamMembers, contacts]);

    const allContacts = useSelector((state) => state.contacts.contacts);
    const spaces = allContacts.filter((c) => c.Type === ApiContactType.Space).filter((s) => s.ParentId != null);
    const places = allContacts.filter((c) => c.Type === ApiContactType.Place);

    const teamLocations = useSelector((state) => state.team.teams).filter((t) => t.Contact?.Type === ApiContactType.Place);
    const teamSpaces = useSelector((state) => state.team.teams).filter((t) => t.Contact?.Type === ApiContactType.Space);

    const teamPlaces = teamLocations.filter((t) => projectIds.includes(t.ProjectId));
    const teamPlacesContactIds = teamPlaces.map((l) => l.ContactId);
    const otherPlaces = places.filter((l) => {
        return !(teamPlacesContactIds.includes(l.Id) && !contacts.map((c) => c.ParentId).includes(l.Id));
    });

    const projectLocations = teamPlacesToOptions(teamPlaces, spaces, teamSpaces, teamMembers);
    const otherLocations = contactPlacesToOptions(otherPlaces, spaces);

    const projectLocationsOption = {
        title: ProjectLocationsKey,
        value: ProjectLocationsKey,
        key: ProjectLocationsKey,
        filterOff: true,
        children: projectLocations,
    };

    const allOtherLocationsOption = {
        title: AllOtherLocationsKey,
        value: AllOtherLocationsKey,
        key: AllOtherLocationsKey,
        filterOff: true,
        children: otherLocations,
    };

    const groups = [projectLocationsOption];

    if (otherLocations.length > 0) {
        groups.push(allOtherLocationsOption);
    }

    function initFilterHandler(filterValues) {
        if (filterValues.length === 0) {
            setFilterHandler(null);
        } else {
            setFilterHandler((item) => filterValues.includes(item.Location?.ContactId));
        }
    }

    function onItemChanged(filterValues, a) {
        initFilterHandler(filterValues);
        setSelectedItems(sort(filterValues));
        onChange({
            ...value,
            teamMembers: filterValues.filter((i) => _.isNumber(i)).map((i) => ({Id: i})),
            contacts: filterValues
                .filter((i) => _.isString(i) && i.indexOf("contact:") !== -1)
                .map((i) => ({Id: parseInt(i.split(":")[1], 10)})),
        });
        onChanged();
    }

    useEffect(() => {
        if (setSelectedLocationCount == null) return;

        let subItemsCount = [...projectLocationsOption.children, ...allOtherLocationsOption.children].filter((l) => {
            return (
                _.intersection(
                    l.children.map((c) => c.value),
                    selectedItems
                ).length > 0
            );
        }).length;

        if (!disabled && selectedItems.length === 0) {
            subItemsCount = projectLocations.length;
        }

        setSelectedLocationCount(subItemsCount);
    }, [selectedItems, setSelectedLocationCount, projectLocationsOption.children]);

    useEffect(() => initFilterHandler(teamMembers), [teamMembers]);

    return (
        <>
            {selectProjectsLocations && (
                <TreeSelectFilter
                    treeDefaultExpandedKeys={[projectLocationsOption.value]}
                    value={selectedItems}
                    disabled={disabled}
                    treeData={groups}
                    placeholder="All Project Locations"
                    onChanged={onItemChanged}
                    selectDefaultOption={selectAllProjectDefaultOption}
                    onSelectAllChange={() => {
                    }}
                    onResetCompleted={() => {
                    }}></TreeSelectFilter>
            )}
            {!selectProjectsLocations && (
                <TreeSelectFilter
                    treeDefaultExpandedKeys={[projectLocationsOption.value]}
                    value={selectedItems}
                    disabled={disabled}
                    treeData={groups}
                    placeholder="All Locations"
                    onChanged={onItemChanged}
                    selectDefaultOption={selectDefaultOption}
                    onSelectAllChange={() => {
                    }}
                    onResetCompleted={() => {
                    }}></TreeSelectFilter>
            )}
        </>
    );
};

export default Locations;
