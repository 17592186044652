import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import _ from "lodash";

import {Tabs} from "antd";
import {BulbFilled, ToolOutlined} from "@ant-design/icons";

import {RequirementType} from "../../constants";
import {requirementsPath} from "../../helpers/paths";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";
import {loadRequirements} from "../../services/requirements";
import {loadLabourLines} from "../../services/labour";
import {loadPositions} from "../../services/positions";
import {loadContacts} from "../../services/contacts";
import RequirementsTable from "./RequirementItems/RequirementsTable";
import moment from "moment";
import Labour from "./Labour/LabourTable";

const {TabPane} = Tabs;

const tabs = [
    {
        key: RequirementType.Item,
        path: requirementsPath[RequirementType.Item],
    },
    {
        key: RequirementType.Labour,
        path: requirementsPath[RequirementType.Labour],
    },
];

const getTabByKey = (key) => tabs.find((tab) => tab.key === key);

const Requirements = ({activeKey}) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    //const format = useDateTimeFormat();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const rawItems = useSelector((state) => state.requirements.requirements || []);

    const orderByDate = (m) => (!m.Requirement.StartDateTime ? -1 : moment(m.Requirement.StartDateTime).valueOf());
    const orderByProject = (m) => m.Project.Name;
    const orderByName = (m) => m.Requirement.Name;

    const items = _.orderBy(rawItems, [orderByDate, orderByProject, orderByName], ["asc", "asc", "asc"]);

    const urlWithPortfolioId = useUrlWithPortfolioId();

    const labourLines = useSelector((state) => state.labour.lines);

    useEffect(() => {
        if (!activePortfolioId) return;

        switch (activeKey) {
            case RequirementType.Item:
                loadContacts(activePortfolioId).then(() => loadRequirements(activePortfolioId));
                break;
            case RequirementType.Labour:
                setLoading(true);
                Promise.all([loadLabourLines(activePortfolioId), loadPositions(activePortfolioId)]).finally(() => setLoading(false));

                break;
            default:
                break;
        }
    }, [activePortfolioId]);

    const changeTab = (key) => {
        const path = getTabByKey(key).path;
        history.push(urlWithPortfolioId(path));
    };

    return (
        <Tabs onChange={changeTab} type="card" activeKey={activeKey}>
            <TabPane
                tab={
                    <span>
            <BulbFilled/>
            Items
          </span>
                }
                key={RequirementType.Item}>
                <RequirementsTable items={items}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <ToolOutlined/>
            Labour
          </span>
                }
                key={RequirementType.Labour}>
                <Labour loading={loading} items={labourLines}></Labour>
            </TabPane>
        </Tabs>
    );
};

export default Requirements;
