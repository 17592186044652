import React from "react";
import RoleFilter from "./RoleFilter";
import GroupFilter from "./GroupFilter";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import SearchFilter from "./SearchFilter";
import GroupBy from "./GroupBy";
import FilterBar from "../../Common/Tables/FilterBar";

const ContactsFilterBar = (props) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[RoleFilter, GroupFilter, ChangedAtFilter, SearchFilter]}
            GroupByComponent={GroupBy}
        />
    );
};

export default ContactsFilterBar;
