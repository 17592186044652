import React from "react";
import {SwapOutlined} from "@ant-design/icons";
import {Popover} from "antd";

const ShiftEventAction = ({onClick, record, title = "Shift Event"}) => (
    <Popover trigger="hover" content={<div>{title}</div>}>
        <SwapOutlined
            className="btn-table-actions"
            onClick={(e) => {
                if (onClick) {
                    e.stopPropagation();
                    onClick(record);
                }
            }}
        />
    </Popover>
);

export default ShiftEventAction;
