import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Modal, Button} from "antd";
import "./BatchDelete.scss";

export const doBatchDelete = ({
                                  onFinish,
                                  content = "You cannot undo this action",
                                  title,
                                  batchRemove,
                                  onFinishMessage,
                                  okText,
                              }) =>
    Modal.confirm({
        title,
        icon: <DeleteOutlined/>,
        content,
        className: "delete-modal",
        okText,
        okButtonProps: {danger: true},
        async onOk() {
            if (batchRemove) await batchRemove();
            if (onFinish) await onFinish();
            if (onFinishMessage) onFinishMessage();
        },
        onCancel() {
        },
    });

const BatchDelete = ({
                         disabled = false,
                         selected,
                         onFinish,
                         content = "You cannot undo this action",
                         title,
                         buttonText = "Delete",
                         batchRemove,
                         onFinishMessage,
                         okText = "Delete",
                     }) => {
    return (
        <Button
            disabled={disabled || selected.length === 0}
            danger
            onClick={(e) => {
                e.stopPropagation();
                doBatchDelete({selected, onFinish, content, title, batchRemove, onFinishMessage, okText});
            }}>
            {buttonText}
        </Button>
    );
};

export default BatchDelete;
