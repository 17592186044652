import React from 'react';
import {Row, Col} from 'antd';

const Address = ({address, span, offset}) => {
    return address ? (<div className="view-edit-list">
        <Row align="middle">
            <Col span={span} offset={offset}>
                {(address.Line1 || "").split("\n").map((text, key) => (<div key={key}>{text}</div>))}
            </Col>
        </Row>
    </div>) : false;
};

export default Address;
