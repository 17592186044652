import {createSlice} from "@reduxjs/toolkit";

const reportsSlice = createSlice({
    name: "reports",
    initialState: {items: [], templates: [], notes: [], notesFilterIsDone: false},
    reducers: {
        setReports(state, action) {
            state.items = action.payload;
        },

        setReportTemplates(state, action) {
            state.templates = action.payload;
        },

        setNotes(state, action) {
            state.notes = action.payload;
        },

        setNotesFilterIsDone(state, action) {
            state.notesFilterIsDone = action.payload;
        },
    },
});

export const {setReports, setReportTemplates, setNotes, setNotesFilterIsDone} = reportsSlice.actions;

export default reportsSlice.reducer;
