import React from "react";
// import ArchiveFilter from "./ArchiveFilter";
import StateFilter from "./StateFilter/StateFilter";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import ManagersFilter from "./ManagersFilter/ManagersFilter";

import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import SearchFilter from "./SearchFilter/SearchFilter";
import GroupBy from "./GroupBy/GroupBy";
import DateFilter from "./DateFilter/DateFilter";
import FilterBar from "../../Common/Tables/FilterBar";

const ProjectsFilterBar = ({isActiveProjectsTab, ...props}) => {
    const filters = [DateFilter, CategoryFilter, ManagersFilter, ChangedAtFilter, SearchFilter];
    if (isActiveProjectsTab) {
        filters.unshift(StateFilter);
    }
    return <FilterBar {...props} isActiveProjectsTab={isActiveProjectsTab} filterComponents={filters}
                      GroupByComponent={GroupBy}/>;
};

export default ProjectsFilterBar;
