import React, {useState} from "react";
import {Form} from "antd";
import {InputWithFormItem, InputWithFormItemWithRef, TextAreaWithFormItem} from "../../Common/Input";
import EmailList from "../../Common/ViewEditList/EmailList/EmailList";
import PhoneList from "../../Common/ViewEditList/PhoneList/PhoneList";
import {ApiContactType, TagType} from "../../../constants/constants";
import TagsSelect from "../../Common/Selects/TagsSelect";
import ContactSelect from "../../Common/Selects/ContactSelect";
import CreateContact from "../../Common/QuickCreate/CreateContact";

const PersonInputs = ({
                          save,
                          firstInputRef,
                          nextInputRef,
                          isOptionalFieldDisabled,
                          placeholderForFirstName,
                          roles,
                          groups,
                          contactToEdit,
                          isReadOnly,
                          showOrganizationField,
                          form,
                      }) => {
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    return (
        <>
            <InputWithFormItemWithRef
                ref={firstInputRef}
                name="FirstName"
                label="First Name"
                hasFeedback
                rules={[{required: true, message: "Please input your First Name"}]}
                onChanged={save}
                placeholder={placeholderForFirstName}
                disabled={isReadOnly}
            />
            <InputWithFormItemWithRef
                name="LastName"
                label="Last Name"
                ref={nextInputRef}
                onChanged={save}
                disabled={isOptionalFieldDisabled}
                placeholder="Enter last name"
            />
            <InputWithFormItem
                name="Nickname"
                label="Preferred Name"
                onChanged={save}
                disabled={isOptionalFieldDisabled}
                placeholder="Enter preferred name"
            />
            <InputWithFormItem
                name="Pronouns"
                label="Pronouns"
                onChanged={save}
                disabled={isOptionalFieldDisabled}
                placeholder="Enter pronouns"
            />
            {showOrganizationField && (
                <Form.Item name={["ParentId"]} label={"Organization"} className="ant-form-item-without-validation">
                    <ContactSelect
                        organizationsOnly={true}
                        allowClear={true}
                        placeholder="Select an organization"
                        selectedContactId={form.getFieldValue("ParentId")}
                        onChange={(v) => {
                        }}
                        onChanged={save}
                        disabled={isOptionalFieldDisabled}
                        onOpenCreatePopup={() => setIsQuickCreateContactVisible(true)}
                        hideQuickCreate={false}
                    />
                </Form.Item>
            )}
            <TagsSelect
                tagType={TagType.Role}
                disabled={isOptionalFieldDisabled}
                label="Default Roles"
                name="Specialties"
                placeholder="Select default project roles"
                options={roles}
                save={save}
                form={form}
            />
            <TagsSelect
                tagType={TagType.Group}
                disabled={isOptionalFieldDisabled}
                label="Default Groups"
                name="Groups"
                placeholder="Select default project groups"
                options={groups}
                save={save}
                form={form}
            />

            <Form.Item name="EmailAddresses" wrapperCol={24} className="ant-form-item-without-validation">
                <EmailList readOnly={isReadOnly} onChanged={save} contactId={contactToEdit?.Id}></EmailList>
            </Form.Item>
            <Form.Item name="PhoneNumbers" wrapperCol={24} className="ant-form-item-without-validation">
                <PhoneList readOnly={isReadOnly} onChanged={save} contactId={contactToEdit?.Id}></PhoneList>
            </Form.Item>
            <Form.List name="Websites">
                {(fields) => {
                    return fields.map((field, i) => (
                        <InputWithFormItem
                            key={i}
                            name={[field.name, "Url"]}
                            label="Website"
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                            placeholder="Enter a website"
                        />
                    ));
                }}
            </Form.List>
            <Form.List name="Addresses">
                {(fields) => {
                    return fields.map((field, i) => (
                        <TextAreaWithFormItem
                            key={i}
                            name={[field.name, "Line1"]}
                            label="Address"
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                            autoSize={{minRows: 2}}
                            placeholder="Enter an address"
                        />
                    ));
                }}
            </Form.List>
            <TextAreaWithFormItem
                label="Internal Details"
                name="Notes"
                onChanged={save}
                disabled={isOptionalFieldDisabled}
                autoSize={{minRows: 2}}
                placeholder="Enter details"></TextAreaWithFormItem>

            <CreateContact
                form={form}
                apiContactType={ApiContactType.Organization}
                isVisible={isQuickCreateContactVisible}
                isEmailRequired={true}
                onClose={() => {
                    setIsQuickCreateContactVisible(false);
                }}
                onContactCreated={(contactId) => {
                    form.setFieldsValue({ParentId: contactId});
                    save();
                }}
                hideSpaces={true}
            />
        </>
    );
};

export default PersonInputs;
