import React, {useEffect, useState} from "react";

import AttachmentsTable from "./AttachmentsTable";
import {useSelector} from "react-redux";
import {loadAttachments} from "../../services/attachments";
// import ContactsTable, { peopleColumns, organizationsColumns, locationsColumns } from "./ContactsTable";

const Attachments = () => {
    const [loading, setLoading] = useState(false);
    const items = useSelector((state) => state.attachments.attachments);
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    useEffect(() => {
        if (!activePortfolioId) return;

        setLoading(true);
        loadAttachments(activePortfolioId).finally(() => setLoading(false));
    }, [activePortfolioId]);

    return <AttachmentsTable items={items} loading={loading}/>;
};

export default Attachments;
