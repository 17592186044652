import React from "react";
import SearchFilter from "./SearchFilter";
import {filterHandler as projectsFilter} from "./ProjectsMultiple/ProductionBooksProjectMultipleSelector";

import GroupBy from "./GroupBy/GroupBy";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter";
import {useSelector} from "react-redux";

const AttachmentsFilterBar = ({...props}) => {
    const selectedProjectsIds = useSelector((state) => state.projects.activeProjects).map((p) => p.Id);
    const activeProjectsExtra = useSelector((state) => state.projects.activeProjectsExtra);
    const projectKeys = [...activeProjectsExtra, ...selectedProjectsIds];

    const additionalFilters = [
        {
            handler: projectsFilter(projectKeys),
            key: projectKeys.join(","),
        },
    ];

    return (
        <FilterBar
            {...props}
            filterComponents={[ChangedAtFilter, SearchFilter]}
            additionalFilters={additionalFilters}
            GroupByComponent={GroupBy}
        />
    );
};

export default AttachmentsFilterBar;
