import axios from "axios";
import {API_BASE} from "../constants";
import {isRequestCanceled} from "../helpers/error";

const cancelTokens = new Map();

const handleCancelLogic = (cancelKey) => {
    if (!cancelKey) return null;

    const existedCancelToken = cancelTokens.get(cancelKey)
    if (existedCancelToken) {
        existedCancelToken.cancel();
    }

    const cancelTokenSource = axios.CancelToken.source();
    cancelTokens.set(cancelKey, cancelTokenSource);
    return cancelTokenSource.token;
}

export const post = (url, data, cancelKey = null) => {
    const token = handleCancelLogic(cancelKey);
    return new Promise((resolve, reject) => {
        axios
            .create({
                baseURL: API_BASE,
                withCredentials: true,
                cancelToken: token
            })
            .post(url, data)
            .then(({data}) => {
                if (cancelKey) cancelTokens.delete(cancelKey);
                resolve(data);
            })
            .catch(err => {
                if (cancelKey && !isRequestCanceled(err)) {
                    cancelTokens.delete(cancelKey);
                }
                reject(err)
            })
    });
};

export const get = (url, cancelKey = null) => {
    const token = handleCancelLogic(cancelKey);

    return new Promise((resolve, reject) => {
        axios
            .create({
                baseURL: API_BASE,
                withCredentials: true,
                cancelToken: token,
            })
            .get(url)
            .then(({data}) => {
                if (cancelKey) cancelTokens.delete(cancelKey);
                resolve(data);
            })
            .catch(err => {
                if (cancelKey && !isRequestCanceled(err)) {
                    cancelTokens.delete(cancelKey);
                }
                reject(err)
            })
    });

};

export const fetchFile = (url, name = "Data.csv", data = null) => {
    const method = !!data ? "POST" : "GET";
    const req = axios.create({
        baseURL: API_BASE,
        withCredentials: true,
        method: method,
        responseType: "blob" // important
    });

    let prom = method === "POST"
        ? req.post(url, data)
        : req.get(url);

    prom.then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        link.setAttribute("rel", "noopener noreferrer");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    });
}

export default {
    post,
    get,
    fetchFile
};
