import React, {useState, useEffect, useRef} from "react";
import {Modal, Form, Space, InputNumber} from "antd";
import {ToolOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {InputWithFormItem} from "../../../Common/Input";

import _ from "lodash";

import {createRuleset, loadRulesets, updateRuleset} from "../../../../services/rulesets";
import {formatToFixedFlex, trimEnd} from "../../../../helpers/text";

const AddRulesetModal = ({visible, onClose, itemToEdit}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const form = useRef();

    useEffect(() => {
        if (!activePortfolioId || !form?.current) return;

        if (itemToEdit) form.current.setFieldsValue(itemToEdit);
        else form.current.resetFields();
    }, [activePortfolioId, itemToEdit, form]);

    const onOk = async () => {
        const values = await form.current.validateFields();

        const payload = {
            Id: itemToEdit?.Id,
            AccountId: activePortfolioId,
            Name: values.Name,
            OvertimeMultiplier: values.OvertimeMultiplier,
            OvertimeStartAfter: values.OvertimeStartAfter,
        };

        setConfirmLoading(true);

        const apiAction = payload?.Id > 0 ? updateRuleset : createRuleset;

        apiAction(payload).then((response) => {
            loadRulesets(activePortfolioId).then(() => {
                setConfirmLoading(false);
                onCancel();
            });
        });
    };

    const onCancel = () => {
        form.current.resetFields();

        onClose();
    };

    return (
        <>
            <Modal
                title={
                    <>
                        <ToolOutlined/> &nbsp; Labour Cost Calculation Ruleset
                    </>
                }
                visible={visible}
                maskClosable={false}
                confirmLoading={confirmLoading}
                onOk={onOk}
                onCancel={onCancel}
                onClose={onClose}
                okText="Save"
                cancelText="Cancel"
                width={"560px"}>
                <Form
                    ref={form}
                    name="add-ruleset"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    layout="horizontal"
                    size="middle"
                    requiredMark={false}>
                    <InputWithFormItem
                        name={["Name"]}
                        label={"Ruleset Name"}
                        hasFeedback
                        rules={[{required: true, message: "Ruleset"}]}
                        placeholder="Enter a ruleset name"
                    />

                    <Form.Item label="Overtime Multiplier">
                        <Space>
                            <Form.Item name={["OvertimeMultiplier"]} noStyle
                                       rules={[{required: true, message: "Overtime Multiplier"}]}>
                                <InputNumber
                                    style={{width: 160}}
                                    parser={(v) => parseFloat(v) && parseFloat(v).toFixed(2)}
                                    formatter={formatToFixedFlex}
                                    min={0}
                                    controls={false}
                                    placeholder="Enter an OT multiplier"
                                />
                            </Form.Item>
                            <span> times regular pay.</span>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Overtime starts after">
                        <Space>
                            <Form.Item name={["OvertimeStartAfter"]} noStyle
                                       rules={[{required: true, message: "Overtime starts after"}]}>
                                <InputNumber style={{width: 160}} min={1} controls={false}
                                             placeholder="Enter OT start hour"/>
                            </Form.Item>
                            <span> hours of regular time in a labour line.</span>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddRulesetModal;
