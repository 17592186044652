import React, {useState, useEffect} from "react";
import update from "immutability-helper";
import {Button, Row, Col, Input, Space} from "antd";
import {CloseCircleOutlined, EditOutlined} from "@ant-design/icons";
import "./ViewEditList.scss";

export const EmptyRow = ({switchToEditMode, emptyRowText, readOnly = false}) => (
    <Row className="view-row-item">
        <Col span={8} className="view-row-item-type">
            <div className="ant-form-item-without-validation">
                <span>{emptyRowText}</span>
            </div>
        </Col>

        <Col span={16}>
            <div className="ant-form-item-without-validation">
                {!readOnly && <EditOutlined onClick={switchToEditMode} className="btn-edit-row"/>}
            </div>
        </Col>
    </Row>
);

export const DefaultViewList = ({items, switchToEditMode, emptyRowText, readOnly = false}) => (
    <div className="view-edit-list">
        {items.length === 0 &&
            <EmptyRow switchToEditMode={switchToEditMode} emptyRowText={emptyRowText} readOnly={readOnly}/>}
        {items.map((item, index) => (
            <Row key={`${item.id}_${index}`} className="view-row-item">
                <Col span={8} className="view-row-item-type">
                    <div className="ant-form-item-without-validation">
                        <span>{/* type */}</span>
                    </div>
                </Col>

                <Col span={16}>
                    <div className="ant-form-item-without-validation">
                        {item.value}
                        {!readOnly && <EditOutlined onClick={switchToEditMode} className="btn-edit-row"/>}
                    </div>
                </Col>
            </Row>
        ))}
    </div>
);

const EditableRow = ({item = {}, onChange, remove, valuePlaceholder}) => {
    const [value, setValue] = useState(item.value);

    useEffect(() => {
        setValue(item.value);
    }, [item]);

    const triggerChange = (changedValue) => {
        if (onChange) {
            onChange({value, ...item, ...changedValue});
        }
    };

    const onValueChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        triggerChange({value: newValue});
    };

    return (
        <Row align="middle">
            <Col span={7}>{/* <Input disabled={true} placeholder="Enter an type"></Input> */}</Col>
            <Col span={14} offset={1}>
                <Input value={value} onChange={onValueChange} placeholder={valuePlaceholder}></Input>
            </Col>
            <Col span={2} className={{"btn-remove-row-holder": true}}>
                <CloseCircleOutlined className="btn-remove-row" onClick={remove}/>
            </Col>
        </Row>
    );
};

const DefaultEditList = ({items = [], onChange, onChanged, onCancel, addNewRowTex, valuePlaceholder}) => {
    const [editableItems, setEditableItems] = useState(items);

    useEffect(() => {
        setEditableItems(items);
    }, [items]);

    function add() {
        setEditableItems([...editableItems, {}]);
    }

    function remove(index) {
        setEditableItems(editableItems.filter((e, i) => i !== index));
    }

    function onItemChange(index, item) {
        const changedItem = {...editableItems[index], ...item};

        let updatedItems = update(editableItems, {[index]: {$set: changedItem}});

        if (changedItem.IsForCommunication) {
            updatedItems = updatedItems.map((email, i) => update(email, {IsForCommunication: {$set: i === index}}));
        }
        setEditableItems(updatedItems);
    }

    function saveClick() {
        if (onChange) {
            onChange(editableItems);
        }
        if (onChanged) {
            onChanged();
        }
    }

    return (
        <div className="view-edit-list edit-mode">
            {editableItems.map((item, index) => (
                <EditableRow
                    key={index}
                    item={item}
                    valuePlaceholder={valuePlaceholder}
                    onChange={(changedItem) => onItemChange(index, changedItem)}
                    remove={() => remove(index)}
                />
            ))}
            <Row>
                <Col span={24}>
                    <Button
                        block
                        type="dashed"
                        onClick={() => {
                            add();
                        }}
                        style={{width: "100%"}}>
                        {addNewRowTex}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="btn-actions-holder">
                    <Space>
                        <Button type="primary" size="small" onClick={saveClick}>
                            Save
                        </Button>
                        <Button size="small" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

const ViewEditList = ({
                          items = [],
                          onChanged,
                          onChange,
                          readOnly = false,
                          EditList = DefaultEditList,
                          ViewList = DefaultViewList,
                          contactId,
                          emptyRowText,
                          addNewRowTex = "Add new row",
                          valuePlaceholder = "Enter a value",
                          editOnly = false,
                          onCancel = () => {
                          },
                      }) => {
    const [isEdit, setIsEdit] = useState(editOnly);

    function switchToEditMode() {
        setIsEdit(true);
    }

    function switchToViewMode() {
        setIsEdit(false);
    }

    useEffect(() => {
        switchToViewMode();
    }, [contactId]);

    return isEdit ? (
        <EditList
            items={items}
            addNewRowTex={addNewRowTex}
            valuePlaceholder={valuePlaceholder}
            onChanged={() => {
                if (!editOnly) switchToViewMode();

                onChanged();
            }}
            onChange={onChange}
            onCancel={() => {
                switchToViewMode();
                onCancel();
            }}
            readOnly={readOnly}
        />
    ) : (
        <ViewList items={items} emptyRowText={emptyRowText} switchToEditMode={switchToEditMode} readOnly={readOnly}/>
    );
};

export default ViewEditList;
