import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {MailOutlined} from "@ant-design/icons";
import {sendFilterEmail} from "../../../services/productionBooks";
import EmailSend from "../../Common/EmailSend/EmailSend";

const Email = ({selected = [], disabled}) => {
    const allProjects = useSelector((state) => state.projects.projects);

    const isAllProjects = selected.find((s) => s.Schedule?.IsAllProjects) !== undefined;
    const projectIds = _.flatten(selected.map((s) => s.Schedule.Projects.map((p) => p.Id)));
    const targetProjects = isAllProjects ? allProjects : allProjects.filter((p) => projectIds.includes(p.Id));

    const getMessageFirstLine = (count = 0) => {
        return selected.length === 1
            ? `A link to the ${selected[0].Name} will be emailed to ${count} ${count === 1 ? "person" : "people"}`
            : `Links to these Production Books will be emailed to ${count} ${count === 1 ? "person" : "people"}: ${selected
                .map((i) => i.Name)
                .join(", ")}`;
    };

    const getTitle = () => (
        <>
            <MailOutlined/> Email {selected.length === 1 ? selected[0].Name : `${selected.length} Production Books`}
        </>
    );

    const getSubject = () => (
        selected && selected.length === 1 ? selected[0].Name : ""
    );

    const onSendEmail = (isTest, values) =>
        sendFilterEmail({
            Subject: values.Subject,
            FilterId: selected[0].Id,
            TimeZoneId: selected[0].TimeZoneId,
            UseBcc: values.UseBcc,
            IncludeMyself: values.IncludeMyself,
            RecipientContactsIds: values.AdditionalContacts || [],
            RecipientGroupTagIds: values.RecipientGroupTagIds || [],
            RecipientNoGroupProjectIds: values.RecipientNoGroupProjectIds || [],
            EmailText: values.EmailText,
            FilterLayoutType: 1,
            SendToMe: isTest,
            RecipientsIds: [],
            RecipientContactGroups: values.RecipientContactGroups || [],
            IncludeNoGroupContacts: values.IncludeNoGroupContacts || false,
        });

    return (
        <EmailSend
            targetProjects={targetProjects}
            disabled={disabled}
            onSendEmail={onSendEmail}
            getTitle={getTitle}
            getSubject={getSubject}
            getMessageFirstLine={getMessageFirstLine}></EmailSend>
    );
};

export default Email;
