import React from "react";
import {useSelector} from "react-redux";
import {MailOutlined} from "@ant-design/icons";
import {sendEmail} from "../../../services/team";
import EmailSend from "../../Common/EmailSend/EmailSend";

const Email = ({selected = [], disabled}) => {

    const activeProject = useSelector((state) => state.projects.activeProject);

    const getMessageFirstLine = (count = 0) => {
        return `An email will be sent to ${count} ${count === 1 ? "person" : "people"}.`;
    };

    const getTitle = () => (
        <>
            <MailOutlined/> Email
        </>
    );

    const getSubject = () => (
        selected && selected.length === 1 ? selected[0].Name : ""
    );

    const onSendEmail = (isTest, values) =>
        sendEmail({
            Subject: values.Subject,
            AccountId: values.AccountId,
            UseBcc: values.UseBcc,
            IncludeMyself: values.IncludeMyself,
            RecipientContactsIds: [...values.AdditionalContacts || [], ...selected.map(s => s.Id)],
            RecipientGroupTagIds: values.RecipientGroupTagIds || [],
            EmailText: values.EmailText,
            SendToMe: isTest,
            RecipientsIds: [],
            RecipientContactGroups: values.RecipientContactGroups || [],
            IncludeNoGroupContacts: values.IncludeNoGroupContacts || false,
        });

    return (
        <EmailSend
            targetProjects={[activeProject]}
            disabled={disabled}
            onSendEmail={onSendEmail}
            getTitle={getTitle}
            getSubject={getSubject}
            getMessageFirstLine={getMessageFirstLine}
            selected={selected}
        ></EmailSend>
    );
};

export default Email;
