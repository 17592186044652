import React from "react";

import AccessTable from "./Access/AccessTable";
import Preferences from "./Preferences";

import {Tabs, Tooltip} from "antd";
import {SettingsType} from "../../constants/constants";
import {DollarCircleOutlined, SearchOutlined, SettingFilled, TagsFilled} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../helpers/paths";
import Subscription from "./Subscription";
import {useSelector} from "react-redux";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";
import usePermissions from "../../hooks/usePermissions";
import "./Settings.scss";
import Tags from "./Tags";

const SubscriptionTabContent = () => (
    <span>
    <DollarCircleOutlined/> Subscription
  </span>
);

const SubscriptionTab = ({canAccessSubscription}) =>
    canAccessSubscription ? (
        <SubscriptionTabContent/>
    ) : (
        <Tooltip title="Only owners may access the subscriptions page.">
            <SubscriptionTabContent/>
        </Tooltip>
    );

const Settings = ({type}) => {
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {canAccessSettings, canAccessSubscription} = usePermissions();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    const changeTab = (key) => {
        history.push(urlWithPortfolioId(pathCollection.settingsPath[key]));
    };

    if (activePortfolio && !canAccessSettings) {
        history.push(urlWithPortfolioId("/"));
    }

    return (
        <Tabs onChange={changeTab} type="card" activeKey={type}>
            <Tabs.TabPane
                tab={
                    <span>
            <SearchOutlined/> Access
          </span>
                }
                key={SettingsType.Access}>
                <AccessTable/>
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={
                    <span>
            <SettingFilled/> Preferences
          </span>
                }
                key={SettingsType.Preferences}>
                <Preferences activePortfolio={activePortfolio}></Preferences>
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={
                    <span>
            <TagsFilled/> Tag Lists
          </span>
                }
                key={SettingsType.Tags}>
                <Tags></Tags>
            </Tabs.TabPane>
            <Tabs.TabPane
                disabled={!canAccessSubscription}
                tab={<SubscriptionTab canAccessSubscription={canAccessSubscription}></SubscriptionTab>}
                key={SettingsType.Subscription}>
                <Subscription></Subscription>
            </Tabs.TabPane>
        </Tabs>
    );
};

export default Settings;
