import React from "react";
import {Form, Empty} from "antd";
import moment from "moment";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {formatContactName} from "../../../helpers/text";

const EmailsBlock = ({form, emailsHistory}) => {
    const formats = useDateTimeFormat();

    if (!emailsHistory) return <Empty description="No Email History" image={Empty.PRESENTED_IMAGE_SIMPLE}/>;

    const {SentAt: sentAt, SentByAccount: sentBy, RecipientsIds: recipients = []} = emailsHistory;

    const formatSentAt = (time) => (!!time ? moment(time, "YYYY-MM-DDThh:mm:ss").format(formats.dateTime) : "-");

    return (
        <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
            <Form.Item label="Last Sent" className="ant-form-item-without-validation">
                {formatSentAt(sentAt)}
            </Form.Item>
            <Form.Item label="Sent By" className="ant-form-item-without-validation">
                {formatContactName(sentBy?.Contact, false)}
            </Form.Item>
            <Form.Item label="Recipients" className="ant-form-item-without-validation">
                {recipients.map((r) => formatContactName(r?.Contact, false)).join(", ")}
            </Form.Item>
        </Form>
    );
};

export default EmailsBlock;
