import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";

const noItem = {
    value: "noItem",
    title: "— No Location —",
    sortRank: 1,
};

export function getLocations(data) {
    return _.chain(data)
        .filter((item) => item.Locations.length > 0)
        .flatMap((item) => item.Locations)
        .map((location) => ({
            value: location.Id,
            title: location.Name,
            spaceName: location.Name,
            locationName: location.ParentName,
            parentId: location.ParentId,
            contactId: location.ContactId,
        }))
        .uniqBy((item) => item.contactId)
        .value();
}

const getGroups = (data) => {
    const locations = getLocations(data);
    const groups = _.groupBy(locations, (l) => l.parentId);
    return _.keys(groups).map((key) => ({title: groups[key][0].locationName, items: groups[key]}));
};

const LocationsFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const items = useMemo(() => [noItem, ...getLocations(data)], [data]);
    const groups = useMemo(() => [{item: noItem, sortRank: 1}, ...getGroups(data)], [data]);
    const itemsHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [itemsHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [itemsHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const filterContactIds = selectedItems.map((i) => i.contactId).filter((id) => id != null);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);
        setFilterHandler(
            (item) =>
                _.intersection(
                    filterValues,
                    item.Locations.map((l) => l.Id)
                ).length > 0 ||
                _.intersection(
                    filterContactIds,
                    item.Locations.map((l) => l.ContactId)
                ).length > 0 ||
                (isNoItemSelected && item.Locations.length === 0)
        );
        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            groups={groups}
            allItemsText="All Locations"
            onChanged={onChanged}
            search
        />
    );
};

export default LocationsFilter;
