import {List, Button} from "antd";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {ApiContactType} from "../../../../constants/constants";
import "./SourcesManagement.scss";
import EditSourceRow from "./EditSourceRow";

import {loadContacts} from "../../../../services/contacts";
import {loadSources} from "../../../../services/sources";
import {getResource} from "../../../../services/resources";
import CreateContact from "../../../Common/QuickCreate/CreateContact";
import {formatContactName} from "../../../../helpers/text";
import {upsertSource} from "../../../../services/sources";
import {sortInventory} from "../../../../helpers/lists";
import {useDispatch, useSelector} from "react-redux";
import {continueEdit} from "../../../../redux/reducers/detailsPanel";

const SourcesManagement = ({resourceId, onChange, onDelete}) => {
    const [freeSlotsCount, setFreeSlotsCount] = useState(0);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [contactType, setContactType] = useState(ApiContactType.Space);
    const [itemToEdit, setItemToEdit] = useState();
    const dispatch = useDispatch();
    const resourceItemToEdit = useSelector((state) => state.detailsPanel.item);

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const allContacts = useSelector((state) => state.contacts.contacts);
    const places = allContacts.filter((t) => t.Type === ApiContactType.Place);

    const sources = resourceItemToEdit?.Sources || [];
    const sortedSources = sortInventory(sources);

    const refresh = () =>
        Promise.all([
            getResource(resourceItemToEdit.Id).then((item) => dispatch(continueEdit({item}))),
            loadSources({resourceId, accountId: activePortfolioId}),
        ]);

    const getSlots = (count) => {
        const template = {
            ResourceId: resourceId,
            AccountId: activePortfolioId,
            Quantity: null,
            DefaultCost: 0,
            CostUnit: "Item",
        };

        let slots = [];
        for (var i = 0; i < count; i++) slots.push({...template});

        return slots;
    };

    const sourcesAndSlots = [...sortedSources, ...getSlots(freeSlotsCount)].map((s) => {
        const contact = s.ContactId && allContacts.find((c) => c.Id === s.ContactId);

        return {...s, Contact: contact};
    });

    useEffect(() => {
        if (!resourceId) return;

        if (sortedSources.length === 0) {
            loadSources({resourceId, accountId: activePortfolioId});
        }
    }, [resourceId, activePortfolioId, sortedSources.map((r) => r.Id).join("")]);

    useEffect(() => {
        if (!resourceId) return;
        loadContacts(activePortfolioId);
    }, [resourceId, activePortfolioId]);

    useEffect(() => {
        if (!resourceId) return;
        if (sourcesAndSlots.length === 0) onSlotAdd();
    }, [resourceId, activePortfolioId, sourcesAndSlots.length]);

    const onSlotAdd = () => setFreeSlotsCount(freeSlotsCount + 1);
    const onSlotRemove = () => setFreeSlotsCount(freeSlotsCount - 1);

    const onItemDelete = (itemId) => {
        if (onDelete && itemId) onDelete(itemId);
        if (!itemId) onSlotRemove();

        refresh();
    };

    const onItemChange = (item) => {
        if (!item.Id) onSlotRemove();
        if (onChange) onChange(item);

        refresh();
    };

    const itemPersister = (payload, item, onChange) => {
        return upsertSource(payload)
            .then((response) => {
                if (onChange) onChange(item);

                item.Id = response.Id;
            })
            .catch();
    };

    const onOpenCreatePopup = (type, sourceItem) => {
        setContactType(type);
        setIsQuickCreateContactVisible(true);
        setItemToEdit(sourceItem);
    };

    const trySetContact = (contactId, item, reloadedContacts = []) => {
        if (!item || !itemToEdit) return;

        const isPlace = item.Type === ApiContactType.Place;

        let payload = {
            Id: itemToEdit.Id,

            ResourceId: resourceId,
            AccountId: activePortfolioId,
            ContactId: contactId,

            Name: formatContactName({...item, Type: item.ContactType}, false),
            Quantity: itemToEdit.Quantity,
            DefaultCost: itemToEdit.DefaultCost,
            CostUnit: itemToEdit.CostUnit,
        };

        // location contacts are not selectable
        if (isPlace) {
            const spaceId = reloadedContacts.find((c) => c.ParentId === contactId)?.Id;
            itemPersister({...payload, ContactId: spaceId}, itemToEdit, onItemChange);
        } else {
            itemPersister(payload, itemToEdit, onItemChange);
        }
    };

    const mapPlace = (place) => ({
        value: place.Id,
        label: formatContactName(place, false),
        contact: place,
    });

    const allContactIds = allContacts.map((c) => c.Id);
    const usedContactIds = sources.map((c) => c.ContactId);
    return (
        <div>
            <List
                hidden={!resourceId}
                header={
                    <div className="details-src-item">
                        <div className="source">Source</div>
                        <div className="inventory">Inventory</div>
                        <div className="cost-unit">Default Cost</div>
                        <div className="details">Details</div>
                        <div className="actions">
                            <Button type="primary" size="small" onClick={onSlotAdd}>
                                Add
                            </Button>
                        </div>
                    </div>
                }
                size="small"
                dataSource={sourcesAndSlots}
                renderItem={(item) => {
                    const disabledContactIds = allContactIds.filter((cId) => {
                        if (!item.ContactId) return false;
                        if (item.ContactId === cId) return false;

                        return usedContactIds.includes(cId);
                    });

                    return (
                        <List.Item>
                            <EditSourceRow
                                item={item}
                                itemPersister={itemPersister}
                                contacts={allContacts}
                                resourceId={resourceId}
                                disabledContactIds={disabledContactIds}
                                onDelete={onItemDelete}
                                onChange={onItemChange}
                                onOpenCreatePopup={onOpenCreatePopup}
                                activePortfolioId={activePortfolioId}
                            />
                        </List.Item>
                    );
                }}></List>
            <CreateContact
                apiContactType={contactType}
                isVisible={isQuickCreateContactVisible}
                isEmailRequired={true}
                onClose={() => {
                    setIsQuickCreateContactVisible(false);
                    setItemToEdit();
                }}
                onContactCreated={trySetContact}
                places={places.map(mapPlace)}
                hideSpaces={true}
            />
        </div>
    );
};

export default SourcesManagement;
