import {createSlice} from "@reduxjs/toolkit";

const mainTableSlice = createSlice({
    name: "mainTable",
    initialState: {
        isBulkSelection: false,
        selected: [],
        groupedBy: {},
        filtered: []
    },
    reducers: {
        setSelected(state, action) {
            state.selected = action.payload;
            state.isBulkSelection = action.payload.length > 1;
        },
        setGroupedBy(state, action) {
            state.groupedBy = action.payload;
        },
        setFiltered(state, action) {
            state.filtered = action.payload;
        }
    },
});

export const {setSelected, setGroupedBy, setFiltered} = mainTableSlice.actions;

export default mainTableSlice.reducer;
