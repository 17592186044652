import React from "react";
import DepartmentsFilter from "./DepartmentsFilter";
import CategoriesFilter from "./CategoriesFilter"; //
import {filterHandler as projectsFilter} from "../../Common/ProjectsMultipleSelector";
import SearchFilter from "./SearchFilter/SearchFilter";
import GroupBy from "./GroupBy/GroupBy";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";
import {useSelector} from "react-redux";

const AttachmentsFilterBar = ({...props}) => {
    const selectedProjectsIds = useSelector((state) => state.projects.activeProjects).map((p) => p.Id);
    const activeProjectsExtra = useSelector((state) => state.projects.activeProjectsExtra);
    const projectKeys = [...activeProjectsExtra, ...selectedProjectsIds];

    const additionalFilters = [
        {
            handler: projectsFilter(projectKeys),
            key: projectKeys.join(","),
        },
    ];
    return (
        <FilterBar
            {...props}
            filterComponents={[CategoriesFilter, DepartmentsFilter, SearchFilter]}
            additionalFilters={additionalFilters}
            GroupByComponent={GroupBy}
        />
    );
};

export default AttachmentsFilterBar;
