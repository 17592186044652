import React from "react";
import _ from "lodash";
import {Col, Collapse, List, Row} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";
import GoToRequirement from "./GoToRequirement";
import {dateRange} from "../../../../helpers/time";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import {useSelector} from "react-redux";
import {ProjectTag} from "../../../Common/Projects/ProjectsTag";

const ConflictIcon = (props) => <InfoCircleFilled className="warning-icon" {...props} />;

const ListOfRequirements = ({items}) => {

    const formats = useDateTimeFormat();
    const projects = useSelector(state => state.projects.portfolioProjects) || [];

    const nonZeroItems = items.filter(m => m.Quantity > 0);

    return nonZeroItems.length > 0 && (
        <List
            className="table-in-panel"
            header={false}
            dataSource={nonZeroItems}
            renderItem={(item) => {
                const project = projects.find(p => p.Id === item.Project.Id);
                const range = dateRange(item.StartDate, item.EndDate, formats);

                return (
                    <List.Item>
                        <Row>
                            <Col offset={0} span={2} style={{paddingLeft: "5px"}}>
                                ({item.Quantity})
                            </Col>
                            <Col offset={0} span={6}>
                                <small>{range}</small>
                            </Col>
                            <Col offset={0} span={6}>
                                {item.Name}
                            </Col>
                            <Col offset={0} span={7}>
                                {project && <ProjectTag project={project}/>}
                            </Col>
                            <Col offset={0} span={2}>
                                <GoToRequirement requirement={item}></GoToRequirement>
                            </Col>
                        </Row>
                    </List.Item>
                );
            }}></List>
    );
};

const SourcesBlock = ({sources}) => {
    if (!sources || sources?.length === 0) return null;

    const formatHeader = (source) => {
        const {Inventory: inventory} = source;
        const allocated = _.sumBy(source.Requirements || [], r => r.Quantity || 0);

        if (inventory > 0) {
            const overAllocated = inventory < allocated;
            return (
                <div style={{width: "100%", fontWeight: 800}}>
                    <span>{source.Name} <ConflictIcon hidden={!overAllocated}/></span>
                    <span style={{display: "inline-block", float: "right"}}>{allocated} of {inventory} used</span>
                </div>
            );
        } else if (!inventory && allocated > 0) {
            return (
                <div style={{width: "100%", fontWeight: 800}}>
                    <span>{source.Name}</span>
                    <span style={{display: "inline-block", float: "right"}}>{allocated} used</span>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <Collapse defaultActiveKey={sources.map(s => s.Id)} className="nested-details-collapse">
                {sources.map(source => {
                    const header = formatHeader(source);
                    const requirements = source.Requirements || [];

                    return header && (
                        <Collapse.Panel key={source.Id} header={header}>
                            <ListOfRequirements items={requirements}/>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </>
    );
};

export default SourcesBlock;
