import React, {useState} from "react";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {useEffect} from "react";

export const columns = {
    Location: {value: "Location", title: "Location"},
    Team: {value: "Team", title: "Team"},
    Status: {value: "Status", title: "Status"},
    Categories: {value: "Categories", title: "Categories"},
    Notes: {value: "Notes", title: "Details"},
    Roles: {value: "Project Roles", title: "Project Roles"},
    Organization: {value: "Associated Organization", title: "Associated Organization"},
    Email: {value: "Email", title: "Email"},
    Phone: {value: "Phone", title: "Phone"},
    Address: {value: "Address", title: "Address"},
    Departments: {value: "Departments", title: "Departments"},
    Reports: {value: "Reports", title: "Reports"},
};

const isSelected = (item, selected) => selected.find((i) => i.value === item.value) != null;

const Columns = ({value, items, selected, onChanged, onChange, disabled}) => {
    const [selectedItems, setSelectedItems] = useState(selected || []);
    useEffect(() => {
        if (value != null) {
            setSelectedItems(
                [
                    value.OptionalLocation ? columns.Location : null,
                    value.OptionalNotes ? columns.Notes : null,
                    value.OptionalStatus ? columns.Status : null,
                    value.OptionalTeam ? columns.Team : null,
                    value.OptionalCategories ? columns.Categories : null,
                    value.OptionalDepartments ? columns.Departments : null,
                    value.OptionalReports ? columns.Reports : null,
                ].filter((i) => i)
            );
        }
    }, [value]);

    function onSelected(filterItems) {
        setSelectedItems(filterItems);
        onChange({
            ...value,
            OptionalLocation: isSelected(columns.Location, filterItems),
            OptionalStatus: isSelected(columns.Status, filterItems),
            OptionalNotes: isSelected(columns.Notes, filterItems),
            OptionalTeam: isSelected(columns.Team, filterItems),
            OptionalCategories: isSelected(columns.Categories, filterItems),
            OptionalDepartments: isSelected(columns.Departments, filterItems),
            OptionalReports: isSelected(columns.Reports, filterItems),
        });

        onChanged();
    }

    return (
        <MultipleFilterWithSearchFilter
            disabled={disabled}
            selectedItems={selectedItems}
            onChanged={onSelected}
            items={items}
            trigger="click"
            nothingSelectedText="No Columns"
            allItemsText="All Columns"></MultipleFilterWithSearchFilter>
    );
};

export default Columns;
