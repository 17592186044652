import FilterBar from "../../../../Common/Tables/FilterBar";
import RoleFilter from "./RoleFilter";
import GroupFilter from "./GroupFilter";
import SearchFilter from "../../../../Contacts/FilterBar/SearchFilter";

const ContactsSearchFilterBar = ({positionName, ...props}) => {
    return (
        <FilterBar {...props} filterComponents={[RoleFilter, GroupFilter, SearchFilter]} GroupByComponent={null}
                   resetText="Reset"/>
    );
};

export default ContactsSearchFilterBar;
