import React, {useEffect, useState} from "react";

import {Modal, Card, Row, Col, Select, message} from "antd";
import {formatContactName} from "../../../helpers/text";
import "./MergeContact.scss";
import ContactDetailsPanelTitle from "../DetailsPanel/ContactDetailsPanelTitle";
import {getContact, mergeContact, refreshContacts} from "../../../services/contacts";
import {useSelector} from "react-redux";
import {selectFilterOption} from "../../Common/Input";

const mapItem = (contact) => ({
    value: contact.Id,
    label: formatContactName(contact, false),
    contact,
});

const ContactInfo = ({contact}) => (
    <>
        {contact?.EmailAddresses && (
            <Row>
                <Col span={8}>Email: </Col>
                <Col span={16}>{contact.EmailAddresses.map((ea) => ea.EmailAddress).join(", ")}</Col>
            </Row>
        )}
        {contact?.ProjectEntries && (
            <Row>
                <Col span={8}>Projects: </Col>
                <Col span={16}>{contact.ProjectEntries.map((pe) => pe.Project.Name).join(", ")}</Col>
            </Row>
        )}
    </>
);

const MergeContactContent = ({contact, contacts = [], targetContact, setTargetContact}) => {
    const getItems = () => contacts.filter((c) => c.Type === contact.Type && c.Id !== contact.Id);

    return (
        <div className="merge_contact">
            <p>
                Copies all contact information and project data from the duplicate contact into the principal contact,
                and then deletes
                the duplicate. This cannot be undone!
            </p>
            {contact && (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h2>Merge: {formatContactName(contact, false)}</h2>
                        </Col>
                        <Col span={12}>
                            <h2 className="into">Into: </h2>
                            <Select
                                showSearch={true}
                                filterOption={selectFilterOption}
                                placeholder="Select a Person"
                                className="person_selection"
                                options={getItems().map(mapItem)}
                                onSelect={(val, item) => getContact(val).then((response) => setTargetContact(response))}></Select>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Card type="inner"
                                  title={<ContactDetailsPanelTitle type={contact.Type} editedItem={contact}/>}>
                                <ContactInfo contact={contact}/>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card type="inner" title={<ContactDetailsPanelTitle type={contact.Type}
                                                                                editedItem={targetContact || {}}/>}>
                                <ContactInfo contact={targetContact}/>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export const canMergeContact = (contact) => !contact.IsAccountProfile && !contact.IsOwnerAccess;

const areYouSure = (onOk) =>
    Modal.confirm({
        title: null,
        icon: null,
        content: "Are you sure?",
        okText: "Yes!",
        async onOk() {
            onOk();
        },
        onCancel() {
        },
    });

const MergeContact = ({disabled}) => {
    const selected = useSelector((store) => store.mainTable.selected);
    const contacts = useSelector((state) => state.contacts.contacts);
    const [targetContact, setTargetContact] = useState(null);
    const [isVisible, setVisible] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [fullContact, setFullContact] = useState(null);

    useEffect(() => {
        if (!selected[0] || !isVisible) return;

        setTargetContact(null);
        setFullContact(selected[0]);
        getContact(selected[0].Id).then((response) => setFullContact(response));
    }, [selected[0], isVisible]);

    const doMerge = () => {
        setSubmitting(true);
        mergeContact(selected[0].Id, targetContact.Id)
            .then(() => {
                message.success(
                    `Contact ${formatContactName(selected[0], false)} successfully merged into ${formatContactName(targetContact, false)}`
                );
                setVisible(false);
                setTargetContact(null);
                setFullContact(null);
            })
            .then(() => {
                refreshContacts();
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <div disabled={disabled} onClick={() => setVisible(!disabled)}>
                Merge
            </div>
            <Modal
                width={560}
                title="Merge Contacts"
                visible={isVisible}
                maskClosable={false}
                onOk={() => {
                    return areYouSure(doMerge);
                }}
                onCancel={() => {
                    setVisible(false);
                    setFullContact(null);
                    setTargetContact(null);
                    setFullContact(null);
                }}
                okButtonProps={{disabled: !targetContact}}
                confirmLoading={isSubmitting}
                okText="Merge">
                <MergeContactContent
                    contact={fullContact}
                    contacts={contacts}
                    targetContact={targetContact}
                    setTargetContact={setTargetContact}
                />
            </Modal>
        </>
    );
};

export default MergeContact;
