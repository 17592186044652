import {BulbFilled} from "@ant-design/icons";
import React from "react";

const RequirementDetailsPanelTitle = ({editedItem}) => (
    <>
        <BulbFilled/> {editedItem.Name}
    </>
);

export default RequirementDetailsPanelTitle;
