import React from 'react';
import {Alert, Col, Row} from "antd";
import {useSelector} from "react-redux";
import "./InvitationAlert.scss";

const InvitationAlert = () => {
    const inviteLink = useSelector(state => state.inviteLinks?.inviteLink);
    const invitationsLimit = inviteLink?.InvitedAccounts?.InvitationsLimit;
    const usedInvitationsCount = inviteLink?.InvitedAccounts?.UsedInvitationsCount;

    const isDisplayAlert = invitationsLimit < usedInvitationsCount;

    if (isDisplayAlert) {
        return (<div className="invitation-alert">
            <Row justify="center" className="settings-alert">
                <Col span={24}>
                    <Row justify="center">
                        <Col>
                            <div className="alert-container">
                                <Alert
                                    message="More students are assigned than your subscription allows. Please remove existing students or contact us to add more."
                                    type="error"
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);
    }

    return null;
};

export default InvitationAlert;