import {Tag, Form} from "antd";
import React from "react";
import {mapProjectWithBadgeToTag} from "../../../../helpers/Tags";
import {SelectWithFormItemRef} from "../../../Common/Input";

const FormProjectSelect = ({forwardedRef, projects = [], project, projectId, save, validateStatus}) => {
    const selected = project || projects.find((p) => p.Id === projectId);
    return selected ? (
        <Form.Item label="Project" className="ant-form-item-without-validation">
            <Tag className="project_tag" color={selected.Color}>
                {selected.Name}
            </Tag>
        </Form.Item>
    ) : (
        <SelectWithFormItemRef
            ref={forwardedRef}
            label="Project"
            name="ProjectId"
            showSearch={true}
            style={{width: "100%"}}
            placeholder="Select a Project"
            hasFeedback
            validateStatus={validateStatus}
            rules={[{required: true, message: "Project"}]}
            options={projects.map(mapProjectWithBadgeToTag)}
            onChanged={save}
        />
    );
};

export default FormProjectSelect;
