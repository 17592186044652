import api from "../api";
import store from "../redux/store";
import {setSources} from "../redux/reducers/resources";

const {dispatch} = store;

export const loadSources = ({resourceId, accountId}) =>
    api.post(`api/Source/GetBy`, {resourceId, accountId})
        .then((response) => {
            dispatch(setSources({
                items: response.Items, resourceId
            }));
        });

export const upsertSource = (payload) =>
    api.post(`api/Source/Upsert`, payload, `Upsert_Source_${payload?.ResourceId}_${payload?.RequirementId}_${payload?.SourceId}`);

export const deleteSources = (accountId, ids) =>
    api.post(`api/Source/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})), `Batch_Source_Remove_${accountId}`);
