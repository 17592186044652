import React from "react";
import CommonSearchFilter from "../../../../Common/Tables/FilterBar/SearchFilter";

const fieldsToSearch = ["Name"];

const SearchFilter = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter
        setFilterHandler={setFilterHandler}
        needResetFilter={needResetFilter}
        fieldsToSearch={fieldsToSearch}/>
);

export default SearchFilter;
