import {Form} from "antd";
import React from "react";
import {useEffect} from "react";
import {updateUserSettings, whoAmI} from "../../services/auth";
import DateTimeFormat from "../Common/DateTimeFormat";

const Preferences = ({user}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (!user) return;

        form.setFieldsValue({
            DateFormat: user.DateTimeSettings.Date.Value,
            TimeFormat: user.DateTimeSettings.Time.Value,
        });
    }, [user]);

    const onChange = () => {
        const values = form.getFieldsValue();
        updateUserSettings({
            Settings: {
                Date: {Value: values.DateFormat},
                Time: {Value: values.TimeFormat},
                StartWeekOn: {Value: user.DateTimeSettings.StartWeekOn.Value},
            },
        }).then(whoAmI);
    };
    return (
        <div>
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                <DateTimeFormat onChange={onChange}></DateTimeFormat>
            </Form>
        </div>
    );
};

export default Preferences;
