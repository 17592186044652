import React, {useEffect, useState} from "react";
import _ from "lodash";
import MultipleFilterWithDisable from "./MultipleFilterWithDisable";
import {formatContactName} from "../../../../helpers/text";
import {SelectWithFormItem} from "../../../Common/Input";
import {createColorTagRendered, geTagsGroup} from "../../../../helpers/Tags";
import TreeSelectFilter, {isOptionSelected} from "./TreeSelectFilter";
import {Select, TreeSelect} from "antd";
import {useSelector} from "react-redux";
import {ApiContactType} from "../../../../constants/constants";

export const selectDefaultOption = {value: "— All Team Members —", title: "— All Team Members —"};
export const noTeamOption = {value: "— No Team Members —", title: "— No Team Members —"};
export const noContactOption = {value: "— Team Members with No Contact —", title: "— Team Members with No Contact —"};

const teamMemberToOptions = (teamMembers = [], value = []) => {
    return _.uniqBy(teamMembers, (p) => p.ContactId).map((tm) => {
        const team = value.find((v) => v.ContactId === tm.ContactId) || tm;
        return {
            value: team.Id,
            title: formatContactName(tm.Contact, false),
            key: team.Id,
        };
    });
};

const sort = (items) => _.sortBy(items, (i) => i);

const Team = ({value = {}, projectIds = [], setFilterHandler, disabled, onChange, onChanged}) => {
    const {TeamMembers = [], ScheduleTeamMemberContacts = [], IsNoTeam = false, IsNoAssignedContact = false} = value;

    const [selectedItems, setSelectedItems] = useState([]);
    const allTeamMembers = useSelector((state) => state.team.teams) || [];
    const teamMembers = allTeamMembers.filter(
        (t) => t.Contact === null || t.Contact?.Type === ApiContactType.Person || t.Contact?.Type === ApiContactType.Organization
    );

    useEffect(() => {
        if (TeamMembers.length > 0 || ScheduleTeamMemberContacts.length > 0)
            setSelectedItems(
                sort([
                    ...TeamMembers.map((v) => v.Id),
                    ...ScheduleTeamMemberContacts.map((v) => `contact:${v.Id}`),
                    ...[IsNoTeam ? noTeamOption.value : null, IsNoAssignedContact ? noContactOption.value : null].filter((i) => i !== null),
                ])
            );
    }, [value]);

    function initFilterHandler(filterValues) {
        const isNoItemSelected = filterValues.includes(noTeamOption.value);
        const isNoContactSelected = filterValues.includes(noContactOption.value);
        if (filterValues.length === 0) {
            setFilterHandler(null);
        } else {
            setFilterHandler((item) => {
                const contactIds = item.TeamMembers?.map((m) => m.ContactId) || [];

                return (
                    _.intersection(contactIds, filterValues).length > 0 ||
                    (isNoItemSelected && (item.TeamMembers?.length === 0 || !item.TeamMembers)) ||
                    (isNoContactSelected && contactIds.includes(null))
                );
            });
        }
    }

    function onItemChanged(filterValues) {
        initFilterHandler(filterValues);
        setSelectedItems(sort(filterValues));
        onChange({
            ...value,
            IsNoTeam: isOptionSelected(noTeamOption.value, filterValues),
            IsNoAssignedContact: isOptionSelected(noContactOption.value, filterValues),
            TeamMembers: filterValues.filter((i) => _.isNumber(i)).map((i) => ({Id: i})),
            ScheduleTeamMemberContacts: filterValues
                .filter((i) => _.isString(i) && i.indexOf("contact:") !== -1)
                .map((i) => ({Id: parseInt(i.split(":")[1], 10)})),
        });
        onChanged();
    }

    useEffect(() => initFilterHandler(TeamMembers), [TeamMembers]);

    const teamMemberWithContacts = teamMembers.filter((tm) => tm.ContactId);

    const projectTeamMembers = _.chain(teamMemberWithContacts)
        .filter((tm) => projectIds.includes(tm.ProjectId))
        .uniqBy((tm) => tm.ContactId)
        .value();

    const projectTeamMembersIds = projectTeamMembers.map((tm) => tm.ContactId);

    const otherContacts = _.chain(teamMemberWithContacts)
        .uniqBy((tm) => tm.ContactId)
        .filter((tm) => !projectTeamMembersIds.includes(tm.ContactId))
        .value();

    const projectTeamMemberOption = "Project Team Members";
    const groups = [
        {
            title: "Project Team Members",
            value: projectTeamMemberOption,
            key: "Project Team Members",
            filterOff: true,
            children: [noTeamOption, noContactOption, ...teamMemberToOptions(projectTeamMembers, TeamMembers)],
        },
    ];

    if (otherContacts.length > 0) {
        groups.push({
            title: "All Other Contacts",
            value: "All Other Contacts",
            key: "All Other Contacts",
            filterOff: true,
            children: otherContacts.map((tm) => ({
                value: `contact:${tm.ContactId}`,
                title: formatContactName(tm.Contact)
            })),
        });
    }
    return (
        <TreeSelectFilter
            treeDefaultExpandedKeys={[projectTeamMemberOption]}
            value={selectedItems}
            disabled={disabled}
            placeholder="All Team Members"
            treeData={groups}
            selectDefaultOption={selectDefaultOption}
            onChanged={onItemChanged}></TreeSelectFilter>
    );
};

export default Team;
