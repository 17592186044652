import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Divider, Form, message, Modal, Radio, Select, Switch, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";
import {InputWithFormItem, SelectWithFormItem, TextAreaWithFormItem} from "../Input";
import {getByTags, loadTeams} from "../../../services/team";
import {ApiContactType} from "../../../constants/constants";
import {formatContactName} from "../../../helpers/text";
import CreateContact from "../QuickCreate/CreateContact";
import {errorFromHttpResponse} from "../../../helpers/error";

const getPrimaryEmail = (emailAddresses = []) => emailAddresses.find((a) => a.IsForCommunication)?.EmailAddress;

const getPrimaryEmailString = (emailAddresses) => {
    const email = getPrimaryEmail(emailAddresses);
    return email !== undefined ? `(${email})` : "";
};

const noDefaultGroup = "noDefaultGroup";

const EmailSend = ({
                       targetProjects,
                       disabled,
                       onSendEmail,
                       getMessageFirstLine,
                       getTitle,
                       getSubject,
                       renderAsButton = false,
                       selected = [],
                   }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [groupTags, setGroupTags] = useState([]);
    const [loadingTest, setLoadingTest] = useState(false);
    const [tags, setTags] = useState([]);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [recipientsCount, setRecipientsCount] = useState(0);

    const initialValues = {
        Subject: getSubject ? getSubject() : "",
        UseBcc: false,
    }

    const currentEmail = getPrimaryEmail(useSelector((state) => state.currentUser.user).EmailAddresses);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const projects = useSelector((state) => state.projects.projects);
    const contacts = useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Person);

    const contactOptions = contacts.map((c) => ({
        value: c.Id,
        label: `${formatContactName(c, false)} ${getPrimaryEmailString(c.EmailAddresses)}`,
        disabled: getPrimaryEmail(c.EmailAddresses) === undefined,
    }));

    const contactsWithEmails = contacts.filter((c) => getPrimaryEmail(c.EmailAddresses) !== undefined);
    const contactGroups = _.chain(contactsWithEmails)
        .flatMap((c) => c.Groups)
        .uniqBy((x) => x.AccountTagId)
        .sort()
        .value();

    const contactGroupOptions = _.sortBy(contactGroups).map((g) => {
        const numberOfUsages = contactsWithEmails.filter((c) => c.Groups.map((x) => x.AccountTagId).includes(g.AccountTagId)).length;
        return {
            label: `${g.Name} (${numberOfUsages})`,
            value: `contactTag:${g.AccountTagId}`,
            numberOfUsages,
        };
    });

    const noDefaultGroupCount = contactsWithEmails.filter((c) => c.Groups.length === 0).length;

    const teamMembersWithoutGroup = useSelector((state) => state.team.teams).filter(
        (t) => t.Contact?.Type === ApiContactType.Person && t.GroupTags.length === 0
    );

    useEffect(() => {
        onRecipientsChanged();
    });

    useEffect(() => {
        if (isVisible) {
            form.resetFields();

            const selectedContactIds = selected
                .filter(tm => tm.Contact && tm.Contact.Id)
                .map(tm => tm.Contact.Id);

            form.setFieldsValue({
                AdditionalContacts: selectedContactIds,
            });

            setDataLoading(true);

            return Promise.all([
                getByTags(targetProjects.map((p) => p.Id)),

                loadTeams(
                    activePortfolio.Id,
                    targetProjects.map((p) => p.Id)
                ),
            ]).then(([responseTags]) => {
                setGroupTags(responseTags.Tags);
                setDataLoading(false);
            });
        } else {
            setGroupTags([]);
        }
    }, [isVisible, targetProjects]);

    useEffect(() => {
        let groups = _.groupBy(groupTags, (t) => t.ProjectId);

        const tagGroups = targetProjects.map((project) => {
            const projectId = project.Id;
            let gTags = [];
            if (!!groups[projectId]) {
                gTags = groups[projectId].map((item) => ({
                    label: `${item.Name} (${item.NumberOfUsages})`,
                    value: item.ProjectTagId,
                    id: item.ProjectTagId,
                    numberOfUsages: item.NumberOfUsages,
                }));
            }

            return {
                label: project.Name,
                id: project.Id,
                tags: [...gTags, getNoTeamTag(project.Id)].filter(_.isObject),
            };
        });
        setTags(_.sortBy(tagGroups, "label"));
    }, [groupTags]);

    const getNoTeamTag = (projectId) => {
        const membersByProject = teamMembersWithoutGroup.filter((tm) => tm.ProjectId === projectId);
        if (membersByProject.length > 0) {
            const project = projects.find((p) => p.Id === projectId);
            return {
                label: `— No Group — (${membersByProject.length})`,
                value: `no_group:${projectId}`,
                id: `no_group:${projectId}`,
                numberOfUsages: membersByProject.length,
                projectLabel: project.ShortName || project.Name,
            };
        } else {
            return null;
        }
    };

    const onOk = async (isTest) => {
        const values = isTest ? form.getFieldsValue() : await form.validateFields();
        isTest ? setLoadingTest(true) : setLoading(true);

        const RecipientGroupTagIds = (values.Groups || []).filter((g) => _.isNumber(g));
        const RecipientNoGroupProjectIds = (values.Groups || [])
            .filter((g) => _.isString(g) && g.indexOf(":") !== -1 && g.indexOf("contactTag:") !== -1)
            .map((g) => parseInt(g.split(":")[1], 10));

        const IncludeNoGroupContacts = (values.Groups || []).includes(noDefaultGroup);
        const RecipientContactGroups = (values.Groups || [])
            .filter((g) => _.isString(g) && g.indexOf("contactTag:") !== -1)
            .map((g) => parseInt(g.split(":")[1], 10));

        onSendEmail(isTest, {
            ...values,
            RecipientGroupTagIds,
            RecipientNoGroupProjectIds,
            RecipientContactGroups,
            IncludeNoGroupContacts,
            AccountId: activePortfolio.Id,
        })
            .then(() => {
                
                message.success(isTest ? `Test email sent to ${currentEmail}` : "Email has been sent successfully");
              
                if (!isTest) {
                    setVisible(false);
                    dispatch(setSelected([]));
                }
            })
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
            })
            .finally(() => {
                setLoading(false);
                setLoadingTest(false);
            });
    };

    const onRecipientsChanged = () => {
        const contactsCount = (form.getFieldValue("AdditionalContacts") || []).length;
        const allTag = _.flatten(tags.map((t) => t.tags));

        const groups = form.getFieldValue("Groups") || [];

        const groupsNumberOfUsages = groups.map((g) => {
            if (g === noDefaultGroup) return noDefaultGroupCount;

            const projectTag = allTag.find((t) => t.value === g);
            if (projectTag) return projectTag.numberOfUsages;

            const contactGroup = contactGroupOptions.find((o) => o.value === g);
            if (contactGroup) return contactGroup.numberOfUsages;

            return 0;
        });

        setRecipientsCount(contactsCount + _.sum(groupsNumberOfUsages));
    };

    const isGroupOrContactSet = () =>
        (form.getFieldValue("Groups") || []).length > 0 || (form.getFieldValue("AdditionalContacts") || []).length > 0;

    const getTagLabel = (value, label) => {
        if (_.isNumber(value)) return label;
        if (value === noDefaultGroup) return `No Default Group (${noDefaultGroupCount})`;

        if (value.indexOf("contactTag:") !== -1) return label;

        const projectTag = tags
            .map((t) => tags)
            .flat()
            .map((t) => t.tags)
            .flat()
            .find((t) => t.value === value);
        if (projectTag) return `${projectTag.label} [${projectTag.projectLabel}]`;
    };

    return (
        <>
            {renderAsButton ? (
                <Button disabled={disabled} onClick={() => setVisible(!disabled)}>
                    Email
                </Button>
            ) : (
                <div disabled={disabled} onClick={() => setVisible(!disabled)}>
                    Email
                </div>
            )}

            <Modal
                width={560}
                title={getTitle()}
                visible={isVisible}
                onOk={onOk}
                maskClosable={false}
                onCancel={() => setVisible(false)}
                okButtonProps={{disabled: false}}
                confirmLoading={loading || loadingTest}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Cancel
                    </Button>,
                    // <Button key="test" disabled={disableTest} loading={loadingTest} onClick={() => onOk(true)}>
                    //   Send me a Test
                    // </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={() => onOk(false)}>
                        Send
                    </Button>,
                ]}
                okText="Send">
                <Form
                    form={form}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    layout="horizontal"
                    size="middle"
                    initialValues={initialValues}>
                    <Form.Item className="ant-form-item-without-validation" label="From (Reply To)">
                        {currentEmail}
                    </Form.Item>
                    <InputWithFormItem name="Subject" label="Subject" placeholder="Enter a subject"/>
                    <Divider orientation="left" className="divider-first">
                        Recipients
                    </Divider>

                    <SelectWithFormItem
                        label="Groups"
                        name="Groups"
                        mode="multiple"
                        loading={dataLoading}
                        tagRender={({onClose, value, label}) => (
                            <Tag color="purple" closable={true} onClose={onClose}>
                                {getTagLabel(value, label)}
                            </Tag>
                        )}
                        onChanged={onRecipientsChanged}
                        style={{width: "100%"}}
                        rules={[
                            {
                                validator: async () => {
                                    if (!isGroupOrContactSet()) throw new Error("Group or contact is required");
                                    else Promise.resolve();
                                },
                            },
                        ]}
                        placeholder="Select groups">
                        {!dataLoading && (
                            <>
                                {tags.map((t) => (
                                    <Select.OptGroup key={t.id} label={t.label}>
                                        {t.tags.map((tag) => (
                                            <Select.Option key={tag.id} value={tag.id} label={tag.label}>
                                                {tag.label}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                                {(contactGroupOptions.length > 0 || noDefaultGroupCount > 0) && (
                                    <Select.OptGroup key="Default Groups" label="Default Groups">
                                        {noDefaultGroupCount > 0 && (
                                            <Select.Option key={noDefaultGroup} value={noDefaultGroup}
                                                           label="No Default Group">
                                                — No Default Group — ({noDefaultGroupCount})
                                            </Select.Option>
                                        )}
                                        {contactGroupOptions.map((tag) => (
                                            <Select.Option key={tag.value} value={tag.id} label={tag.label}>
                                                {tag.label}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                )}
                            </>
                        )}
                    </SelectWithFormItem>

                    <SelectWithFormItem
                        label="Additional Contacts"
                        name="AdditionalContacts"
                        style={{width: "100%"}}
                        placeholder="Select contacts"
                        options={contactOptions}
                        onChanged={onRecipientsChanged}
                        mode="multiple"
                        rules={[
                            {
                                validator: async () => {
                                    if (!isGroupOrContactSet()) throw new Error("Group or contact is required");
                                    else Promise.resolve();
                                },
                            },
                        ]}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <div className="btn-quick-create">
                                    <Button type="dashed" onClick={() => setIsQuickCreateContactVisible(true)}>
                                        + Add a Contact
                                    </Button>
                                </div>
                            </>
                        )}
                    />

                    <Form.Item label="To/BCC" name="UseBcc" className="ant-form-item-without-validation">
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={false}>Use "TO" field</Radio.Button>
                            <Radio.Button value={true}>Use "BCC" field</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="BCC Myself" name="IncludeMyself" valuePropName="checked"
                               className="ant-form-item-without-validation">
                        <Switch/>
                    </Form.Item>

                    <Divider orientation="left" className="divider-first">
                        Message
                    </Divider>
                    <TextAreaWithFormItem formItemProps={{wrapperCol: {span: 24}}} name="EmailText"
                                          autoSize={{minRows: 2}}
                                          placeholder="Enter a message"/>
                </Form>

                <p>{recipientsCount > 0 && getMessageFirstLine(recipientsCount)}</p>

                <CreateContact
                    apiContactType={ApiContactType.Person}
                    isVisible={isQuickCreateContactVisible}
                    isEmailRequired={true}
                    onClose={() => setIsQuickCreateContactVisible(false)}
                    onContactCreated={(contactId, contact) => {
                        const contacts = form.getFieldValue("AdditionalContacts");
                        form.setFieldsValue({
                            AdditionalContacts: [...contacts, contactId],
                        });
                        onRecipientsChanged();
                    }}
                />
            </Modal>
        </>
    );
};
export default EmailSend;
