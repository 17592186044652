import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Input, Button, message} from "antd";
import {resetPassword} from "../../services/auth";
import {useHistory, useParams} from "react-router-dom";
import {errorFromHttpResponse} from "../../helpers/error";

const ResetPassword = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const {key} = useParams();

    const onFinish = async (values) => {
        try {
            setLoading(true);
            await resetPassword(key, values.Password, values.ConfirmPassword);
            history.push("/");
        } catch (err) {
            const errorText = errorFromHttpResponse(err);
            message.error(errorText);
        } finally {
            setLoading(false);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("errorInfo", errorInfo);
    };

    return (
        <Form layout="vertical" name="basic" initialValues={{remember: true}} onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
            <Form.Item
                label="New Password (16 character minimum)"
                name="Password"
                rules={[
                    {min: 16, message: "Password must contain at least 16 characters in length."},
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                ]}>
                <Input.Password placeholder="Enter new Password"/>
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="ConfirmPassword"
                dependencies={["Password"]}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: "Please confirm your password!",
                    },
                    ({getFieldValue}) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue("Password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject("The two passwords that you entered do not match!");
                        },
                    }),
                ]}>
                <Input.Password placeholder="Re-Enter your new Password"/>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" size="large">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetPassword;
