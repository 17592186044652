import {eventDateRange} from "./time";

export const mapWCToSelect = ({Id, Name, EndDateTime, StartDateTime, IsTimeOnly, IsAllDay}, formats) => ({
    label: StartDateTime ? `${Name} (${eventDateRange(StartDateTime, EndDateTime, formats, IsTimeOnly, IsAllDay)})` : Name,
    value: Id,
});

export const mapIdNameToSelect = ({Id, Name}) => ({
    label: Name,
    value: Id,
});
