import {EnvironmentOutlined, UserOutlined, ShopOutlined} from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import {SidePanelItemType} from "../../../constants/constants";
import {formatContactName} from "../../../helpers/text";

const icons = {
    [SidePanelItemType.TeamPerson]: <UserOutlined/>,
    [SidePanelItemType.TeamOrganization]: <ShopOutlined/>,
    [SidePanelItemType.TeamLocation]: <EnvironmentOutlined/>,
};

const TeamMemberDetailsPanelTitle = ({editedItem, type}) => {
    return (
        <>
            {editedItem?.Contact?.ImageUrl && (
                <Avatar className="detail_panels_avatar" shape="square" size={36} icon={icons[type]}
                        src={editedItem.Contact.ImageUrl}/>
            )}
            {!editedItem?.Contact?.ImageUrl && icons[type]}
            {editedItem && editedItem.Contact ? formatContactName(editedItem.Contact, false) : ""}{" "}
            {editedItem &&
                editedItem.RoleTags &&
                editedItem.RoleTags.length > 0 &&
                `(${editedItem.RoleTags.map((t) => t.Name).join(", ")})`}
            {!editedItem && "No Team Member"}
        </>
    );
};

export default TeamMemberDetailsPanelTitle;
