import React from "react";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";
import {formatContactName} from "../../../../helpers/text";

const fieldsToSearch = [
    "FirstName",
    "LastName",
    "Nickname",
    (item) => item.Specialties.map((x) => x.Name),
    (item) => item.Groups.map((x) => x.Name),
    "EmailAddresses[0].EmailAddress",
    "Addresses[0].Line1",
    "Notes",
    "AlsoKnownAs",
    "Company",
    (item) => (item.ProjectEntries || []).map((p) => p.Project.Name),
    (item) => (item.spaces || []).map((s) => formatContactName(s)),
    (item) => formatContactName(item.Parent),
];


const SearchFilter = ({setFilterHandler, needResetFilter}) => (

    <CommonSearchFilter
        setFilterHandler={setFilterHandler}
        needResetFilter={needResetFilter}
        fieldsToSearch={fieldsToSearch}/>
);

export default SearchFilter;
