import api from "../api/api";
import store from "../redux/store";
import {setLines} from "../redux/reducers/labour";

const {dispatch} = store;

export const loadLabourLines = (accountId) => {
    return api
        .get(`api/LabourLine/GetByAccount?accountId=${accountId}`)
        .then((response) => {
            dispatch(setLines(response));
        })
        .then(() => {
        });
};

export const getLabourLine = (id) => api.get(`api/LabourLine/Get?id=${id}`);

export const createLabourLine = (taskId) => api.post(`api/LabourLine/Create?taskId=${taskId}`);
export const createLabourLines = (payload) => api.post(`api/LabourLine/BatchCreate`, payload);
export const updateLabourLine = (payload) => api.post(`api/LabourLine/Update`, payload, `updateLabourLine_${payload.Id}`);
export const createCrewBookings = (taskId) => api.post(`api/LabourLine/CreateCrewBookings?taskId=${taskId}`);

export const deleteLabourLines = (ids) => {
    const accountId = store.getState().projects.activePortfolio?.Id;
    return api.post(`api/LabourLine/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})));
};


export const resetRatesLabourLines = (ids) => {
    return api.post(`api/LabourLine/ResetRates`, ids.map(Id => ({Id})));
};
