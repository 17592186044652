import api from "../api/api";
import store from "../redux/store";
import {setAttachments} from "../redux/reducers/attachments";
import {setSelected} from "../redux/reducers/mainTable";

const {dispatch} = store;

const defaultFilter =
    {
        Filter: {Keyword: "", Projects: []}
    }

export const loadAttachments = (accountId) =>
    api
        .post(`api/Attachment/GetAttachments?accountId=${accountId}`, defaultFilter)
        .then((team) => {
            dispatch(setAttachments(team.Items));
            return team;
        });

export const refreshAttachments = () => {
    const accountId = store.getState().projects.activePortfolio.Id;
    return loadAttachments(accountId);
}

export const createOrUpdate = (accountId, payload) =>
    api.post(`api/Attachment/CreateOrUpdate?accountId=${accountId}`, payload);

export const deleteAttachmentBatch = (ids) =>
    api.post(`api/Attachment/BatchRemove`, ids);

export const getItem = (id) => api.get(`api/Attachment/GetById?id=${id}`);

export const batchUpdate = (ids, projectsData, categoriesData, departmentsData, IsAllProjects, urlPayload) => {
    const payload = {
        Ids: ids,
        UpdateName: false,
        UpdateDescription: false,
        UpdateProjects: projectsData.isUpdated,
        UpdateOnlyCommonProjects: projectsData.updateOnlyCommon,
        Projects: projectsData.tags,
        IsAllProjects,
        UpdateDepartments: departmentsData.isUpdated,
        UpdateOnlyCommonDepartments: departmentsData.updateOnlyCommon,
        Departments: departmentsData.tags,
        UpdateCategories: categoriesData.isUpdated,
        UpdateOnlyCommonCategories: categoriesData.updateOnlyCommon,
        Categories: categoriesData.tags,
        ...urlPayload
    };

    return api.post(`api/Attachment/BatchUpdate`, payload).then(() => refreshAttachments())
}
