import React from "react";
import {Button, Modal} from "antd";
import {
    batchUpdate, refreshAllPortfolioProjects, refreshArchivedProjects, refreshProjects,
} from "../../../../services/projects";
import {ApiProjectType} from "../../../../constants/constants";
import {useDispatch} from "react-redux";
import {setSelected} from "../../../../redux/reducers/mainTable";
import {LockOutlined} from "@ant-design/icons";
import {TooltipWithOrNot} from "../../../Common/Input";
import usePermissions from "../../../../hooks/usePermissions";

const getDescriptions = (apiProjectType, selected) => {
    switch (true) {
        case apiProjectType === ApiProjectType.Archive && selected.length === 1:
            return `It will no longer appear in any other Propared screens or in your Production Books. Archived projects can be accessed from the Archives tab.`;
        case apiProjectType === ApiProjectType.Archive && selected.length > 1:
            return `They will no longer appear in any other Propared screens or in your Production Books. Archived projects can be accessed from the Archives tab.`;
        case apiProjectType === ApiProjectType.Inactive && selected.length === 1:
            return `It will return to your project list in "Draft" state.`;
        case apiProjectType === ApiProjectType.Inactive && selected.length > 1:
            return `They will be returned to your project list in "Draft" state.`;
        default:
            return "";
    }
};

const getTitle = (apiProjectType, selected) => {
    switch (true) {
        case apiProjectType === ApiProjectType.Archive && selected.length === 1:
            return `Are you sure you want to archive ${selected[0].Name}?`;
        case apiProjectType === ApiProjectType.Archive && selected.length > 1:
            return `Are you sure you want to archive these ${selected.length} projects?`;
        case apiProjectType === ApiProjectType.Inactive && selected.length === 1:
            return `Are you sure you want to unarchive ${selected[0].Name}?`;
        case apiProjectType === ApiProjectType.Inactive && selected.length > 1:
            return `Are you sure you want to unarchive these ${selected.length} projects?`;
        default:
            return "";
    }
};

const buttonText = {
    [ApiProjectType.Archive]: "Archive", [ApiProjectType.Inactive]: "Unarchive",
};

const Archive = ({selected = [], apiProjectType}) => {
    const dispatch = useDispatch();
    const {canManageProjects, canStudentAddNewProject, isStudent} = usePermissions();

    const showArchive = () => {
        Modal.confirm({
            title: getTitle(apiProjectType, selected),
            icon: <LockOutlined/>,
            className: "grey-modal",
            content: getDescriptions(apiProjectType, selected),
            okText: buttonText[apiProjectType],
            async onOk() {
                await batchUpdate(selected.map((p) => p.Id), {isUpdated: false}, {
                    isUpdated: true, value: apiProjectType
                });

                dispatch(setSelected([]));
                return Promise.all([refreshProjects(), refreshArchivedProjects()]);
            },
            onCancel() {
            },
        });
    };

    const isButtonDisabled = () => {
        if (selected.length === 0) {
            return true;
        }

        if (apiProjectType === ApiProjectType.Inactive && isStudent) {
            return !canStudentAddNewProject || selected.length > 1;
        }

        return !canManageProjects;
    };

    const shouldShowTooltip = () => {
        if (apiProjectType === ApiProjectType.Inactive && isStudent && selected.length === 0) {
            return false;
        }

        if (apiProjectType === ApiProjectType.Inactive && isStudent) {
            return !canStudentAddNewProject || selected.length > 1;
        }

        return !canManageProjects;
    };

    return (<TooltipWithOrNot
        showTooltip={shouldShowTooltip()}
        title={isStudent ? "Users with student access may only have one active or draft project at a time. To create a new project, you’ll first need to archive the current one." : ""}>
        <Button danger onClick={showArchive} disabled={isButtonDisabled()}>
            {buttonText[apiProjectType]}
        </Button>
    </TooltipWithOrNot>);
};

export default Archive;
