import React, {useState} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import {ApiFilterCrewType} from "../../../../constants/constants";
import {useEffect} from "react";

const items = [
    {value: ApiFilterCrewType.LabourLines, title: "Labour Lines"},
    {value: ApiFilterCrewType.CrewBookings, title: "Crew Bookings"},
];

const defaultItem = items[0];
const CrewOrLabourLine = ({value, onItemsChanged, disabled, onChange, onChanged}) => {
    const [selectedItem, setSelectedItem] = useState(defaultItem);

    useEffect(() => {
        if (value != null) setSelectedItem(items.find((i) => i.value === value));
    }, [value]);

    function onSelected(filteredItems) {
        setSelectedItem(filteredItems);
        onChange(filteredItems.value);
        onChanged();
    }

    useEffect(() => {
        onItemsChanged(selectedItem.value);
    }, [selectedItem, onItemsChanged]);

    return (
        <SingleItemFilter disabled={disabled} selectedItem={selectedItem} items={items} onChanged={onSelected}
                          trigger="click"/>
    );
};

export default CrewOrLabourLine;
