import {Button, Select} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ApiContactType, ApiPermissionLevel} from "../../../../constants/constants";
import {formatContactName} from "../../../../helpers/text";
import {createColorTagRendered} from "../../../../helpers/Tags";
import CreateContact from "../../../Common/QuickCreate/CreateContact";
import {selectFilterOption} from "../../../Common/Input";
import _ from "lodash";

const isAccountMember = (contactId, members = []) => members.find((m) => m.MemberAccount?.Contact?.Id === contactId) != null;

const getContactName = (contact, admins, owners) => {
    if (isAccountMember(contact.Id, admins) || isAccountMember(contact.OriginalId, admins))
        return `${formatContactName(contact, false)} (Administrator)`;

    if (isAccountMember(contact.Id, owners) || isAccountMember(contact.OriginalId, owners))
        return `${formatContactName(contact, false)} (Owner)`;

    return formatContactName(contact, false);
};

const mapContact = (contact, admins, owners) => ({
    value: contact.Id.toString(),
    label: getContactName(contact, admins, owners),
    contact: contact,
    disabled:
        isAccountMember(contact.Id, admins) ||
        isAccountMember(contact.Id, owners) ||
        isAccountMember(contact.OriginalId, admins) ||
        isAccountMember(contact.OriginalId, owners),
});

const ProjectManagers = ({
                             setIsQuickCreateContactVisible,
                             value = [],
                             onChange,
                             onChanged,
                             disabled,
                             admins,
                             owners
                         }) => {
    const contactIdValues = value.map((pm) => pm.ContactId);
    const [selectedPms, setSelectedPms] = useState(contactIdValues);
    useEffect(() => {
        if (contactIdValues.length === selectedPms.length && selectedPms.length === 0) return;
        setSelectedPms(contactIdValues);
    }, [value]);

    const contacts = useSelector((state) => state.contacts.contacts).filter(
        (c) => c.Type === ApiContactType.Person && c.EmailAddresses.length > 0
    );

    const applyChanges = (selected) => {
        if (!_.isArray(selected)) {
            selected = selectedPms;
        }

        const updatedValues = value
            .filter((pm) => selected.includes(pm.ContactId))
            .map((v) => ({
                Id: v.Id,
                PermissionLevel: ApiPermissionLevel.AssistantOrViewer,
                ProjectIds: [],
                EmailAddresses: v.EmailAddresses,
                MemberAccountId: v.MemberAccountId,
                ContactId: v.ContactId,
            }));

        const newPms = selected
            .filter((pm) => !contactIdValues.includes(pm))
            .map((contactId) => parseInt(contactId))
            .map((contactId) => ({
                Id: null,
                ContactId: contactId.toString(),
                EmailAddresses: contacts.find((c) => contactId === c.Id).EmailAddresses.map((e) => e.EmailAddress),
                PermissionLevel: ApiPermissionLevel.AssistantOrViewer,
                ProjectIds: [],
            }));

        onChange([...updatedValues, ...newPms]);
        if (onChanged) onChanged();
    };

    return (
        <Select
            allowClear
            disabled={disabled}
            value={selectedPms}
            mode="multiple"
            style={{width: "100%"}}
            placeholder="Select project managers"
            options={contacts.map((c) => mapContact(c, admins, owners))}
            onChange={(val) => {
                setSelectedPms(val);
                applyChanges(val);
            }}
            onBlur={applyChanges}
            tagRender={createColorTagRendered(null, !disabled)}
            filterOption={selectFilterOption}
            onClear={() => {
                setSelectedPms([]);
                applyChanges([]);
            }}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <div className="btn-quick-create">
                        <Button type="dashed" onClick={() => setIsQuickCreateContactVisible(true)}>
                            + Add a new project manager
                        </Button>
                    </div>
                </>
            )}
        />
    );
};

export const QuickCreateProjectManager = ({
                                              isQuickCreateContactVisible,
                                              setIsQuickCreateContactVisible,
                                              onPmCreated
                                          }) => (
    <CreateContact
        isEmailRequired={true}
        apiContactType={ApiContactType.Person}
        isVisible={isQuickCreateContactVisible}
        onClose={() => setIsQuickCreateContactVisible(false)}
        onContactCreated={(contactId, contact) =>
            onPmCreated({
                Id: null,
                ContactId: contactId.toString(),
                EmailAddresses: contact.EmailAddresses.map((e) => e.EmailAddress),
                PermissionLevel: ApiPermissionLevel.AssistantOrViewer,
                ProjectIds: [],
            })
        }></CreateContact>
);

export default ProjectManagers;
