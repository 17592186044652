import {createSlice} from "@reduxjs/toolkit";
import {TagType} from "../../constants/constants";

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        accountMembers: [],
        userToEdit: null,
        currentPlan: null,
        selectedTagType: TagType.Category,
        maxMembers: {}
    },
    reducers: {
        setAccountMembers(state, action) {
            state.accountMembers = action.payload;
        },

        setUserToEdit(state, action) {
            state.userToEdit = action.payload;
        },

        updateUserToEdit(state, action) {
            if (state.userToEdit && state.userToEdit?.Id === action.payload?.Id) {
                state.userToEdit = action.payload;
            }
        },

        clearUserToEdit(state) {
            state.userToEdit = null;
        },

        setCurrentPlan(state, action) {
            state.currentPlan = action.payload;
        },

        setSelectedTagType(state, action) {
            state.selectedTagType = action.payload;
        },

        setMaxMembers(state, action) {
            state.maxMembers = action.payload;
        },

    },
});

export const {
    setAccountMembers,
    setUserToEdit,
    updateUserToEdit,
    clearUserToEdit,
    setCurrentPlan,
    setSelectedTagType,
    setMaxMembers
} = settingsSlice.actions;

export default settingsSlice.reducer;
