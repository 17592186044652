import React from 'react';
import {Modal, Button} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import "./DeleteStudent.scss";

const DeleteStudent = ({student, onOk, onCancel}) => {

    const firstName = student.Name ? student.Name.split(' ')[0] : '';
    const title = <strong>Remove {student.Name}</strong>;
    const content = `Removing ${firstName} will revoke their access to Propared. Are you sure you want to do this?`;

    const showModal = () => {
        Modal.confirm({
            title: title,
            content: content,
            icon: <DeleteOutlined/>,
            className: "delete-student-modal",
            okText: "Remove Student",
            okButtonProps: {danger: true},
            onOk,
            onCancel,
        });
    };

    return <div className="delete-student-button" onClick={showModal}><DeleteOutlined/></div>;
};

export default DeleteStudent;