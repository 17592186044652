import api from "../api/api";
import store from "../redux/store";
import {setRequirements} from "../redux/reducers/requirements";
import moment from "moment";
import {expandRequirement} from "../helpers/api";
import {setSelected} from "../redux/reducers/mainTable";
import {sanitizeHTML} from "../helpers/html";

const {dispatch} = store;

export const loadRequirements = (accountId) => {
    const allContacts = store.getState().contacts.contacts || [];
    const selected = store.getState().mainTable.selected || [];

    return api
        .post(`api/Requirement/GetByAccount`, {AccountId: accountId})
        .then((response) => {
            const items = response?.Items || [];
            items.forEach(m => expandRequirement(m, allContacts));

            dispatch(setRequirements(items));
            dispatch(setSelected(selected.map(s => items.find(m => m.Id === s.Id))));

            return response;
        });
};

export const refreshRequirements = () => {
    const accountId = store.getState().projects.activePortfolio.Id;
    return loadRequirements(accountId);
};

export const createRequirement = (payload) => {
    if (payload.Description) {
        payload.Description = sanitizeHTML(payload.Description);
    }

    return api.post(`api/Requirement/Create`, payload);
}

export const updateRequirement = (payload) => {
    if (payload.Description) {
        payload.Description = sanitizeHTML(payload.Description);
    }

    return api.post(`api/Requirement/Update`, payload, `Update_Requirement_${payload?.Id}`);
}


export const deleteRequirements = (ids) => {
    const accountId = store.getState().projects.activePortfolio?.Id;
    return api.post(`api/Requirement/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})));
};

export const upsertSource = (payload) => api.post(`api/Requirement/UpsertSource`, payload, `Upsert_RequirementSource_${payload?.ResourceId}_${payload?.RequirementId}_${payload?.SourceId}`);


export const getRequirement = (id) => {
    const allContacts = store.getState().contacts.contacts || [];

    return api.get(`api/Requirement/Get?id=${id}`)
        .then(m => expandRequirement(m, allContacts));
};

export const getRequirementItem = (requirementId, resourceId) => {
    const allContacts = store.getState().contacts.contacts || [];

    return api.get(`api/Requirement/GetResource?requirementId=${requirementId}&resourceId=${resourceId}`)
        .then(response => {
            return expandRequirement(response, allContacts);
        });
};

export const addCollection = (payload) => api.post(`api/Requirement/AddCollection`, payload);

export const cloneRequirements = (payload) => {
    const accountId = store.getState().projects.activePortfolio?.Id;

    return api.post(`api/Requirement/Clone`, payload, `Clone_Requirements_${accountId}`)
        .then((cloneResult) => refreshRequirements()
            .then((loadResult) => ({
                cloned: cloneResult?.Items || [], all: loadResult?.Items || []
            })));
};

export const batchUpdate = (requirementIds = [], projectData, nameData, datesData, locationData, departmentData, detailsData, itemData, statusTag) => {

    const payload = {
        Versions: requirementIds.map(id => ({Id: id})),

        UpdateProjectId: projectData.isUpdated, ProjectId: projectData.value,
        UpdateName: nameData.isUpdated, Name: nameData.value,
        UpdateDates: datesData.isUpdated, Dates: datesData.value,
        UpdateLocationId: locationData.isUpdated, LocationId: locationData.value,
        UpdateDepartment: departmentData.isUpdated, DepartmentTag: departmentData.value,
        UpdateDetails: detailsData.isUpdated, Details: sanitizeHTML(detailsData.value),
        UpdateItemId: itemData.isUpdated, ItemId: itemData.value,
        UpdateStatus: statusTag.isUpdated, StatusTagId: statusTag.value,
    };

    return api.post(`api/Requirement/BatchUpdate`, payload)
        .then(() => refreshRequirements());
};

export const downloadRequirementsAsCsv = (items = []) => {
    const timestamp = moment().format("yyyy-MM-DDTHH-mm-ss");
    const activePortfolio = store.getState().projects.activePortfolio;
    const portfolio = activePortfolio?.Contact?.Company;

    const data = {
        AccountId: activePortfolio?.Id, ClientDateTime: timestamp, Items: items
    };

    const fileName = `${portfolio}_Requirements_${timestamp}.csv`;

    api.fetchFile("api/Requirement/DownloadRequirementsAsCsv", fileName, data);
};

export const shift = (payload) => {

    return api.post(`api/Requirement/Shift`, {...payload, Ids: payload?.Ids})
        .then(() => refreshRequirements());
};






