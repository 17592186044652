import React, {useEffect, useState} from "react";

import ReportsTable from "./ReportsTable";
import {useSelector} from "react-redux";
import {loadReports} from "../../services/reports";
import {loadContacts} from "../../services/contacts";

const Reports = () => {
    const [loading, setLoading] = useState(false);
    const items = useSelector((state) => state.reports.items);
    const templates = useSelector((state) => state.reports.templates);
    const activeProject = useSelector((state) => state.projects.activeProject);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        if (!activeProject?.Id) return;

        const loadData = async () => {
            try {
                setLoading(true);
                await Promise.all([loadReports(activeProject.Id, activePortfolio.Id), loadContacts(activePortfolio.Id)]);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, [activeProject?.Id]);

    return <ReportsTable items={items} templates={templates} loading={loading}/>;
};

export default Reports;
