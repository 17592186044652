import React, {useEffect} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import {useDispatch, useSelector} from "react-redux";
import {setNotesFilterIsDone} from "../../../../redux/reducers/reports";

const DONE_FILTER_VALUE = "DONE";
const NOT_DONE_FILTER_VALUE = "NOTDONE";
const items = [
    {
        value: NOT_DONE_FILTER_VALUE,
        title: "Not Done",
        isDone: false,
    },
    {
        value: DONE_FILTER_VALUE,
        title: "Done",
        isDone: true,
    },
];

const defaultItem = items[0];
const IsDoneFilter = ({setFilterHandler, needResetFilter, data}) => {
    const dispatch = useDispatch();
    const notesFilterIsDone = useSelector((state) => state.reports.notesFilterIsDone);
    useEffect(() => {
        if (needResetFilter) {
            onChanged(defaultItem);
            applyFiler();
        }
    }, [needResetFilter]);

    useEffect(() => {
        if (data.length > 0) {
            applyFiler();
        }
    }, [data, notesFilterIsDone]);

    function onChanged(selectedItem) {
        dispatch(setNotesFilterIsDone(selectedItem.isDone));
    }

    function applyFiler() {
        setFilterHandler((item) => notesFilterIsDone === item.IsDone);
    }

    const selectedItem = items.find((i) => i.isDone === notesFilterIsDone) || defaultItem;
    return <SingleItemFilter selectedItem={selectedItem} items={items} onChanged={onChanged}/>;
};

export default IsDoneFilter;
