import React, {useState, useEffect} from "react";
import {DatePicker, Row, Col, Button} from "antd";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";

const {RangePicker} = DatePicker;

const intervals = [
    {minutes: 30, label: "30min"},
    {minutes: 60, label: "1hr"},
    {minutes: 240, label: "4hr"},
    {minutes: 480, label: "8hr"},
    {minutes: 600, label: "10hr"},
];

const TimelineDates = ({placeholder, showTime, onDateChanged, isDisabled, startDate, endDate, showTimeShiftInfo}) => {
    const [isOpen, setIsOpen] = useState();
    const [value, setValue] = useState([]);
    const {dateTime: dateTimeFormat, date: dateFormat} = useDateTimeFormat();
    useEffect(() => {
        setValue([startDate, endDate]);
    }, [startDate, endDate]);

    const onOpenChange = (open) => {
        if (isOpen !== open) setIsOpen(open);
    };

    const onChange = (val) => {
        setValue(val);
        if (onDateChanged) onDateChanged(val);
    };

    const onCalendarChange = (val) => setValue(val);

    const [start] = value || [];

    const timeOptions = !!showTime
        ? {
            ...showTime,
        }
        : showTime;

    const rangePickerFooter = () => {
        const onIntervalClick = (minutes, range = null) => {
            let [s, e] = range || [null, null];

            if (!s) return;

            e = s.clone().add(minutes, "minutes");
            range = [s, e];
            setValue(range);

            if (onDateChanged) onDateChanged(range);
            setIsOpen(false);
        };

        return (
            <>
                {showTimeShiftInfo &&
                    <div className="center">The time-frame of all associated bookings will be updated as well.</div>}
                {!!showTime && (
                    <Row>
                        {intervals.map((item, i) => (
                            <Col key={i}>
                                <Button disabled={!start} type="link"
                                        onClick={() => onIntervalClick(item.minutes, value)}>
                                    {item.label}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                )}
            </>
        );
    };

    return (
        <RangePicker
            open={isOpen}
            style={{width: "100%"}}
            format={showTime ? dateTimeFormat : dateFormat}
            disabled={isDisabled}
            showTime={timeOptions}
            value={value}
            placeholder={placeholder}
            onCalendarChange={onCalendarChange}
            onChange={onChange}
            onOpenChange={onOpenChange}
            renderExtraFooter={rangePickerFooter}
        />
    );
};

export default TimelineDates;
