import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadProductionBooks} from "../../../services/productionBooks";
import {List, Row, Col} from "antd";
import {EyeOutlined, SendOutlined} from "@ant-design/icons";
import {openNew, close} from "../../../redux/reducers/detailsPanel";
import {SidePanelItemType} from "../../../constants/constants";
import {pathCollection} from "../../../helpers/paths";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {getPublicUrl} from "../../ProductionBooks/DetailsPanel";

const ProductionBooksByProject = ({project}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const items = useSelector((state) => state.productionBooks.items)
        .filter((pb) => !pb.Schedule.IsAllProjects && pb.Schedule.Projects.length === 1 && pb.Schedule.Projects[0].Id === project?.Id)
        .sort((pb) => pb.Name);

    useEffect(() => {
        loadProductionBooks(activePortfolioId);
    }, [activePortfolioId]);

    const newProductionBook = () => {
        dispatch(close());
        history.push(urlWithPortfolioId(pathCollection.productionBooks));
        setTimeout(
            () => dispatch(openNew({
                type: SidePanelItemType.ProductionBooks,
                default: {Schedule: {Projects: [project]}}
            })),
            100
        );
    };

    return (
        <List
            dataSource={items}
            size="small"
            className="table-in-panel"
            header={null}
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col span={1}>
                            <SendOutlined/>
                        </Col>
                        <Col span={21}>{item.Name} </Col>
                        <Col span={1} offset={1}>
                            <a href={getPublicUrl(item)} target="_blank" rel="noopener noreferrer">
                                <EyeOutlined/>
                            </a>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    );
};
export default ProductionBooksByProject;
