import React, {useState, useEffect, useRef} from "react";
import {Modal, Form} from "antd";
import {ToolOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {SelectWithFormItem} from "../../../Common/Input";

import _ from "lodash";

import {loadRulesets, deleteRuleset} from "../../../../services/rulesets";

const ReplaceRulesetModal = ({visible, onClose, itemToDelete, rulesets = []}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const form = useRef();

    const replacements = rulesets.filter((r) => r.Id !== itemToDelete?.Id);

    useEffect(() => {
        if (!activePortfolioId || !form?.current) return;

        if (itemToDelete) form.current.setFieldsValue(itemToDelete);
        else form.current.resetFields();
    }, [activePortfolioId, itemToDelete, rulesets, form]);

    const onOk = async () => {
        const values = await form.current.validateFields();

        const payload = {
            RemoveId: itemToDelete.Id,
            ReplacementId: values.ReplacementId,
        };

        setConfirmLoading(true);

        deleteRuleset(payload).then((response) => {
            loadRulesets(activePortfolioId).then(() => {
                setConfirmLoading(false);
                onCancel();
            });
        });
    };

    const onCancel = () => {
        form.current.resetFields();

        onClose();
    };

    return (
        <>
            <Modal
                title={
                    <>
                        <ToolOutlined/> &nbsp; Delete & Replace Ruleset
                    </>
                }
                visible={visible}
                maskClosable={false}
                confirmLoading={confirmLoading}
                onOk={onOk}
                onCancel={onCancel}
                onClose={onClose}
                okText="Delete & Replace"
                className="delete-modal"
                okButtonProps={{danger: true}}
                cancelText="Cancel">
                <Form
                    ref={form}
                    name="replace-ruleset"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    layout="horizontal"
                    size="middle">
                    <p>
                        This ruleset is being used in one or more positions. To delete the ruleset, please choose a
                        replacement. This may
                        change existing labour cost calculations.
                    </p>
                    <SelectWithFormItem
                        name={["ReplacementId"]}
                        label={"Ruleset"}
                        hasFeedback
                        rules={[{required: true, message: "Ruleset"}]}
                        placeholder="Select a Ruleset"
                        showSearch={true}
                        allowClear={true}
                        style={{width: "100%"}}
                        options={replacements.map((r) => ({label: r.Name, value: r.Id}))}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default ReplaceRulesetModal;
