import {Button, Form, message, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {sendResetPasswordRequest, whoAmI} from "../../services/auth";
import {getContact, loadContacts, updateContact} from "../../services/contacts";
import ImageUpload, {changeImageUpdatePayload} from "../Common/ImageUpload/ImageUpload";
import {InputWithFormItem} from "../Common/Input";
import EmailList from "../Common/ViewEditList/EmailList/EmailList";

const getPrimaryEmail = (emailAddresses = []) => emailAddresses.find((a) => a.IsForCommunication)?.EmailAddress;

const Profile = ({account}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState(true);

    useEffect(() => {
        if (!account) return;

        getContact(account.Contact.Id)
            .then((contact) => {
                form.setFieldsValue({...contact});
                setContact(contact);
            })
            .finally(() => setLoading(false));
    }, [account]);

    const onChange = () => {
        if (!contact) return;

        const values = form.getFieldsValue();
        updateContact({...contact, ...values, ...changeImageUpdatePayload(values, contact)}).then(() =>
            Promise.all([whoAmI(), loadContacts(account.Id)])
        );
    };

    const onPasswordReset = () => {
        setSubmitting(true);
        const values = form.getFieldsValue();
        const email = getPrimaryEmail(values.EmailAddresses);
        sendResetPasswordRequest(email)
            .then(() => message.success(`A password reset email has been sent to ${email}`))
            .catch(() => message.error("server error"))
            .finally(() => setSubmitting(false));
    };

    return (
        <Spin spinning={loading}>
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                <InputWithFormItem
                    name="FirstName"
                    label="First Name"
                    hasFeedback
                    rules={[{required: true, message: "Please input your First Name"}]}
                    onChanged={onChange}
                    placeholder="Enter first name"
                />
                <InputWithFormItem name="LastName" label="Last Name" onChanged={onChange}
                                   placeholder="Enter Last name"/>
                <Form.Item name="EmailAddresses" wrapperCol={24} className="ant-form-item-without-validation">
                    <EmailList onChanged={onChange} contactId={contact.Id}></EmailList>
                </Form.Item>
                <Form.Item label="Image" name="ImageUrl" className="ant-form-item-without-validation">
                    <ImageUpload onChanged={onChange}/>
                </Form.Item>
                <Form.Item label=" " colon={false}>
                    <Button loading={submitting} type="primary" onClick={onPasswordReset}>
                        Reset Password
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default Profile;
