import React from "react";
import {DatePicker, Form, Select, Switch} from "antd";
import {EnvironmentOutlined, ExpandOutlined} from "@ant-design/icons";
import {InputWithFormItem, SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import {geTagsGroup} from "../../../helpers/Tags";
import "./BatchEdit.scss";
import TeamMembersForEvent from "../DetailsPanel/TeamMembers/TeamMembers";
import ContactsForEvents from "../DetailsPanel/TeamMembers/ContactsForEvents";
import BulkEditableField from "../../Common/BulkEditableField/BulkEditableField";
import {TimeRangeWithPresets} from "../DetailsPanel/DateAndTime";
import {TagType} from "../../../constants/constants";
import TagsSelect from "../../Common/Selects/TagsSelect";

const {RangePicker} = DatePicker;

const EventInputs = ({
                         form,
                         isSingleProject,
                         projectDepartments,
                         departments,
                         projectCategories,
                         categories,
                         projectIds,
                         setIsQuickCreateOrgVisible,
                         setIsQuickCreateContactVisible,
                         locationGroups,
                         statusOptions,
                         dateFormat,
                         timeFormat,
                         selectedTimeZone,
                     }) => {
    return (
        <>
            <BulkEditableField name="Name" form={form}>
                {(name, isFieldDisabled) => (
                    <InputWithFormItem disabled={isFieldDisabled} name={name} label="Name" placeholder="Enter a name"/>
                )}
            </BulkEditableField>
            <BulkEditableField name="Date" form={form}>
                {(name, isFieldDisabled) => (
                    <Form.Item label="Date" name={name} className="ant-form-item-without-validation">
                        <RangePicker
                            disabled={isFieldDisabled}
                            placeholder={["Start date", "End date"]}
                            format={dateFormat}
                            showTime={false}></RangePicker>
                    </Form.Item>
                )}
            </BulkEditableField>
            <BulkEditableField name="Time" form={form}>
                {(name, isFieldDisabled) => (
                    <>
                        <Form.Item label="Time" name={name} className="ant-form-item-without-validation">
                            <TimeRangeWithPresets
                                isDisabled={isFieldDisabled}
                                popupClassName="time-range-picker"
                                timeFormat={timeFormat}></TimeRangeWithPresets>
                        </Form.Item>
                    </>
                )}
            </BulkEditableField>
            <Form.Item wrapperCol={{offset: 8}} className="ant-form-item-without-validation">
                {selectedTimeZone}
            </Form.Item>
            <BulkEditableField name="StatusTagId" form={form}>
                {(name, isFieldDisabled) => (
                    <SelectWithFormItem
                        allowClear={true}
                        label="Status"
                        name={name}
                        showSearch={true}
                        placeholder="Select a Status"
                        options={statusOptions}
                        disabled={isFieldDisabled}
                    />
                )}
            </BulkEditableField>
            <BulkEditableField name="CategoryTags" form={form}>
                {(name, isFieldDisabled) => (
                    <TagsSelect
                        tagType={TagType.Category}
                        disabled={isFieldDisabled}
                        label="Categories"
                        name={name}
                        placeholder="Select categories"
                        form={form}
                        groups={[geTagsGroup("Project Categories", projectCategories), geTagsGroup("All Categories", categories)]}
                    />
                )}
            </BulkEditableField>

            <BulkEditableField name="DepartmentTags" form={form}>
                {(name, isFieldDisabled) => (
                    <TagsSelect
                        tagType={TagType.Department}
                        disabled={isFieldDisabled}
                        label="Departments"
                        name={name}
                        placeholder="Select departments"
                        form={form}
                        groups={[geTagsGroup("Project Departments", projectDepartments), geTagsGroup("All Departments", departments)]}
                    />
                )}
            </BulkEditableField>
            <BulkEditableField name="Notes" form={form}>
                {(name, isFieldDisabled) => (
                    <TextAreaWithFormItem
                        label="Details"
                        disabled={isFieldDisabled}
                        name={name}
                        useTextEditor={true}
                        placeholder="Enter details"></TextAreaWithFormItem>
                )}
            </BulkEditableField>
            <BulkEditableField name="IsFavorite" form={form}>
                {(name, isFieldDisabled) => (
                    <Form.Item label="Highlight" name={name} valuePropName="checked"
                               className="ant-form-item-without-validation">
                        <Switch disabled={isFieldDisabled}/>
                    </Form.Item>
                )}
            </BulkEditableField>
            <BulkEditableField name="Locations" form={form}>
                {(name, isFieldDisabled) => (
                    <SelectWithFormItem
                        allowClear={true}
                        disabled={isFieldDisabled}
                        label="Location"
                        name={name}
                        showSearch={true}
                        mode="multiple"
                        style={{width: "100%"}}
                        placeholder="Select a location"
                        filterOption={(input, option) => (option.header || option.label).toLowerCase().indexOf(input?.toLowerCase()) >= 0}>
                        {locationGroups.map((group, gIndex) => (
                            <Select.OptGroup
                                header={group.group}
                                label={
                                    <>
                                        <EnvironmentOutlined/> {group.group}
                                    </>
                                }
                                key={gIndex}>
                                {group.items.map((contact) => (
                                    <Select.Option label={contact.label} key={contact.value} value={contact.value}>
                                        <ExpandOutlined/> {contact.label}
                                    </Select.Option>
                                ))}
                            </Select.OptGroup>
                        ))}
                    </SelectWithFormItem>
                )}
            </BulkEditableField>
            <BulkEditableField name="LocationAvailability" form={form}>
                {(name, isFieldDisabled) => (
                    <Form.Item
                        label="Affect Location Availability"
                        className="long-label ant-form-item-without-validation"
                        name={name}
                        valuePropName="checked">
                        <Switch disabled={isFieldDisabled}/>
                    </Form.Item>
                )}
            </BulkEditableField>
            {isSingleProject && (
                <BulkEditableField name="TeamMemberIds" form={form}>
                    {(name, isFieldDisabled) => (
                        <TeamMembersForEvent
                            allowClear={true}
                            projectId={projectIds[0]}
                            isOptionalFieldDisabled={isFieldDisabled}
                            name={name}
                            label={"Team"}
                            setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                            setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                            form={form}
                            onChanged={(val) => {
                            }}></TeamMembersForEvent>
                    )}
                </BulkEditableField>
            )}
            {!isSingleProject && (
                <BulkEditableField name="TeamMemberContactIds" form={form}>
                    {(name, isFieldDisabled) => (
                        <ContactsForEvents
                            allowClear={true}
                            isOptionalFieldDisabled={isFieldDisabled}
                            name={name}
                            setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                            setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                            onChanged={() => {
                            }}></ContactsForEvents>
                    )}
                </BulkEditableField>
            )}
        </>
    );
};

export default EventInputs;
