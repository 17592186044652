import React from "react";
import {Row, Col} from "antd";
import Breadcrumb from "./Breadcrumb";
import {getRoute} from "../../helpers";
import {useLocation} from "react-router-dom";
import "./TitleRow.scss";
import UserProfile from "../UserProfile/UserProfile";

const TitleRow = ({
                      hideTitleButtons = false,
                      breadcrumbSpan = 11,
                      titleButtonsSpan = 11,
                      userProfileSpan = 2,
                      userProfileOffset = 0
                  }) => {
    const route = getRoute(useLocation().pathname);
    return (
        <>
            <Row>
                <Col span={breadcrumbSpan}>
                    <Breadcrumb/>
                </Col>
                <Col span={titleButtonsSpan} className="title-buttons-holder">
                    {!hideTitleButtons && route.titleButtons && <route.titleButtons/>}
                </Col>
                <Col span={userProfileSpan} offset={userProfileOffset}>
                    <UserProfile></UserProfile>
                </Col>
            </Row>
            {route.headerSecondRow && <route.headerSecondRow/>}
        </>
    );
};

export default TitleRow;
