import React, {useState, useEffect} from "react";
import _ from "lodash";
import {message, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {getResourceUsage} from "../../../services/resources";
import {ResourceType} from "../../../constants";
import {startLoading, endLoading} from "../../../redux/reducers/detailsPanel";
import {getDaysOfMonth} from "../../../helpers/time";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import moment from "moment";
import {refreshContacts} from "../../../services/contacts";
import FilterBar from "./FilterBar";

const UsageChart = ({items = []}) => {
    const dispatch = useDispatch();
    var formats = useDateTimeFormat();
    const activeMonthStr = useSelector((state) => state.resources.activeMonth);
    const activeMonth = activeMonthStr && moment(activeMonthStr, formats.monthYearFull);
    const [datesList, setDatesList] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        const dates = getDaysOfMonth(activeMonth);
        setDatesList(dates);
    }, [activeMonthStr]);

    function loadItemToEdit(req) {
        dispatch(startLoading({item: req, type: ResourceType.Usage}));
        refreshContacts().then(() => {
            getResourceUsage(req.Id)
                .then((resp) => {
                    return dispatch(endLoading({item: resp}));
                })
                .catch(() => message.error("Server error"));
        })
            .catch(() => message.error("Server error"));
    }

    const columns = [
        {
            title: "Item",
            dataIndex: "Name",
            width: "300px",
            key: "Id"
        },
        ...datesList.map((d, i) => {
            return {
                title: d.format("MMM D"),
                width: "40px",
                render: (item) => {
                    const allocations = item?.UsageItems[i]?.Allocations || [];
                    const overs = item?.UsageItems[i]?.OverAllocations || [];
                    const qty = _.sumBy(allocations, a => a.Quantity);

                    if (!qty) return null;

                    const className = overs.length > 0 ? "cell-conflict" : "cell-filled";

                    return {
                        props: {
                            className: className,
                            style: {backgroundColor: overs.length > 0 ? "#faad14" : "#bcbcbc", textAlign: "center"}
                        },
                        children: <>{qty}</>
                    };
                },
                onCell: (record, rowIndex) => {
                    return {
                        onClick: event => { // click cell
                            const cellIndex = i;
                            const date = activeMonth
                                .clone()
                                .date(cellIndex + 1);

                            const dateFormatted = date.format(formats.universalDateFormat);
                            const item = {
                                Id: `${record.Id}_${dateFormatted}`,
                                Name: record.Name,
                                ResourceId: record.Id,
                                Date: dateFormatted
                            }

                            loadItemToEdit(item);
                        },
                    };
                }
            }
        })
    ];

    return (
        <>
            <FilterBar items={items} applyFilters={setFilteredItems}/>
            <Table
                scroll={{x: 'max-content'}}
                pagination={false}
                bordered
                className="common-table"
                size="small"
                dataSource={filteredItems}
                columns={columns}
                onSelect={(column) => {
                }}
            />
        </>
    );
};

export default UsageChart;
