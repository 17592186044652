import React from "react";
import _ from "lodash";
import {ContactGroupBy} from "./FilterBar/GroupBy";
import GroupedTables from "../Common/Tables/GroupedTable";

function getAllRoles(data) {
    return _.chain(data)
        .map((d) => d.Specialties)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getAllGroups(data) {
    return _.chain(data)
        .map((d) => d.Groups)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getGroups(groupBy, dataSource) {
    let groups = [];
    let noGroupItems = [];
    let noGroupText = "";
    switch (groupBy) {
        case ContactGroupBy.DefaultRoles:
            const roles = getAllRoles(dataSource);

            groups = roles.map((role) => ({
                group: role.Name,
                items: dataSource.filter((item) => item.Specialties.map((x) => x.AccountTagId).includes(role.AccountTagId)),
            }));
            noGroupItems = dataSource.filter((item) => item.Specialties.length === 0);
            noGroupText = "No Role";

            break;
        case ContactGroupBy.DefaultGroups:
            const allGroups = getAllGroups(dataSource);
            groups = allGroups.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.Groups.map((x) => x.AccountTagId).includes(group.AccountTagId)),
            }));
            noGroupItems = dataSource.filter((item) => item.Groups.length === 0);
            noGroupText = "No Group";

            break;
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({group: noGroupText, items: noGroupItems});
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });

    return groups;
}

const GroupContactsTable = ({columns, dataSource, onRow, groupByFilter}) => (
    <GroupedTables
        columns={columns}
        dataSource={dataSource}
        onRow={onRow}
        getGroups={() => getGroups(groupByFilter.value, dataSource)}
    />
);

export default GroupContactsTable;
