import React from "react";
import ChangedAtFilter from "../../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import FilterBar from "../../../Common/Tables/FilterBar";

import SearchFilter from "./SearchFilter";

const CollectionsFilterBar = (props) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[ChangedAtFilter, SearchFilter]}
        />
    );
};

export default CollectionsFilterBar;
