import api from "../api";
import store from "../redux/store";
import {setRulesets} from "../redux/reducers/labour";

const {dispatch} = store;

export const loadRulesets = (accountId) =>
    api.post(`api/LabourRuleset/GetByAccount`, {accountId})
        .then((response) => {
            dispatch(setRulesets(
                response.Items
            ));
        });

export const createRuleset = (payload) =>
    api.post(`api/LabourRuleset/Create`, payload, `Create_Ruleset_${payload?.AccountId}`);

export const updateRuleset = (payload) =>
    api.post(`api/LabourRuleset/Update`, payload, `Update_Ruleset_${payload?.Id}`);

export const deleteRuleset = ({RemoveId, ReplacementId}) =>
    api.post(`api/LabourRuleset/Remove`, {RemoveId, ReplacementId}, `Ruleset_Remove_${RemoveId}`);
