import React, {useState, useEffect, useRef} from "react";
import {Modal, Form, message} from "antd";
import {FileTextFilled, ProjectOutlined, ReconciliationOutlined} from "@ant-design/icons";
import {SelectWithFormItem, SelectWithFormItemRef} from "../../Common/Input";
import {useSelector, useDispatch} from "react-redux";
import {openEdit} from "../../../redux/reducers/detailsPanel";
import {ApiProjectType, SidePanelItemType} from "../../../constants/constants";
import {errorFromHttpResponse} from "../../../helpers/error";
import {mapProjectWithBadge} from "../../../helpers/Tags";
import {createFromTemplate, loadReports} from "../../../services/reports";
import _ from "lodash";

const Title = () => (<>
    <FileTextFilled/> New Report from Template
</>);

const mapTemplates = (template) => ({
    value: template.Id,
    label: template.Name,
});

const CreateFromTemplate = ({isVisible, onClose}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();

    const selected = useSelector((store) => store.mainTable.selected) || [];
    const projects = useSelector((state) => state.projects.projects).filter((p) => [ApiProjectType.Active, ApiProjectType.Inactive].includes(p.State));
    const activeProject = useSelector((state) => state.projects.activeProject);
    const templates = useSelector((state) => state.reports.templates);
    const [loading, setLoading] = useState(false);
    const singleTemplateSelected = selected.length === 1 && _.isNil(selected[0].Type);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        if (isVisible) {
            const formValues = singleTemplateSelected ? {TemplateId: selected[0].Id} : {ProjectId: activeProject.Id};

            form.setFieldsValue(formValues);

            if (!singleTemplateSelected) {
                setTimeout(() => firstInputRef.current?.focus(), 200);
            }
        }
    }, [isVisible]);

    async function handleOk() {
        try {
            setLoading(true);
            const values = await form.validateFields();
            const response = await createFromTemplate(values.TemplateId, values.ProjectId);
            message.success(`${response.Name} was created`);

            dispatch(openEdit({item: response, type: SidePanelItemType.Reports}));
            loadReports(values.ProjectId, activePortfolio.Id);

            close();
        } catch (err) {
            if (err.errorFields) return;

            const errorText = errorFromHttpResponse(err);
            message.error(errorText);
        } finally {
            setLoading(false);
        }
    }

    function close() {
        onClose();
        form.resetFields();
    }

    return (<>
        <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
            <Modal
                confirmLoading={loading}
                title={<Title/>}
                visible={isVisible}
                maskClosable={false}
                onOk={handleOk}
                onCancel={close}
                okText="Create New Report"
                className="create-report-modal">
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">

                    <SelectWithFormItemRef
                        ref={firstInputRef}
                        showSearch
                        label="Template"
                        name="TemplateId"
                        hasFeedback
                        rules={[{required: true, message: "Template"}]}
                        style={{width: "100%"}}
                        placeholder="Select a Template"
                        suffixIcon={<ReconciliationOutlined/>}
                        options={templates.map(mapTemplates)}
                    />

                    <SelectWithFormItem
                        showSearch
                        label="Project"
                        name="ProjectId"
                        hasFeedback
                        rules={[{required: true, message: "Project"}]}
                        style={{width: "100%"}}
                        placeholder="Select a Project"
                        suffixIcon={<ProjectOutlined/>}
                        options={projects.map(mapProjectWithBadge)}
                    />
                </Form>
            </Modal>
        </Form>
    </>);
};
export default CreateFromTemplate;
