import React from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {ContactType} from "../../../constants/constants";
import BatchEdit from "../BatchEdit";

import {openNew} from "../../../redux/reducers/detailsPanel";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../../helpers/paths";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import usePermissions from "../../../hooks/usePermissions";
import {doBatchDeleteContacts} from "../DeleteContact";
import {setSelected} from "../../../redux/reducers/mainTable";
import {refreshContacts} from "../../../services/contacts";
import MergeContact, {canMergeContact} from "../MergeContact/MergeContact";
import ContactImport from "../ContactImport";
import ContactExport from "../ContactExport";
import AddToTeams from "../AddToTeams/AddToTeams";
import ClearSelection from "../../Common/ClearSelection";

const selectedType = {
    [ContactType.Person]: "People",
    [ContactType.Organization]: "Organizations",
    [ContactType.Location]: "Locations",
};
const ContactSelected = ({selected, contactType}) =>
    <div>{`${selected.length} ${selectedType[contactType]} Selected`}</div>;

const ContactsTitleRowButtons = ({contactType}) => {
    const history = useHistory();
    const selected = useSelector((store) => store.mainTable.selected);
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {isProjectManager, canCreateContacts} = usePermissions();

    function handleNewContactMenuClick(e) {
        const newContactType = e.key;
        history.push(urlWithPortfolioId(pathCollection.contactsPath[newContactType]));
        setTimeout(() => dispatch(openNew({type: newContactType})), 100);
    }

    const dispatch = useDispatch();
    const isMergeDisabled = selected.length !== 1 || isProjectManager || !canMergeContact(selected[0]);
    const isAddToTeamDisabled = selected.length === 0;
    const MoreMenu = (
        <Menu>
            <Menu.Item disabled={isMergeDisabled} key="1">
                <MergeContact disabled={isMergeDisabled} contact={selected[0]}></MergeContact>
            </Menu.Item>
            <Menu.Item disabled={isAddToTeamDisabled} key="2">
                <AddToTeams disabled={isAddToTeamDisabled} contactType={contactType}></AddToTeams>
            </Menu.Item>
            <Menu.Item disabled={!canCreateContacts} key="3">
                <ContactImport disabled={!canCreateContacts} contactType={contactType}></ContactImport>
            </Menu.Item>
            <Menu.Item disabled={false} key="4">
                <ContactExport contactType={contactType}></ContactExport>
            </Menu.Item>
            <Menu.Item
                key="5"
                disabled={selected.length === 0 || isProjectManager}
                onClick={() =>
                    doBatchDeleteContacts(selected, () => {
                        dispatch(setSelected([]));
                        refreshContacts();
                    })
                }>
                Delete
            </Menu.Item>
        </Menu>
    );

    const NewContactMenu = (
        <Menu onClick={handleNewContactMenuClick}>
            <Menu.Item key={ContactType.Person}>Person</Menu.Item>
            <Menu.Item key={ContactType.Organization}>Organization</Menu.Item>
            <Menu.Item key={ContactType.Location}>Location</Menu.Item>
        </Menu>
    );

    return (
        <Space>
            {isBulkSelection && <ContactSelected contactType={contactType} selected={selected}/>}
            <ClearSelection/>

            <Dropdown trigger="click" overlay={MoreMenu}>
                <Button>
                    More <DownOutlined/>
                </Button>
            </Dropdown>
            {!isBulkSelection && (
                <Dropdown disabled={!canCreateContacts} trigger="click" overlay={NewContactMenu}>
                    <Button type="primary">
                        New Contact <DownOutlined/>
                    </Button>
                </Dropdown>
            )}
            {isBulkSelection && <BatchEdit contactType={contactType} selected={selected}/>}
        </Space>
    );
};

export default ContactsTitleRowButtons;
