import React from "react";
import "./Home.scss";
import PortfolioSelector from "./PortfolioSelector/";
import RecentlyUpdatedProjects from "./RecentlyUpdatedProjects/";
import {TodaysNotes} from "./TodaysNotes/TodaysNotes";

const Home = () => {
    return (<div className="home_page">
        <div className="col1">
            <PortfolioSelector></PortfolioSelector>
            <RecentlyUpdatedProjects></RecentlyUpdatedProjects>
        </div>
        <div className="col2">
            <TodaysNotes/>
        </div>
    </div>);
};

export default Home;
