import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {TagIcon} from "../../../../helpers/Tags";
import {ExclamationCircleFilled, ToolFilled, ToolOutlined, FileTextFilled} from "@ant-design/icons";

const noItem = {
    value: "noItem",
    title: "— No Status —",
    sortRank: 1,
};

const ConflictOption = {
    icon: <ExclamationCircleFilled className="warning-icon"/>,
    title: "Conflicts",
    value: "Conflicts",
    sortRank: 4,
};

const LabourLinesOption = {
    icon: <ToolOutlined/>,
    title: "Labour Lines",
    value: "LabourLines",
    sortRank: 5,
};

const BookingsOption = {
    icon: <ToolFilled/>,
    title: "Crew Bookings",
    value: "CrewBookings",
    sortRank: 6,
};

const ReportsOption = {
    icon: <FileTextFilled/>,
    title: "Reports",
    value: "Reports",
    sortRank: 6,
};

export const getStatuses = (data) => {
    return _.chain(data)
        .filter((d) => d.StatusTag)
        .map((d) => d.StatusTag)
        .uniqBy((x) => x.AccountTagId)
        .map((item) => ({
            value: item.AccountTagId,
            title: item.Name,
            icon: <TagIcon icon={item.Icon}/>,
            sortRank: 2,
        }))
        .sortBy((x) => x.title)
        .value();
};

const StatusFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const items = useMemo(() => {
        return [noItem, ...getStatuses(data), ConflictOption, LabourLinesOption, BookingsOption, ReportsOption];
    }, [data]);

    const rolesHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [rolesHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [rolesHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler(
            (item) =>
                filterValues.includes(item.StatusTag?.AccountTagId) ||
                (isNoItemSelected && !item.StatusTag) ||
                (filterValues.includes(ConflictOption.value) &&
                    (item.LocationConflicts?.length > 0 || item.BookingConflicts?.length > 0)) ||
                (filterValues.includes(LabourLinesOption.value) && item.LabourLines?.length > 0 && !item.HasBookings) ||
                (filterValues.includes(BookingsOption.value) && item.HasBookings) ||
                (filterValues.includes(ReportsOption.value) && item.HasDeliverables)
        );
        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Statuses"
            onChanged={onChanged}
            search
        />
    );
};

export default StatusFilter;
