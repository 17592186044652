import React from "react";
import {DeleteOutlined} from "@ant-design/icons";

const DeleteAction = ({onClick, record, className}) => (
    <DeleteOutlined
        className={className}
        onClick={(e) => {
            e.stopPropagation();

            if (onClick) {
                onClick(record);
            }
        }}
    />
);

export default DeleteAction;
