import React from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import BatchEdit from "../BatchEdit";

import {openNew} from "../../../redux/reducers/detailsPanel";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../../helpers/paths";
import {TeamContactType} from "../../../constants/constants";
import DeleteTeamMember from "../Delete/DeleteTeamMember";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import usePermissions from "../../../hooks/usePermissions";
import ClearSelection from "../../Common/ClearSelection";
import Export from "../Export";
import Email from "../Email/Email";


const TeamSelected = ({selected}) => <div>{`${selected.length} Team Members selected`}</div>;

const TeamTitleRowButtons = ({teamContactType}) => {

    const history = useHistory();
    const selected = useSelector((store) => store.mainTable.selected);
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {canCreateTeamMembers} = usePermissions();

    function onNewTeamMemberMenuClick(e) {
        const newContactType = e.key;

        history.push(urlWithPortfolioId(pathCollection.teamPath[newContactType]));
        setTimeout(() => dispatch(openNew({type: newContactType})), 100);
    }

    const dispatch = useDispatch();
    const NewTeamMemberMenu = (
        <Menu onClick={onNewTeamMemberMenuClick}>
            <Menu.Item key={TeamContactType.Person}>Person</Menu.Item>
            <Menu.Item key={TeamContactType.Organization}>Organization</Menu.Item>
            <Menu.Item key={TeamContactType.Location}>Location</Menu.Item>
        </Menu>
    );

    const MoreMenu = (
        <Menu>
            <Menu.Item disabled={false} key="1">
                <Export teamContactType={teamContactType}></Export>
            </Menu.Item>
            <Menu.Item key="2">
                <Email selected={selected}></Email>
            </Menu.Item>
        </Menu>
    );

    const shouldHideMoreButton = teamContactType === TeamContactType.Organization || teamContactType === TeamContactType.Location;

    return (
        <Space>
            {isBulkSelection && <TeamSelected selected={selected}/>}
            <ClearSelection/>
            <DeleteTeamMember/>
            {!shouldHideMoreButton && (
                <Dropdown trigger="click" overlay={MoreMenu}>
                    <Button>
                        More <DownOutlined/>
                    </Button>
                </Dropdown>
            )}
            {!isBulkSelection && (
                <Dropdown disabled={!canCreateTeamMembers} trigger="click" overlay={NewTeamMemberMenu}>
                    <Button type="primary">
                        New Team Member <DownOutlined/>
                    </Button>
                </Dropdown>
            )}
            {isBulkSelection && <BatchEdit selected={selected}/>}
        </Space>
    );
};

export default TeamTitleRowButtons;