import React from "react";
import {useSelector} from "react-redux";
import {downloadResourceItemsAsCsv} from "../../../services/resources";

const Export = () => {
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const selectedItems = useSelector((store) => store.mainTable.selected);

    const onExport = () => {
        const selectedItemIds = selectedItems.map((d) => d.Id);

        downloadResourceItemsAsCsv(activePortfolio?.Id, activePortfolio?.Contact?.Company, selectedItemIds);
    };

    return <div onClick={onExport}>Export</div>;
};

export default Export;