import {ExclamationCircleFilled, EyeOutlined, UnorderedListOutlined} from "@ant-design/icons";
import moment from "moment";
import {Card, Button, List, Row, Col, Empty} from "antd";
import {refreshNotes} from "../../../services/reports";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {ProjectTagForId} from "../../Common/Projects/ProjectsTag";
import "./TodaysNotes.scss";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {ApiProjectType, SidePanelItemType} from "../../../constants/constants";
import {pathCollection} from "../../../helpers/paths";
import {setNotesFilterIsDone} from "../../../redux/reducers/reports";
import {setActiveProjects} from "../../../redux/reducers/projects";
import {openEdit} from "../../../redux/reducers/detailsPanel";
import {TodaysNotesFilterBar} from "./FilterBar/FilterBar";
import {sortNotes} from "../../Timeline/Notes/GroupNotesTable";
import {sanitizeHTML} from "../../../helpers/html";

export const TodaysNotes = () => {
    const [loading, setLoading] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const notes = useSelector((state) => state.reports.notes);
    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const today = moment();
    const items = useMemo(() => notes.filter((item) => !item.IsDone && item.DueDate), [notes]);

    const overdueItems = useMemo(() => sortNotes(filteredItems.filter((item) => moment(item.DueDate).isBefore(today, "day")), projects), [filteredItems]);

    const todayItems = useMemo(() => sortNotes(filteredItems.filter((item) => moment(item.DueDate).isSame(today, "day")), projects), [filteredItems]);

    useEffect(() => {
        let isMounted = true;

        (async () => {
            if (isMounted) setLoading(true);
            try {
                await refreshNotes(projects.map((x) => x.Id));
            } catch (error) {
                console.error(error);
            }
            if (isMounted) setLoading(false);
        })();

        return () => {
            isMounted = false;
        };
    }, [projects.map((p) => p.Id).join(", ")]);

    const goToNote = (note) => {
        seeAllNotes();
        setTimeout(() => {
            dispatch(openEdit({item: note, type: SidePanelItemType.Notes}));
        }, 200);
    };

    const seeAllNotes = () => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Notes]));
        dispatch(setActiveProjects(projects));
        dispatch(setNotesFilterIsDone(false));
    };

    return (<Card
        loading={loading}
        type="inner"
        style={{width: "100%"}}
        title={<>
            <UnorderedListOutlined/> Today's Notes
        </>}
        extra={<Button type="link" size="small" onClick={seeAllNotes}>
            See All Notes
        </Button>}
        className="recently-updated-projects">
        <TodaysNotesFilterBar
            items={items}
            applyFilters={(filtered) => {
                setFilteredItems(filtered);
            }}
        />
        {overdueItems.length > 0 && (
            <NotesTable items={overdueItems} goToNote={goToNote} headerTitle="Overdue" showOverdueIcon={true}/>)}
        {todayItems.length > 0 && <NotesTable items={todayItems} goToNote={goToNote} headerTitle="Due Today"/>}
        {overdueItems.length === 0 && todayItems.length === 0 && (
            <Empty description="No notes due today. Hooray!" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>)}
    </Card>);
};

const NotesTable = ({items, headerTitle, goToNote, showOverdueIcon = false}) => (<List
    dataSource={items}
    className="table-in-panel home-notes"
    header={<>
        {headerTitle} ({items.length}) {showOverdueIcon && <ExclamationCircleFilled className="error-icon"/>}
    </>}
    renderItem={(item) => (<List.Item actions={[]}>
        <div className="notes-project">
            <ProjectTagForId projectId={item.ReportInfo.ProjectId}/>
        </div>
        <div className="notes-text"
             dangerouslySetInnerHTML={{__html: sanitizeHTML(item.Text)}}></div>
        <div className="notes-action">
            <Button type="link" size="small" onClick={() => goToNote(item)}>
                <EyeOutlined/>
            </Button>
        </div>
    </List.Item>)}></List>);
