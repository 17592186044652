import {Button, Form, Input, message} from "antd";
import React from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {SelectWithFormItem} from "../../Common/Input";
import {LabourCostUnit, ResourceType} from "../../../constants/constants";
import {EyeOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../helpers/paths";
import {openEdit} from "../../../redux/reducers/detailsPanel";
import {getPosition, refreshPositions} from "../../../services/positions";
import {useEffect} from "react";
import {formatPosition} from "../../../helpers/text";

const toLabourCostUnit = (positionType) => {
    if (positionType === "Flat") return LabourCostUnit.Flat;

    return LabourCostUnit.Hour;
};

export const sortLabourLines = (items) =>
    _.sortBy(items, [(i) => i.Position?.Source?.Name?.toLowerCase(), (i) => i.Position?.Name?.toLowerCase()]);

export const sortPositions = (items) => _.sortBy(items, [(p) => p.Source?.Name?.toLowerCase(), (p) => p.Name.toLowerCase()]);

export const mapPositionToSelect = ({Id, Name, Source}, usedPositions = []) => ({
    label: formatPosition({Name, Source}),
    value: Id,
    disabled: (usedPositions || []).includes(Id),
});

const PositionSelector = ({form, itemToEdit, save, isOptionalFieldDisabled, name}) => {
    const usedPositions = _.chain(useSelector((state) => state.labour.lines))
        .filter((p) => p.Position && itemToEdit)
        .filter((p) => p.Task.Id === itemToEdit.Task?.Id)
        .map((p) => p.Position.Id)
        .uniqBy((id) => id)
        .value();

    const positions = useSelector((state) => state.labour.positions);

    useEffect(() => {
        if (positions.length === 0) refreshPositions();
    }, []);

    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const onPositionChanged = (positionId) => {
        const position = positions.find((p) => p.Id === positionId);
        const Cost = position ? position.Cost : 0;
        const Unit = position ? toLabourCostUnit(position.Unit) : LabourCostUnit.Hour;

        form.setFieldsValue({
            [name]: positionId,
            Cost,
            Unit,
        });
        save();
    };

    const goToPosition = () => {
        history.push(urlWithPortfolioId(pathCollection.resourcesPath[ResourceType.Position]));
        getPosition(itemToEdit.Position.Id)
            .then((item) => dispatch(openEdit({item, type: ResourceType.Position})))
            .catch(() => message.error("Server error"));
    };

    return (
        <Form.Item label="Position" className="ant-form-item-without-validation">
            <Input.Group>
                <SelectWithFormItem
                    allowClear
                    disabled={isOptionalFieldDisabled}
                    name={name}
                    className="ant-form-item-without-validation"
                    showSearch={true}
                    style={{width: "90%", maxWidth: "300px"}}
                    placeholder="Select a Position"
                    options={sortPositions(positions).map((p) => mapPositionToSelect(p, usedPositions))}
                    onChanged={onPositionChanged}
                />
                <Form.Item className="goto-event goto-event-labour">
                    {itemToEdit?.Position?.Id && (
                        <Button type="link" size="small" onClick={goToPosition}>
                            <EyeOutlined/>
                        </Button>
                    )}
                </Form.Item>
            </Input.Group>
        </Form.Item>
    );
};

export default PositionSelector;
