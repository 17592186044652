import {Card, Space, Spin, Form, Button, message} from "antd";
import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {timeToLong} from "../../../helpers/time";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import usePermissions from "../../../hooks/usePermissions";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {loadContacts, updateContact} from "../../../services/contacts";
import {loadPortfolios} from "../../../services/projects";
import {loadCurrentPlan} from "../../../services/settings";
import {InputWithFormItem} from "../../Common/Input";

const Subscription = () => {
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(null);
    const currentPlan = useSelector((state) => state.settings.currentPlan);
    const {canAccessSubscription} = usePermissions();
    const dateTimeFormats = useDateTimeFormat();

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const profileContact = activePortfolio?.Contact;

    if (activePortfolio && !canAccessSubscription) {
        history.push(urlWithPortfolioId("/"));
    }

    useEffect(() => {
        if (activePortfolio) {
            setIsLoading(true);
            loadContacts(activePortfolio.Id).finally(() => setIsLoading(false));
            loadCurrentPlan(activePortfolio.Id, activePortfolio.TimeZoneId);
        }
    }, [activePortfolio]);

    useEffect(() => {
        if (profileContact) setName(profileContact.Company);
    }, [profileContact]);

    const updateName = () => {
        if (!profileContact) return;

        if (name !== profileContact.Company) {
            updateContact({
                ...profileContact,
                Company: name,
                FirstName: name,
                LastName: "",
            })
                .then(() => Promise.all([loadPortfolios(), loadContacts(activePortfolio.Id)]))
                .catch((err) => {
                    console.error(err);
                    message.error("Unable to update Contact");
                });
        }
    };

    return (
        <Spin spinning={isLoading}>
            {currentPlan && (
                <Space className="settings-blocks">
                    <Card
                        type="inner"
                        title="Subscription"
                        extra={
                            <Button type="primary" size="small" href={currentPlan?.CurrentSubscription?.HamUrl}
                                    target="_blank">
                                See Billing Info
                            </Button>
                        }>
                        {currentPlan && (
                            <Form labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                                <InputWithFormItem
                                    disabled={!profileContact}
                                    label="Organization"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    onChanged={updateName}></InputWithFormItem>
                                <Form.Item label="Next Billing Cycle">
                                    {timeToLong(currentPlan.CurrentSubscription?.RenewalDateTime, dateTimeFormats)}
                                </Form.Item>
                            </Form>
                        )}
                    </Card>
                </Space>
            )}
        </Spin>
    );
};

export default Subscription;
