import React, {useEffect} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import TreeSelectFilter from "./TreeSelectFilter";

export const selectDefaultOption = {value: "— All Departments —", title: "— All Departments —"};
export const noDepartmentsOption = {value: "— Events With No Departments —", title: "— Events With No Departments —"};

const Departments = ({projectIds, setFilterHandler, value, disabled, onChanged, onChange}) => {
    const departments = useSelector((state) => state.tags.departments || []);

    function initFilterHandler(filterValues = []) {
        if (filterValues.length === 0) {
            setFilterHandler(null);
        } else {
            const isNoItemSelected = filterValues.find((i) => i === noDepartmentsOption.value);
            setFilterHandler(
                (item) =>
                    _.intersection(item.DepartmentTags, filterValues).length > 0 || (isNoItemSelected && item.DepartmentTags.length === 0)
            );
        }
    }

    function onItemsChanged(filterValues) {
        initFilterHandler(filterValues);

        onChange(filterValues);
        onChanged();
    }

    useEffect(() => initFilterHandler(value), [value]);

    const departmentOption = "All Other Departments";
    const groups = [
        {
            title: "All Other Departments",
            key: departmentOption,
            value: "All Other Departments",
            filterOff: true,
            children: [noDepartmentsOption, ...departments.map((d) => ({
                value: d.AccountTagId.toString(),
                title: d.Name
            }))],
        },
    ];

    return (
        <TreeSelectFilter
            treeDefaultExpandedKeys={[departmentOption]}
            value={value}
            disabled={disabled}
            treeData={groups}
            placeholder="All Departments"
            selectDefaultOption={selectDefaultOption}
            onChanged={onItemsChanged}></TreeSelectFilter>
    );
};

export default Departments;
