import React from "react";
import {message} from "antd";
import BatchDelete from "../../Common/Modal/BatchDelete/BatchDelete";
import {remove, loadAccountMembers} from "../../../services/settings";
import {useDispatch, useSelector} from "react-redux";
import {formatContactName} from "../../../helpers/text";
import {setSelected} from "../../../redux/reducers/mainTable";

const showFinishMessage = () => {
    message.success(`User was deleted.`);
};

const getName = (user) => formatContactName(user?.CreatedFromContact, false);

const DeleteUserAccess = () => {
    const dispatch = useDispatch();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const selected = useSelector((store) => store.mainTable.selected) || [];
    return (
        <BatchDelete
            selected={selected}
            disabled={selected.length !== 1}
            buttonText="Remove User"
            okText="Yes, Remove Access"
            onFinish={() => {
                dispatch(setSelected([]));
                return loadAccountMembers(activePortfolio.Id);
            }}
            title={`Are you sure you want to remove user access for ${getName(selected[0])}?`}
            batchRemove={() => remove(selected[0].Id)}
            onFinishMessage={showFinishMessage}></BatchDelete>
    );
};
export default DeleteUserAccess;
