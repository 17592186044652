import {Badge, Col, Empty, List, Row} from "antd";
import React from "react";
import {ApiProjectType} from "../../../../constants/constants";
import GoTo from "../../../Common/Projects/GoTo";

const ListOfProjects = ({items, showGotTo = false, title}) =>
    items.length > 0 && (
        <List
            className="table-in-panel"
            header={title}
            dataSource={items}
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col offset={1} span={9}>
                            {item.Project.Name}{" "}
                            {item.Project.State === ApiProjectType.Inactive &&
                                <Badge className="project-status-badge" count="Draft"></Badge>}
                        </Col>
                        <Col offset={1} span={8} className="one-line-wrap">
                            {item.TeamMember.RoleTags.map((x, i, arr) =>
                                <React.Fragment key={i}>
                                    {x.Name}
                                    {i !== arr.length - 1 && <br/>}
                                </React.Fragment>
                            )}
                        </Col>
                        <Col offset={1} span={4}>
                            {showGotTo && <GoTo project={item.Project}></GoTo>}
                        </Col>
                    </Row>
                </List.Item>
            )}></List>
    );

const ProjectHistory = ({value}) => {
    const activeProjects = (value || []).filter((i) => [ApiProjectType.Active, ApiProjectType.Inactive].includes(i.Project.State));
    const archivedProjects = (value || []).filter((i) => i.Project.State === ApiProjectType.Archive);

    return (
        <>
            <ListOfProjects title="Active Projects" items={activeProjects} showGotTo={true}></ListOfProjects>
            <ListOfProjects title="Archived Projects" items={archivedProjects}></ListOfProjects>
            {activeProjects.length === 0 && archivedProjects.length === 0 && (
                <Empty description="No Project History" image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            )}
        </>
    );
};
export default ProjectHistory;
