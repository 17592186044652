import React, {useState} from "react";
import _ from "lodash";
import {BulbOutlined} from "@ant-design/icons";
import {Button, Drawer, Input, Space, Collapse, List, Row, Col} from "antd";
import {useSelector} from "react-redux";
import "./ResourceSearch.scss";

const mapResource = ({Name, DepartmentTag, Id}, SourceName) => ({
    Name,
    DepartmentTag,
    Id,
    SourceName,
});

const NoSourcesKey = "NoSourcesKey";
const NoSourcesTitle = "— No Source —";

const getPanelKey = (s, i) => `${s}_${i}`;

const ResourceSearch = ({isVisible, onClose, onSelect}) => {
    const [searchQuery, setSearchQuery] = useState("");
    let resources = useSelector((state) => state.resources.resources);
    const groups = {};

    if (!Array.isArray(resources)) {
        resources = [];
    }

    resources.forEach((r) => {
        if (r.Sources.length > 0) {
            r.Sources.forEach((s) => {
                const group = groups[s.ContactId];
                if (group) {
                    group.items.push(mapResource(r, s.Name));
                } else {
                    groups[s.ContactId] = {
                        title: s.Name,
                        items: [mapResource(r, s.Name)],
                    };
                }
            });
        } else {
            const group = groups[NoSourcesKey];
            if (group) {
                group.items.push(mapResource(r, NoSourcesTitle));
            } else {
                groups[NoSourcesKey] = {
                    title: NoSourcesTitle,
                    items: [mapResource(r, NoSourcesTitle)],
                };
            }
        }
    });

    const sources = _.keys(groups);
    var items = _.sortBy(
        sources.map((s) => groups[s]),
        (s) => s.title
    );

    const searchQueryFormat = searchQuery.toLowerCase();
    const filterItems = (items) => {
        const filtered = items.filter(
            (i) =>
                _.isEmpty(searchQueryFormat) ||
                i.Name.toLowerCase().indexOf(searchQueryFormat) !== -1 ||
                i.SourceName.toLowerCase().indexOf(searchQueryFormat) !== -1 ||
                (i.DepartmentTag?.Name || "").toLowerCase().indexOf(searchQueryFormat) !== -1
        );

        return _.sortBy(filtered, "Name");
    };
    return (
        <Drawer
            title={
                <>
                    <BulbOutlined/> Select an Item
                </>
            }
            visible={isVisible}
            onClose={onClose}
            className="sub-details-panel resource-search">
            <div className="fixed-search">
                <Space className="search-filter">
                    <Input.Search
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search"
                        allowClear={true}
                    />
                    <Button size="small" type="link" disabled={!searchQuery} onClick={() => setSearchQuery("")}>
                        Reset
                    </Button>
                </Space>
                <Row className="resource-table-header">
                    <Col span={10}>Item</Col>
                    <Col span={10}>Department</Col>
                    <Col span={4}></Col>
                </Row>
            </div>
            <Collapse defaultActiveKey={items.map((s, i) => getPanelKey(s, i))} className="resource-search-content">
                {items.map(
                    (s, i) =>
                        filterItems(s.items).length > 0 && (
                            <Collapse.Panel key={getPanelKey(s, i)} header={`${s.title} (${s.items.length})`}>
                                <List
                                    className="table-in-panel"
                                    dataSource={filterItems(s.items)}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Row>
                                                <Col span={10}>{item.Name}</Col>
                                                <Col span={10}>{item.DepartmentTag?.Name}</Col>
                                                <Col span={4}>
                                                    <Button size="small" type="link" onClick={() => onSelect(item)}>
                                                        Select
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Collapse.Panel>
                        )
                )}
            </Collapse>
        </Drawer>
    );
};

export default ResourceSearch;
