import {createSlice} from "@reduxjs/toolkit";

const teamSlice = createSlice({
    name: "team",
    initialState: {project: {}, teams: []},
    reducers: {
        setTeam(state, action) {
            const {projectId, items} = action.payload;
            state.project[projectId] = items

        },

        setTeams(state, action) {
            state.teams = action.payload
        },
    },
});

export const {setTeam, setTeams} = teamSlice.actions;

export default teamSlice.reducer;
