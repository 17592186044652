import React from "react";
import _ from "lodash";
import moment from "moment";
import {ProjectGroupBy} from "./FilterBar/GroupBy";
import GroupedTables from "../Common/Tables/GroupedTable";
import {ProjectType, ApiProjectType} from "../../constants";
import {formatContactName} from "../../helpers/text";
import {formatAmount} from "../../helpers/money";

function getAllCategories(data) {
    return _.chain(data)
        .map((d) => d.CategoryTags)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getAllProjectMgrs(data) {
    return _.chain(data)
        .filter((d) => d.ProjectAccountMembers !== null)
        .map((d) => d.ProjectAccountMembers.AssistantMembers)
        .flatten()
        .map((item) => ({
            value: item.Id,
            title: formatContactName(item.CreatedFromContact, false),
        }))
        .uniqBy((item) => item.value)
        .value();
}

function getGroups(groupBy, dataSource) {
    let groups = [];
    let noGroupItems = [];
    let noGroupText = "";
    switch (groupBy) {
        case ProjectGroupBy.Category:
            const roles = getAllCategories(dataSource);

            groups = roles.map((role) => ({
                group: role.Name,
                items: dataSource.filter((item) => item.CategoryTags.map((x) => x.AccountTagId).includes(role.AccountTagId)),
                rightText: <TotalHeader items={dataSource.filter((item) => item.CategoryTags.includes(role))}/>,
                rightTextClass: "header-totals-text-only header-project-total",
            }));
            noGroupItems = dataSource.filter((item) => item.CategoryTags.length === 0);
            noGroupText = "No Categories";

            break;
        case ProjectGroupBy.Month:
            const withMonth = dataSource
                .filter((d) => d.ProjectDate)
                .map((d) => ({month: moment(d.ProjectDate).format("MMMM YYYY"), item: d}));
            const withMonthGroups = _.groupBy(withMonth, "month");

            groups = _.keys(withMonthGroups).map((key) => ({
                group: key,
                items: withMonthGroups[key].map((i) => i.item),
                rightText: <TotalHeader items={withMonthGroups[key].map((i) => i.item)}/>,
                rightTextClass: "header-totals-text-only header-project-total",
            }));

            noGroupItems = dataSource.filter((item) => item.ProjectDate === null);
            noGroupText = "No Date";

            break;

        case ProjectGroupBy.Year:
            const withYear = dataSource
                .filter((d) => d.ProjectDate)
                .map((d) => ({year: moment(d.ProjectDate).format("YYYY"), item: d}));
            const withYearGroups = _.groupBy(withYear, "year");

            groups = _.keys(withYearGroups).map((key) => ({
                group: key,
                items: withYearGroups[key].map((i) => i.item),
                rightText: <TotalHeader items={withYearGroups[key].map((i) => i.item)}/>,
                rightTextClass: "header-totals-text-only header-project-total",
            }));

            noGroupItems = dataSource.filter((item) => item.ProjectDate === null);
            noGroupText = "No Date";

            break;

        case ProjectGroupBy.State:
            const states = [
                {title: ProjectType.Active, value: ApiProjectType.Active},
                {title: ProjectType.Inactive, value: ApiProjectType.Inactive},
            ];

            groups = states.map((state) => ({
                group: state.title,
                items: dataSource.filter((item) => item.State === state.value),
                rightText: <TotalHeader items={dataSource.filter((item) => item.State === state.value)}/>,
                rightTextClass: "header-totals-text-only header-project-total",
            }));

            break;

        case ProjectGroupBy.ProjectManager:
            const managers = getAllProjectMgrs(dataSource);
            groups = managers.map((manager) => ({
                group: manager.title,
                items: dataSource.filter((item) => _.some(item.ProjectAccountMembers.AssistantMembers, (m) => m.Id === manager.value)),
                rightText: (
                    <TotalHeader
                        items={dataSource.filter((item) =>
                            _.some(item.ProjectAccountMembers.AssistantMembers, (m) => m.Id === manager.value)
                        )}
                    />
                ),
                rightTextClass: "header-totals-text-only header-project-total",
            }));

            noGroupItems = dataSource.filter((item) => item.ProjectAccountMembers.AssistantMembers.length === 0);
            noGroupText = "No Project Manager";

            break;

        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({
            group: noGroupText,
            items: noGroupItems,
            rightText: <TotalHeader items={noGroupItems}/>,
            rightTextClass: "header-totals-text-only header-project-total",
        });
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });

    return groups;
}

const TotalHeader = ({items}) => {
    const itemCost = _.sum(items.map((p) => p.ItemCost));
    const labourCost = _.sum(items.map((p) => p.LabourCost));

    return (
        <>
            <div className="item-cost">{formatAmount(itemCost)} </div>
            <div className="labour-cost">{formatAmount(labourCost)}</div>
            <div className="total-cost">{formatAmount(itemCost + labourCost)}</div>
        </>
    );
};

const GroupProjectsTable = ({columns, dataSource, onRow, groupByFilter, footer}) => (
    <div className="grouped-projects">
        <GroupedTables
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            footer={footer}
            getGroups={() => getGroups(groupByFilter.value, dataSource)}
        />
    </div>
);

export default GroupProjectsTable;
