import {FileTextFilled, DownOutlined} from "@ant-design/icons";
import {Dropdown, Button, Menu, message} from "antd";
import React from "react";
import Email from "../Email/Email";
import {useSaveAsTemplate} from "../TitleRowButtons/TitleRowButtons";
import {doBatchDelete} from "../../Common/Modal/BatchDelete/BatchDelete";

import "./ReportsDetailsPanelTitle.scss";
import {useDispatch, useSelector} from "react-redux";
import {loadReports, remove} from "../../../services/reports";
import {close, openEdit, detailsSubPanel} from "../../../redux/reducers/detailsPanel";
import {getEvent, refreshTimeline} from "../../../services/timeline";
import {ApiTimelineItemType, SidePanelItemType} from "../../../constants/constants";

const {close: closeSubPanel} = detailsSubPanel;

const ReportsDetailsPanelTitle = ({editedItem, isSubPanel}) => {
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const saveAsTemplate = useSaveAsTemplate();
    const dispatch = useDispatch();
    const reportEvent = useSelector((state) => state.detailsPanel.item);
    const onSaveAsTemplateClick = () => {
        saveAsTemplate(editedItem.Id, editedItem.ProjectId);
    };

    const deleteReport = () => {
        doBatchDelete({
            title: `Are you sure you want to delete this Report`,
            onFinishMessage: () => message.success(`Report was deleted.`),
            onFinish: () => {
                remove(editedItem.Id).then(() => {
                    if (isSubPanel) {
                        getEvent(reportEvent.Id, editedItem.ProjectId, ApiTimelineItemType.Task).then((item) => {
                            dispatch(closeSubPanel());
                            dispatch(openEdit({item, type: SidePanelItemType.Event}));
                            refreshTimeline(ApiTimelineItemType.Task);
                        });
                    } else {
                        loadReports(editedItem.ProjectId, activePortfolio.Id);
                        dispatch(close());
                    }
                });
            },
        });
    };

    return (
        <div className="report-panel-title">
            <div>
                <FileTextFilled className="details-panel-title-icon"/> {editedItem.Name}
            </div>
            <div className="report-panel-buttons">
                <Dropdown
                    trigger="click"
                    overlay={
                        <Menu>
                            <Menu.Item key="1" onClick={onSaveAsTemplateClick}>
                                Save as Template
                            </Menu.Item>
                            <Menu.Item key="2" onClick={deleteReport}>
                                Delete
                            </Menu.Item>
                        </Menu>
                    }>
                    <Button>
                        More <DownOutlined/>
                    </Button>
                </Dropdown>
                <Email renderAsButton={true} selected={[editedItem]}></Email>
            </div>
        </div>
    );
};

export default ReportsDetailsPanelTitle;
