import React, {useState} from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {ApiDeliverableType, SidePanelItemType} from "../../../constants/constants";

import {openEdit, openNew} from "../../../redux/reducers/detailsPanel";
import {pathCollection} from "../../../helpers/paths";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import DeleteReports from "../Delete/DeleteReports";
import Email from "../Email";
import {createTemplate, loadReports} from "../../../services/reports";
import CreateFromTemplate from "../CreateFromTemplate/CreateFromTemplate";
import ClearSelection from "../../Common/ClearSelection";
import {useHistory} from "react-router-dom";

const ReportsSelected = ({selected}) => <div>{`${selected.length} Reports selected`}</div>;

export const useSaveAsTemplate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    return (reportId, activeProjectId) =>
        createTemplate(reportId, activePortfolio.Id).then((template) => {
            loadReports(activeProjectId, activePortfolio.Id);
            history.push(urlWithPortfolioId(pathCollection.reports, activePortfolio.Id));
            dispatch(openEdit({type: SidePanelItemType.ReportTemplate, item: template}));
        });
};

const ReportsRowButtons = () => {
    const activeProject = useSelector((state) => state.projects.activeProject);
    const [isFromTemplateVisible, setFromTemplateVisible] = useState(false);
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const saveAsTemplate = useSaveAsTemplate();

    function onClickSaveAsTemplate() {
        saveAsTemplate(selected[0].Id, activeProject.Id);
    }

    const dispatch = useDispatch();

    const singleReportSelected = selected.length === 1 && selected[0].Type === ApiDeliverableType.Report;
    const onlyReportSelected =
        selected.length > 0 && selected.filter((i) => i.Type === ApiDeliverableType.Report).length === selected.length;

    const MoreMenu = (
        <Menu>
            <Menu.Item key="1" disabled={!onlyReportSelected}>
                <Email selected={selected} disabled={!onlyReportSelected}></Email>
            </Menu.Item>
            <Menu.Item key="2" disabled={!singleReportSelected} onClick={onClickSaveAsTemplate}>
                Save as Template
            </Menu.Item>
        </Menu>
    );

    function newItemClick() {
        setTimeout(() => dispatch(openNew({type: SidePanelItemType.Reports})), 100);
    }

    const NewMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => newItemClick()}>
                Blank
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setFromTemplateVisible(true)}>
                From Template
            </Menu.Item>
        </Menu>
    );

    return (
        <Space>
            {isBulkSelection && <ReportsSelected selected={selected}/>}
            <ClearSelection/>
            <Dropdown trigger="click" overlay={MoreMenu}>
                <Button>
                    More <DownOutlined/>
                </Button>
            </Dropdown>
            <DeleteReports></DeleteReports>
            {!isBulkSelection && (
                <Dropdown trigger="click" overlay={NewMenu}>
                    <Button type="primary">
                        New Report <DownOutlined/>
                    </Button>
                </Dropdown>
            )}
            <CreateFromTemplate isVisible={isFromTemplateVisible} onClose={() => setFromTemplateVisible(false)}/>
        </Space>
    );
};

export default ReportsRowButtons;
