import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {List, Row, Col, Button, message} from "antd";
import React, {useState} from "react";
import _ from "lodash";
import {useDispatch} from "react-redux";
import {ApiTimelineItemType, SidePanelItemType} from "../../../constants/constants";
import {detailsSubPanel, openEdit} from "../../../redux/reducers/detailsPanel";
import {getItem, remove} from "../../../services/reports";
import CreateReportFromEvent from "../CreateReportFromEvent";
import {doBatchDelete} from "../../Common/Modal/BatchDelete/BatchDelete";
import {getEvent, refreshTimeline} from "../../../services/timeline";

const {openEdit: openEditSubPanel, startLoading} = detailsSubPanel;

const EventDeliverables = ({itemToEdit}) => {
    const dispatch = useDispatch();
    const [isCreateReportVisible, setCreateReportVisible] = useState(false);

    const goToReport = (item) => {
        dispatch(startLoading({item, type: SidePanelItemType.Reports}));
        getItem(item.Id)
            .then((item) => dispatch(openEditSubPanel({item, type: SidePanelItemType.Reports})))
            .catch(() => message.error("Server error"));
    };

    const deleteReport = (report) => {
        doBatchDelete({
            title: `Are you sure you want to delete this Report`,
            onFinishMessage: () => message.success(`Report was deleted.`),
            onFinish: () => {
                remove(report.Id).then(() =>
                    getEvent(itemToEdit.Id, itemToEdit.ProjectId, ApiTimelineItemType.Task).then((item) => {
                        dispatch(openEdit({item, type: SidePanelItemType.Event}));
                        refreshTimeline(ApiTimelineItemType.Task);
                    })
                );
            },
        });
    };

    return (
        <>
            <List
                size="small"
                className="table-in-panel"
                dataSource={_.sortBy(itemToEdit.Deliverables || [], (d) => d.Name.toLowerCase())}
                header={
                    <Row>
                        <Col offset={1} span={17}>
                            Report
                        </Col>
                        <Col offset={1} span={5}>
                            <Button onClick={() => setCreateReportVisible(true)} size="small" type="primary">
                                Add Report
                            </Button>
                        </Col>
                    </Row>
                }
                renderItem={(item) => (
                    <List.Item>
                        <Row>
                            <Col offset={1} span={19}>
                                {item.Name}
                            </Col>

                            <Col offset={0} span={4}>
                                <div className="event-report-actions">
                                    <Button onClick={() => goToReport(item)} type="link" size="small">
                                        <EyeOutlined className="details"/>
                                    </Button>
                                    <Button onClick={() => deleteReport(item)} type="link" size="small">
                                        <DeleteOutlined className="delete"/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>
                )}></List>
            <CreateReportFromEvent
                selected={[itemToEdit]}
                isVisible={isCreateReportVisible}
                onClose={() => setCreateReportVisible(false)}
            />
        </>
    );
};

export default EventDeliverables;
