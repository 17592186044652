import React from "react";
import {EditOutlined} from "@ant-design/icons";

const EditAction = ({onClick, record}) => (
    <EditOutlined
        className="btn-table-actions"
        onClick={(e) => {
            if (onClick) {
                e.stopPropagation();
                onClick(record);
            }
        }}
    />
);

export default EditAction;
