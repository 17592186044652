import {ApiContactType, ContactType} from "../../../constants/constants";
import _ from "lodash";

export const FieldType = Object.freeze({
    Name: "Name",
    StartDate: "StartDate",
    StartTime: "StartTime",
    EndDate: "EndDate",
    EndTime: "EndTime",
    Location: "Location",
    AssignedTeam: "AssignedTeam",
    Categories: "Categories",
    Departments: "Departments",
    Notes: "Notes",
    Empty: "Empty",
});

const commonCsvToFieldType = {
    "Start Date": FieldType.StartDate,
    "Start Time": FieldType.StartTime,
    "End Date": FieldType.EndDate,
    "End Time": FieldType.EndTime,
    Name: FieldType.Name,
    Location: FieldType.Location,
    "Assigned Team": FieldType.AssignedTeam,
    Categories: FieldType.Categories,
    Departments: FieldType.Departments,
    Notes: FieldType.Notes
};


export const commonModelFields = {
    [FieldType.Name]: "Name",
    [FieldType.StartDate]: "StartDate",
    [FieldType.StartTime]: "StartTime",
    [FieldType.EndDate]: "EndDate",
    [FieldType.EndTime]: "EndTime",
    [FieldType.Categories]: "CategoryTags",
    [FieldType.Departments]: "DepartmentTags",
    [FieldType.Notes]: "Notes",
    [FieldType.Location]: "Location",
    [FieldType.AssignedTeam]: "Contacts",
};

export const getCsvToFieldType = () => commonCsvToFieldType;

export const TimelineImportInputLabel = {
    [FieldType.Name]: "Event Name",
    [FieldType.StartDate]: "Start Date",
    [FieldType.StartTime]: "Start Time",
    [FieldType.EndDate]: "End Date",
    [FieldType.EndTime]: "End Time",
    [FieldType.Categories]: "Categories",
    [FieldType.Departments]: "Departments",
    [FieldType.Notes]: "Details",
    [FieldType.Location]: "Location",
    [FieldType.AssignedTeam]: "People & Organizations",
};

const commonTypes = [
    FieldType.Name,
    FieldType.StartDate,
    FieldType.StartTime,
    FieldType.EndDate,
    FieldType.EndTime,
    FieldType.Categories,
    FieldType.Departments,
    FieldType.Notes,
    FieldType.Location,
    FieldType.AssignedTeam,
];

export const getTargetFieldTypes = () => commonTypes

const toModelField = (data, type) => {
    let parsed = null;
    switch (type) {
        case FieldType.Categories:
        case FieldType.Departments:
        case FieldType.AssignedTeam:
            parsed = data ? data.split(";") : [];
            break;
        case FieldType.Location:
            parsed = data ? data.split(",") : [];
            break;

        default:
            parsed = data;
    }

    return parsed;
};


export const getParsedData = (dataList = [], columnsMap) => {
    const defaultFields = _.fromPairs(_.keys(commonModelFields).map((k) => [commonModelFields[k], toModelField(null, k)]));

    const result = dataList.map(data => ({
        //ContactType: contactType,
        ...defaultFields,
        ..._.fromPairs(
            data.map((d, i) => {
                return [commonModelFields[columnsMap[i]], toModelField(d, columnsMap[i])];
            })
        ),
    }));

    return result.map(item => ({
        ...item,
        IsAllDay: _.isEmpty(item.StartTime) && _.isEmpty(item.EndTime)
    }));
}
