import React from "react";
import ViewEditList from "../ViewEditList";

const mapPhoneToRow = (phones = []) =>
    phones.map((phone) => ({
        id: phone.Id,
        value: phone.PhoneNumber,
    }));

const mapRowToPhone = (itemRows = []) =>
    itemRows.map((itemRow) => ({
        Id: itemRow.id,
        PhoneNumber: itemRow.value,
    }));

const PhoneList = ({value, onChanged, onChange, readOnly = false, contactId}) => (
    <ViewEditList
        contactId={contactId}
        items={mapPhoneToRow(value)}
        onChanged={onChanged}
        onChange={(value) => onChange(mapRowToPhone(value))}
        readOnly={readOnly}
        addNewRowTex="Add a Phone Number"
        valuePlaceholder="Enter a phone number"
        emptyRowText="Phone: "
    />
);

export default PhoneList;
