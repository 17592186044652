import React from "react";
import _ from "lodash";
import GroupedTables from "../Common/Tables/GroupedTable";
import {AttachmentGroupBy} from "./FilterBar/GroupBy/GroupBy";

function getAllCategories(data) {
    return _.chain(data)
        .map((d) => d.Categories)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getAllDepartment(data) {
    return _.chain(data)
        .map((d) => d.Departments)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .value();
}

function getAllProjects(data) {
    return _.chain(data)
        .map((d) => d.Projects)
        .flatten()
        .uniqBy((d) => d.Id)
        .value();
}

function getGroups(groupBy, dataSource) {
    let groups = [];
    let noGroupItems = [];
    let noGroupText = "";
    switch (groupBy) {
        case AttachmentGroupBy.Project:
            const projects = getAllProjects(dataSource);
            groups = projects.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.Projects.find((p) => p.Id === group.Id)),
            }));

            noGroupItems = dataSource.filter((item) => item.Projects.length === 0);
            noGroupText = "No Project";

            break;

        case AttachmentGroupBy.Category:
            const categories = getAllCategories(dataSource);

            groups = categories.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.Categories.map((x) => x.AccountTagId).includes(group.AccountTagId)),
            }));
            noGroupItems = dataSource.filter((item) => item.Categories.length === 0);
            noGroupText = "No Categories";

            break;
        case AttachmentGroupBy.Department:
            const departments = getAllDepartment(dataSource);

            groups = departments.map((group) => ({
                group: group.Name,
                items: dataSource.filter((item) => item.Departments.map((x) => x.AccountTagId).includes(group.AccountTagId)),
            }));
            noGroupItems = dataSource.filter((item) => item.Departments.length === 0);
            noGroupText = "No Department";

            break;

        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({group: noGroupText, items: noGroupItems});
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });
    return groups;
}

const GroupAttachmentsTable = ({columns, dataSource, onRow, groupByFilter, loading}) => (
    <GroupedTables
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onRow={onRow}
        getGroups={() => getGroups(groupByFilter.value, dataSource)}
    />
);

export default GroupAttachmentsTable;
