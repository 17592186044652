import React, {useEffect, useMemo} from "react";
import {Tabs} from "antd";
import _ from "lodash";
import {UserOutlined, ShopOutlined, EnvironmentOutlined} from "@ant-design/icons";

import {loadContacts} from "../../services/contacts";
import {ContactType, ApiContactType} from "../../constants";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import ContactsTable from "./ContactsTable";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";

const {TabPane} = Tabs;

const tabs = [
    {
        key: ContactType.Person,
        path: "/contacts/people",
        contactsFilter: {Types: [0]},
    },
    {
        key: ContactType.Organization,
        path: "/contacts/organizations",
        contactsFilter: {Types: [1]},
    },
    {
        key: ContactType.Location,
        path: "/contacts/locations",
        contactsFilter: {Types: [2]},
    },
];
const getTabByKey = (key) => tabs.find((tab) => tab.key === key);

const Contacts = ({activeKey}) => {
    const history = useHistory();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const people = _.sortBy(
        useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Person),
        [(p) => _.isEmpty(p.LastName), (p) => p.LastName]
    );
    const locations = useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Place);
    const spaces = useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Space);
    const organizations = useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Organization);
    const urlWithPortfolioId = useUrlWithPortfolioId();

    useEffect(() => {
        if (!activePortfolio) return;

        const fetchData = async () => {
            await loadContacts(activePortfolio.Id);
        };
        fetchData();
    }, [activePortfolio]);

    const locationsWithSpaces = useMemo(() => {
        return locations.map((l) => ({...l, spaces: spaces.filter((s) => s.ParentId === l.Id)}));
    }, [locations, spaces]);

    const changeTab = (key) => {
        const path = getTabByKey(key).path;
        history.push(urlWithPortfolioId(path));
    };

    return (
        <Tabs onChange={changeTab} type="card" activeKey={activeKey}>
            <TabPane
                tab={
                    <span>
            <UserOutlined/> People
          </span>
                }
                key={ContactType.Person}>
                <ContactsTable items={people} contactType={ContactType.Person}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <ShopOutlined/> Organizations
          </span>
                }
                key={ContactType.Organization}>
                <ContactsTable items={organizations} contactType={ContactType.Organization}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <EnvironmentOutlined/> Locations
          </span>
                }
                key={ContactType.Location}>
                <ContactsTable items={locationsWithSpaces} contactType={ContactType.Location}/>
            </TabPane>
        </Tabs>
    );
};

export default Contacts;
