import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import loggerMiddleware from "./middleware/logger";
import rootReducer from "./reducers";

function configureAppStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [loggerMiddleware, ...getDefaultMiddleware()],
        preloadedState,
    });

    if (process.env.NODE_ENV !== "production" && module.hot) {
        module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
    }

    return store;
}

export default configureAppStore();
