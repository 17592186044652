import React from "react";
import CommonSearchFilter from "../../../Common/Tables/FilterBar/SearchFilter";

const fieldsToSearchRequirements = [
    "Requirement.Name",
    "Project.Name",
    (item) =>
        item.Sources.filter((m) => m.Value?.Quantity > 0)
            .map((m) => m.Name)
            .join(", "),
    "Location.Name",
    "Requirement.Description",
    "Requirement.StatusTag.Name",
];

const fieldsToSearchLabour = [
    "Task.Name",
    (item) => item.Task.DepartmentTags.map((x) => x.Name),
    "Position.Name",
    "Position.Source.Name",
];

export const SearchFilterRequirements = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter
        setFilterHandler={setFilterHandler}
        needResetFilter={needResetFilter}
        fieldsToSearch={fieldsToSearchRequirements}
    />
);

export const SearchFilterLabour = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter
        setFilterHandler={setFilterHandler}
        needResetFilter={needResetFilter}
        fieldsToSearch={fieldsToSearchLabour}
    />
);
