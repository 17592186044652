import React, {useEffect} from "react";
import {Alert, Col, Layout, Row} from "antd";
import "./Frontend.scss";
import logo from "./Propared_LMWM_Square_Pond_300px.png";
import {useParams} from "react-router-dom";
import {loadCompanyNameByLinkHash} from "../../services/inviteLinks";
import {useSelector} from "react-redux";

const {Content} = Layout;

const FrontendLayout = ({children, route}) => {
    const {linkHash} = useParams();
    const inviteLinkShortInfo = useSelector(state => state.inviteLinks?.shortInfo);

    useEffect(() => {
        loadCompanyNameByLinkHash(linkHash);
    }, [linkHash]);

    if (linkHash && !inviteLinkShortInfo) {
        return null;
    }

    return (<Layout>
        <Content>
            <Content>
                <div className="login_content">
                    {linkHash && !inviteLinkShortInfo?.IsEnabled ? (<Row justify="center">
                        <Col>
                            <Alert
                                message="This link has been disabled"
                                type="error"></Alert>
                        </Col>
                    </Row>) : (<>
                        <img className="logo" src={logo} alt="Propared" img-src="self"></img>
                        {inviteLinkShortInfo?.CompanyName && <h1>{inviteLinkShortInfo?.CompanyName}</h1>}
                        {route.title.map((line, index) => (<h1 key={index}>{line}</h1>))}
                        {children}
                    </>)}
                </div>
            </Content>
        </Content>
    </Layout>);
};

export default FrontendLayout;