import React, {useState, useEffect} from "react";
import _ from "lodash";
import {Menu, Dropdown, Button, Space, Checkbox, Input} from "antd";
import {DownOutlined, FilterFilled} from "@ant-design/icons";
import "./MultipleFilterWithSearchFilter.scss";

const sortItems = (items) =>
    _.sortBy(items, ["sortRank", (i) => (_.isString(i.title) ? i.title.toLowerCase() : i.title?.props?.children[0].toString())]);

export const FilterSearchRow = ({searchText, onSearchChange, onResetClick, isResetDisabled, ...props}) => (
    <Menu.Item key="FilterSearchRow" className="filter-search-item" {...props}>
        <Space>
            <Input.Search placeholder="search" value={searchText} onChange={onSearchChange}></Input.Search>
            <Button size="small" type="link" disabled={isResetDisabled()} onClick={onResetClick}>
                Reset
            </Button>
        </Space>
    </Menu.Item>
);

export const FilterSelectAllRow = ({
                                       selectAllText = "(select all)",
                                       isSelectAllChecked,
                                       isSelectAllPartChecked,
                                       onSelectAllChange,
                                       isSelectAllDisabled,
                                       ...props
                                   }) => (
    <Menu.Item key="selectAll" {...props}>
        <Checkbox
            disabled={isSelectAllDisabled}
            checked={isSelectAllChecked()}
            indeterminate={isSelectAllPartChecked()}
            onChange={(e) => onSelectAllChange(e)}>
            {selectAllText}
        </Checkbox>
    </Menu.Item>
);

const FilterView = ({
                        displayedItems,
                        searchText,
                        onCheckboxChange,
                        onSearchChange,
                        onResetClick,
                        isResetDisabled,
                        search,
                        isSelectAllChecked,
                        isSelectAllPartChecked,
                        onSelectAllChange,
                        selectedItems,
                        width = 250,
                        selectAllText,
                        buttons,
                    }) => (
    <Menu selectable={false} style={{width}} className="multiple-items-filter-menu">
        {search && (
            <FilterSearchRow
                key="FilterSearchRow"
                searchText={searchText}
                onSearchChange={onSearchChange}
                onResetClick={onResetClick}
                isResetDisabled={isResetDisabled}
            />
        )}

        <FilterSelectAllRow
            key="selectAll"
            selectAllText={selectAllText}
            isSelectAllChecked={isSelectAllChecked}
            isSelectAllPartChecked={isSelectAllPartChecked}
            onSelectAllChange={onSelectAllChange}
        />
        <Menu.Divider key="Divider"></Menu.Divider>
        {sortItems(displayedItems).map((item) => (
            <Menu.Item key={item.value} className={getLevelClassName(item.level)}>
                <Checkbox key={item.value} checked={isChecked(item, selectedItems)}
                          onChange={(e) => onCheckboxChange(item, e)}>
                    {item.icon}
                    {item.title}
                </Checkbox>
                {item.postfixIcon}
            </Menu.Item>
        ))}
        {buttons && (
            <Menu.Item key="buttons" className="bottom-buttons">
                {buttons}
            </Menu.Item>
        )}
    </Menu>
);

export const isChecked = (item, selectedItems) => selectedItems.map((i) => i.value).indexOf(item.value) !== -1;

const isGroupChecked = (group, selectedItems) =>
    group.items.length > 0
        ? _.intersection(
        selectedItems.map((i) => i.value),
        group.items.map((i) => i.value)
    ).length === group.items.length
        : selectedItems.map((i) => i.value).indexOf(group.value) !== -1;

const isGroupPartChecked = (group, selectedItems) => {
    const selectedInGroup = _.intersection(
        selectedItems.map((i) => i.value),
        group.items.map((i) => i.value)
    ).length;
    return selectedInGroup !== 0 && selectedInGroup !== group.items.length;
};

const getLevelClassName = (level = 0) => (level > 0 ? `padding_${level}` : "");

export const getGroupMenuRow = (
    group,
    index,
    {isDisableChecked = false, selectedItems, onCheckboxChange, onCheckboxesChange}
) =>
    group.item ? (
        <Menu.Item key={index} className={getLevelClassName(group.item.level)}>
            <Checkbox
                disabled={isDisableChecked}
                key={group.item.value}
                checked={isChecked(group.item, selectedItems)}
                onChange={(e) => onCheckboxChange(group.item, e)}>
                {group.item.icon}
                {group.item.title}
            </Checkbox>
            {group.item.postfixIcon}
        </Menu.Item>
    ) : (
        <Menu.ItemGroup
            key={index}
            title={
                <Checkbox
                    disabled={isDisableChecked}
                    indeterminate={isGroupPartChecked(group, selectedItems)}
                    checked={isGroupChecked(group, selectedItems)}
                    onChange={(e) => onCheckboxesChange(group, e)}>
                    {group.title}
                </Checkbox>
            }>
            {sortItems(group.items).map((item) => (
                <Menu.Item key={item.value} className={getLevelClassName(item.level)}>
                    <Checkbox
                        disabled={isDisableChecked}
                        key={item.value}
                        checked={isChecked(item, selectedItems)}
                        onChange={(e) => onCheckboxChange(item, e)}>
                        {item.icon}
                        {item.title}
                    </Checkbox>
                    {item.postfixIcon}
                </Menu.Item>
            ))}
        </Menu.ItemGroup>
    );

const FilterGroupsView = ({
                              selectedItems,
                              searchText,
                              onCheckboxesChange,
                              onCheckboxChange,
                              onSearchChange,
                              onResetClick,
                              isResetDisabled,
                              search,
                              isSelectAllChecked,
                              isSelectAllPartChecked,
                              onSelectAllChange,
                              groups,
                              width = 250,
                              selectAllText,
                              buttons,
                              className = "",
                          }) => (
    <Menu selectable={false} style={{width}} className={`multiple-items-filter-menu ${className}`}>
        {search && (
            <FilterSearchRow
                key="FilterSearchRow"
                searchText={searchText}
                onSearchChange={onSearchChange}
                onResetClick={onResetClick}
                isResetDisabled={isResetDisabled}
            />
        )}
        <FilterSelectAllRow
            key="selectAll"
            selectAllText={selectAllText}
            isSelectAllChecked={isSelectAllChecked}
            isSelectAllPartChecked={isSelectAllPartChecked}
            onSelectAllChange={onSelectAllChange}
        />
        <Menu.Divider key="Divider"></Menu.Divider>
        <Menu.ItemGroup className="grouped-menu-items">
            {sortItems(groups).map((group, index) =>
                getGroupMenuRow(group, index, {selectedItems, onCheckboxChange, onCheckboxesChange})
            )}
        </Menu.ItemGroup>
        {buttons && (
            <Menu.Item key="buttons" className="bottom-buttons">
                {buttons}
            </Menu.Item>
        )}
    </Menu>
);

export const MultipleFilterWithSearch = (props) => {
    const {
        onChanged,
        selectedItems = [],
        items = [],
        search,
        groups = [],
        width,
        selectAllText,
        FilterComponent,
        disableAllOption,
        ...restProps
    } = props;

    const [searchText, setSearchText] = useState("");
    const [displayedItems, setDisplayedItems] = useState([]);
    const [displayedGroups, setDisplayedGroups] = useState(groups);
    const [resetClicked, setResetClicked] = useState(false);

    const itemsHash = items.map((i) => i.value).join(",");
    useEffect(() => {
        if (items.length > 0) setDisplayedItems(items);
    }, [itemsHash]);

    const groupsHash = groups
        .map((i) => i.item?.value.toString() + (i.items || []).map((ii) => ii.value?.toString()).join(","))
        .join(",");
    useEffect(() => {
        if (groups.length > 0) setDisplayedGroups(groups);
    }, [groupsHash]);

    function onSearchChange(e) {
        const newSearchText = e.target.value;
        setSearchText(newSearchText);
        setDisplayedItems(items.filter((item) => item.title.toLowerCase().indexOf(newSearchText.toLowerCase()) !== -1));
        if (groups.length) {
            const filteredGroups = groups
                .map((g) => ({
                    ...g,
                    items: g.items?.filter((item) => item.title.toLowerCase().indexOf(newSearchText.toLowerCase()) !== -1),
                }))
                .filter((g) => g.items?.length > 0);

            setDisplayedGroups(filteredGroups);
        }
    }

    function onResetClick() {
        setSearchText("");
        setDisplayedItems(items);
        if (groups.length) {
            setDisplayedGroups(groups);
        }
        onChanged(items);

        setResetClicked(true);
    }

    const onResetCompleted = () => setResetClicked(false);

    function onCheckboxChange(item, e) {
        const newSelectedItems = e.target.checked ? [...selectedItems, item] : selectedItems.filter((i) => i.value !== item.value);
        onChanged(newSelectedItems);
    }

    function onCheckboxesChange(group, e) {
        if (group.items.length > 0) {
            const checkedIds = group.items.map((i) => i.value);
            const newSelectedItems = e.target.checked
                ? [...selectedItems, ...group.items]
                : selectedItems.filter((i) => !checkedIds.includes(i.value));
            onChanged(newSelectedItems);
        } else {
            const newSelectedItems = e.target.checked
                ? [...selectedItems, group]
                : selectedItems.filter((i) => i.value !== group.value);
            onChanged(newSelectedItems);
        }
    }

    function onSelectAllChange(e) {
        onChanged(e.target.checked ? items : []);
    }

    const isSelectAllChecked = () => selectedItems.length === items.length;
    const isSelectAllPartChecked = () => selectedItems.length !== items.length && selectedItems.length !== 0;
    const isResetDisabled = () => selectedItems.length === items.length && searchText === "";

    const modifiedProps = {
        ...restProps,
        displayedItems,
        selectedItems,
        searchText,
        onCheckboxChange,
        onSearchChange,
        onResetClick,
        isResetDisabled,
        search,
        isSelectAllChecked,
        isSelectAllPartChecked,
        onSelectAllChange,
        width,
        selectAllText,
        groups: displayedGroups,
        onCheckboxesChange,
        resetClicked,
        onResetCompleted,
        disableAllOption,
    };

    const Component = FilterComponent || (groups.length > 0 ? FilterGroupsView : FilterView);
    return <Component {...modifiedProps} />;
};

export const getFilterText = (items, selectedItems, allItemsText, nothingSelected = "Nothing selected") => {
    switch (true) {
        case 0 === selectedItems.length:
            return nothingSelected;
        case items.length <= selectedItems.length:
            return allItemsText || selectedItems.map((item) => item.title).join(", ");
        default:
            return selectedItems.map((item) => item.title).join(", ");
    }
};

export const isDefaultSelection = (items, selectedItems) => items.length === 0 || selectedItems.length >= items.length;

const MultipleFilterWithSearchFilter = ({
                                            items = [],
                                            groups = [],
                                            selectedItems = [],
                                            onChanged = () => {
                                            },
                                            allItemsText = "All Items",
                                            search,
                                            className = "",
                                            width,
                                            selectAllText,
                                            nothingSelectedText,
                                            trigger = "click",
                                            disabled,
                                        }) => (
    <Dropdown
        disabled={disabled}
        trigger={trigger}
        overlay={
            <MultipleFilterWithSearch
                items={items}
                groups={groups}
                selectedItems={selectedItems}
                onChanged={onChanged}
                search={search}
                width={width}
                selectAllText={selectAllText}
            />
        }
        className={`multiple-items-filter ${className}`}>
        <Button type="link" className="btn-multiple-items-filter">
            <span
                className="filter-text">{getFilterText(items, selectedItems, allItemsText, nothingSelectedText)}</span>
            {isDefaultSelection(items, selectedItems) && <DownOutlined className="filter-text-down"/>}
            {!isDefaultSelection(items, selectedItems) && <FilterFilled className="active-filter filter-text-down"/>}
        </Button>
    </Dropdown>
);

export default MultipleFilterWithSearchFilter;
