import React from "react";
import {Button, Dropdown, Menu, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {ResourceType} from "../../../constants/constants";
import {doBatchDeleteResources} from "../Items/DeleteResource";
import {doBatchDeleteCollections} from "../Collections/DeleteCollection";
import {doBatchDeletePositions} from "../Positions/DeletePosition";
import ResourceImport from "../Items/ResourceImport";
import {openNew} from "../../../redux/reducers/detailsPanel";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../../helpers/paths";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import usePermissions from "../../../hooks/usePermissions";
import {loadCollections, loadResources} from "../../../services/resources";
import {setSelected} from "../../../redux/reducers/mainTable";
import {loadPositions} from "../../../services/positions";
import ClearSelection from "../../Common/ClearSelection";
import CloneCollection from "../../../components/Resources/Collections/CloneCollection/CloneCollection";
import Export from "../Export";

const selectedType = {
    [ResourceType.Item]: "Items",
    [ResourceType.Collection]: "Collections",
    [ResourceType.Usage]: "Usage",
    [ResourceType.Position]: "Positions",
};

const ResourceSelected = ({selected, resourceType}) =>
    <div>{`${selected.length} ${selectedType[resourceType]} Selected`}</div>;

const ResourcesTitleRowButtons = ({resourceType}) => {
    const history = useHistory();
    const selected = useSelector((store) => store.mainTable.selected);
    const nothingSelected = selected.length === 0;
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const accountId = activePortfolio?.Id;
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {hasThingsAccess, isProjectManager} = usePermissions();
    const dispatch = useDispatch();

    function handleNewResourceMenuClick(e) {
        const newResourceType = e.key;
        history.push(urlWithPortfolioId(pathCollection.resourcesPath[newResourceType]));
        setTimeout(() => dispatch(openNew({type: newResourceType})), 100);
    }

    const onBatchDelete = () => {
        if (resourceType === ResourceType.Item) {
            doBatchDeleteResources(accountId, selected, () => {
                dispatch(setSelected([]));
                loadResources(accountId);
            });
        } else if (resourceType === ResourceType.Collection) {
            doBatchDeleteCollections(accountId, selected, () => {
                dispatch(setSelected([]));
                loadCollections(accountId);
            });
        } else if (resourceType === ResourceType.Position) {
            doBatchDeletePositions(accountId, selected, () => {
                dispatch(setSelected([]));
                loadPositions(accountId);
            });
        }
    };

    const MoreMenu = (
        <Menu>
            {resourceType !== ResourceType.Collection && resourceType !== ResourceType.Position && (
                <Menu.Item key="1">
                    <ResourceImport resourceType={resourceType}></ResourceImport>
                </Menu.Item>
            )}
            {resourceType === ResourceType.Item && (
                <Menu.Item key="2">
                    <Export resourceType={resourceType}></Export>
                </Menu.Item>
            )}
            {resourceType === ResourceType.Collection && (
                <Menu.Item key="3" disabled={nothingSelected}>
                    <CloneCollection disabled={nothingSelected} selected={selected}></CloneCollection>
                </Menu.Item>
            )}
            <Menu.Item key="4" disabled={selected.length === 0 || isProjectManager} onClick={onBatchDelete}>
                Delete
            </Menu.Item>
        </Menu>
    );

    const NewResourceMenu = (<Menu onClick={handleNewResourceMenuClick}>
        <Menu.Item key={ResourceType.Item}>Item</Menu.Item>
        <Menu.Item key={ResourceType.Collection}>Collection</Menu.Item>
        <Menu.Item key={ResourceType.Position}>Position</Menu.Item>
    </Menu>);

    if (!hasThingsAccess) return null;

    return (<Space>
        {isBulkSelection && <ResourceSelected resourceType={resourceType} selected={selected}/>}
        <ClearSelection/>
        {resourceType !== ResourceType.Usage && (<Dropdown trigger="click" overlay={MoreMenu}>
            <Button>
                More <DownOutlined/>
            </Button>
        </Dropdown>)}
        <Dropdown disabled={isBulkSelection} trigger="click" overlay={NewResourceMenu}>
            <Button type="primary">New Resource<DownOutlined/>
            </Button>
        </Dropdown>
    </Space>);
};

export default ResourcesTitleRowButtons;
