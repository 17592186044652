import {createSlice} from "@reduxjs/toolkit";

const myProjectsSlice = createSlice({
    name: "projects",
    initialState: {
        portfolios: [],
        activePortfolio: null,
        activeProject: null,
        projects: [],
        archivedProjects: [],
        portfolioProjects: [],
        recentlyUpdatedProjects: [],
        isPortfolioLoading: false,
        activeProjects: [],
        activeProjectsExtra: [],
        filterDateRange: [],
        sortOrderState: null,
    },
    reducers: {
        setMyPortfolios(state, action) {
            state.portfolios = action.payload;
        },

        setActivePortfolio(state, action) {
            state.activePortfolio = action.payload;
        },

        setProjects(state, action) {
            state.projects = action.payload;
        },

        setArchivedProjects(state, action) {
            state.archivedProjects = action.payload;
        },

        setAllPortfolioProjects(state, action) {
            state.portfolioProjects = action.payload;
        },

        setActiveProject(state, action) {
            state.activeProject = action.payload;
        },

        setActiveProjects(state, action) {
            state.activeProjects = action.payload;
        },

        setActiveProjectsExtra(state, action) {
            state.activeProjectsExtra = action.payload;
        },

        setRecentlyUpdatedProjects(state, action) {
            state.recentlyUpdatedProjects = action.payload;
        },

        setPortfolioLoading(state, action) {
            state.isPortfolioLoading = action.payload;
        },

        setFilterDateRange(state, action) {
            state.filterDateRange = action.payload;
        },

        setSortOrderState(state, action) {
            state.sortOrderState = action.payload;
        },
    }
});

export const {
    setMyPortfolios,
    setActivePortfolio,
    setProjects,
    setArchivedProjects,
    setActiveProject,
    setActiveProjects,
    setActiveProjectsExtra,
    setAllPortfolioProjects,
    setRecentlyUpdatedProjects,
    setPortfolioLoading,
    setFilterDateRange,
    setSortOrderState,
} = myProjectsSlice.actions;

export default myProjectsSlice.reducer;