import React, {useState} from "react";
import SingleItemFilter from "../../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const ItemGroupBy = Object.freeze({
    Source: "Source",
    Department: "Department"
});

const defaultItems = {
    source: {
        value: ItemGroupBy.Source,
        title: ItemGroupBy.Source,
    },
    department: {
        value: ItemGroupBy.Department,
        title: ItemGroupBy.Department,
    },
};

const items = [
    defaultItems.source,
    defaultItems.department,
];

const GroupByFilter = ({setGroupBy, type}) => {
    const [selectedItem, setSelectedItem] = useState();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
