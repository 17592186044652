import React, {useState} from "react";
import {Button, Modal, Form} from "antd";
import {UserOutlined, ShopOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {ContactType, TagType} from "../../../constants/constants";
import {getTags, mapTagToOption, getTagsPayload} from "../../../helpers/Tags";
import {useSelector} from "react-redux";
import {batchUpdate} from "../../../services/team";
import "./BatchEdit.scss";
import {useEffect} from "react";
import TagsSelect from "../../Common/Selects/TagsSelect";
import BulkEditableField, {isFieldDisabled} from "../../Common/BulkEditableField/BulkEditableField";

const icon = {
    [ContactType.Person]: <UserOutlined/>,
    [ContactType.Organization]: <ShopOutlined/>,
    [ContactType.Location]: <EnvironmentOutlined/>,
};

const Title = ({contactType, selected}) => (
    <>
        {icon[contactType]} {`Bulk Edit ${selected.length} Team Members`}
    </>
);

function getFormData(selected) {
    return {
        RoleTags: getTags(selected, "RoleTags"),
        GroupTags: getTags(selected, "GroupTags"),
    };
}

const BulkEdit = ({contactType, selected}) => {
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const roles = useSelector((state) => state.tags.roles).map(mapTagToOption);
    const groups = useSelector((state) => state.tags.groups).map(mapTagToOption);

    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) form.setFieldsValue(getFormData(selected));
    }, [selected, form, isVisible]);

    function showModal() {
        setVisible(true);
    }

    function onOk() {
        const selectedContactIds = selected.map((i) => i.Id);

        const formValue = form.getFieldsValue();
        setLoading(true);
        batchUpdate(
            selectedContactIds,
            getTagsPayload(formValue.RoleTags, !isFieldDisabled("RoleTags", form)),
            getTagsPayload(formValue.GroupTags, !isFieldDisabled("GroupTags", form))
        ).finally(() => {
            setLoading(false);
            close();
        });
    }

    function onCancel() {
        close();
    }

    function close() {
        setVisible(false);
    }

    return (
        <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
            <Button type="primary" onClick={showModal}>
                Edit
            </Button>
            <Modal
                confirmLoading={loading}
                title={<Title contactType={contactType} selected={selected}/>}
                visible={isVisible}
                maskClosable={false}
                onOk={onOk}
                onCancel={onCancel}>
                {isVisible && (
                    <>
                        <BulkEditableField name="RoleTags" form={form}>
                            {(name, isFieldDisabled) => (
                                <TagsSelect
                                    tagType={TagType.Role}
                                    disabled={isFieldDisabled}
                                    label="Roles"
                                    name={name}
                                    placeholder="Select project roles"
                                    hasEnableCheckbox={true}
                                    options={roles}
                                    form={form}
                                />
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="GroupTags" form={form}>
                            {(name, isFieldDisabled) => (
                                <TagsSelect
                                    tagType={TagType.Group}
                                    disabled={isFieldDisabled}
                                    label="Groups"
                                    name={name}
                                    placeholder="Select project groups"
                                    options={groups}
                                    form={form}
                                />
                            )}
                        </BulkEditableField>
                    </>
                )}
            </Modal>
        </Form>
    );
};
export default BulkEdit;
