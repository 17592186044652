import React, {useState, useEffect} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import {ApiFilterLayout} from "../../../../constants/constants";

const items = [
    {
        value: ApiFilterLayout.Year,
        title: "Year",
    },
    {
        value: ApiFilterLayout.Calendar,
        title: "Month",
    },
    {
        value: ApiFilterLayout.Week,
        title: "Week",
    },
    {
        value: ApiFilterLayout.Day,
        title: "Day",
    },
    {
        value: ApiFilterLayout.List,
        title: "List",
    },
];

const defaultItem = items[0];

const Layout = ({value, onChange, onChanged, disabled}) => {
    const [selectedItem, setSelectedItem] = useState(defaultItem);

    useEffect(() => {
        if (value != null) setSelectedItem(items.find((i) => i.value === value));
    }, [value]);

    function onSelected(selectedItem) {
        setSelectedItem(selectedItem);
        onChange(selectedItem.value);
        onChanged();
        //setFilterHandler((item) => selectedItem.value === allTime.value || moment(item.UpdatedAt) >= selectedItem.date);
    }

    return (
        <SingleItemFilter disabled={disabled} selectedItem={selectedItem} items={items} onChanged={onSelected}
                          trigger="click"/>
    );
};

export default Layout;
