import React from "react";
import {message} from "antd";
import {doBatchDelete} from "../../Common/Modal/BatchDelete/BatchDelete";
import {batchRemove, loadProductionBooks} from "../../../services/productionBooks";
import {setSelected} from "../../../redux/reducers/mainTable";

const getTitle = (selected) => {
    if (selected.length === 1) {
        return `Are you sure you want to delete this Production Book?`;
    } else {
        return `Are you sure you want to delete ${selected.length} Production Books?`;
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Production Book was deleted.`);
    } else {
        message.success(`${selected.length} Production Books were deleted.`);
    }
};

const deleteProductionBooks = (selected, activePortfolioId, dispatch) => {
    return doBatchDelete({
        onFinish: () => {
            dispatch(setSelected([]));
            loadProductionBooks(activePortfolioId);
        },
        title: getTitle(selected),
        batchRemove: () => batchRemove(selected.map((s) => s.Id)),
        onFinishMessage: () => showFinishMessage(selected),
    });
};

export {deleteProductionBooks};
