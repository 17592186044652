import React from "react";
import _ from "lodash";
import {Table} from "antd";

import {formatInventory} from "../../../helpers/text";

export const getResourcesSummary = (pageData = []) => {
    let totalQty = 0;

    pageData.forEach(({Sources}) => {
        totalQty += formatInventory(Sources);
    });

    return {totalQty};
};

const ItemsTableFooter = (pageData) => {

    const {totalQty} = getResourcesSummary(pageData);
    return (
        <>
            <Table.Summary.Row className="res-summary">
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell><b>{totalQty}</b></Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};

export const GroupItemsTableFooter = (pageData) => {

    const {totalQty} = getResourcesSummary(pageData);
    return (
        <>
            <Table.Summary.Row className="res-summary">
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell><b>{totalQty}</b></Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
                <Table.Summary.Cell> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};


export default ItemsTableFooter;
