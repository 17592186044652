import React, {useMemo, useState, useEffect} from "react";
import _ from "lodash";
import {Button, Modal, Form, Switch, Row, Col, Select, Upload, Table, Alert, message} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import Papa from "papaparse";
import {errorFromHttpResponse} from "../../../../helpers/error";
import {FieldType} from "./helper";
import "./Import.scss";

const Uploader = ({setFileData}) => (
    <Upload.Dragger
        accept=".csv"
        beforeUpload={(file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    setFileData(reader.result);
                };
            });
        }}>
        <p className="ant-upload-drag-icon">
            <InboxOutlined/>
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Upload.Dragger>
);

export const CsvMapper = ({
                              fileData,
                              validate,
                              csvToFieldType,
                              setParsedData,
                              fieldTypes,
                              renderPlaceholder1,
                              renderRecordViewTitle,
                              renderRecordView,
                              inputs,
                              getParsedData,
                          }) => {
    const [form] = Form.useForm();
    const [ignoreFirst, setIgnoreFirst] = useState(true);
    const [currentRow, setCurrentRow] = useState(0);
    const [columnsMap, setColumnsMap] = useState({});
    const [currentData, setCurrentData] = useState([]);

    const parsed = useMemo(() => {
        return Papa.parse(fileData || "", {header: false, skipEmptyLines: "greedy"}).data;
    }, [fileData, ignoreFirst]);

    const header = useMemo(() => {
        return Papa.parse(fileData || "", {preview: 1});
    }, [fileData]);

    useEffect(() => {
        const data = parsed.slice(ignoreFirst ? 1 : 0);
        const processed = getParsedData(data, columnsMap);
        setParsedData(processed);
        setCurrentData(processed);
    }, [columnsMap, parsed, ignoreFirst]);

    const rowsCount = currentData.length > 0 ? currentData.length : parsed.length + (ignoreFirst ? -1 : 0);
    const columns = useMemo(() => {
        const columnList = ignoreFirst ? header.data[0] : header.data[0].map((c, i) => `Column ${i + 1}`);
        return (columnList || []).map((key) => ({key, fieldTypes}));
    }, [header]);

    useEffect(() => {
        const initial = _.fromPairs(columns.map((c, i) => [i, csvToFieldType[c.key] || FieldType.Empty]));
        setColumnsMap(initial);
    }, [columns]);

    const getCurrentRowData = () => {
        return currentData.length === 0 ? parsed[currentRow + (ignoreFirst ? 1 : 0)][0] : currentData[currentRow];
    };
    useEffect(() => {
        const formValues = getCurrentRowData();
        form.setFieldsValue(formValues);
    }, [currentRow, columnsMap, rowsCount, currentData]);

    useEffect(() => validate(columnsMap), [columnsMap]);

    const getInput = (index) => (
        <Select
            value={columnsMap[index] || FieldType.Empty}
            onChange={(changedVal) => {
                const alreadyUsed = _.keys(columnsMap).find((i) => columnsMap[i] === changedVal);

                const empty = {};
                if (alreadyUsed) {
                    empty[alreadyUsed] = FieldType.Empty;
                }
                setColumnsMap({...columnsMap, ...empty, ...{[index]: changedVal}});
            }}
            options={inputs}
            style={{width: "100%"}}></Select>
    );

    return (
        <>
            <Row>
                {renderPlaceholder1 && <Col span={10}>{renderPlaceholder1()}</Col>}
                <Col span={6}>
                    <Form.Item name="IgnoreFirst" labelCol={{span: 14}} wrapperCol={{span: 2}} label="Ignore first row">
                        <Switch onChange={setIgnoreFirst} checked={ignoreFirst}></Switch>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    Preview Row {currentRow + 1} of {rowsCount}{" "}
                    <Button onClick={() => setCurrentRow(currentRow === 0 ? 0 : currentRow - 1)}>{"<"}</Button>
                    <Button
                        onClick={() => {
                            return setCurrentRow(currentRow === rowsCount - 1 ? rowsCount - 1 : currentRow + 1);
                        }}>
                        {">"}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <h2>Map .CSV Data to Propared Fields:</h2>
                    <Table
                        pagination={false}
                        rowKey="key"
                        size="small"
                        columns={[
                            {
                                title: ".CSV Columns",
                                width: "50%",
                                key: "key",
                                dataIndex: "key",
                            },
                            {
                                title: "Propared fields",
                                width: "50%",
                                key: "key",
                                render: (val, record, index) => getInput(index),
                            },
                        ]}
                        dataSource={columns}></Table>
                </Col>
                <Col span={12} offset={1} className="border-left-3">
                    <h2>{renderRecordViewTitle()}</h2>
                    <Form form={form} labelCol={{span: 7}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                        {renderRecordView(form, getCurrentRowData())}
                    </Form>
                </Col>
            </Row>
        </>
    );
};

const showImportResults = (
    {TotalCount = 0, ImportedCount = 0, TotalMismatches = 0, Errors = []},
    mainIcon,
    title,
    importResultError = null
) => {
    Modal.info({
        title: (
            <>
                {mainIcon} {title}
            </>
        ),
        icon: null,
        content: (
            <>
                Records: {TotalCount}
                <br/>
                Records Imported: {ImportedCount}
                <br/>
                <br/>
                {Errors.length === 0 && "Import completed successfully! "}
                {Errors.length !== 0 && "We had trouble with some of the field values for these records."}
                {TotalMismatches > 0 && importResultError}
            </>
        ),
        okText: "Close",
        okCancel: null,
        closable: true,
    });
};

const Import = ({
                    mainIcon,
                    title,
                    instructions,
                    CsvMapperComponent,
                    disabled,
                    onImport,
                    onImportFinish,
                    importResultError,
                    onClose,
                }) => {
    const [form] = Form.useForm();
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showError, setShowError] = useState(false);
    const [parsedData, setParsedData] = useState([]);

    function showModal() {
        if (!disabled) setVisible(true);
    }

    function onOk() {
        form.validateFields();
        if (errorMessage) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
        }

        setLoading(true);
        onImport(parsedData)
            .then((response) => {
                showImportResults(response, mainIcon, title, importResultError);
                close();
                onImportFinish();
            })
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
            })
            .finally(() => setLoading(false));
    }

    function onCancel() {
        close();
    }

    function close() {
        form.resetFields();
        setLoading(false);
        setFileData(null);
        setVisible(false);
        onClose();
    }

    return (
        <>
            <div disabled={disabled} onClick={showModal}>
                Import
            </div>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                <Modal
                    width={880}
                    maskClosable={false}
                    confirmLoading={loading}
                    title={
                        <>
                            {mainIcon} {title}
                        </>
                    }
                    visible={isVisible}
                    onOk={onOk}
                    okText="Import"
                    className="modal-import"
                    onCancel={onCancel}>
                    {isVisible && (
                        <>
                            {instructions}
                            {showError && errorMessage && (
                                <Alert message="Required mapping" description={errorMessage}
                                       className="required-field-error" type="error"/>
                            )}
                            {!fileData && <Uploader setFileData={setFileData}></Uploader>}
                            {fileData && (
                                <CsvMapperComponent
                                    fileData={fileData}
                                    setErrorMessage={setErrorMessage}
                                    setParsedData={setParsedData}></CsvMapperComponent>
                            )}
                        </>
                    )}
                </Modal>
            </Form>
        </>
    );
};

export default Import;
