import React from "react";

import {InputWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";

import AuditFormItem from "../../../Common/AuditFormItem";

const InformationBlock = ({form, itemToEdit, save, isOptionalFieldDisabled}) => {
    return (
        <>
            <InputWithFormItem
                name="Name"
                label={"Collection"}
                hasFeedback
                rules={[{required: true, message: "Name"}]}
                onChanged={save}
                placeholder="Enter a collection name"
            />

            <TextAreaWithFormItem
                disabled={isOptionalFieldDisabled}
                name="Description"
                label={"Details"}
                placeholder="Enter details"
                autoSize={{minRows: 2}}
                onChanged={save}/>

            <AuditFormItem
                item={itemToEdit}
                label="Created"
                timeProp="CreatedAt"
                userProp="Creator"
            />

            <AuditFormItem
                item={itemToEdit}
                label="Last Updated"
                timeProp="UpdatedAt"
                userProp="Updater"
            />
        </>
    )
};

export default InformationBlock;
