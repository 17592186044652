import React, {useState} from "react";
import {Button, Modal, Form, Switch, message} from "antd";
import {PaperClipOutlined} from "@ant-design/icons";
import {SelectWithFormItem} from "../../Common/Input";
import {
    createColorTagRendered,
    getTags,
    mapTagToOption,
    getProjectTags,
    getTagsPayload,
    mapProjectWithBadgeToTag,
} from "../../../helpers/Tags";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {ApiProjectType, TagType} from "../../../constants/constants";
import {batchUpdate} from "../../../services/attachments";
import BulkEditableField, {isFieldDisabled} from "../../Common/BulkEditableField/BulkEditableField";
import {errorFromHttpResponse} from "../../../helpers/error";
import TagsSelect from "../../Common/Selects/TagsSelect";

const AllProjectOption = {value: "— All Projects —", label: "— All Projects —"};
const isAllProjectsSelected = (selected) => selected.includes(AllProjectOption.value);

const Title = ({selected}) => (
    <>
        <PaperClipOutlined/> {`Bulk Edit ${selected.length} Attachments`}
    </>
);

function getFormData(selected) {
    return {
        Categories: getTags(selected, "Categories"),
        Departments: getTags(selected, "Departments"),
        Projects: getProjectTags(selected, AllProjectOption),
        EnableLocationConflicts: false,
    };
}

const BatchEdit = ({selected}) => {
    const [loading, setLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);

    const categories = useSelector((state) => state.tags.categories || []);
    const departments = useSelector((state) => state.tags.departments || []);
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);

    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) form.setFieldsValue(getFormData(selected));
    }, [selected, isVisible]);

    function showModal() {
        setVisible(true);
    }

    function onOk() {
        const selectedIds = selected.map((i) => i.Id);
        const formValue = form.getFieldsValue();

        setLoading(true);
        batchUpdate(
            selectedIds,
            getTagsPayload(
                formValue.Projects.map((p) => parseInt(p, 10)).filter((p) => !isNaN(p)),
                !isFieldDisabled("Projects", form)
            ),
            getTagsPayload(formValue.Categories, !isFieldDisabled("Categories", form)),
            getTagsPayload(formValue.Departments, !isFieldDisabled("Departments", form)),
            isAllProjectsSelected(formValue.Projects),
            {IsUrlEnabled: formValue.IsUrlEnabled, UpdateUrlEnabled: !isFieldDisabled("IsUrlEnabled", form)}
        )
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
            })
            .finally(() => {
                setLoading(false);
                close();
            });
    }

    function onCancel() {
        close();
    }

    function close() {
        form.resetFields();
        setVisible(false);
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Edit
            </Button>
            {isVisible && (
                <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                    <Modal
                        confirmLoading={loading}
                        title={<Title selected={selected}/>}
                        visible={isVisible}
                        onOk={onOk}
                        okText="Ok"
                        maskClosable={false}
                        onCancel={onCancel}>
                        {isVisible && (
                            <>
                                <BulkEditableField name="IsUrlEnabled" form={form}>
                                    {(name, isFieldDisabled) => (
                                        <Form.Item label="URL Enabled" name={name} valuePropName="checked">
                                            <Switch disabled={isFieldDisabled}/>
                                        </Form.Item>
                                    )}
                                </BulkEditableField>

                                <BulkEditableField name="Projects" form={form}>
                                    {(name, isFieldDisabled) => (
                                        <SelectWithFormItem
                                            disabled={isFieldDisabled}
                                            label="Projects"
                                            name={name}
                                            mode="tags"
                                            hasEnableCheckbox={true}
                                            options={[AllProjectOption, ...projects.map(mapProjectWithBadgeToTag)]}
                                            tagRender={createColorTagRendered("blue", !isFieldDisabled)}
                                            style={{width: "100%"}}
                                            placeholder="Select projects"></SelectWithFormItem>
                                    )}
                                </BulkEditableField>

                                <BulkEditableField name="Categories" form={form}>
                                    {(name, isFieldDisabled) => (
                                        <TagsSelect
                                            tagType={TagType.Category}
                                            disabled={isFieldDisabled}
                                            label="Categories"
                                            name="Categories"
                                            mode={name}
                                            placeholder="Select categories"
                                            options={categories.map(mapTagToOption)}
                                            form={form}
                                        />
                                    )}
                                </BulkEditableField>

                                <BulkEditableField name="Departments" form={form}>
                                    {(name, isFieldDisabled) => (
                                        <TagsSelect
                                            tagType={TagType.Department}
                                            disabled={isFieldDisabled}
                                            label="Departments"
                                            name={name}
                                            mode={name}
                                            placeholder="Select departments"
                                            options={departments.map(mapTagToOption)}
                                            form={form}
                                        />
                                    )}
                                </BulkEditableField>
                            </>
                        )}
                    </Modal>
                </Form>
            )}
        </>
    );
};
export default BatchEdit;
