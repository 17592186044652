import {
    DownOutlined,
    ClockCircleFilled,
    PaperClipOutlined,
    SendOutlined,
    UserOutlined,
    BuildFilled,
    FileTextFilled,
} from "@ant-design/icons";
import {Button, Dropdown, Menu} from "antd";
import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {SidePanelItemType, TeamContactType, RequirementType} from "../../../constants/constants";
import {pathCollection} from "../../../helpers/paths";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {setActiveProject, setActiveProjects} from "../../../redux/reducers/projects";

const GoTo = ({project}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const NavigateTo = {
        Team: pathCollection.teamPath[TeamContactType.Person],
        Timeline: pathCollection.timelinePath[SidePanelItemType.Event],
        Attachments: pathCollection.attachments,
        ProductionBooks: pathCollection.productionBooks,
        Requirements: pathCollection.requirementsPath[RequirementType.Item],
        Reports: pathCollection.reports,
    };

    const goTo = (project, path) => {
        dispatch(setActiveProject(project));
        dispatch(setActiveProjects([project]));
        history.push(urlWithPortfolioId(path, project.AccountId));
    };

    return (
        <Dropdown
            overlay={() => (
                <Menu selectable={false} style={{width: 250}} className="goto_menu">
                    <Menu.Item key="Team" onClick={() => goTo(project, NavigateTo.Team)}>
            <span type="link">
              <UserOutlined/> Team
            </span>
                    </Menu.Item>
                    <Menu.Item key="Timeline" onClick={() => goTo(project, NavigateTo.Timeline)}>
            <span type="link">
              <ClockCircleFilled/> Timeline
            </span>
                    </Menu.Item>
                    <Menu.Item key="Requirements" onClick={() => goTo(project, NavigateTo.Requirements)}>
            <span type="link">
              <BuildFilled/> Requirements
            </span>
                    </Menu.Item>
                    <Menu.Item key="Attachments" onClick={() => goTo(project, NavigateTo.Attachments)}>
            <span type="link">
              <PaperClipOutlined/> Attachments
            </span>
                    </Menu.Item>
                    <Menu.Item key="ProductionBooks" onClick={() => goTo(project, NavigateTo.ProductionBooks)}>
            <span type="link">
              <SendOutlined/> Production Books
            </span>
                    </Menu.Item>
                    <Menu.Item key="Reports" onClick={() => goTo(project, NavigateTo.Reports)}>
            <span type="link">
              <FileTextFilled/> Reports
            </span>
                    </Menu.Item>
                </Menu>
            )}>
            <Button type="link" size="small">
                Go To
                <DownOutlined/>
            </Button>
        </Dropdown>
    );
};
export default GoTo;
