import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {ApiContactType} from "../../../constants";
import {Modal} from "antd";

const deleteTeamMemberTitles = {
    [ApiContactType.Person]: "Are you sure you want to remove this person from",
    [ApiContactType.Organization]: "Are you sure you want to remove this organization from",
    [ApiContactType.Place]: "Are you sure you want to remove this location from",
};

const deleteTeamMemberDescriptions = {
    [ApiContactType.Person]: (
        <div>
            It will be removed from anywhere they are mentioned in this project. Its contact information will not be
            deleted
            <br/>
            <br/>
            You cannot undo this action.
        </div>
    ),
    [ApiContactType.Organization]: (
        <div>
            It will be removed from anywhere they are mentioned in this project. Its contact information will not be
            deleted
            <br/>
            <br/>
            You cannot undo this action.
        </div>
    ),
    [ApiContactType.Place]: (
        <div>
            It will be removed from anywhere they are mentioned in this project. All related spaces will be removed as
            well. Its contact
            information will not be deleted
            <br/>
            <br/>
            You cannot undo this action.
        </div>
    ),
};

const removeContact = (contact, projectName, onOk, onCancel) => {
    const deleteTeamMemberTitle = `${deleteTeamMemberTitles[contact.Type]} ${projectName}?`;
    const deleteTeamMemberDescription = deleteTeamMemberDescriptions[contact.Type];

    Modal.confirm({
        title: deleteTeamMemberTitle,
        icon: <DeleteOutlined/>,
        content: deleteTeamMemberDescription,
        className: "delete-contact-modal",
        okText: "Remove",
        okButtonProps: {danger: true},
        onOk,
        onCancel,
    });
};

export {removeContact};
