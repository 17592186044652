import {EnvironmentOutlined, UserOutlined, ShopOutlined} from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import {ApiContactType, ContactType} from "../../../constants/constants";
import {formatContactName} from "../../../helpers/text";
import "./ContactDetailsPanelTitle.scss";

const icons = {
    [ContactType.Person]: <UserOutlined/>,
    [ApiContactType.Person]: <UserOutlined/>,
    [ContactType.Organization]: <ShopOutlined/>,
    [ApiContactType.Organization]: <ShopOutlined/>,
    [ContactType.Location]: <EnvironmentOutlined/>,
    [ApiContactType.Place]: <EnvironmentOutlined/>,
};

const ContactDetailsPanelTitle = ({editedItem, type}) => {
    return (
        <>
            {editedItem.ImageUrl && (
                <Avatar className="detail_panels_avatar" shape="square" size={36} icon={icons[type]}
                        src={editedItem.ImageUrl}/>
            )}
            {!editedItem.ImageUrl && icons[type]}
            {formatContactName(editedItem, false) || " "}
        </>
    );
};

export default ContactDetailsPanelTitle;
