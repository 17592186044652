import {GroupOutlined} from "@ant-design/icons";
import React from "react";

const CollectionDetailsPanelTitle = ({editedItem}) => (
    <>
        <GroupOutlined/> {editedItem.Name} {editedItem.IsArchived && "(Archived)"}
    </>
);

export default CollectionDetailsPanelTitle;
