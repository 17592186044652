import React, {useState} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const GroupBy = Object.freeze({
    Roles: "Project Roles",
    Groups: "Project Groups",
    Organization: "Organization",
});

const items = [
    {
        value: GroupBy.Roles,
        title: GroupBy.Roles,
    },
    {
        value: GroupBy.Groups,
        title: GroupBy.Groups,
    },
];

const GroupByFilter = ({setGroupBy}) => {
    const [selectedItem, setSelectedItem] = useState();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
