import {Button, message} from "antd";
import React from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {useHistory} from "react-router";
import {EyeFilled} from "@ant-design/icons";

import {RequirementType} from "../../../../constants/constants";
import {pathCollection} from "../../../../helpers/paths";
import {startLoading, endLoading} from "../../../../redux/reducers/detailsPanel";
import {getRequirement} from "../../../../services/requirements";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";

const GoToRequirement = ({requirement}) => {
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const dispatch = useDispatch();

    const clickHandler = () => {

        history.push(urlWithPortfolioId(pathCollection.requirementsPath[RequirementType.Item]));

        setTimeout(() => {
            dispatch(startLoading({requirement: requirement, type: RequirementType.Item}));
            getRequirement(requirement.Id)
                .then((item) => dispatch(endLoading({item, type: RequirementType.Item})))
                .catch(() => message.error("Server error"));
        }, 200);
    };

    return (
        <>
            <div style={{float: "right"}}><EyeFilled onClick={clickHandler} className="info-icon"/></div>
        </>
    );
};

export default GoToRequirement;
