import {Row, Col, Checkbox} from "antd";
import React, {useState} from "react";

export const isFieldDisabled = (name, form) => form.getFieldValue(`${name}_disabled`) !== false;

const BulkEditableField = ({children, name, form}) => {
    const [isFieldDisabled, setFieldDisabled] = useState(true);
    form.setFieldsValue({[`${name}_disabled`]: isFieldDisabled});

    return (
        <Row gutter={24}>
            <Col span={2}>
                <div className="enable-form-item">
                    <Checkbox checked={!isFieldDisabled} onChange={(e) => setFieldDisabled(!e.target.checked)}/>
                </div>
            </Col>
            <Col span={22}>{children(name, isFieldDisabled)}</Col>
        </Row>
    );
};

export default BulkEditableField;
