import React, {useState} from "react";
import _ from "lodash";
import {Button, Modal, Form, DatePicker} from "antd";
import {FolderOpenFilled} from "@ant-design/icons";
import {SelectWithFormItem} from "../../Common/Input";
import {createColorTagRendered, getTags, mapTagToOption, mapTextToOption, getTagsPayload} from "../../../helpers/Tags";
import {useSelector} from "react-redux";
import {batchUpdate} from "../../../services/projects";
import {ApiProjectType, TagType} from "../../../constants/constants";
import "./BatchEdit.scss";
import {useEffect} from "react";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import BulkEditableField, {isFieldDisabled} from "../../Common/BulkEditableField/BulkEditableField";
import ColorPicker from "../ColorPicker/ColorPicker";
import TagsSelect from "../../Common/Selects/TagsSelect";

const projectStates = [
    {
        value: ApiProjectType.Active,
        label: "Active",
    },
    {
        value: ApiProjectType.Inactive,
        label: "Draft",
    },
    {
        value: ApiProjectType.Archive,
        label: "Archive",
    },
];

const Title = ({selected}) => (
    <>
        <FolderOpenFilled/> {`Bulk Edit ${selected.length} Projects`}
    </>
);

const getColor = (selected) => (_.every(selected, {Color: selected[0].Color}) ? selected[0].Color : null);

function getFormData(selected) {
    return {
        CategoryTags: getTags(selected, "CategoryTags"),
        Color: getColor(selected),
    };
}

const BulkEdit = ({selected, apiProjectType}) => {

    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const dateTimeFormats = useDateTimeFormat();
    const [[projectStartDate, projectEndDate], setProjectDates] = useState([]);

    const timeZones = useSelector((state) => state.infrastructure.timeZones).map(mapTextToOption);
    const categories = useSelector((state) => state.tags.categories).map(mapTagToOption);

    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible) form.setFieldsValue(getFormData(selected));
    }, [selected, isVisible]);

    function showModal() {
        setVisible(true);
    }

    function onOk() {
        const selectedProjectIds = selected.map((i) => i.Id);
        const formValue = form.getFieldsValue();
        setLoading(true);
        batchUpdate(
            selectedProjectIds,
            getTagsPayload(formValue.CategoryTags, !isFieldDisabled("CategoryTags", form)),
            {
                value: formValue.State,
                isUpdated: !isFieldDisabled("State", form),
            },
            {
                value: formValue.Color,
                isUpdated: !isFieldDisabled("Color", form),
            },
            {
                value: formValue.TimeZoneId,
                isUpdated: !isFieldDisabled("TimeZoneId", form),
            }
        ).finally(() => {
            setLoading(false);
            close();
        });
    }

    function onCancel() {
        close();
    }

    function close() {
        setVisible(false);
        form.resetFields();
    }

    return (
        <>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                <Button type="primary" onClick={showModal}>
                    Edit
                </Button>
                <Modal
                    confirmLoading={loading}
                    title={<Title selected={selected}/>}
                    visible={isVisible}
                    maskClosable={false}
                    onOk={onOk}
                    onCancel={onCancel}>
                    {isVisible && (
                        <>
                            <BulkEditableField name="Color" form={form}>
                                {(name, isFieldDisabled) => (
                                    <Form.Item label="Color" name={name}>
                                        <ColorPicker disabled={isFieldDisabled}></ColorPicker>
                                    </Form.Item>
                                )}
                            </BulkEditableField>

                            <BulkEditableField name="Date" form={form}>
                                {(name, isFieldDisabled) => (
                                    <Form.Item label="Date">
                                        <DatePicker.RangePicker
                                            format={dateTimeFormats.date}
                                            disabled={isFieldDisabled}
                                            onChange={(startEndDates) => setProjectDates(startEndDates)}
                                            value={[projectStartDate, projectEndDate]}></DatePicker.RangePicker>
                                    </Form.Item>
                                )}
                            </BulkEditableField>

                            <BulkEditableField name="CategoryTags" form={form}>
                                {(name, isFieldDisabled) => (
                                    <TagsSelect
                                        tagType={TagType.Category}
                                        disabled={isFieldDisabled}
                                        label="Categories"
                                        name={name}
                                        placeholder="Select categories"
                                        options={categories}
                                        form={form}
                                    />
                                )}
                            </BulkEditableField>
                            <BulkEditableField name="TimeZoneId" form={form}>
                                {(name, isFieldDisabled) => (
                                    <SelectWithFormItem
                                        disabled={isFieldDisabled}
                                        label="Time Zone"
                                        name={name}
                                        hasEnableCheckbox={true}
                                        showSearch={true}
                                        tagRender={createColorTagRendered("red", !isFieldDisabled)}
                                        style={{width: "100%"}}
                                        options={timeZones}
                                    />
                                )}
                            </BulkEditableField>
                            {apiProjectType !== ApiProjectType.Inactive && (
                                <BulkEditableField name="State" form={form}>
                                    {(name, isFieldDisabled) => (
                                        <SelectWithFormItem
                                            disabled={isFieldDisabled}
                                            label="State"
                                            name={name}
                                            style={{width: "100%"}}
                                            options={projectStates}
                                        />
                                    )}
                                </BulkEditableField>
                            )}
                            <BulkEditableField name="Date" form={form}>
                                {(name, isFieldDisabled) => (
                                    <Form.Item label="Date">
                                        <DatePicker.RangePicker
                                            format={dateTimeFormats.date}
                                            disabled={isFieldDisabled}
                                            onChange={(startEndDates) => setProjectDates(startEndDates)}
                                            value={[projectStartDate, projectEndDate]}></DatePicker.RangePicker>
                                    </Form.Item>
                                )}
                            </BulkEditableField>
                        </>
                    )}
                </Modal>
            </Form>
        </>
    );
};
export default BulkEdit;
