import React from "react";
import {useSelector} from "react-redux";
import {downloadTeamMembersAsCsv} from "../../../services/team";
import {TeamApiContactTypes} from "../../../constants/constants";

const Export = (props) => {
    const activeProject = useSelector((state) => state.projects.activeProject);
    const teamContactType = props.teamContactType;
    const selected = useSelector((store) => store.mainTable.selected);

    const onExport = () => {
        const types = [TeamApiContactTypes[teamContactType]];
        const teamMemberIds = selected.map((c) => c.Id);

        downloadTeamMembersAsCsv(activeProject, types, "", teamMemberIds);
    };

    return <div onClick={onExport}>Export</div>;
};

export default Export;