import React, {useEffect, useState} from "react";
import ViewEditList from "../ViewEditList";
import {swapSpaceContact} from "../../Modal/SwapContact";
import update from "immutability-helper";
import {CloseCircleOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {Button, Row, Col, Space, Select, Modal} from "antd";

const mapTeamMemberSpace = (teamMemberSpaces = []) =>
    teamMemberSpaces
        .map((teamMemberSpace) => ({
            id: teamMemberSpace.Id,
            contactId: teamMemberSpace.Contact.Id,
            contact: teamMemberSpace.Contact,
            value: teamMemberSpace.Contact.Company,
        }))
        .sort((a, b) => a.id - b.id);

const mapRowToSpace = (itemRows = []) =>
    itemRows.map((itemRow) => ({
        Id: itemRow.id,
        ContactId: itemRow.contactId,
        Contact: itemRow.contact,
    }));

const EditableRow = ({item = {}, onChange, remove, valuePlaceholder, project, spaces = {}, onCreateSpaceClick}) => {
    const [value, setValue] = useState(item.value);

    useEffect(() => {
        setValue(item.contactId);
    }, [item]);

    const onValueChange = (value) => {
        const targetSpace = spaces.find((s) => s.Id === value);

        const updateItem = () => {
            setValue(value);
            onChange({
                ...item,
                contactId: value,
                contact: targetSpace,
            });
        };

        if (item.id) {
            swapSpaceContact(targetSpace, item.contact, project.Name, updateItem);
        } else {
            updateItem();
        }
    };

    return (
        <Row align="middle">
            <Col span={22}>
                <Select
                    value={value}
                    onChange={onValueChange}
                    placeholder={valuePlaceholder}
                    options={spaces.map((space) => ({
                        value: space.Id,
                        label: space.Company,
                    }))}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <div className="btn-quick-create">
                                <Button type="dashed" onClick={onCreateSpaceClick}>
                                    + Create a Space
                                </Button>
                            </div>
                        </>
                    )}></Select>
            </Col>
            <Col span={2} className={{"btn-remove-row-holder": true}}>
                {item?.contactId && <CloseCircleOutlined className="btn-remove-row" onClick={remove}/>}
            </Col>
        </Row>
    );
};

const getUnusedSpaces = (allSpaces = [], usedSpaces = [], currentSpace) =>
    allSpaces.filter((s) => !usedSpaces.map((us) => us.contactId).includes(s.Id) || currentSpace?.contactId === s.Id);

const SpacesEditList = ({
                            items = [],
                            onChange,
                            onChanged,
                            onCancel,
                            addNewRowTex,
                            valuePlaceholder,
                            spaces,
                            onCreateSpaceClick,
                            project,
                        }) => {
    const [editableItems, setEditableItems] = useState(items);

    useEffect(() => {
        setEditableItems(items);
    }, [items]);

    function add() {
        setEditableItems([...editableItems, {}]);
    }

    function remove(index, item) {
        const deleteItem = () => {
            const items = editableItems.filter((e, i) => i !== index);
            setEditableItems(items);
        };

        if (!item.id) {
            deleteItem();
        } else {
            const deleteTeamMemberTitle = `Are you sure you want to remove this location from ${project.Name}?`;
            const deleteTeamMemberDescription = (
                <div>
                    Then will be removed from anywhere they are mentioned in this project. Their contact information
                    will not be deleted.
                    <br/>
                    <br/>
                    You cannot undo this action.
                </div>
            );

            Modal.confirm({
                title: deleteTeamMemberTitle,
                icon: <DeleteOutlined/>,
                content: deleteTeamMemberDescription,
                className: "delete-contact-modal",
                okText: "Remove",
                okButtonProps: {danger: true},
                onOk() {
                    deleteItem();
                },
                onCancel() {
                },
            });
        }
    }

    function onItemChange(index, item) {
        const changedItem = {...editableItems[index], ...item};

        let updatedItems = update(editableItems, {[index]: {$set: changedItem}});

        setEditableItems(updatedItems);
        //onChange(updatedItems);
    }

    function saveClick() {
        if (onChange) {
            onChange(editableItems.filter((i) => i.contactId));
        }
        if (onChanged) {
            onChanged();
        }
    }

    return (
        <div className="view-edit-list edit-mode">
            {editableItems.map((item, index) => (
                <EditableRow
                    key={index}
                    item={item}
                    spaces={getUnusedSpaces(spaces, editableItems, item)}
                    onCreateSpaceClick={onCreateSpaceClick}
                    valuePlaceholder={valuePlaceholder}
                    onChange={(changedItem) => onItemChange(index, changedItem)}
                    project={project}
                    remove={() => remove(index, item)}
                />
            ))}
            <Row>
                <Col span={24}>
                    <Button
                        block
                        type="dashed"
                        onClick={() => {
                            add();
                        }}
                        style={{width: "100%"}}>
                        {addNewRowTex}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="btn-actions-holder">
                    <Space>
                        <Button type="primary" size="small" onClick={saveClick}>
                            Save
                        </Button>
                        <Button size="small" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export const SpaceEmptyRow = ({switchToEditMode, emptyRowText, readOnly = false}) => (
    <Row className="view-row-item">
        <Col span={8} className="view-row-item-type">
            <div className="ant-form-item-without-validation">
                <span>{emptyRowText}</span>
            </div>
        </Col>

        <Col span={16}>
            <div className="ant-form-item-without-validation">
                <EditOutlined onClick={switchToEditMode} className="btn-edit-row"/>
            </div>
        </Col>
    </Row>
);

export const SpaceViewList = ({items, switchToEditMode, emptyRowText}) => (
    <div className="view-edit-list">
        {items.length === 0 && <SpaceEmptyRow switchToEditMode={switchToEditMode} emptyRowText={emptyRowText}/>}
        {items.map((item, index) => (
            <Row key={item.contactId} className="view-row-item">
                <Col span={24}>
                    <div className="ant-form-item-without-validation">
                        {item.value}
                        {<EditOutlined onClick={switchToEditMode} className="btn-edit-row"/>}
                    </div>
                </Col>
            </Row>
        ))}
    </div>
);

const withSpaces = (Component, spaces, onCreateSpaceClick, project) => (props) => (
    <Component spaces={spaces} onCreateSpaceClick={onCreateSpaceClick} project={project} {...props} />
);

const SpaceList = ({
                       value,
                       onChanged,
                       onChange,
                       readOnly = false,
                       spaces,
                       onCreateSpaceClick,
                       project,
                       onCancel,
                       contactId,
                   }) => (
    <ViewEditList
        contactId={contactId}
        items={mapTeamMemberSpace(value)}
        EditList={withSpaces(SpacesEditList, spaces, onCreateSpaceClick, project)}
        ViewList={SpaceViewList}
        onChanged={onChanged}
        onChange={(value) => onChange(mapRowToSpace(value))}
        readOnly={readOnly}
        emptyRowText=""
        addNewRowTex="Add a Space"
        valuePlaceholder="Select a space"
        onCancel={onCancel}
    />
);

export default SpaceList;
