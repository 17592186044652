import React, {useState} from "react";
import {mapValueToOption} from "../../../../helpers/Tags";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const GroupByOption = Object.freeze({
    Project: "Projects",
    Date: "Date",
    Location: "Location",
    Creator: "Creator",
});

const items = [
    mapValueToOption(GroupByOption.Project),
    // mapValueToOption(GroupByOption.Date),
    // mapValueToOption(GroupByOption.Location),
    // mapValueToOption(GroupByOption.Creator),
];

const GroupByFilter = ({setGroupBy, type}) => {
    const [selectedItem, setSelectedItem] = useState();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
