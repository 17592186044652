import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";

const noItem = {
    value: "noItem",
    title: "— No Position —",
    sortRank: 1,
};

export function getItems(data) {
    return _.chain(data)
        .filter((d) => d.LabourLine)
        .map((d) => d.LabourLine)
        .uniqBy((d) => d)
        .map((item) => ({value: item, title: item}))
        .value();
}

const PositionFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const items = useMemo(() => {
        return [noItem, ...getItems(data)];
    }, [data]);

    const itemsHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [itemsHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [itemsHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);
        setFilterHandler((item) => filterValues.includes(item.LabourLine) || (isNoItemSelected && !item.LabourLine));

        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Positions"
            onChanged={onChanged}
            search
        />
    );
};

export default PositionFilter;
