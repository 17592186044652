import {CheckOutlined, EyeOutlined, QuestionOutlined} from "@ant-design/icons";
import {Button, Col, List, message, Row} from "antd";
import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {ApiTimelineItemStatus, ApiTimelineItemType, SidePanelItemType} from "../../../../constants/constants";
import {formatAmount} from "../../../../helpers/money";
import {pathCollection} from "../../../../helpers/paths";
import {formatTeamMemberName} from "../../../../helpers/text";
import {eventDateRange} from "../../../../helpers/time";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {openEdit} from "../../../../redux/reducers/detailsPanel";
import {getEvent} from "../../../../services/timeline";
import {sortBookings} from "../../../Timeline/CrewManagement";
import {getCostForBookingData} from "../../Common/CostDataCalculation";

const TimelineItemStatusIcon = {
    [ApiTimelineItemStatus.None]: "",
    [ApiTimelineItemStatus.Tentative]: <QuestionOutlined/>,
    [ApiTimelineItemStatus.Confirmed]: <CheckOutlined/>,
};

const getCost = (labourLine, booking) => {
    const {totalCost} = getCostForBookingData(labourLine, booking);
    return formatAmount(totalCost || 0);
};

const CrewBookingsBlock = ({itemToEdit}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const formats = useDateTimeFormat();

    const goToBooking = (booking) => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Booking]));
        getEvent(booking.Id, itemToEdit.Task.Project.Id, ApiTimelineItemType.Booking)
            .then((item) => dispatch(openEdit({item, type: SidePanelItemType.Booking})))
            .catch(() => message.error("Server error"));
    };
    return (
        <List
            dataSource={sortBookings(itemToEdit.WorkCallBookings)}
            size="small"
            className="table-in-panel"
            header={
                <Row>
                    <Col span={18}>Name</Col>
                    <Col span={6} className="cost-header">
                        Cost
                    </Col>
                </Row>
            }
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col span={1} className="booking-status-icon">
                            {TimelineItemStatusIcon[item.Status]}
                        </Col>
                        <Col span={18}>
                            {item.TeamMember && formatTeamMemberName(item.TeamMember)}
                            {!item.TeamMember && "(unassigned)"}
                            {!item.SyncTimeFrame &&
                                item.StartDateTime &&
                                item.EndDateTime &&
                                ` (${eventDateRange(item.StartDateTime, item.EndDateTime, formats, item.IsTimeOnly, item.IsAllDay)})`}
                        </Col>
                        <Col span={3}>{getCost(itemToEdit, item)}</Col>
                        <Col span={1} offset={1} className="">
                            <div className="goto-event goto-event-labour ">
                                <Button type="link" size="small" onClick={() => goToBooking(item)}>
                                    <EyeOutlined/>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    );
};

export default CrewBookingsBlock;
