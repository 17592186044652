import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {Space} from "antd";

import GroupedTables from "../../Common/Tables/GroupedTable";
import {ItemGroupBy} from "./FilterBar/GroupBy";
import {getResourcesSummary} from "./ItemsTableFooter";
import {formatLocationName} from "../../../helpers/text";
import {ApiContactType} from "../../../constants/constants";

function getAllSources(data, allContacts) {
    const flattener = (d) => d?.Sources || [];

    const mapper = (s) => ({
        Contact: allContacts.find((c) => c.Id === s.ContactId),
        Source: s,
    });

    const grouper = (d) => d.Contact?.Id;

    const byTypeSorter = (source) => {
        const contact = source.contact;

        switch (contact?.Type) {
            case ApiContactType.Organization:
                return 1;
            case ApiContactType.Place:
            case ApiContactType.Space:
                return 2;
            case ApiContactType.Person:
                return 3;
            default:
                return 4;
        }
    };

    const byNameSorter = (source) => {
        const contact = source.contact;

        switch (contact?.Type) {
            case ApiContactType.Organization:
                return contact?.Company;
            case ApiContactType.Space:
            case ApiContactType.Place:
                return formatLocationName(contact);
            case ApiContactType.Person:
                return `${contact?.LastName || contact?.FirstName || contact?.Nickname || ""}`;
            default:
                return source?.Name;
        }
    };

    const grouped = _.chain(data).flatMap(flattener).map(mapper).groupBy(grouper).value();

    const groups = _.keys(grouped).map((id) => {
        const contactId = id && parseInt(id, 10);
        const items = data.filter((m) => m?.Sources.find((s) => s.ContactId === contactId));
        const group = grouped[id];

        return {
            id: contactId,
            items: _.sortBy(items, (m) => m.Name),
            contact: group[0].Contact,
            sources: group.map((g) => g.Source),
        };
    });

    const sorted = _.sortBy(groups, [byTypeSorter, byNameSorter]);

    return sorted;
}

function getAllDepartments(data) {
    return _.chain(data)
        .filter((d) => d.DepartmentTag)
        .map((d) => d.DepartmentTag)
        .uniqBy((d) => d.AccountTagId)
        .sortBy((x) => x.Name)
        .value();
}

function getGroups(groupBy, dataSource, allContacts) {
    let groups = [];
    let noGroupItems = [];
    let noGroupTitle = "";
    let noGroupText = null;

    const dataSourceSorted = _.sortBy(dataSource, (d) => d.Name);

    const TextControl = ({items}) => {
        const {totalQty} = getResourcesSummary(items);
        return (
            <>
                <Space direction="horizontal" size="large">
                    <span className="col-50px">{totalQty}</span>
                </Space>
            </>
        );
    };

    switch (groupBy) {
        case ItemGroupBy.Source:
            const sources = getAllSources(dataSourceSorted, allContacts);
            groups = sources
                .filter((group) => group?.sources.length > 0)
                .map((group) => {
                    const {contact, items} = group;
                    const name = formatLocationName(contact);

                    const transformed = items.map((m) => ({
                        ...m,
                        Sources: m.Sources.map((s) => ({
                            ...s,
                            Quantity: s.ContactId === contact.Id ? s.Quantity : null,
                        })),
                    }));

                    return {
                        group: name,
                        level: 200,
                        items: transformed,
                        rightText: <TextControl items={transformed}/>,
                        rightTextClass: "header-totals-text",
                    };
                });

            noGroupItems = dataSourceSorted.filter((item) => !(item?.Sources?.length > 0));
            noGroupTitle = "No Sources";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;

        case ItemGroupBy.Department:
            const departments = getAllDepartments(dataSourceSorted);

            groups = departments.map((group) => {
                const departmentItems = dataSourceSorted.filter((item) => item.DepartmentTag?.AccountTagId === group.AccountTagId);
                return {
                    group: group.Name,
                    level: 200,
                    items: departmentItems,
                    rightText: <TextControl items={departmentItems}/>,
                    rightTextClass: "header-totals-text",
                };
            });

            noGroupItems = dataSourceSorted.filter((item) => !item.DepartmentTag);
            noGroupTitle = "No Department";
            noGroupText = <TextControl items={noGroupItems}/>;

            break;
        default:
            throw new Error(`${groupBy} is not implemented`);
    }

    if (noGroupItems.length !== 0) {
        groups.push({
            group: noGroupTitle,
            level: 200,
            items: noGroupItems,
            rightText: noGroupText,
            rightTextClass: "header-totals-text",
        });
    }

    groups.forEach((group) => {
        group.keys = group.items.map((i) => i.Id);
    });
    return groups;
}

const GroupItemsTable = ({columns, dataSource, onRow, groupByFilter, loading, footer}) => {
    const allContacts = useSelector((state) => state.contacts.contacts);

    return (
        <GroupedTables
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            onRow={onRow}
            footer={footer}
            getGroups={() => getGroups(groupByFilter.value, dataSource, allContacts)}
        />
    );
};

export default GroupItemsTable;
