import React, {useEffect} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import TreeSelectFilter from "./TreeSelectFilter";

export const selectDefaultOption = {value: "— All Groups —", title: "— All Groups —"};

const AllGroupsKey = "all_groups";
const NoGroupsKey = "no_groups";

const parseSelectedValue = (value) => {
    const parts = value.split(":");
    const projectId = parseInt(parts[0], 10);
    const tag = parts[1];

    return {
        projectId,
        tag,
    };
};

const isItemFiltered = (item, parsedValues, allTags) => {
    const projectValues = parsedValues.filter((v) => item.ProjectId === v.projectId);
    if (projectValues.length === 0) {
        return false;
    }

    const hasAllGroups = projectValues.find((v) => v.tag === AllGroupsKey);
    if (hasAllGroups) {
        return true;
    }

    const hasNoGroups = projectValues.find((v) => v.tag === NoGroupsKey);
    if (hasNoGroups && item.GroupTags.length === 0) {
        return true;
    }

    return (
        _.intersection(
            item.GroupTags,
            projectValues.map((v) => allTags.find((t) => t.Id === parseInt(v.tag, 10))?.Name)
        ).length > 0
    );
};

const Groups = ({value, projectIds, scheduleProjects = [], setFilterHandler, onChanged, onChange, disabled}) => {
    const portfolioProjects = useSelector((state) => state.projects.portfolioProjects);
    const projects = useSelector((state) => state.projects.projects);
    const getProject = (projectId) => [...portfolioProjects, ...projects, ...scheduleProjects].find((p) => p.Id === projectId);

    const getProjectName = (projectId) => getProject(projectId)?.Name;
    const getProjectShortName = (projectId) => {
        const project = getProject(projectId);
        return project?.ShortName || project?.Name;
    };

    const projectGroups = useSelector((state) =>
        _.chain(projectIds)
            .map((projectId) => ({
                title: getProjectName(projectId),
                value: `${projectId}:${AllGroupsKey}`,
                inputLabel: `All Groups (${getProjectShortName(projectId)})`,
                key: `${projectId}:${AllGroupsKey}`,
                filterOff: true,
                children: [
                    ...(state.tags.projectGroupsExtended[projectId] || []).map((r) => ({
                        inputLabel: `${r.Name} (${getProjectShortName(projectId)})`,
                        value: `${projectId}:${r.ProjectTagId}`,
                        title: r.Name,
                    })),
                    {
                        inputLabel: `— No Group — (${getProjectShortName(projectId)})`,
                        value: `${projectId}:${NoGroupsKey}`,
                        title: "— No Group —",
                    },
                ],
            }))
            .filter((i) => i.children.length > 0)
            .value()
    );

    const allTags = useSelector((state) =>
        _.chain(projectIds)
            .map((projectId) => state.tags.projectGroupsExtended[projectId] || [])
            .flatten()
            .value()
    );

    const initFilterHandler = (filterValues) => {
        const parsedValues = filterValues.map(parseSelectedValue);

        if (filterValues.length === 0) {
            setFilterHandler(null);
        } else {
            setFilterHandler((item) => isItemFiltered(item, parsedValues, allTags));
        }
    };

    function onSelectionChanged(filterValues) {
        initFilterHandler(filterValues);
        const updated = filterValues.map((fv) => {
            const [projectId, tagId] = fv.split(":");
            return {
                ProjectId: parseInt(projectId, 10),
                ProjectTagId: tagId === NoGroupsKey ? null : parseInt(tagId, 10),
            };
        });
        onChange(updated);
        onChanged();
    }

    const selected = (value || []).map((v) => ({
        value: `${v.ProjectId}:${
            v.ProjectTagId !== undefined
                ? v.ProjectTagId === 0
                    ? NoGroupsKey
                    : v.ProjectTagId
                : v.ProjectTagId == null
                    ? NoGroupsKey
                    : v.ProjectTagId
        }`,
    }));

    useEffect(() => {
        if (value?.length > 0) {
            initFilterHandler(selected.map((s) => s.value));
        }
    }, [value]);

    return (
        <TreeSelectFilter
            treeDefaultExpandedKeys={projectGroups.map((g) => g.value)}
            value={selected}
            disabled={disabled}
            treeNodeLabelProp="inputLabel"
            treeData={projectGroups}
            placeholder="All Groups"
            selectDefaultOption={selectDefaultOption}
            onChanged={onSelectionChanged}
            onSelectAllChange={() => {
            }}
            onResetCompleted={() => {
            }}></TreeSelectFilter>
    );
};

export default Groups;
