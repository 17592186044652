import DOMPurify from 'dompurify';

export function sanitizeHTML(data) {
    if (!data) {
        return '';
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const links = doc.getElementsByTagName('a');

    for (let i = 0; i < links.length; i++) {
        links[i].setAttribute('target', '_blank');
    }

    const modifiedHTML = doc.body.innerHTML;
    const config = {ADD_ATTR: ['target']};
    const sanitizedHTML = DOMPurify.sanitize(modifiedHTML, config) || '';

    return `<div class="html-content">${sanitizedHTML}</div>`;
}