import React, {useState, useEffect} from "react";
import {ApiTimelineItemStatus} from "../../../../constants/constants";
import MultipleFilterWithSearchFilter from "../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {QuestionOutlined, CheckOutlined, ExclamationCircleFilled} from "@ant-design/icons";

const ConflictKey = "Conflicts";

export const items = [
    {
        title: "None",
        value: ApiTimelineItemStatus.None,
        sortRank: 1,
    },
    {
        icon: <QuestionOutlined/>,
        title: "Tentative",
        value: ApiTimelineItemStatus.Tentative,
        sortRank: 2,
    },
    {
        icon: <CheckOutlined/>,
        title: "Confirmed",
        value: ApiTimelineItemStatus.Confirmed,
        sortRank: 3,
    },
    {
        icon: <ExclamationCircleFilled className="warning-icon"/>,
        title: "Conflicts",
        value: ConflictKey,
        sortRank: 4,
    },
];

const BookingStatusFilter = ({needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const reset = () => setSelectedItems(items);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter]);

    useEffect(() => {
        onChanged(items);
    }, []);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);

        setFilterHandler((item) => {
            const byStatus = filterValues.includes(item.BookingStatus);
            if (byStatus) return true;

            if (filterValues.includes(ConflictKey)) return item.LocationConflicts?.length > 0 || item.BookingConflicts?.length > 0;

            return false;
        });
        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Statuses"
            onChanged={onChanged}
        />
    );
};

export default BookingStatusFilter;
