import React, {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import _ from "lodash";
import {Form, Input, InputNumber, Select, Tooltip} from "antd";
import "./Input.scss";
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';

const getClassName = (rules, className = "") => [rules && rules.length ? "" : "ant-form-item-without-validation", className].filter((cn) => cn).join(" ");

export const selectFilterOption = (input, option) => ((_.isString(option.label) ? option.label : option.label?.props?.children[0]) || "")
    .toString()
    .toLowerCase()
    .indexOf(input?.toLowerCase()) >= 0;

const modules = {
    toolbar: {
        container: [['bold', 'italic', 'underline'], [{'list': 'ordered'}, {'list': 'bullet'}], [{color: []}], ['link'], ['clean'], //['emoji']
        ],
    }, 'emoji-toolbar': false, 'emoji-textarea': false, 'emoji-shortname': false,
};

export function withFormItem(Component) {
    return function WithFormItem(props) {
        const {
            name, label, rules, hasFeedback, validateStatus, onChanged, forwardedRef, formItemProps, ...passThroughProps
        } = props;
        return (<Form.Item
            name={name}
            label={label}
            rules={rules}
            className={getClassName(rules)}
            hasFeedback={hasFeedback}
            validateStatus={validateStatus}
            {...formItemProps}>
            <Component onPressEnter={onChanged} onBlur={onChanged} ref={forwardedRef} {...passThroughProps} />
        </Form.Item>);
    };
}

export function withFormItemTextArea() {
    return function WithFormItem(props) {
        const {
            name,
            label,
            rules,
            hasFeedback,
            validateStatus,
            onChanged,
            forwardedRef,
            formItemProps,
            rows,
            disabled,
            useTextEditor,
            ...passThroughProps
        } = props;

        const quillRef = useRef(null);

        useEffect(() => {

            if (!useTextEditor) {
                return
            }

            const closeButton = document.querySelector('.ant-drawer-close');
            if (closeButton) {
                const handleCloseButtonClicked = (event) => {
                    if (quillRef.current) {
                        const quillInstance = quillRef.current.getEditor();
                        quillInstance.blur();
                    }
                };

                closeButton.addEventListener('click', handleCloseButtonClicked);

                return () => {
                    closeButton.removeEventListener('click', handleCloseButtonClicked);
                };
            }
        }, []);

        return (<Form.Item
            name={name}
            label={label}
            rules={rules}
            className={getClassName(rules)}
            hasFeedback={hasFeedback}
            validateStatus={validateStatus}
            {...formItemProps}
        >
            {useTextEditor ? (<ReactQuill
                className={`quill-text-editor ${disabled ? 'quill-disabled' : ''}`}
                onBlur={(value, source) => {
                    if (source !== 'silent' && typeof onChanged === 'function') {
                        onChanged(value);
                    }
                }}
                ref={quillRef}
                readOnly={disabled}
                modules={modules}
            />) : (<Input.TextArea
                rows={rows}
                onBlur={onChanged}
                ref={forwardedRef}
                disabled={disabled}
                {...passThroughProps}
            />)}
        </Form.Item>);
    };
}

export function withFormItemSelect() {
    return function WithFormItemSelect(props) {
        const {
            hasEnableCheckbox,
            name,
            label,
            rules,
            hasFeedback,
            validateStatus,
            onChanged,
            isOpen,
            dropdownRender,
            forwardedRef,
            getValueProps,
            className,
            closeOnChange,
            filterOption = selectFilterOption,
            formItemProps,
            onClose,
            ...passThroughProps
        } = props;

        const [open, setOpen] = useState(false);
        const onBlur = () => setTimeout(() => {
            setOpen(false);
            if (onClose) onClose();
        }, dropdownRender ? 350 : 0);
        const handleChange = (value, option) => {
            if (onChanged) onChanged(value, option);
            if (closeOnChange) onBlur();
        };

        return (<Form.Item
            name={name}
            label={label}
            rules={rules}
            getValueProps={getValueProps}
            className={getClassName(rules, className)}
            hasFeedback={hasFeedback}
            validateStatus={validateStatus}
            {...formItemProps}>
            <Select
                ref={forwardedRef}
                onChange={handleChange}
                {...passThroughProps}
                open={open}
                dropdownRender={dropdownRender}
                onBlur={onBlur}
                onClick={() => (["tags", "multiple"].includes(passThroughProps.mode) ? setOpen(true) : setOpen(!open))}
                filterOption={filterOption}
            />
        </Form.Item>);
    };
}

export const TooltipWithOrNot = ({showTooltip = true, children, ...props}) => showTooltip ?
    <Tooltip {...props}>{children}</Tooltip> : {...children};

export const InputWithFormItem = withFormItem(Input);
export const InputNumberWithFormItem = withFormItem(InputNumber);

export const TextAreaWithFormItem = withFormItemTextArea();
export const SelectWithFormItem = withFormItemSelect();

export const InputWithFormItemWithRef = React.forwardRef(function InputWithFormItemWithRef(props, ref) {
    return <InputWithFormItem {...props} forwardedRef={ref}/>;
});

export const SelectWithFormItemRef = React.forwardRef(function SelectWithFormItemRef(props, ref) {
    return <SelectWithFormItem {...props} forwardedRef={ref}/>;
});
