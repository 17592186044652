import React from "react";
import {Form} from "antd";
import {InputWithFormItem, InputWithFormItemWithRef, TextAreaWithFormItem} from "../../Common/Input";
import EmailList from "../../Common/ViewEditList/EmailList/EmailList";
import PhoneList from "../../Common/ViewEditList/PhoneList/PhoneList";
import {TagType} from "../../../constants/constants";
import TagsSelect from "../../Common/Selects/TagsSelect";

const OrganizationInputs = ({
                                save,
                                firstInputRef,
                                nextInputRef,
                                isOptionalFieldDisabled,
                                placeholderForFirstName,
                                roles,
                                groups,
                                contactToEdit,
                                isReadOnly,
                                form,
                            }) => {
    return (
        <>
            <InputWithFormItemWithRef
                ref={firstInputRef}
                name="Company"
                label="Name"
                hasFeedback
                rules={[{required: true, message: "Name"}]}
                onChanged={save}
                disabled={isReadOnly}
                placeholder={placeholderForFirstName}
            />
            <InputWithFormItemWithRef
                name="AlsoKnownAs"
                label="Short Name"
                ref={nextInputRef}
                onChanged={save}
                disabled={isOptionalFieldDisabled}
                placeholder="Enter short name"
            />

            <TagsSelect
                tagType={TagType.Role}
                disabled={isOptionalFieldDisabled}
                label="Default Roles"
                name="Specialties"
                placeholder="Select default project roles"
                options={roles}
                save={save}
                form={form}
            />
            <TagsSelect
                tagType={TagType.Group}
                disabled={isOptionalFieldDisabled}
                label="Default Groups"
                name="Groups"
                placeholder="Select default project groups"
                options={groups}
                save={save}
                form={form}
            />
            <Form.Item name="EmailAddresses" wrapperCol={24} className="ant-form-item-without-validation">
                <EmailList onChanged={save} contactId={contactToEdit?.Id}></EmailList>
            </Form.Item>
            <Form.Item name="PhoneNumbers" wrapperCol={24} className="ant-form-item-without-validation">
                <PhoneList onChanged={save} contactId={contactToEdit?.Id}></PhoneList>
            </Form.Item>
            <Form.List name="Websites">
                {(fields) => {
                    return fields.map((field, i) => (
                        <InputWithFormItem
                            key={i}
                            name={[field.name, "Url"]}
                            label="Website"
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                            placeholder="Enter a website"
                        />
                    ));
                }}
            </Form.List>
            <Form.List name="Addresses">
                {(fields) => {
                    return fields.map((field, i) => (
                        <TextAreaWithFormItem
                            key={i}
                            name={[field.name, "Line1"]}
                            label="Address"
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                            placeholder="Enter an address"
                            autoSize={{minRows: 2}}
                        />
                    ));
                }}
            </Form.List>
            <TextAreaWithFormItem
                label="Internal Details"
                name="Notes"
                onChanged={save}
                disabled={isOptionalFieldDisabled}
                autoSize={{minRows: 2}}
                placeholder="Enter details"></TextAreaWithFormItem>
        </>
    );
};
export default OrganizationInputs;
