import React, {useState} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";
import {SidePanelItemType} from "../../../../constants/constants";
import {useSelector} from "react-redux";

export const TimelineGroupBy = Object.freeze({
    Project: "Project",
    Date: "Date",
    Status: "Status",
    Location: "Location",
    Team: "Team",
    Category: "Category",
    Department: "Department",
    Position: "Position",
    Contact: "Contact",
    Event: "Event",
});

const defaultItems = {
    project: {
        value: TimelineGroupBy.Project,
        title: TimelineGroupBy.Project,
    },
    date: {
        value: TimelineGroupBy.Date,
        title: TimelineGroupBy.Date,
    },
    status: {
        value: TimelineGroupBy.Status,
        title: TimelineGroupBy.Status,
    },
    location: {
        value: TimelineGroupBy.Location,
        title: TimelineGroupBy.Location,
    },
    team: {
        value: TimelineGroupBy.Team,
        title: TimelineGroupBy.Team,
    },
    contact: {
        value: TimelineGroupBy.Contact,
        title: TimelineGroupBy.Contact,
    },
    category: {
        value: TimelineGroupBy.Category,
        title: TimelineGroupBy.Category,
    },
    department: {
        value: TimelineGroupBy.Department,
        title: TimelineGroupBy.Department,
    },
    position: {
        value: TimelineGroupBy.Position,
        title: TimelineGroupBy.Position,
    },
    event: {
        value: TimelineGroupBy.Event,
        title: TimelineGroupBy.Event,
    },
};

const GroupByFilter = ({setGroupBy, type}) => {
    const [selectedItem, setSelectedItem] = useState();
    const isSingleProject = useSelector((state) => state.projects.activeProjects).length === 1;

    const contactOrTeamColumn = isSingleProject ? defaultItems.contact : defaultItems.team;
    const items = {
        [SidePanelItemType.Event]: [
            defaultItems.project,
            defaultItems.date,
            defaultItems.status,
            defaultItems.location,
            contactOrTeamColumn,
            defaultItems.category,
            defaultItems.department,
        ],
        [SidePanelItemType.Booking]: [
            defaultItems.project,
            defaultItems.event,
            defaultItems.date,
            defaultItems.status,
            contactOrTeamColumn,
            defaultItems.position,
        ],
    };

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items[type]}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
